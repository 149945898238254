import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { basicReducer } from "./basicCalls";
import { centerProfileReducer } from "./profile";
import { chatReducer } from "./chat";

const appReducer = combineReducers({
  auth: authReducer,
  basicCalls: basicReducer,
  centerProfile: centerProfileReducer,
  chat: chatReducer,
});

export default appReducer;
