import { useStyles } from "./muiStyling";

interface IProps {
  text: string;
  customStyle?: string;
  buttonClick: () => void;
}

export function CustomizedButtons({ text, buttonClick, customStyle }: IProps) {
  const classes = useStyles();

  return (
    <button
      id="round-button"
      className={`${classes.styleButton} ${customStyle}`}
      onClick={buttonClick}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
}
