import { Button } from "@material-ui/core";
import React from "react";
import { INote } from "../../store/types/note";
import { NotePatchDTO } from "../../store/types/type";
import CircleSpinner from "../CircleSpinner";

export default function Note({
  note,
  saveNote,
  errorWhileSavingNote,
  savingNote,
}: {
  note?: INote;
  saveNote: (updateNoteDto: NotePatchDTO) => Promise<void>;
  errorWhileSavingNote: string;
  savingNote: boolean;
}) {
  React.useEffect(() => {
    setNoteText(note?.text || "");
  }, [note]);
  const [noteText, setNoteText] = React.useState("");
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        padding: "5px",
      }}
      className={"noteWrap"}
    >
      <label>Take notes for this chat</label>
      <textarea
        style={{
          background: "#cfc",
          margin: "0px",
          width: "100%",
          height: "30vh",
          resize: "none",
          overflow: "auto",
          border: "none",
        }}
        value={noteText}
        onChange={(e) => setNoteText(e.target.value)}
      />
      {savingNote ? (
        <CircleSpinner width="50px" height="50px" />
      ) : (
        <Button onClick={() => (note ? saveNote({ noteID: note._id, text: noteText }) : () => {})}>
          Save Note
        </Button>
      )}
      {errorWhileSavingNote || null}
    </div>
  );
}
