import React from "react";
import { Button } from "@material-ui/core";
import { createStyles, makeStyles, Theme, ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../ColorPalete";

export const buttonStyles = makeStyles((theme: Theme) =>
  createStyles({
    primaryButton: {
      color: "#4caf50",
      border: "1px solid rgb(1 99 5 / 50%)",
      fontWeight: "bolder",
      margin: "0",
      "&:hover": {
        boxShadow: "none",
        borderColor: "#ffffff",
        backgroundColor: "#4caf50",
        color: "white",
      },
    },
  })
);

export const PrimaryButton = (props: { onClick: any; title: string; disable?: boolean }) => {
  const classes = buttonStyles();
  return (
    <>
      <ThemeProvider theme={theme}>
        <Button
          className={classes.primaryButton}
          onClick={() => props.onClick()}
          color="primary"
          type="submit"
          variant="outlined"
          disabled={props.disable}
        >
          {props.title}
        </Button>
      </ThemeProvider>
    </>
  );
};
