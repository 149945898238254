import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

interface Iprops {
  openCrisisPopup: boolean;
  setOpenCrisisPopup: any;
  sendCrisisPopup: () => void;
}

export const ProfessionalCrisisPopup = ({
  openCrisisPopup,
  setOpenCrisisPopup,
  sendCrisisPopup,
}: Iprops) => {
  const helpUrl = "https://www.deloresdigital.com/help.html";
  const isUser = localStorage.getItem("current") === "PATIENT";

  return (
    <Dialog
      open={openCrisisPopup}
      onClose={() => setOpenCrisisPopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {!isUser ? (
        <DialogTitle id="alert-dialog-title">
          {"Do you wish to  send this user the below crisis escalation message?"}
        </DialogTitle>
      ) : null}

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          In our professional opinion, the situation as described may require additional resources
          beyond those we con provide here. If you are in a crisis or any other person may be in
          danger{" "}
          <a style={{ color: "red" }} href={helpUrl}>
            These resources{" "}
          </a>
          can provide you with immediate help.
          <br />
          If you believe your situation is not a crisis, please convey this to your professional
          through this chat.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <>
          {!isUser ? (
            <Button
              variant="contained"
              onClick={() => {
                sendCrisisPopup();
                setOpenCrisisPopup(false);
              }}
              color="primary"
            >
              Send Message
            </Button>
          ) : null}
        </>
        <Button variant="contained" onClick={() => setOpenCrisisPopup(false)} color="primary">
          {isUser ? "Close" : "Cancel Message"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
