import { INote } from "../types/note";
import * as types from "./types";

export const gettingMyNotes = () => ({
  type: types.gettingMyNotes,
});
export const getMyNotesSuccess = (notes: INote[]) => ({
  type: types.getMyNotesSuccess,
  payload: notes,
});

export const getMyNoteFailure = (errorMessage: string) => ({
  type: types.getMyNotesFailure,
  payload: errorMessage,
});

export const updatingNote = () => ({
  type: types.updatingNote,
});
export const updateNoteSuccess = (notes: INote[]) => ({
  type: types.updateNoteSuccess,
  payload: notes,
});
export const updateNoteFailure = (errorMessage: string) => ({
  type: types.updateNoteFailure,
  payload: errorMessage,
});

export const creatingNote = () => ({
  type: types.creatingNote,
});
export const createNoteSuccess = (notes: INote[]) => ({
  type: types.createNoteSuccess,
  payload: notes,
});
export const createNoteFailure = (errorMessage: string) => ({
  type: types.createNoteFailure,
  payload: errorMessage,
});
