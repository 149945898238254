// import { Menu, MenuItem } from "@material-ui/core";
import React from "react";
import { IPhysician, USER_TYPE } from "../../../store/types";
import Navbar from "./Navbar";

type Anchor = "top" | "left" | "bottom" | "right";

export const NotificationMenu = (props: {
  anchorEl: any;
  //   menuId: any;
  isMenuOpen: any;
  handleMenuClose: any;
  type: USER_TYPE;
  patientProfile: any;
  getPatientProfile: () => Promise<void>;
  setNotifications: any;
  fetchPhysicians: IPhysician[];
  getPhysicians: () => Promise<void>;
}) => {
  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent
  ) => {
    if (
      event &&
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [state, setState] = React.useState({
    right: false,
  });
  return (
    <>
      {/* <Menu */}
      {/* anchorEl={props.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        // id={props.menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.isMenuOpen}
        onClose={props.handleMenuClose}
      > */}
      {/* <MenuItem onClick={props.handleMenuClose}> */}
      <Navbar
        // onClick={toggleDrawer('right', true)}
        handleMenuClose={props.handleMenuClose}
        state={props.anchorEl}
        setState={setState}
        isOpen={toggleDrawer("right", true)}
        toggleDrawer={toggleDrawer}
        type={props.type}
        patientProfile={props.patientProfile}
        getPatientProfile={props.getPatientProfile}
        setNotifications={props.setNotifications}
        fetchPhysicians={props.fetchPhysicians}
        getPhysicians={props.getPhysicians}
      />
      {/* </MenuItem> */}

      {/* </Menu> */}
    </>
  );
};
