import axios from "axios";
import { NewNoteDTO, NotePatchDTO } from "../types/type";
import * as actions from "./actions";

export const getMyNotes = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.gettingMyNotes());
  try {
    const response = await axios.get(`/notes/my-notes`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          `${localStorage.getItem("current")?.toLowerCase()}Token`
        )}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.getMyNotesSuccess(response.data));
    } else throw new Error("Unable to get your notes");
  } catch (err) {
    // @ts-ignore
    dispatch(actions.getMyNoteFailure(err.message));
  }
};

export const createNote = (noteCreateddto: NewNoteDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.creatingNote());
  try {
    const response = await axios.post(`/notes`, noteCreateddto, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 200) dispatch(actions.createNoteSuccess(response.data));
    else throw new Error("Unable to create new note");
  } catch (err) {
    // @ts-ignore
    dispatch(actions.createNoteFailure(err.message));
  }
};

export const updateNote = (noteUpdatedto: NotePatchDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updatingNote());
  try {
    const response = await axios.patch(`/notes/update-note`, noteUpdatedto, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(
          `${localStorage.getItem("current")?.toLowerCase()}Token`
        )}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.updateNoteSuccess(response.data));
    } else throw new Error("Unable to update note");
  } catch (err) {
    // @ts-ignore
    dispatch(actions.updateNoteFailure(err.message));
  }
};
