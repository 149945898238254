import { Box, Button, Card, Paper, Select, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import React from "react";
import { USER_TYPE } from "../../../../../store/types";
import { IPatient } from "../../../../../store/types/patient";
import { IPhysician } from "../../../../../store/types/physician";

interface Props {
  message: string;
  patient: IPatient;
  physicians: IPhysician[];
  requestID: string;
  type: string;
  getProfile: () => Promise<void>;
}
export default function SingleRequest(props: Props) {
  const assignPhysician = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res = await Axios.post(
        `/patient-request/accept-request`,
        {
          isPrivate: false,
          physicianID: selectedPhysician,
          requestID: props.requestID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              localStorage.getItem("current") === USER_TYPE.CENTER
                ? "centerToken"
                : "physicianToken"
            )}`,
          },
        }
      );
      props.getProfile();
      setErrorAssigningOrAssigningDone("Done");
    } catch (err: any) {
      setErrorAssigningOrAssigningDone(err.message || "An unexpected error occurred");
    }
  };
  const [errorAssigningOrAssigningDone, setErrorAssigningOrAssigningDone] = React.useState("");
  const [raised, setRaised] = React.useState(false);
  const [selectedPhysician, setSelectedPhysician] = React.useState(
    props.physicians[0]?._id as string
  );

  return (
    <Card
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseLeave={() => setRaised(false)}
      style={{ textAlign: "center", border: "3px solid purple" }}
    >
      <Paper variant="outlined" elevation={2}>
        <Box flexDirection="column">
          <h4>
            Name:
            <span> {`${props.patient?.firstName} ${props.patient?.lastName}`}</span>
          </h4>
          <h4>
            Email:<span> {`${props.patient?.email}`}</span>
          </h4>
        </Box>
      </Paper>
      <Typography>{props.message}</Typography>
      {props.type === USER_TYPE.CENTER ? (
        <Select
          native
          defaultValue={props.physicians[0]?._id}
          onChange={(e) => {
            setSelectedPhysician(e.target.value as string);
          }}
          inputProps={{
            name: "age",
            id: "age-native-simple",
          }}
          style={{ width: "100%" }}
        >
          {props.physicians.map((physician, i) => {
            return (
              <option key={i} value={physician._id}>
                {`${physician.firstName} ${physician.lastName}`}
              </option>
            );
          })}
        </Select>
      ) : null}
      {selectedPhysician ? (
        <Button onClick={assignPhysician}>
          {props.type === USER_TYPE.CENTER ? "Assign" : "Accept"}
        </Button>
      ) : null}
      <Snackbar
        open={errorAssigningOrAssigningDone !== ""}
        autoHideDuration={3000}
        onClose={(e, r) => {
          if (r === "timeout") setErrorAssigningOrAssigningDone("");
        }}
      >
        <Alert
          severity={
            errorAssigningOrAssigningDone !== "" && errorAssigningOrAssigningDone !== "Done"
              ? "error"
              : "success"
          }
        >
          {errorAssigningOrAssigningDone !== "" && errorAssigningOrAssigningDone !== "Done"
            ? errorAssigningOrAssigningDone
            : "Assigned Professional"}
        </Alert>
      </Snackbar>
    </Card>
  );
}
