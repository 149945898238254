import * as types from "./types";

const initialState = {
  startingNewChat: false,
  startNewChatFailure: "",
  chats: [],
  getAllChatsFailure: "",
  gettingAllChats: false,
};

export const chatReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.startNewChat:
      return {
        ...state,
        startingNewChat: true,
        startNewChatFailure: "",
        chats: [],
      };
    case types.startNewChatFailure:
      return {
        ...state,
        startingNewChat: false,
        startNewChatFailure: action.payload,
      };
    case types.startNewChatSuccess: {
      return {
        ...state,
        startingNewChat: false,
        chats: action.payload,
      };
    }
    case types.gettingAllChats: {
      return {
        ...state,
        gettingAllChats: true,
        getAllChatsFailure: "",
        chats: [],
      };
    }
    case types.getAllChatsSuccess: {
      return {
        ...state,
        gettingAllChats: false,
        chats: action.payload,
      };
    }
    case types.getAllChatsFailure: {
      return {
        ...state,
        gettingAllChats: false,
        getAllChatsFailure: action.payload,
      };
    }
    default: {
      return state;
    }
  }
};

// todo
// sign up 3 states
