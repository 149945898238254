/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import "../authComponents/style.css";
import { PrimaryButton } from "../base/Components/PrimaryButton";
import { VerifyUpdatedNumberDTO } from "../../store/types/type";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  dailog: {
    padding: theme.spacing(2),
    position: "absolute",
  },
}));

export const OtpPopup = (props: {
  openPopupOtp: boolean;
  setOpenPopupOtp: any;
  verifyUpdatedNumber: (verifyUpdatedNumberDTO: VerifyUpdatedNumberDTO) => {};
  getPatientProfile: () => Promise<any>;
}) => {
  const classes = useStyles();

  const [updateProfile, setUpdateProfile] = useState<VerifyUpdatedNumberDTO>({
    newNumber: "",
    type: "",
    id: "",
    otp: "",
  });

  const [otpError, setOtpError] = useState("");

  const handleSubmit = () => {
    if (updateProfile.otp?.trim() === "") {
      setOtpError("Please enter otp!");
      return;
    }
    props.verifyUpdatedNumber(updateProfile);
    props.getPatientProfile();
    setUpdateProfile({ ...updateProfile, otp: "" });
    props.setOpenPopupOtp(false);
  };

  useEffect(() => {
    const newNumber: string | null = localStorage.getItem("newNumber");
    const type: string | null = localStorage.getItem("current");
    const id: string | null = localStorage.getItem("_id");
    setUpdateProfile({ ...updateProfile, type: type!, id: id!, newNumber: newNumber! });
  }, [updateProfile.otp]);

  return (
    <Dialog open={props.openPopupOtp} classes={{ paper: classes.dailog }}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            style={{
              flexGrow: 1,
            }}
          >
            Update Number
          </Typography>
          <Button onClick={() => props.setOpenPopupOtp(false)}>X</Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.root}>
          <div>
            <TextField
              id="margin-normal"
              margin="normal"
              style={{ margin: 8 }}
              error={otpError ? true : false}
              helperText={otpError ? otpError : false}
              size="small"
              fullWidth
              label="OTP"
              type="string"
              name="otp"
              onChange={(e) => {
                setUpdateProfile({ ...updateProfile, otp: e.target.value });
                setOtpError("");
              }}
            />

            <PrimaryButton onClick={handleSubmit} title="Verify" />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
