import axios from "axios";
import { NewChatDTO } from "../../types/type";
import * as actions from "./actions";

export const startChat = (chatDTO: NewChatDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.startChat());
  try {
    const response = await axios.post(`/chat/new-chat`, chatDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 201) {
      dispatch(actions.startChatSuccess(response.data));
    } else throw new Error("Could not Create new chat");
  } catch (err: any) {
    dispatch(actions.startChatFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};

export const startChatwithFile = (chatDTO: NewChatDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.startChat());
  const data = new FormData();
  if (chatDTO?.file) {
    data.append("fileField", chatDTO.file);
  }
  if (chatDTO?.centerID) {
    data.append("centerID", chatDTO.centerID);
  }
  if (chatDTO?.patientID) {
    data.append("patientID", chatDTO.patientID);
  }
  if (chatDTO.physicianIDs) {
    for (let i = 0; i < chatDTO.physicianIDs.length; i++) {
      data.append("physicianIDs", chatDTO.physicianIDs[i]);
    }
  }
  if (chatDTO.isPrivate) {
    data.append("isPrivate", JSON.stringify(chatDTO.isPrivate));
  }
  try {
    const response = await axios.post(`/chat/new-chat-withFile`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });

    if (response.status === 201) {
      dispatch(actions.startChatSuccess(response.data));
    } else throw new Error("Could not Create new chat");
  } catch (err: any) {
    dispatch(actions.startChatFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};

export const getAllChats = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.gettingAllChats());
  try {
    const response = await axios.get(`/chat/my-chat`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.getAllChatsSuccess(response.data));
    } else throw new Error("Could not fetch chats");
  } catch (err: any) {
    dispatch(actions.getAllChatsFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};
