import React from "react";
import { Grid } from "@material-ui/core";
import { useStyles } from "./muiStyling";
import { UploadDocumentComp } from "./ProfileComponents/UploadDocument/UploadDocumentComp";
import ProfileComp from "./ProfileComponents/Profile/ElevatedTop";
import { ProfileProps } from "../Profile";
import { MainAvatorComp } from "./ProfileComponents/Avatar/MainAvatorComp";
import { USER_TYPE } from "../../../store/types";
// import { usRedListStates } from "../../../store/types/states";

// const StyledOnlineBadge = withStyles((theme) => ({
//   badge: {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     overlap: "circular",
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "$ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
// }))(Badge);
// const StyledOffLineBadge = withStyles((theme) => ({
//   badge: {
//     backgroundColor: "#ffcc00",
//     color: "#ffcc00",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     overlap: "circular",
//   },
// }))(Badge);

export const DynamicProfile = (props: ProfileProps) => {
  const [formData, setFormData] = React.useState<any>({
    state: "",
  });
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchState, setSearchState] = React.useState<any>([]);
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.searchPhysician(formData);
    setFormData({
      state: "",
    });
  };
  React.useMemo(() => {
    setSearchState(props.searchPhysicianSuccess);
  }, [props.searchPhysicianSuccess]);
  // const onlineNow = props.isOnlineSuccess;
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div className={classes.root} style={{ overflowY: "scroll" }}>
        <Grid container spacing={2} className={classes.prof}>
          <Grid lg={1} xl={1}></Grid>
          <Grid
            item
            md={4}
            xs={11}
            sm={4}
            lg={3}
            xl={3}
            style={{
              paddingLeft: "0.5rem",
              margin: "auto",
            }}
          >
            <MainAvatorComp {...props} />
          </Grid>
          <Grid item md={8} xs={11} sm={8} lg={7} xl={7} style={{ margin: "auto" }}>
            <UploadDocumentComp {...props} />
          </Grid>
          <Grid xl={1} lg={1}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={10} style={{ margin: "auto" }}>
            <ProfileComp {...props} />
          </Grid>
          {localStorage.getItem("current") === USER_TYPE.PATIENT && (
            /*props.userType!=="PATIENT" &&*/ <Grid
              item
              xs={12}
              sm={10}
              style={{ margin: "auto" }}
            ></Grid>
          )}
        </Grid>
      </div>
    </div>
  );
};
