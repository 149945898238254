import "./style.css";
import "fontsource-roboto";
import MainScreen3 from "../../views/auth/MainScreen3";
import { Grid } from "@material-ui/core";
import desktopBackImage from "../../staticResources/background/fin.png";
// import mobileBackImage from "../../staticResources/background/mobile.png";

export const Main3 = () => {
  return (
    <Grid className="mainContainer" container >
      <Grid item lg={7} md={7} >
        <div className="main" style={{ background: "#E6EBD1", marginTop: "4%", marginLeft: "3%", borderRadius: "10px", padding: "10.1rem 0" }} >
          <MainScreen3 />
        </div>
      </Grid>
      <Grid className="backgroundImage" item lg={5} md={5}>
        <img className="desktopBackImage" alt="" src={desktopBackImage} />
        <img className="mobileBackImage" alt="" src={desktopBackImage} />
      </Grid>
    </Grid>
  );
};
