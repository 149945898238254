import { Card, Typography } from "@material-ui/core";
import { CardContent } from "@material-ui/core";
import { Paper } from "@material-ui/core";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardImage: {
      padding: "80px 0px",
      borderRadius: "25px",
      "@media (max-width: 500px)": {
        padding: "30px 0px",
      },
    },
    card: {
      opacity: "0.8",
    },
    cardContent: {
      // padding: "20px",
      "@media (max-width: 500px)": {
        padding: "0px",
        paddingTop: "10px",
      },
    },
    innerDiv: {
      height: "20px",
      justifyContent: "center",
    },
    title: {
      fontSize: "1.3rem",
      fontWeight: "bolder",
      lineHeight: "1.25rem",
      "@media (max-width: 1047px)": {
        fontSize: "0.9rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.0rem",
        lineHeight: "0.8rem",
      },
    },
  })
);
interface Props {
  url: string;
  specialityTitle: string;
  onClick: any;
}
export default function DashboardChip(props: Props) {
  const classes = useStyles();

  return (
    <Paper
      className={classes.cardImage}
      onClick={props.onClick}
      style={{
        background: `url(${props.url})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <Card className={classes.card}>
        <CardContent className={classes.cardContent}>
          <div className={classes.innerDiv}>
            <Typography variant="subtitle1" className={classes.title}>
              {props.specialityTitle}
            </Typography>
          </div>
        </CardContent>
      </Card>
    </Paper>
  );
}
