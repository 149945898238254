/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  Avatar,
  Button,
  FormControlLabel,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Switch,
} from "@material-ui/core";
import io from "socket.io-client";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { AvatarGroup } from "@material-ui/lab";
import { ICenter } from "../../store/types/center";
import { IChat } from "../../store/types/chat";
import { USER_TYPE } from "../../store/types";
import { IPhysician } from "../../store/types/physician";
import AddPhysicianChatModal from "../modals/AddPhysicianChat";
import { ProfessionalCrisisPopup } from "../popup/ProfessionalCrisisPopup";
import { useLocation } from "react-router-dom";
import { NotificationDTO } from "../../store/types/type";

const URL = `${process.env.REACT_APP_API_URL}/chat`;
// const socket = io(URL);
const socket: SocketIOClient.Socket = io(URL, {
  transports: ["polling"],
});
interface CrisisDTO {
  socketId?: string;
  userId?: string;
  chatID?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chatBar: {
      //   height: "50px",
      //   background: "#666",
      position: "sticky",
      // marginTop: "10px",
      width: "100%",
      zIndex: 99999,
    },
    label: {
      fontSize: "10px",
    },
  })
);
interface Props {
  currentChat: IChat;
  showPrivacy: boolean;
  setShowPrivacy: (toggle: boolean) => void;
  changeChatPrivacy: (id: string, toggle: boolean) => Promise<void>;
  userType: USER_TYPE;
  centersAndPhysicians: ICenter[];
  getAllCentersAndPhysicians: () => Promise<void>;
  gettingCenters: boolean;
  getCentersError: string;
  getChatsAfterAdd: (chat: IChat) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  physicianProfile?: IPhysician;
  physicianProfileCalling: boolean;
  physicianProfileFailure: string;
  centerProfile?: ICenter;
  centerProfileCalling: boolean;
  centerProfileFailure: string;
}
export default function ChatBar(props: Props) {
  const { pathname } = useLocation();
  const res = pathname.split("/");
  const type = res[res.length - 1];

  const classes = useStyles();
  const [openCrisisPopup, setOpenCrisisPopup] = useState(false);
  const changeChatPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(`Privacy changed for whole Chat`);
    console.log(`New privacy: ${event.target.checked}`);
    props.changeChatPrivacy(props.currentChat?._id, event.target.checked);
  };

  const toggleShowPrivacy = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.setShowPrivacy(event.target.checked);
  };

  const [physicians, setPhysicians] = React.useState<IPhysician[]>([]);
  const addPhysician = async (e: React.MouseEvent<HTMLButtonElement>) => {
    await props.getAllCentersAndPhysicians();
    setOpen(true);
  };
  React.useEffect(() => {
    if (props.centersAndPhysicians?.length && (props.physicianProfile || props.centerProfile)) {
      //TDOD Center ID
      const myCenter = props.centersAndPhysicians.filter(
        (center) =>
          center._id ===
          (props.physicianProfile ? props.physicianProfile.center._id : props.centerProfile?._id)
      );
      setPhysicians(
        myCenter.length
          ? myCenter[0].physicians.filter((phy) => {
              return !props.currentChat.physicians
                .map((currentPhy) => {
                  if (currentPhy._id) return currentPhy._id === phy._id;
                  //@ts-ignore
                  else return currentPhy === phy._id;
                })
                .includes(true);
            })
          : []
      );
    }

    // else check if there was an error while getting centers call and update state errorWhileGettingPhysicians with that error
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.centersAndPhysicians, props.physicianProfile]);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const sendCrisisPopup = () => {
    let obj = {
      socketId: socket.id,
      userId: props.currentChat.patient?._id,
      chatID: props.currentChat._id,
    };
    if (props.currentChat) {
      socket.emit("crisis-escalation", obj);
    }
  };
  React.useEffect(() => {
    socket.on("crisis-escalation-popup", (payload: CrisisDTO) => {
      if (payload.userId === props.currentChat.patient?._id) {
        setOpenCrisisPopup(!openCrisisPopup);
      }
    });
  }, []);
  const names = props.currentChat.patient
    ? [
        props.currentChat.patient.firstName,
        ...props.currentChat.physicians.map((phy) => phy.firstName),
        // props.currentChat.center.name
      ].toString()
    : props.currentChat.center
    ? [
        props.currentChat.center,
        ...props.currentChat.physicians.map((phy) => phy.firstName),
      ].toString()
    : [...props.currentChat.physicians.map((phy) => phy.firstName)].toString();

  return (
    <div className={classes.chatBar}>
      <Paper elevation={3} style={{}}>
        <ListItem
          alignItems="flex-start"
          title="Title"
          className="cardList"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ListItemAvatar>
            <AvatarGroup max={4}>
              {props.currentChat.patient && props.currentChat.isGroup ? (
                <Avatar
                  alt={"User Icon"}
                  src={
                    props.currentChat.patient.files.filter((f) => f.isProfile)[0]?.url ||
                    "https://via.placeholder.com/150?text=Profile"
                  }
                />
              ) : (
                ""
              )}
              {props.currentChat.center && props.currentChat.isGroup ? (
                <Avatar
                  alt={"Provider Icon"}
                  src={
                    props.currentChat.center.files.filter((f) => f.isProfile)[0]?.url ||
                    "https://via.placeholder.com/150?text=Profile"
                  }
                />
              ) : (
                ""
              )}
              {props.currentChat.physicians
                .filter((phy) => phy._id !== props.physicianProfile?._id)
                .map((phy, i) => {
                  return (
                    <Avatar
                      key={i}
                      alt={"Physician Icon"}
                      src={
                        phy.files.filter((f) => f.isProfile)[0]?.url ||
                        "https://via.placeholder.com/150?text=Profile"
                      }
                    />
                  );
                })}
            </AvatarGroup>
          </ListItemAvatar>
          <ListItemText
            className="ChatbarText"
            primary={
              props.currentChat.isGroup
                ? names
                : props.userType === USER_TYPE.PATIENT || props.userType === USER_TYPE.CENTER
                ? props.currentChat.physicians[0]?.firstName
                : props.currentChat.patient?.firstName ||
                  props.currentChat.center?.name ||
                  props.currentChat.physicians.filter(
                    (phy) => phy._id !== props.physicianProfile?._id
                  )[0]?.firstName
            }
            secondary={
              props.currentChat.isGroup
                ? "GROUP"
                : props.userType === USER_TYPE.PATIENT || props.userType === USER_TYPE.CENTER
                ? "PROFESSIONAL"
                : props.currentChat.patient
                ? "USER"
                : props.currentChat.center
                ? "PROVIDER"
                : "PROFESSIONAL"
            }
          />
          {props.userType === USER_TYPE.PHYSICIAN || props.userType === USER_TYPE.CENTER ? (
            <>
              {type === "messages" ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setOpenCrisisPopup(true)}
                  disableElevation
                  style={{ margin: "4px 8px 4px 8px" }}
                  size={window.screen.width < 768 ? "small" : "large"}
                >
                  Crisis Escalation
                </Button>
              ) : null}
              <Button
                variant="contained"
                color="primary"
                onClick={addPhysician}
                style={{ margin: "4px 8px 4px 8px" }}
                disableElevation
                size={window.screen.width < 768 ? "small" : "large"}
              >
                Add Professionals
              </Button>
              <AddPhysicianChatModal
                sendSMS={props.sendSMS}
                open={open}
                currentChat={props.currentChat}
                handleClose={handleClose}
                physicians={physicians}
                chatID={props.currentChat._id}
                getCentersError={props.getCentersError}
                gettingCenters={props.gettingCenters}
                getChatsAfterAdd={props.getChatsAfterAdd}
                userType={props.userType}
              ></AddPhysicianChatModal>
            </>
          ) : props.userType === USER_TYPE.PATIENT ? (
            <>
              <FormControlLabel
                label={
                  <span className="ChatbarText">
                    {window.screen.width <= 768 ? "Private Chat" : "Make Full Chat Private"}
                  </span>
                }
                labelPlacement="start"
                control={
                  <Switch
                    checked={false || props.currentChat?.isPrivate}
                    onChange={changeChatPrivacy}
                    name={props.currentChat?._id}
                    color="secondary"
                  />
                }
              />
              <FormControlLabel
                label={<span className="ChatbarText">Show Privacy</span>}
                labelPlacement="start"
                control={
                  <Switch
                    checked={false || props.showPrivacy}
                    onChange={toggleShowPrivacy}
                    name={props.currentChat?._id}
                    color="secondary"
                  />
                }
              />
            </>
          ) : (
            ""
          )}
        </ListItem>
      </Paper>

      <ProfessionalCrisisPopup
        openCrisisPopup={openCrisisPopup}
        setOpenCrisisPopup={setOpenCrisisPopup}
        sendCrisisPopup={sendCrisisPopup}
      />
    </div>
  );
}
