import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import { theme } from "../../base/ColorPalete";
import { ThemeProvider } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";
import axios from "axios";
import { CloudUpload } from "@material-ui/icons";
import { useEffect } from "react";

function getModalStyle() {
  const top = 45;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    outline: "0px",
    border: "0px",
    height: "260px",
    width: "430px",
    borderRadius: "8px",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);
interface Props {
  show: boolean;
  handleShowFolderModal: (val: boolean) => void;
  getProfile: () => Promise<void>;
  read?: boolean;
  folderName?: string;
}
export default function FolderModal(props: Props) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [inputFolder, setInputFolder] = React.useState("");
  const [image, setImage] = React.useState<any>("");
  // const [imageUrl, setImageUrl] = React.useState("");
  const handleUploadChange = (e: any) => {
    const initialFile = e.target.files[0];
    console.log("initialFile", initialFile.name);
    if (!initialFile) {
      return;
    }
    setImage(initialFile);
    // setImageUrl(URL.createObjectURL(initialFile));
  };
  useEffect(() => {
    if (props.folderName) {
      setInputFolder(props?.folderName);
    } else {
      setInputFolder("");
    }
  }, [props.folderName]);
  const handleSubmit = async () => {
    const data = new FormData();
    data.append("fileType", "FOLDER");
    data.append("fName", inputFolder);
    data.append("fileField", image);
    try {
      const res = await axios.post("/files-manager/upload-file", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      });
      if (res.status === 201) {
        props.handleShowFolderModal(false);
        await props?.getProfile();
      }
    } catch (error) {
      throw error;
    }
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Typography style={{ margin: "10px 0px" }}>Folder Name:</Typography>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        label="Folder Name"
        type="string"
        name="name"
        value={props?.folderName ? props?.folderName : inputFolder}
        onChange={(e) => setInputFolder(e.target.value)}
      />
      <div className="flex justify-center sm:justify-start flex-wrap" style={{ marginTop: "10px" }}>
        <input
          style={{ display: "none" }}
          id={`button-file-${"fileID"}​​​​​​​​`}
          type="file"
          onChange={handleUploadChange}
        />
        <label
          htmlFor={`button-file-${"fileID"}​​​​​​​​`}
          className="flex flex-col items-center justify-center relative w-48 h-48 rounded-4 overflow-hidden cursor-pointer shadow-1 hover:shadow-5"
          style={{ cursor: "pointer" }}
        >
          <div>
            {image ? <p style={{ marginTop: "10px", marginBottom: "10px" }}>{image.name}</p> : ""}
          </div>
          <CloudUpload style={{ fontSize: "62px", marginTop: "5px", marginBottom: "10px" }} />
        </label>
      </div>
      <ThemeProvider theme={theme}>
        <Button
          onClick={handleSubmit}
          disabled={inputFolder.length > 0 && image ? false : true}
          color="primary"
          fullWidth
          type="submit"
          variant="contained"
          style={{ marginTop: "10x" }}
        >
          Add Folder
        </Button>
      </ThemeProvider>
    </div>
  );

  return (
    <div>
      <Modal open={props.show} onClose={() => props.handleShowFolderModal(false)}>
        {body}
      </Modal>
    </div>
  );
}
