/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import "../authComponents/style.css";
import { PrimaryButton } from "../base/Components/PrimaryButton";
import { NumberUpdateDTO } from "../../store/types/type";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  dailog: {
    padding: theme.spacing(2),
    position: "absolute",
  },
}));

export const UpdateNumberPopup = (props: {
  openPopupNumber: boolean;
  setOpenPopupNumber: any;
  updateProfileNumber: (numberUpdateDTO: NumberUpdateDTO) => {};
  getPatientProfile: () => Promise<any>;
  setOpenPopupOtp: () => void;
}) => {
  const classes = useStyles();

  const [updateProfile, setUpdateProfile] = useState({
    newNumber: "",
    type: "",
    id: "",
  });

  const [numberError, setNumberError] = useState("");

  const handleSubmit = () => {
    if (updateProfile.newNumber.trim() === "") {
      setNumberError("Please enter a number!");
      return;
    }
    localStorage.setItem("newNumber", updateProfile.newNumber);
    props.updateProfileNumber(updateProfile);
    props.getPatientProfile();
    props.setOpenPopupNumber(false);
    setUpdateProfile({ ...updateProfile, newNumber: "" });
    props.setOpenPopupOtp();
  };

  useEffect(() => {
    const type: string | null = localStorage.getItem("current");
    const id: string | null = localStorage.getItem("_id");
    setUpdateProfile({ ...updateProfile, type: type!, id: id! });
  }, []);

  return (
    <Dialog open={props.openPopupNumber} classes={{ paper: classes.dailog }}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            style={{
              flexGrow: 1,
            }}
          >
            Update Number
          </Typography>
          <Button onClick={() => props.setOpenPopupNumber(false)}>X</Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.root}>
          <TextField
            id="margin-normal"
            margin="normal"
            fullWidth
            error={numberError ? true : false}
            helperText={numberError ? numberError : false}
            type="text"
            style={{ margin: 8 }}
            size="small"
            label="New Number"
            onChange={(e) => {
              setUpdateProfile({ ...updateProfile, newNumber: e.target.value });
              setNumberError("");
            }}
          />

          <PrimaryButton onClick={handleSubmit} title="Update Number" />
        </div>
      </DialogContent>
    </Dialog>
  );
};
