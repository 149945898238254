export const gettingAllFeeds = "@/feed/@gettingAllFeeds";
export const getAllFeedsSuccess = "@/feed/@getAllFeedsSuccess";
export const getAllFeedsFailure = "@/feed/@getAllFeedsFailure";

export const updateCenterIsMassCalling = "@/feed/@updateCenterIsMassCalling";
export const updateCenterIsMassSuccess = "@/feed/@updateCenterIsMassSuccess";
export const updateCenterIsMassFailure = "@/feed/@updateCenterIsMassFailure";

export const removeOneFeed = "@feed/@removeOneFeed";
export const removeOneFeedSuccess = "@feed/@removeOneFeedSuccess";
export const removeOneFeedFailure = "@feed/@removeOneFeedFailure";

export const drawerOpen = "@feed/@drawerOpen";
