import React from "react";
import { USER_TYPE } from "../../../store/types";
import { Home as AdminHome } from "../main/Home";
import FeedforAdmin from "./FeedforAdmin";

export default function Home() {
  const track = "Track Providers";
  const manage = "Manage Provider's requests.";

  const Items = [
    { title: "Management", desc: manage },
    { title: "Tracking", desc: track },
  ];
  return (
    <>
      <div style={{ overflowY: "scroll", height: "100vh" }}>
        <AdminHome type={USER_TYPE.ADMIN} adminItems={Items} />
        <FeedforAdmin />
      </div>
    </>
  );
}
