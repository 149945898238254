export const loggingIn = "@/center/@loginStart";
export const loginSuccess = "@/center/@loginSuccess";
export const loginFailure = "@/center/@loginFailure";

export const verifyingToken = "@/center/@verifyTokenStart";
export const verifyTokenSuccess = "@/center/@verifyTokenSuccess";
export const verifyTokenFailure = "@/center/@verifyTokenFailure";

export const loggedOut = "@/center/@loggedOut";
export const registerIn = "@/center/@registerStart";
export const registerSuccess = "@/center/@registerSuccess";
export const registerFailure = "@/center/@registerFailure";
