import React, { Component } from "react";
import axios from "axios";
import { Main } from "./Main";
import Appbar from "../../navbars/Appbar";
import { USER_TYPE } from "../../../store/types";
import { Alert } from "@material-ui/lab";

interface State {
  approved?: boolean;
  denied?: boolean;
}
interface Props {
  history: any;
  children?: any;
}
export class RestrictUnApprovedAdmin extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      approved: undefined,
      denied: undefined,
    };
  }
  checkAdminApprovalState = async () => {
    try {
      const response = await axios.get(`/admins`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      });
      if (response.status === 200) {
        this.setState({
          ...this.state,
          approved: response.data.approved,
          denied: response.data.denied,
        });
      } else throw new Error("Unable to get Admin Profile");
    } catch (err) {
      this.setState({
        ...this.state,
        approved: false,
      });
    }
  };
  componentDidMount = async () => {
    document.body.style.background = "none";
    document.body.style.backgroundColor = "none";
    await this.checkAdminApprovalState();
  };

  render() {
    return this.state.approved ? (
      <Main {...this.props} />
    ) : (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <header
          style={{
            top: "0",
            left: "auto",
            position: "sticky",
            display: "flex",
            justifyContent: "center",
            width: "100%",
            zIndex: 999,
          }}
        >
          <Appbar type={USER_TYPE.ADMIN} />
        </header>
        <Alert severity="info">
          Please wait for <strong>SuperUser</strong> to approve your request and try again
        </Alert>
      </div>
    );
  }
}

export default RestrictUnApprovedAdmin;
