import axios from "axios";
import { IAdmin } from "../../types";
import * as actions from "./actions";

export const getAllAdmins = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.gettingAllAdmins());
  try {
    const response = await axios.get<IAdmin[]>(`/admins/get-all`);
    if (response.status === 200) dispatch(actions.getAllAdminsSuccess(response.data));
    else throw new Error("Could not fetch admins list");
  } catch (err: any) {
    dispatch(actions.getAllAdminsFailure(err.message));
  }
};
