import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Container, Grid } from "@material-ui/core";
import { UserLoginDTO } from "../../store/types/type";
import { useStyles } from "./muiStyling";
import { ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { theme } from "../base/ColorPalete";
import CircleSpinner from "../CircleSpinner";
import { Alert } from "@material-ui/lab";
import { IOtp, USER_TYPE } from "../../store/types";
import { useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { login } from "../../store/patients/auth/apiCalls"
// import PaymentForm from "./PaymentForm";

interface StateProps {
  loggingInPatient: boolean;
  patientLoggedIn: boolean;
  errorWhilePatientLogin: string;
}
interface DispatchProps {
  patientLogin: (dto: UserLoginDTO, type: string) => Promise<void>;
  verifyPatientOTP: (userOtp: IOtp) => Promise<void>;
  changeType: (type: string) => void;
  successResponse: string;
}

type Props = StateProps & DispatchProps;
export default function PatientLoginCard(props: Props) {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [patientData, setPatientData] = React.useState<UserLoginDTO>({
    email: "",
    password: "",
  });
  const { auth } = useSelector((state: any) => state.patients);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorState, setErrorState] = React.useState({
    email: "",
    password: "",
  });
  const history = useHistory();
  const [flag, setFlag] = React.useState(false);
  const [otp, setOtp] = React.useState<IOtp>({
    userType: undefined,
    _id: "",
    otp: "",
    password: "",
  });

  useEffect(() => {
    const password = localStorage.getItem("password");
    const decryptedPassword = window.atob(password as string);
    setOtp({
      userType: USER_TYPE.PATIENT,
      _id: localStorage.getItem("_id") as string,
      otp: "",
      password: decryptedPassword,
    });
  }, [flag]);

  useEffect(() => {
    if (props.patientLoggedIn) {
      setFlag(true);
    }
  }, [props.patientLoggedIn]);

  useEffect(() => {
    if (props.successResponse) {
      props.changeType("login");
    }
  }, [props]);

  const handleSubmitOtp = async (e: any) => {
    e.preventDefault();
    await props.verifyPatientOTP(otp);
    history.push("/patient");
  };
  const handleSubmit = async (event: any) => {
    // setInputState(patientData);

    if (isValidated(event)) {
      const encryptedPassword = window.btoa(patientData.password);
      localStorage.setItem("password", encryptedPassword);
      await props.patientLogin(patientData, "email");
      // history.push("/patient");
    }
  };
  const handleLogin = async (response: any) => {
    if (response) {
      await props.patientLogin(response.profileObj, "google");
    }
  };
  const isValidated = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // let input = inputState;
    let errors = {
      email: "",
      password: "",
    };
    errors.password = patientData.password ? "" : "Please Enter Password";
    errors.email = patientData.email ? "" : "Please Enter valid Email";
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };
  const classes = useStyles();
  useEffect(() => {
    if (props.errorWhilePatientLogin === "trialend") {
      // goto payment form
      props.changeType("trialend");
    }
  }, [props, props.errorWhilePatientLogin]);

  return props.loggingInPatient ? (
    <Container className={classes.container} maxWidth="xs">
      <Grid container justify="center">
        <CircleSpinner />
      </Grid>
    </Container>
  ) : flag ? (
    <Container className={classes.container} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Otp"
                  type="string"
                  name="otp"
                  onChange={(e: any) =>
                    setOtp({
                      ...otp,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    onClick={handleSubmitOtp}
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    log In
                  </Button>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  ) : (
    <Container className={classes.container} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="email"
                  type="email"
                  name="email"
                  placeholder={`No capitals in email `}
                  onChange={(e) => setPatientData({ ...patientData, email: e.target.value })}
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  onChange={(e) => setPatientData({ ...patientData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              <Grid item xs={12}>
                {/* <PaymentForm/> */}

                <ThemeProvider theme={theme}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    style={{ borderRadius: "20px" }}
                  >
                    GET Security token
                  </Button>
                </ThemeProvider>
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                OR
              </Grid>
              <Grid item xs={12} style={{ textAlign: "center" }}>
                <GoogleLogin
                  clientId={process.env.REACT_APP_GOOGLE_CLIENT || ""}
                  buttonText="Login with Google"
                  onSuccess={(e) => handleLogin(e)}
                  onFailure={() => alert("Server Error: Try Again")}
                  cookiePolicy={"single_host_origin"}
                  style={{ marginTop: "100px" }}
                />
              </Grid>
              <Grid item xs={12}>
                {props.successResponse ? (
                  <Alert severity="success">success</Alert>
                ) : (
                  <>
                    {props.errorWhilePatientLogin && (
                      <Alert severity="error">{props.errorWhilePatientLogin}</Alert>
                    )}
                    {auth.loginError === "You need Pay First" && props.changeType("paypal")}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/* {props.errorWhilePatientLogin ? <h2>{props.errorWhilePatientLogin}</h2> : ""} */}
      </form>
    </Container>
  );
}
