import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { IAdmin, ICenter } from "../../../store/types";
import { adminProfileCalls, adminProfileSelectors } from "../../../store/admins/profile";
import ApproveCenter from "./ApproveCenter";
import TrackCenters from "./TrackCenters";
import { centerProfileCalls, centerProfileSelectors } from "../../../store/centers/profile";
interface OwnProps {}
interface DispatchProps {
  getAdminProfile: () => Promise<void>;
  getCenterProfile: () => Promise<void>;
  denyCenterRequest: (id: string) => Promise<void>;
  approveCenterRequest: (id: string) => Promise<void>;
}
interface StateProps {
  adminProfileCalling: boolean;
  adminProfile: IAdmin;
  adminProfileFetchError?: string;
  centerProfileCalling: boolean;
  centerProfile: ICenter;
  centerProfileFetchError?: string;
  updatingCenterRequest: boolean;
  updatingCenterError: string;
}
type Props = OwnProps & StateProps & DispatchProps;
function Management(props: Props) {
  React.useEffect(() => {
    props.getAdminProfile();

  }, []);
  return (
    <>
      <Switch>
        <Route
          exact
          path={`/admin/management/approval`}
          component={() => (
            <ApproveCenter
              profile={props.adminProfile}
              approveCenterRequest={props.approveCenterRequest}
              denyCenterRequest={props.denyCenterRequest}
              updatingCenterRequest={props.updatingCenterRequest}
              updatingCenterError={props.updatingCenterError}
              getProfile={props.getAdminProfile}
            />
          )}
        />
        <Route
          exact
          path={`/admin/management/tracking`}
          component={() => (
            <TrackCenters
              adminProfile={props.adminProfile}
              denyCenterRequest={props.denyCenterRequest}
            />
          )}
        />
        <Redirect to={`/admin/management/approval`} />
      </Switch>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getAdminProfile: () => dispatch(adminProfileCalls.getAdminProfile()),
  getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  denyCenterRequest: (id: string) => dispatch(adminProfileCalls.denycenterRequest(id)),
  approveCenterRequest: (id: string) => dispatch(adminProfileCalls.approveCenterRequest(id)),
});
const mapStateToProps = (state: any) => ({
  adminProfileCalling: adminProfileSelectors.isCallingProfile(state.admins.adminProfile),
  adminProfile: adminProfileSelectors.isAdminProfile(state.admins.adminProfile),
  adminProfileFetchError: adminProfileSelectors.isProfileFailure(state.admins.adminProfile),

  updatingAdminRequest: adminProfileSelectors.updatingCenterRequest(state.admins.adminProfile),
  updatingCenterError: adminProfileSelectors.CenterRequestFailed(state.centers.centerProfile),

  centerProfileCalling: centerProfileSelectors.isCallingProfile(state.centers.centerProfile),
  centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
  centerProfileFetchError: centerProfileSelectors.isProfileFailure(state.centers.centerProfile),
});
export default connect(mapStateToProps, mapDispatchToProps)(Management);
