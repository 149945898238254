import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  authCalls as superAuthCalls,
  authSelectors as superAuthSelectors,
} from "../../store/supers/auth";

import SuperLogin from "../../components/authComponents/SuperLoginCard";
import { IOtp } from "../../store/types";
import { SuperLoginDTO } from "../../store/types/type";

export class LoginContainer extends Component {
  constructor(props: any) {
    super(props);

    this.state = { value: localStorage.getItem("tabB") ? Number(localStorage.getItem("tabB")) : 0 };
  }
  state: any;
  props: any;

  a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  render() {
    return (
      <>
        <SuperLogin
          superLogin={this.props.superLogin}
          loggingInSuper={this.props.loggingInSuper}
          superLoggedIn={this.props.superLoggedIn}
          errorWhileSuperLogin={this.props.errorWhileSuperLogin}
          verifySuperOTP={this.props.verifySuperOTP}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  superLogin: (superLogin: SuperLoginDTO) => dispatch(superAuthCalls.login(superLogin)),
  verifySuperOTP: (otp: IOtp) => dispatch(superAuthCalls.verifyToken(otp)),
});

const mapStateToProps = (state: any) => ({
  loggingInSuper: superAuthSelectors.isLoggingIn(state.supers.auth),
  superLoggedIn: superAuthSelectors.hasAuthToken(state.supers.auth),
  errorWhileSuperLogin: superAuthSelectors.hasLoginError(state.supers.auth),
});

const LoginContainerWithRoutes = withRouter(LoginContainer);
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainerWithRoutes);
