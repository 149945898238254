import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  AppBar,
  Avatar,
  Badge,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  createStyles,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
  withStyles,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import ChatIcon from "@material-ui/icons/Chat";
import { IChat, IFeed, IPatient } from "../../../store/types";
import { feedCalls, feedSelectors } from "../../../store/feed";
import { patientProfileCalls, patientProfileSelectors } from "../../../store/patients/profile";
import { PatientUpdateDTO } from "../../../store/types/type";
import { onlineCalls, onlineSelectors } from "../../../store/online";



interface OwnProps {}
interface DispatchProps {
  getAllFeeds: () => Promise<void>;
  updatePatientProfile: (patient: PatientUpdateDTO) => Promise<void>;
  getOnlineUsers: () => Promise<void>;
}

interface StateProps {
  getAllFeedsSuccess: IFeed[];
  patientProfile?: IPatient;
  getAllFeeds: () => Promise<void>;
  isOnlineSuccess: string[];
}

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      height: "400px",
    },
    container: {
      marginTop: 30,
    },
    card: {
      maxWidth: 550,
      margin: "0 auto",
    },
    multiline: {
      whiteSpace: "pre-line",
    },
    feedTitle: {
      fontWeight: "bold",
    },
  })
);
const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);
type Props = OwnProps & StateProps & DispatchProps;
export const FeedForPatient = (props: Props) => {
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [feedData, setFeedData] = React.useState<IFeed[]>([]);
  const handleClickOpen = (id: any) => {
    props.getAllFeeds();
    setOpen(true);
    setFeedData(
      props.getAllFeedsSuccess.filter((feed) => {
        if (feed.center._id === id) {
          return [feed];
        } else {
          return null;
        }
      })
    );
  };
  const handleChat = () => {
    if ((props.patientProfile?.chats.length as unknown) as IChat[]) {
      history.push("/patient/portal/messages");
    } else {
      history.push("/patient/portal/selectCenter");
    }
  };

  const handlePin = async (providerID: string) => {
    await props.updatePatientProfile({
      pinProvider: (providerID as unknown) as string[] | undefined,
    });
    await props.getAllFeeds();
    await props.patientProfile;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [deloresTheme, setDeloresTheme]: any = React.useState(true);

  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };


  const classes = useStyles();
  useEffect(() => {
    props.getAllFeeds();
    props.getOnlineUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allFeed = props?.getAllFeedsSuccess.filter((onlyMass: IFeed) => {
    if (onlyMass.isMass === true) {
      return onlyMass.isMass;
    } else {
      return false;
    }
  });
  const onlineNow = props.isOnlineSuccess;
  return (
    <>

      <Typography variant="h6" align={"center"} style={{ color: "#000" }}>
        Delores Digital Feed
      </Typography>

      <div style={{ minHeight: "90vh", overflowY: "scroll" }}>
        {allFeed.map((data, i) => {
          return (
            <Container className={classes.container} key={i}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    onlineNow.includes(data.center._id) ? (
                      <StyledOnlineBadge
                        variant={onlineNow.includes(data.center._id) ? "dot" : "standard"}
                      >
                        <Avatar
                          alt={data?.file?.name}
                          src={
                            data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                            `https://via.placeholder.com/150?text=Profile`
                          }
                        />
                      </StyledOnlineBadge>
                    ) : (
                      <StyledOffLineBadge variant="dot">
                        <Avatar
                          alt={data?.file?.name}
                          src={
                            data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                            `https://via.placeholder.com/150?text=Profile`
                          }
                        />
                      </StyledOffLineBadge>
                    )
                  }
                  title={data.title}
                  subheader={`Provider :${data?.center?.name}-${data.createdAt.slice(0, 10)}`}
                  onClick={() => handleClickOpen(data.center._id)}
                  style={{ cursor: "pointer" }}
                />
                {data?.file &&
                  (data?.file.url?.includes("mp4") ? (
                    <video src={data.file.url} width="100%" height="100%" controls={true} />
                  ) : (
                    <CardMedia
                      className={classes.media}
                      component="img"
                      image={data.file.url}
                      title={data.file.name}
                    />
                  ))}
                <CardContent>
                  <Typography
                    variant="body2"
                    className={classes.multiline}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </CardContent>
              </Card>
            </Container>
          );
        })}
        <Dialog fullScreen open={open} onClose={handleClose}>
          <AppBar
            style={{
              backgroundColor: "#1a8c1e",
              color: "white",
            }}
          >
            <Toolbar style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </div>
              <div>
                <Typography
                  style={{
                    display: "flex",
                  }}
                  variant="h6"
                  component="div"
                >
                  Bio Page
                </Typography>
              </div>
              <div>
                <Button
                  autoFocus
                  variant="outlined"
                  color="inherit"
                  startIcon={<PersonPinIcon />}
                  disabled={props.patientProfile?.pinProvider?.includes(feedData[0]?.center._id)}
                  onClick={() => handlePin(feedData[0]?.center._id)}
                  style={{ marginRight: 20 }}
                >
                  pin
                </Button>
                <Button
                  autoFocus
                  variant="outlined"
                  color="inherit"
                  endIcon={<ChatIcon />}
                  onClick={handleChat}
                >
                  chat
                </Button>
              </div>
            </Toolbar>
          </AppBar>
          <Container>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  marginTop: "75px",
                  marginInlineStart: "auto",
                }}
              >
                <Grid xs={12}>
                  <Card
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div
                      style={{
                        margin: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-evenly",
                        borderBottom: "4px solid #ccc",
                        paddingBottom: "40px",
                        marginBottom: "30px",
                      }}
                    >
                      <Avatar
                        style={{
                          width: "120px",
                          height: "120px",
                          boxShadow:
                            "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                        }}
                        alt="provider pic"
                        src={
                          feedData[0]?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                          `https://via.placeholder.com/150?text=Profile`
                        }
                      />
                      <div>
                        <Typography
                          variant="h5"
                          component="h2"
                          style={{
                            fontWeight: "bold",
                            marginTop: "20px",
                          }}
                        >
                          {`Provider Name : ${feedData[0]?.center?.name}`}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          {`Provider Address : ${feedData[0]?.center?.address}`}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          {`Provider Phone : ${feedData[0]?.center?.phoneNumber}`}
                        </Typography>
                        <Typography
                          variant="h6"
                          component="h6"
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          {`Provider Bio : ${
                            feedData[0]?.center?.bio ? feedData[0]?.center?.bio : "No Bio"
                          }`}
                        </Typography>
                      </div>
                    </div>
                    <div>
                      {feedData.map((feed, i) => {
                        return (
                          <Card className={classes.card}>
                            <CardHeader
                              avatar={
                                onlineNow.includes(feed.center._id) ? (
                                  <StyledOnlineBadge
                                    variant={
                                      onlineNow.includes(feed.center._id) ? "dot" : "standard"
                                    }
                                  >
                                    <Avatar
                                      alt={feed?.file?.name}
                                      src={
                                        feed?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                                        `https://via.placeholder.com/150?text=Profile`
                                      }
                                    />
                                  </StyledOnlineBadge>
                                ) : (
                                  <StyledOffLineBadge variant="dot">
                                    <Avatar
                                      alt={feed?.file?.name}
                                      src={
                                        feed?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                                        `https://via.placeholder.com/150?text=Profile`
                                      }
                                    />
                                  </StyledOffLineBadge>
                                )
                              }
                              title={feed?.title}
                              subheader={`Provider : ${feed?.center?.name}-${feed?.createdAt.slice(
                                0,
                                10
                              )}`}
                            />
                            {feed?.file &&
                              (feed?.file.url?.includes("mp4") ? (
                                <video
                                  src={feed?.file.url}
                                  width="100%"
                                  height="100%"
                                  controls={true}
                                />
                              ) : (
                                <CardMedia
                                  className={classes.media}
                                  component="img"
                                  image={feed?.file.url}
                                  title={feed?.file.name}
                                />
                              ))}
                            <CardContent>
                              <Typography
                                variant="body2"
                                className={classes.multiline}
                                dangerouslySetInnerHTML={{ __html: feed?.description as any }}
                              />
                            </CardContent>
                          </Card>
                        );
                      })}
                    </div>
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Dialog>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({

  getAllFeeds: () => dispatch(feedCalls.getAllFeeds()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  updatePatientProfile: (patientUpdateDTO: PatientUpdateDTO) =>
    dispatch(patientProfileCalls.updatePatientProfile(patientUpdateDTO)),
});


const mapStateToProps = (state: any) => ({
  getAllFeedsSuccess: feedSelectors.getAllFeedsSuccess(state.feeds),
  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedForPatient);
