import React from "react";
import "../authComponents/style.css";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../authComponents/muiStyling";
import { PrimaryButton } from "../base/Components/PrimaryButton";
import { PatientUpdateDTO } from "../../store/types/type";

export const PatientProfile = (props: {
  updatePatientProfile: (updatepatientData: PatientUpdateDTO) => {};
  getPatientProfile: () => Promise<any>;
  setOpenPopupProfile: any;
}) => {
  const classes = useStyles();

  const [updateProfile, setUpdateProfile] = React.useState({
    firstName: "",
    lastName: "",
    password: "",
  });

  const handleSubmit = () => {
    props.updatePatientProfile(updateProfile);
    props.getPatientProfile();
    props.setOpenPopupProfile(false);
  };

  return (
    <div className={classes.root}>
      <div>
        <TextField
          id="margin-normal"
          margin="normal"
          style={{ margin: 8 }}
          size="small"
          fullWidth
          label="First Name"
          type="string"
          name="firstname"
          onChange={(e) => setUpdateProfile({ ...updateProfile, firstName: e.target.value })}
        />
        <TextField
          id="margin-normal"
          margin="normal"
          fullWidth
          //     id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Last Name"
          onChange={(e) => setUpdateProfile({ ...updateProfile, lastName: e.target.value })}
        />
        <TextField
          id="margin-normal"
          margin="normal"
          fullWidth
          // id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Password"
          type="password"
          onChange={(e) => setUpdateProfile({ ...updateProfile, password: e.target.value })}
        />
        <PrimaryButton onClick={handleSubmit} title="Update Profile" />
      </div>
    </div>
  );
};
