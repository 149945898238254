/* eslint-disable react-hooks/exhaustive-deps */
import { IPhysician } from "../../../store/types";
import { connect } from "react-redux";
import { useEffect } from "react";
import { physicianProfileCalls, physicianProfileSelectors } from "../../../store/physician/profile";
import MaterialTable from "material-table";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  makeStyles,
  // makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  "@media (max-width 650px)": {
    "& .MuiTableCell-root": {
      padding: "2px!important" as any,
    },
  },
});
interface OwnProps {
  getPhysicianProfile: () => Promise<void>;
  physicianProfile?: IPhysician;
}
type Props = OwnProps;
const PhysicalTimeKeeeping = (props: Props) => {
  const getData = async () => {
    await props.getPhysicianProfile();
  };

  const { physicianProfile } = props;
  useEffect(() => {
    getData();
  }, []);
  const classes = useStyles();
  const toSeconds = (str: any) => {
    str = str?.split(":");
    return +str[0] * 60 + +str[1];
  };

  const toHHss = (seconds: number) => {
    //@ts-ignore
    let minutes = parseInt(seconds / 60);
    seconds = seconds - minutes * 60;
    return minutes + ":" + seconds;
  };
  const inRange = (out: any, startTime: any, endTime: any) => {
    //@ts-ignore
    const range = out?.split(" - ");
    const min = range[0];
    const max = range[1];
    const format2 = "MM/DD/YYYY";
    const current = moment(new Date(endTime)).format(format2);
    if (current >= min && current <= max) {
      return true;
    }
    return false;
  };
  const formatActiveEndTime = (start: any, end: any) => {
    var a = start.split(":");
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    var b = end.split(":");
    var seconds2 = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];

    var date = new Date(1970, 0, 1);
    date.setSeconds(seconds + seconds2);

    var c = date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    return c;
  };
  function formatAMPM(date: any) {
    let d = new Date(0, 0, 0, date?.split(":")[0], date?.split(":")[1], date?.split(":")[2]);
    var hours = d.getHours();
    var minutes: any = d.getMinutes();
    var seconds = d.getSeconds();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return strTime;
  }
  const convertToNumber = (number: any) => {
    try {
      const clearedNumber = String(number).replace(/0+/, ""); // Clear leading 0
      return Number(clearedNumber);
    } catch (error) {
      // In case of number converting error return 0
      return 0;
    }
  };

  return (
    <>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          title="Physical Time Keeping"
          columns={[
            { title: "Name", field: "firstName", type: "string" },
            { title: "Email", field: "email", type: "string" },
            { title: "Phone", field: "phoneNumber", type: "string" },
          ]}
          data={[
            {
              firstName: physicianProfile?.firstName,
              email: physicianProfile?.email,
              phoneNumber: physicianProfile?.phoneNumber,
              dormantTime: physicianProfile?.dormantTime,
              activeTime: physicianProfile?.activeTime,
            },
          ]}
          detailPanel={[
            {
              tooltip: "Show Dormant Time and Active Time",
              render: (rowData) => {
                const output: any = {};
                const aTime: any = {};
                // eslint-disable-next-line array-callback-return
                rowData?.dormantTime?.forEach((time) => {
                  const startDate = dayjs(time?.startTime).startOf("week").format("MM/DD/YYYY");
                  const endDate = dayjs(time?.startTime).endOf("week").format("MM/DD/YYYY");
                  const [hours, mins, secs] = time?.totalTime
                    ?.split(":")
                    ?.map((number: any) => convertToNumber(number));
                  const totalTimeInSeconds = hours * 60 * 60 + mins * 60 + secs;
                  output[`${startDate} - ${endDate}`] =
                    (output[`${startDate} - ${endDate}`] || 0) + totalTimeInSeconds;
                });
                let dormant: any = [];
                Object.keys(output)?.map((out: any) => {
                  let arrTime: any = [];
                  rowData?.dormantTime?.forEach((time) => {
                    let check = inRange(out, time?.startTime, time?.endTime);
                    if (check) {
                      const end = formatAMPM(time?.endTime?.split(" ")[4]?.split(" GMT")[0]);
                      const start = formatAMPM(time?.startTime?.split(" ")[4]?.split(" GMT")[0]);
                      return arrTime.push(start + " - " + end);
                    }
                  });
                  return dormant.push({ key: out, time: arrTime });
                });

                let active: any = [];
                Object.keys(output)?.map((out: any) => {
                  let actTime: any = [];
                  rowData?.activeTime?.forEach((time) => {
                    const endTime = formatActiveEndTime(
                      time.startTime?.toString()?.split(" ")[4]?.split(" GMT")[0],
                      time.totalTime
                    );
                    // let check = inRange(out, time?.startTime ,endTime);
                    // if (check) {
                    const end = formatAMPM(endTime);
                    const start = formatAMPM(
                      time?.startTime?.toString().split(" ")[4].split(" GMT")[0]
                    );
                    return actTime.push(start + " - " + end);
                    // }
                  });
                  return active.push({ key: out, time: actTime });
                });

                const arr: any = [];
                // eslint-disable-next-line array-callback-return
                rowData?.activeTime?.forEach((time: any) => {
                  arr.push(time?.totalTime);
                  const startDate = dayjs(time?.startTime).startOf("week").format("MM/DD/YYYY");
                  const endDate = dayjs(time?.startTime).endOf("week").format("MM/DD/YYYY");
                  aTime[`${startDate} - ${endDate}`] = aTime[`${startDate} - ${endDate}`] || 0;
                });

                return (
                  <>
                    <div>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableRow>
                            <TableCell component="td">Week of</TableCell>
                            <TableCell component="td" align="center">
                              Dormant Time
                            </TableCell>
                            <TableCell component="td" align="center">
                              Active Time
                            </TableCell>
                          </TableRow>
                          <TableBody>
                            {Object.keys(output)?.map((key) => (
                              <TableRow key={key} style={{ width: "100%" }}>
                                <TableCell component="td">
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>{key}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between ",
                                        }}
                                      >
                                        <div>
                                          <Typography>
                                            {dormant?.map(
                                              (slot: any) =>
                                                slot?.key === key && (
                                                  <div key={slot.key}>
                                                    <div>Dormant Time</div>
                                                    <div>
                                                      {slot.time?.map((time: any) => {
                                                        return (
                                                          <Chip
                                                            label={time}
                                                            variant="outlined"
                                                            style={{
                                                              background: "grey",
                                                              color: "white",
                                                              margin: "5px",
                                                            }}
                                                          />
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                )
                                            )}
                                          </Typography>
                                        </div>
                                        <div>
                                          <Typography>
                                            {active?.map(
                                              (slot: any) =>
                                                slot?.key === key && (
                                                  <div key={slot?.key}>
                                                    <div>Active Time</div>
                                                    <div>
                                                      {slot?.time?.map((time: any) => {
                                                        return (
                                                          <Chip
                                                            label={time}
                                                            variant="outlined"
                                                            style={{
                                                              background: "grey",
                                                              color: "white",
                                                              margin: "5px",
                                                            }}
                                                          />
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                )
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </TableCell>
                                <TableCell component="td" align="center">
                                  {new Date(output[key] * 1000).toISOString().substr(11, 5)}
                                </TableCell>
                                <TableCell component="td" align="center">
                                  {Object.keys(aTime).includes(key)
                                    ? toHHss(
                                        arr.reduce((r: any, elem: any) => r + toSeconds(elem), 0)
                                      )
                                    : "0:0"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                );
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            showTitle: false,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
});
const mapDispatchToProps = (dispatch: any) => ({
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicalTimeKeeeping);
