import React from "react";
import { Explore as PhysicianExplore } from "../main/Explore";

export const Explore = () => {
  return (
    <>
      <PhysicianExplore />
    </>
  );
};
