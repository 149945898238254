/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Container, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React from "react";
import { useState, useRef, useEffect } from "react";
import { CustomizedButtons } from "../../components/authComponents/AuthButton";
import "fontsource-roboto";
import PatientLoginCard from "../../components/authComponents/PatientLoginCard";
import { connect } from "react-redux";
import {
  Avatar,
  Box,
  Badge,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  MenuItem,
  Paper,
  Select,
  withStyles,
} from "@material-ui/core";
import {
  AdminLoginDTO,
  AdminSignUpDTO,
  CenterLoginDTO,
  CenterSignUpDTO,
  NotificationDTO,
  PatientSignUpDTO,
  PatientSignUpInfo,
  PhysicianSignUpDTO,
  SuperLoginDTO,
  SuperSignUpDTO,
  UserLoginDTO,
} from "../../store/types/type";
import {
  authCalls as superAuthCalls,
  authSelectors as superAuthSelectors,
} from "../../store/supers/auth";
import {
  authCalls as adminAuthCalls,
  authSelectors as adminAuthSelectors,
} from "../../store/admins/auth";
import {
  authCalls as centerAuthCalls,
  authSelectors as centerAuthSelectors,
} from "../../store/centers/auth";
import {
  authCalls as patientAuthCalls,
  authSelectors as patientAuthSelectors,
} from "../../store/patients/auth";
import {
  authCalls as physicianAuthCalls,
  authSelectors as physicianAuthSelectors,
} from "../../store/physician/auth";
import { IOtp } from "../../store/types";

import { authCalls, authSelectors } from "../../store/centers/auth";
import {
  basicCalls as centerBasicCalls,
  basicSelectors as centerBasicSelectors,
} from "../../store/centers/basicCalls";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  basicCalls as superBasicCalls,
  basicSelectors as superBasicSelectors,
} from "../../store/supers/basicCalls/index";
import {
  basicCalls as adminBasicCalls,
  basicSelectors as adminBasicSelectors,
} from "../../store/admins/basicCalls";
import PatientSignUpCard from "../../components/authComponents/PatientSignUpCard";
import AdminSignupCard from "../../components/authComponents/AdminSignupCard";
import AdminLoginCard from "../../components/authComponents/AdminLoginCard";
import CenterLoginCard from "../../components/authComponents/CenterLoginCard";
import PhysicainLoginCard from "../../components/authComponents/PhysicianLoginCard";
import { CenterSignupCard } from "../../components/authComponents/CenterSignupCard";
import PhysicianSignUpCard from "../../components/authComponents/PhysicianSignupCard";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/styles";

import { parse } from "query-string";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import Logo from "../../staticResources/logos/logo.png";
import { PayPalButtonsComponentOptions } from "@paypal/paypal-js/types/components/buttons";
import axios from "axios";
import { IPatientPayment } from "../../store/types/patient";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";

import { PingProfessioanl2 } from "../../components/dashboardComponents/patient/PingProfessioanl2";
import "./MainScreen.css";
//import "../../components/dashboardComponents/main/style.css";
import { notificationSMSCalls } from "../../store/notification/sms";
import { TrialEnd } from "../../components/authComponents/TrialEnd";
import Anxiety from "../../staticResources/UserDashboard/Anxiety_Stress.jpg";
import Depression from "../../staticResources/UserDashboard/Depression.jpg";
import Gender from "../../staticResources/UserDashboard/Gender_Sexuality.jpg";
import Professional from "../../staticResources/UserDashboard/ReligiousCounseling.jpg";
import Sexual from "../../staticResources/UserDashboard/SexualHealth.jpg";
import Disease from "../../staticResources/UserDashboard/Disease_Illness.jpg";
import Diabetes from "../../staticResources/UserDashboard/Diabetes.jpg";
import Chronic from "../../staticResources/UserDashboard/ChronicDisease.jpg";
import Relationships from "../../staticResources/UserDashboard/Relationships.jpg";
import Family from "../../staticResources/UserDashboard/FamilyMatters.jpg";
import PTSD from "../../staticResources/UserDashboard/PTSD_Tramus.jpg";
import Health from "../../staticResources/UserDashboard/Health&Fitness.jpg";
import Substance from "../../staticResources/UserDashboard/SubstanceAbuse.jpg";
import Nutrition from "../../staticResources/UserDashboard/Nutrition_Diet.jpg";
import Holistic from "../../staticResources/UserDashboard/HolisticMedicine.jpg";
import Venting from "../../staticResources/UserDashboard/JustVenting.jpg";
import GeneralClinical from "../../staticResources/UserDashboard/GeneralClinicalAdvice.jpg";
import GeneralMental from "../../staticResources/UserDashboard/GeneralMentalAdvice.jpg";
import Career from "../../staticResources/UserDashboard/Professional.jpg";
import Coaching from "../../staticResources/UserDashboard/Holistic_Coaching.jpg";

import DashboardChip from "../../components/base/Components/DashboardChip";
import SideBar from "../../components/sidebars/Sidebar";
import Appbar from "../../components/navbars/Appbar";
import { USER_TYPE } from "../../store/types";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../theme/ColorPalette";
import { useDispatch } from "react-redux";
import { chatCalls as patientChatCalls } from "../../store/patients/chat";
import { Specialties2 } from "../../components/dashboardComponents/patient/Specialties2";
import FeedForPatient from "../../components/dashboardComponents/patient/FeedForPatient";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { IChat, IPatient, IPhysician, ICenter } from "../../store/types";
import { SearchPhyDTO, SearchPhyDTO2  } from "../../store/types/type";
import { onlineCalls, onlineSelectors } from "../../store/online";
import { IMessage } from "../../store/types/message";
// import { NewMessageDTO } from "../../../store/types/type";
import { chatSelectors as patientChatSelector } from "../../store/patients/chat";
import { chatCalls as physicianChatCalls } from "../../store/physician/chat";
import { chatSelectors as physicianChatSelector } from "../../store/physician/chat";
import { patientProfileCalls, patientProfileSelectors } from "../../store/patients/profile";





//import image from "../../staticResources/background/combine.png"

const useStyles = makeStyles({
  helpingMainText: {
    fontSize: " 1.3rem",
    color: "#075d19",
    fontWeight: "bolder",
    marginBottom: "15px",
    "@media (max-width: 590px)": {
      fontSize: " 0.7rem",
    },
  },
  helpingText: {
    display: "inline-block",
    fontSize: " 1.0rem",
    color: "#075d19",
    fontWeight: "bolder",
    textDecoration: "none",
    "@media (max-width: 590px)": {
      fontSize: " 0.6rem",
    },
  },
  betterToday: {
    color: "black",
    fontWeight: "normal",
    marginTop: "0",
    "@media (max-width: 590px)": {
      fontSize: "1.3rem",
      marginBottom: "1rem",
      marginTop: "0.5rem",
    },
  },
  termsOfService: {
    fontSize: "0.8rem",
    textAlignLast: "center",
    "@media (max-width: 395px)": {
      fontSize: "0.8rem",
      width: "100%",
    },
  },
  wantToLearnMore: {
    marginTop: "1rem",
    marginBottom: "0.5rem",
    textAlign: "left",
    fontSize: "1.3rem",
    "@media (max-width: 450px)": {
      fontSize: "1.3rem",
      marginTop: "1rem",
    },
  },
  goTheDeloresWebsite: {
    width: "400px",
    marginBottom: "1rem",
    fontSize: "1.2rem",
    border: "1px solid #000000",
    textAlign: "left",
    "@media (max-width: 576px)": {
      width: "250px",
      fontSize: "1rem",
    },
  },
  paypalContainer: {
    marginLeft: "0px",
    paddingLeft: "0px",
  },
  PayPalButtons: {
    borderRadius: "50px",
  },
});

const specialties = [
  { title: "General Clinical Advice", url: GeneralClinical },
  { title: "Anxiety / Stress", url: Anxiety },
  { title: "Depression", url: Depression },
  { title: "General Mental Advice", url: GeneralMental },
  { title: "Venting", url: Venting },
  { title: "Sexual Health", url: Sexual },
  { title: "Disease / Illness", url: Disease },
  { title: "Diabetes", url: Diabetes },
  { title: "Chronic Disease", url: Chronic },
  { title: "Relationships", url: Relationships },
  { title: "Family Matters", url: Family },
  { title: "PTSD/Trauma", url: PTSD },
  { title: "Health & Fitness", url: Health },
  { title: "Substance Abuse", url: Substance },
  { title: "Nutrition / Diet", url: Nutrition },
  { title: "Holistic Medicine", url: Holistic },
  { title: "Spiritual Guidance", url: Professional },
  { title: "Gender / Sexuality", url: Gender },
  { title: "Professional / Career Support", url: Career },
  { title: "Holistic Coaching", url: Coaching },
] as any;

const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);

interface DispatchProps {
  searchPhysician2: (search: SearchPhyDTO2) => Promise<void>;
  initiateRequest: (selectedPhyscian: string, initText: string) => Promise<void>;
  getPhysicians: () => Promise<void>;
  getPatient: () => Promise<void>;
  getAllCenters: any;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  handlePhysicianSelectPing: (pingDto: any) => Promise<void>;
  getOnlineUsers: () => Promise<void>;
  patientLogin: (dto: UserLoginDTO, type: string) => Promise<void>;

}


const MainScreen = (props: any) => {
  // console.log("props.centers==", props.centers);
  // console.log("props.admins==", props.admins);

  const classes = useStyles();
  const [type, setType] = useState("main");
  const [authType, setAuthType] = useState("user");
  const [titleText, setTitleText] = useState(" ");
  const location = useLocation();
  const queryParams = parse(location.search);
  const [patientEmail, setPatientEmail] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [successResponse, setSuccessResponse] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [step, setStep] = useState(0);

  const myRef = useRef<HTMLInputElement>(null);

  const [specialtiesDate, setSpecialtiesDate] = React.useState<any>({
    specialties: "",
  });

  const cardClass = useStyles();

  const history = useHistory();


  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  // const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };

  React.useEffect(() => {
    // setChecked((prev) => !prev);
    document.body.style.background = "none";
    document.body.style.backgroundColor = "none";
  }, []);

  React.useEffect(() => {
    const asyncAwait = async () => {
      await props.getAllCenters();
      await props.getOnlineUsers();
      await props.getPhysicians();
    };
    asyncAwait();
      }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.searchPhysician(specialtiesDate);
    setSpecialtiesDate({
      specialties: "",
    });
  };

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessPopup(false);
    setOpenErrorPopup(false);
  };

  const Alert = (props: AlertProps) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  };

  const changeType = (type: string) => {
    setType(type);
  };



  const getData = async () => {
    await props.getAllAdmins();
    await props.getAllCenters();
  };

  useEffect(() => {
    getData();
    // if (queryParams.email && queryParams.firstName && queryParams.id && queryParams.hash) {
    //   setAuthType("userAuth");
    //   setType("signup");
    //   setTitleText("Sign Up");
    // }
  }, []);

  const BackButton = () => {
    return (
      <IconButton
        onClick={() => {
          setType("main");
          setTitleText(" ");
        }}
        color="primary"
        aria-label="upload picture"
        component="span"
        style={{ padding: "0px", marginBottom: "5%" }}
      >
        <ArrowBackIcon fontSize="large" style={{ color: "0d5d18" }} />
      </IconButton>
    );
  };

  const PaypalButton = () => {
    const [{ isPending }] = usePayPalScriptReducer();

    const paypalbuttonTransactionProps: PayPalButtonsComponentOptions = {
      style: { layout: "vertical", label: "subscribe", shape: "pill" },

      createSubscription(_data: any, actions: any) {
        return actions.subscription.create({
          plan_id: "P-46Y80401YR107270JMHXQQZQ",
        });
      },
      onApprove(data: any, actions: any) {
        handlePaypalPayment(data);
        return actions;
      },
    };
    return (
      <>
        {isPending ? <h2>Load Smart Payment Button...</h2> : null}
        <PayPalButtons className={classes.PayPalButtons} {...paypalbuttonTransactionProps} />
      </>
    );
  };

  const handlePaypalPayment = async (details: any) => {
    const obj: IPatientPayment = {
      patientEmail: patientEmail === "" ? localStorage.getItem("patientEmail") : patientEmail,
      orderID: details.orderID,
      subscriptionID: details.subscriptionID,
    };
    try {
      const res = await axios.post(`/auth/user-payment`, obj);
      if (res.statusText === "Created") {
        setOpenSuccessPopup(true);
        setSuccessResponse(res.data);
        setType("login");
        setAuthType("userAuth");
        setTitleText("Sign In");
      }
    } catch (error: any) {
      setErrorResponse(error.response.data.message);
      setOpenErrorPopup(true);
    }
  };

  const handlePromoCode = async (e: any) => {
    e.preventDefault();
    let promoCode = "";

    if (!myRef.current) return;

    if (myRef.current) {
      promoCode = myRef.current.value.trim();
    }
    try {
      const obj = {
        email: patientEmail === "" ? localStorage.getItem("patientEmail") : patientEmail,
        code: promoCode,
      };
      const res = await axios.post(`/auth/promo-code`, obj);
      console.log("server res ", res);

      if (res.status === 201) {
        setSuccessResponse(res.data);
        setOpenSuccessPopup(true);
        setType("login");
        setTitleText("Sign In");
      }
    } catch (error: any) {
      setErrorResponse(error.response.data.message);
      setOpenErrorPopup(true);
    }
  };

  const handleTrial = async (e: any) => {
    e.preventDefault();

    try {
      const email = !patientEmail ? localStorage.getItem("patientEmail") : patientEmail;
      if (!email) return;
      const res = await axios.post(`/auth/14-day-plan`, { email: email });
      if (res.status === 201) {
        setSuccessResponse("14 day trial started successfully");
        setOpenSuccessPopup(true);
        setType("login");
        setTitleText("Sign In");
      }
    } catch (error: any) {
      setErrorResponse(error.response.data.message);
      setOpenErrorPopup(true);
    }
  };

  const [number, setNumber] = useState(null);

  const handleAddNumber = async () => {
    if (!number) return;
    const phone = "+1" + number;

    try {
      const obj = {
        email: patientEmail === "" ? localStorage.getItem("patientEmail") : patientEmail,
        number: phone,
      };
      const res = await axios.post(`/auth/user-addPhone`, obj);

      if (res.status === 201) {
        // console.log(res);
        setOpenSuccessPopup(true);
        setSuccessResponse(res.data);
        setType("paypal");
        setStep(2);
        // setAuthType("userAuth");
        // setTitleText("Sign In");
      }
    } catch (error: any) {
      setErrorResponse(error.response.data.message);
      setOpenErrorPopup(true);
    }
  };
  useEffect(() => {
    console.log(type);
  }, [type, authType]);

  return (
    <>

    <ThemeProvider theme={deloresTheme ? lightTheme : darkTheme}>
      <div >

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: deloresTheme ? "white" : "#272822",
            color: deloresTheme ? "black" : "white",
            width: "100%",
          }}
        >
          <header >
            <Appbar
              type={USER_TYPE.PATIENT}
              handleTheme={handleTheme}
              deloresTheme={deloresTheme}
              handleDrawerOpen={props.handleDrawerOpen}
            />
          </header>

        </div>
      </div>
    </ThemeProvider>

    <div style={{ overflowY: "scroll", height: "100vh" }}>
      {/* <PatientHome type={USER_TYPE.PATIENT} patientItems={Items} /> */}
      <PingProfessioanl2 />
    </div>

    <div className="flex">
      {authType === "userAuth" ? (
        <PatientLoginCard
          changeType={setType}
          successResponse={successResponse}
          patientLogin={props.patientLogin}
          loggingInPatient={props.loggingInPatient}
          patientLoggedIn={props.patientLoggedIn}
          errorWhilePatientLogin={props.errorWhilePatientLogin}
          verifyPatientOTP={props.verifyPatientOTP}
        />
      ) : authType === "adminAuth" ? (
        <AdminLoginCard
          adminLogin={props.adminLogin}
          loggingInAdmin={props.loggingInAdmin}
          adminLoggedIn={props.adminLoggedIn}
          errorWhileAdminLogin={props.errorWhileAdminLogin}
          verifyAdminOTP={props.verifyAdminOTP}
        />
      ) : authType === "providerAuth" ? (
        <CenterLoginCard
          centerLogin={props.centerLogin}
          loggingInCenter={props.loggingInCenter}
          centerLoggedIn={props.centerLoggedIn}
          errorWhileCenterLogin={props.errorWhileCenterLogin}
          verifyCenterOTP={props.verifyCenterOTP}
        />
      ) : authType === "professionalAuth" ? (
        <PhysicainLoginCard
          physicainLogin={props.physicainLogin}
          loggingInPhysician={props.loggingInPhysician}
          physicianLoggedIn={props.physicianLoggedIn}
          errorWhilePhysicianLogin={props.errorWhilePhysicianLogin}
          verifyPhysicianOTP={props.verifyPhysicianOTP}
        />
      ) : null}
    </div>

    <div className="flex flex-column">
      {authType === "userAuth" ? (
        <PatientSignUpCard
          queryParams={queryParams}
          changeType={setType}
          patientSignUp={props.patientSignUp}
          registeringPatient={props.registeringPatient}
          patientRegistered={props.patientRegistered}
          errorWhilePatientRegister={props.errorWhilePatientRegister}
          whenRegisterSuccessPatient={props.whenRegisterSuccessPatient}
          updatePatientRegister={props.updatePatientRegister}
          updateRegisterSuccess={props.updateRegisterSuccess}
          updateRegisterFailure={props.updateRegisterFailure}
          setPatientDetails={setPatientEmail}
          step={step}
          setStep={setStep}
        />
      ) : authType === "adminAuth" ? (
        <AdminSignupCard
          changeType={changeType}
          adminSignUp={props.adminSignUp}
          registeringAdmin={props.registeringAdmin}
          adminRegistered={props.adminRegistered}
          errorWhileAdminRegister={props.errorWhileAdminRegister}
          whenRegisterSuccessAdmin={props.whenRegisterSuccessAdmin}
        />
      ) : authType === "providerAuth" ? (
        <>
          <CenterSignupCard
            sendSMS={props.sendSMS}
            changeType={changeType}
            admins={props.admins}
            gettingAdmins={props.gettingAdmins}
            errorWhileGetAdmins={props.errorWhileGetAdmins}
            centerSignUp={props.centerSignUp}
            registeringCenter={props.registeringCenter}
            centerRegistered={props.centerRegistered}
            errorWhileCenterRegister={props.errorWhileCenterRegister}
            whenRegisterSuccessCenter={props.whenRegisterSuccessCenter}
          />
        </>
      ) : authType === "professionalAuth" ? (
        <PhysicianSignUpCard
          sendSMS={props.sendSMS}
          changeType={changeType}
          centers={props.centers}
          gettingCenters={props.gettingCenters}
          errorWhileGetCenters={props.errorWhileGetCenters}
          physicainSignUp={props.physicainSignUp}
          registeringPhysician={props.registeringPhysician}
          physicianRegistered={props.physicianRegistered}
          errorWhilePhysicianRegister={props.errorWhilePhysicianRegister}
          whenRegisterSuccessPhysician={props.whenRegisterSuccessPhysician}
        />
      ) : null}
    </div>



    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),



  superLogin: (superLogin: SuperLoginDTO) => dispatch(superAuthCalls.login(superLogin)),
  adminLogin: (adminLogin: AdminLoginDTO) => dispatch(adminAuthCalls.login(adminLogin)),
  centerLogin: (centerLogin: CenterLoginDTO) => dispatch(centerAuthCalls.login(centerLogin)),
  patientLogin: (userLogin: UserLoginDTO, type: string) =>
    dispatch(patientAuthCalls.login(userLogin, type)),
  physicainLogin: (userLogin: UserLoginDTO) => dispatch(physicianAuthCalls.login(userLogin)),

  verifySuperOTP: (otp: IOtp) => dispatch(superAuthCalls.verifyToken(otp)),
  verifyCenterOTP: (otp: IOtp) => dispatch(centerAuthCalls.verifyToken(otp)),
  verifyAdminOTP: (otp: IOtp) => dispatch(adminAuthCalls.verifyToken(otp)),
  verifyPatientOTP: (otp: IOtp) => dispatch(patientAuthCalls.verifyToken(otp)),
  verifyPhysicianOTP: (otp: IOtp) => dispatch(physicianAuthCalls.verifyToken(otp)),


  //register
  getAllCenters: () => dispatch(centerBasicCalls.getAllCenters()),
  getAllAdmins: () => dispatch(adminBasicCalls.getAllAdmins()),
  getPhysicians: () => dispatch(superBasicCalls.getPhysicians()),
  superSignUp: (superData: SuperSignUpDTO) => dispatch(superAuthCalls.signUp(superData)),
  adminSignUp: (adminData: AdminSignUpDTO) => dispatch(adminAuthCalls.signUp(adminData)),
  centerSignUp: (centerData: CenterSignUpDTO) => dispatch(authCalls.signUp(centerData)),
  patientSignUp: (patientData: PatientSignUpDTO, type: string) =>
    dispatch(patientAuthCalls.signUp(patientData, type)),
  physicainSignUp: (physicianData: PhysicianSignUpDTO) =>
    dispatch(physicianAuthCalls.signUp(physicianData)),
  updatePatientRegister: (data: PatientSignUpInfo) =>
    dispatch(patientAuthCalls.updatePatientRegister(data)),

  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),

});


const mapStateToProps = (state: any) => ({

  loggingInPatient: patientAuthSelectors.isLoggingIn(state.patients.auth),
  patientLoggedIn: patientAuthSelectors.hasAuthToken(state.patients.auth),
  errorWhilePatientLogin: patientAuthSelectors.hasLoginError(state.patients.auth),

  loggingInPhysician: physicianAuthSelectors.isLoggingIn(state.physicians.auth),
  physicianLoggedIn: physicianAuthSelectors.hasAuthToken(state.physicians.auth),
  errorWhilePhysicianLogin: physicianAuthSelectors.hasLoginError(state.physicians.auth),

  loggingInCenter: centerAuthSelectors.isLoggingIn(state.centers.auth),
  centerLoggedIn: centerAuthSelectors.hasAuthToken(state.centers.auth),
  errorWhileCenterLogin: centerAuthSelectors.hasLoginError(state.centers.auth),

  loggingInAdmin: adminAuthSelectors.isLoggingIn(state.admins.auth),
  adminLoggedIn: adminAuthSelectors.hasAuthToken(state.admins.auth),
  errorWhileAdminLogin: adminAuthSelectors.hasLoginError(state.admins.auth),

  loggingInSuper: superAuthSelectors.isLoggingIn(state.supers.auth),
  superLoggedIn: superAuthSelectors.hasAuthToken(state.supers.auth),
  errorWhileSuperLogin: superAuthSelectors.hasLoginError(state.supers.auth),

  //sign up
  admins: adminBasicSelectors.fetchAdmins(state.admins.basicCalls),
  errorWhileGetAdmins: adminBasicSelectors.getAdminsError(state.admins.basicCalls),
  gettingAdmins: adminBasicSelectors.gettingAdmins(state.admins.basicCalls),

  centers: centerBasicSelectors.fetchCenters(state.centers.basicCalls),
  errorWhileGetCenters: centerBasicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: centerBasicSelectors.gettingCenters(state.centers.basicCalls),
  physicians: superBasicSelectors.fetchPhysicians(state.supers.basicCalls),


  registeringPatient: patientAuthSelectors.isSigningUp(state.patients.auth),
  patientRegistered: patientAuthSelectors.signUpSuccess(state.patients.auth),
  errorWhilePatientRegister: patientAuthSelectors.hasSignUpError(state.patients.auth),
  whenRegisterSuccessPatient: patientAuthSelectors.successMessage(state.patients.auth),
  updateRegisterSuccess: patientAuthSelectors.updateRegisterSuccess(state.patients.auth),
  updateRegisterFailure: patientAuthSelectors.updateRegisterFailure(state.patients.auth),

  registeringPhysician: physicianAuthSelectors.isSigningUp(state.physicians.auth),
  physicianRegistered: physicianAuthSelectors.signUpSuccess(state.physicians.auth),
  errorWhilePhysicianRegister: physicianAuthSelectors.hasSignUpError(state.physicians.auth),
  whenRegisterSuccessPhysician: physicianAuthSelectors.successMessage(state.physicians.auth),

  registeringCenter: authSelectors.isSigningUp(state.centers.auth),
  centerRegistered: authSelectors.signUpSuccess(state.centers.auth),
  errorWhileCenterRegister: authSelectors.hasSignUpError(state.centers.auth),
  whenRegisterSuccessCenter: authSelectors.successMessage(state.centers.auth),

  registeringSuper: authSelectors.isSigningUp(state.supers.auth),
  superRegistered: authSelectors.signUpSuccess(state.supers.auth),
  errorWhileSuperRegister: authSelectors.hasSignUpError(state.supers.auth),
  whenRegisterSuccessSuper: authSelectors.successMessage(state.supers.auth),

  registeringAdmin: authSelectors.isSigningUp(state.admins.auth),
  adminRegistered: adminAuthSelectors.signUpSuccess(state.admins.auth),
  errorWhileAdminRegister: adminAuthSelectors.hasSignUpError(state.admins.auth),
  whenRegisterSuccessAdmin: adminAuthSelectors.successMessage(state.admins.auth),
});



export default connect(mapStateToProps, mapDispatchToProps)(MainScreen);
