import React from "react";
import { Setting as CenterSetting } from "../main/Setting";

export const Setting = () => {
  return (
    <>
      <CenterSetting />
    </>
  );
};
