// import classes from "*.module.css";
import { CardHeader, Grid } from "@material-ui/core";
import React from "react";
// import Zoom from "@material-ui/core/Zoom";
import "./style.css";
import { useStyles } from "./muiStyling";
import WrappedCards from "../../base/Components/ElevatedTop";
import { useHistory } from "react-router-dom";
import { USER_TYPE } from "../../../store/types";
import Logo from "../../../staticResources/logos/logo.png";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";

interface Props {
  type: any;
  centerItems?: any;
  patientItems?: any;
  physicianItems?: any;
  adminItems?: any;
  superItems?: any;
}

export function Home(props: Props) {
  const classes = useStyles();
  // const [checked, setChecked] = React.useState(false);
  const history = useHistory();

  React.useEffect(() => {
    // setChecked((prev) => !prev);
    document.body.style.background = "none";
    document.body.style.backgroundColor = "none";
  }, []);

  const handleRequestsClick = () => {
    history.push(`/${props.type?.toLowerCase()}/management/tracking/requests`);
  };
  const handleChatOrClaimedClick = () => {
    if (props.type === USER_TYPE.PHYSICIAN) history.push("/physician/portal/messages");
    else history.push("/center/management/tracking/claimed_conversation");
  };
  const handleDiscussionClick = () => {
    if (props.type === USER_TYPE.PHYSICIAN) history.push("/physician/portal/group-discussions");
    else history.push(`/${props.type?.toLowerCase()}/management/tracking/group_dicussions`);
  };
  const handleUserProfilesClick = () => {
    if (props.type === USER_TYPE.PHYSICIAN) history.push("/physician/patients-profile");
    else history.push(`/${props.type?.toLowerCase()}/management/tracking/group_dicussions`);
  };

  const componentRoute = async (title: string) => {
    switch (true) {
      case USER_TYPE.PHYSICIAN === props.type && title === "Ping":
        history.push("/physician/ping");
        break;
      case USER_TYPE.PHYSICIAN === props.type && title === "Messages":
        history.push("/physician/portal/messages");
        break;
      case USER_TYPE.PHYSICIAN === props.type && title === "Your Profile":
        history.push("/physician/profile");
        break;
      case USER_TYPE.PATIENT === props.type && title === "Contact a Provider":
        history.push("/patient/portal/selectCenter");
        break;
      case USER_TYPE.PATIENT === props.type && title === "Your Profile":
        history.push("/patient/profile");
        break;
      case USER_TYPE.PATIENT === props.type && title === "Messages":
        history.push("/patient/portal/messages");
        break;
      case USER_TYPE.PATIENT === props.type && title === "Pinned Professionals & Providers":
        history.push("/patient/portal/pin");
        break;
      case USER_TYPE.SUPER === props.type && title === "Management":
        history.push("super/management/admin");
        break;
      case USER_TYPE.SUPER === props.type && title === "Assign Providers":
        history.push("/super/assignCenters");
        break;
      case USER_TYPE.SUPER === props.type && title === "Time Keeping":
        history.push("/super/timeKeeping");
        break;
      case USER_TYPE.SUPER === props.type && title === "Scheduling":
        history.push("/super/scheduling");
        break;
      case USER_TYPE.SUPER === props.type && title === "Control Accounts":
        history.push("/super/control");
        break;
      case USER_TYPE.CENTER === props.type && title === "Management":
        history.push("/center/management");
        break;
      case USER_TYPE.CENTER === props.type && title === "Portal":
        history.push("/center/ping/start-chat");
        break;
      case USER_TYPE.CENTER === props.type && title === "Profile/Files":
        history.push("/center/profile");
        break;
      case USER_TYPE.CENTER === props.type && title === "My Professional":
        history.push("/center/management/physician-list");
        break;
      case USER_TYPE.CENTER === props.type && title === "Your Profile":
        history.push("/center/profile");
        break;
      case USER_TYPE.ADMIN === props.type && title === "Tracking":
        history.push("/admin/management/tracking");
        break;
      case USER_TYPE.ADMIN === props.type && title === "Management":
        history.push("/admin/management");
        break;
      default:
        break;
    }
  };
  return (
    <div className={classes.main}>
      <div
        style={{
          textAlign: "center",
          marginTop: window.screen.width >= 768 ? "3em" : "1.8em",
          marginBottom: "2em",
        }}
      >
        <div style={{ textAlign: "center" }}>
          <img src={Logo} alt={"delores"} style={{ width: "300px" }} />
        </div>
      </div>

      {props.type === USER_TYPE.SUPER || props.type === USER_TYPE.ADMIN ? (
        <div style={{ margin: "3em" }}>
          <Grid container spacing={3} justify="center">
            {props.type === USER_TYPE.CENTER
              ? props.centerItems?.map((item: any, i: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                    <WrappedCards
                      componentRoute={componentRoute}
                      title={item.title}
                      desc={item.desc}
                    />
                  </Grid>
                ))
              : props.type === USER_TYPE.PATIENT
              ? props.patientItems?.map((item: any, i: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                    <WrappedCards
                      componentRoute={componentRoute}
                      title={item.title}
                      desc={item.desc}
                    />
                  </Grid>
                ))
              : props.type === USER_TYPE.PHYSICIAN
              ? props.physicianItems?.map((item: any, i: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                    <WrappedCards
                      componentRoute={componentRoute}
                      title={item.title}
                      desc={item.desc}
                    />
                  </Grid>
                ))
              : props.type === USER_TYPE.ADMIN
              ? props.adminItems?.map((item: any, i: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                    <WrappedCards
                      componentRoute={componentRoute}
                      title={item.title}
                      desc={item.desc}
                    />
                  </Grid>
                ))
              : props.type === USER_TYPE.SUPER
              ? props.superItems?.map((item: any, i: number) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={i}>
                    <WrappedCards
                      componentRoute={componentRoute}
                      title={item.title}
                      desc={item.desc}
                    />
                  </Grid>
                ))
              : null}
          </Grid>
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "3.5em" }}>
          <CustomButtonGroup
            buttonType="horizontal"
            values={[
            //  "Requests",
              `${props.type === USER_TYPE.PHYSICIAN ? "Sessions" : "Claimed Conversations"}`,
          //    `${
          //      props.type === USER_TYPE.PHYSICIAN
        //          ? "Discussion Groups"
        //          : "Tracking-Claimed Conversations"
      //        }`,
              `${props.type === USER_TYPE.PHYSICIAN ? "Client Profiles" : "Professional"}`,
            ]}
            handleClicks={[
          //    handleRequestsClick,
              handleChatOrClaimedClick,
              handleUserProfilesClick,
        //      handleDiscussionClick,
            ]}
            style={{ textAlign: "center", margin: "5%  0rem" }}
          />
          <div className={classes.cardMainDiv}>
            <div className={classes.cardHeaderMainDiv}>
              <div
                // onClick={() => hanldeSpecialityClick("General Clinical Advice")}
                style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
              >
                <CardHeader
                  className={classes.cardHeader}
                  title={
                    props.type === USER_TYPE.PHYSICIAN
                      ? "Edit Your Bio and Explore The Provider Feed"
                      : "Edit Your Bio, Explore & Post To The Provider Feed"
                  }
                  titleTypographyProps={{ variant: "h6" }}
                ></CardHeader>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
