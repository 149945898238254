import * as types from "./types";

const initialState = {
  gettingAdmins: false,
  admins: [],
  getAdminsError: "",
};

export const basicReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.gettingAdmins:
      return {
        ...state,
        gettingAdmins: true,
        getAdminsError: "",
        admins: [],
      };
    case types.getAdminsFailure:
      return {
        ...state,
        gettingAdmins: false,
        getAdminsError: action.payload,
      };
    case types.getAdminsSuccess:
      return {
        ...state,
        gettingAdmins: false,
        admins: action.payload,
      };
    default: {
      return state;
    }
  }
};

// todo
// sign up 3 states
