import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { basicSelectors, basicCalls } from "../../../store/supers/basicCalls";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";
import { IAdmin, ICenter, USER_TYPE } from "../../../store/types";
interface OwnProps {
  userType: USER_TYPE.ADMIN | USER_TYPE.CENTER;
}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
  // denyAdminRequest: (id: string) => Promise<void>;
  // approveAdminRequest: (id: string) => Promise<void>;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;
}
type Props = OwnProps & StateProps & DispatchProps;
function Management(props: Props) {
  const history = useHistory();
  // const handleClickAdminButton = () => {
  //   props.getAdmins();
  //   history.push(`/super/admin`);
  // };
  // const handleClickCenterButton = () => {
  //   props.getCenters();
  //   history.push(`/super/center`);
  // };
  const handleClickA = () => {
    if (props.userType === USER_TYPE.ADMIN) {
      history.push(`/super/management/admin/request`);
    }
  };
  const handleClickB = () => {
    if (props.userType === USER_TYPE.ADMIN) {
      history.push(`/super/management/admin/tracking`);
    }
    if (props.userType === USER_TYPE.CENTER) {
      history.push(`/super/management/center/tracking`);
    }
  };
  const handleClickC = () => {
    if (props.userType === USER_TYPE.ADMIN) {
      history.push(`/super/management/admin/users`);
    }
    if (props.userType === USER_TYPE.CENTER) {
      history.push(`/super/management/center/users`);
    }
  };

  return (
    <>
      {/* <div style={{ textAlign: "center" }}>
        <CustomButtonGroup
          buttonType="horizontal"
          values={["Admins", "Centers"]}
          handleClicks={[handleClickAdminButton, handleClickCenterButton]}
          style={{ textAlign: "center", marginTop: "3%" }}
        />
      </div> */}
      <div style={{ textAlign: "center", marginTop: "2em" }}>
        {props.userType === USER_TYPE.ADMIN ? (
          <CustomButtonGroup
            buttonType="horizontal"
            values={["Request", "Tracking", "Users"]}
            handleClicks={[handleClickA, handleClickB, handleClickC]}
            style={{ textAlign: "center", marginTop: "3%" }}
          />
        ) : (
          <CustomButtonGroup
            buttonType="horizontal"
            values={["Tracking", "Users"]}
            handleClicks={[handleClickB, handleClickC]}
            style={{ textAlign: "center", marginTop: "3%" }}
          />
        )}
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  getCenters: () => dispatch(basicCalls.getCenters()),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),

  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(Management);
