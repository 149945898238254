import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { basicSelectors, basicCalls } from "../../../store/supers/basicCalls";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";
import { IAdmin, ICenter, IPatient, IPhysician } from "../../../store/types";
interface OwnProps {}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
  getPatients: () => Promise<void>;
  getPhysicians: () => Promise<void>;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;

  gettingPatients: boolean;
  fetchPatients: IPatient[];
  getPatientsError: string;

  gettingPhysicians: boolean;
  fetchPhysicians: IPhysician[];
  getPhysiciansError: string;
}
type Props = OwnProps & StateProps & DispatchProps;
function TimeControlAccount(props: Props) {
  const history = useHistory();

  const handleClickB = () => {
    props.getCenters();
    history.push(`/super/timekeeping/centers`);
  };

  const handleClickD = () => {
    props.getPhysicians();
    history.push(`/super/timekeeping/physician`);
  };
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "2em" }}>
        <CustomButtonGroup
          buttonType="horizontal"
          values={["Provider", "Professional"]}
          handleClicks={[handleClickB, handleClickD]}
          style={{ textAlign: "center", marginTop: "3%" }}
        />
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getCenters: () => dispatch(basicCalls.getCenters()),
  getPhysicians: () => dispatch(basicCalls.getPhysicians()),
});
const mapStateToProps = (state: any) => ({
  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),

  fetchPhysicians: basicSelectors.fetchPhysicians(state.supers.basicCalls),
  gettingPhysicians: basicSelectors.gettingPhysicians(state.supers.basicCalls),
  getPhysiciansError: basicSelectors.getPhysiciansError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(TimeControlAccount);
