import { IPatient } from "../../types/patient";
import { IFileData, IPhysician } from "../../types";
import * as types from "./types";

export const patientProfileCalling = () => ({
  type: types.patientProfileCalling,
});
export const patientProfileSuccess = (profile: IPatient) => ({
  type: types.patientProfileSuccess,
  payload: profile,
});

export const patientProfileFailure = (errorMessage: string) => ({
  type: types.patientProfileFailure,
  payload: errorMessage,
});

export const patientProfileUpdating = () => ({
  type: types.patientProfileUpdating,
});
export const patientProfileUpdateSuccess = (updatedProfile: IPatient) => ({
  type: types.patientProfileUpdateSuccess,
  payload: updatedProfile,
});
export const patientProfileUpdateFailure = (errorMessage: string) => ({
  type: types.patientProfileUpdateFailure,
  payload: errorMessage,
});

export const patientNumberUpdating = () => ({
  type: types.patientNumberUpdating,
});
export const patientNumberUpdateSuccess = (updatedProfile: IPatient) => ({
  type: types.patientNumberUpdateSuccess,
  payload: updatedProfile,
});
export const patientNumberUpdateFailure = (errorMessage: string) => ({
  type: types.patientNumberUpdateFailure,
  payload: errorMessage,
});

export const verifyNumberUpdating = () => ({
  type: types.patientNumberVerificationUpdating,
});
export const verifyNumberUpdatingSuccess = (updatedProfile: IPatient) => ({
  type: types.patientNumberVerificationUpdateSuccess,
  payload: updatedProfile,
});
export const verifyNumberUpdatingFailure = (errorMessage: string) => ({
  type: types.patientNumberVerificationUpdateFailure,
  payload: errorMessage,
});

// update the privacy of uploaded file
export const updatePatientFilePrivacyCalling = () => ({
  type: types.updatePatientFilePrivacyCalling,
});
export const updatePatientFilePrivacySuccess = (updatedPatientFile: IFileData) => ({
  type: types.updatePatientFilePrivacySuccess,
  payload: updatedPatientFile,
});

export const updateCenterFilePrivacyFailure = (errorMessage: string) => ({
  type: types.updatePatientFilePrivacyFailure,
  payload: errorMessage,
});

export const searchPhysicianCalling = () => ({
  type: types.searchPhysicianCalling,
});

export const searchPhysicianSuccess = (physician: IPhysician | IPhysician[] | string) => ({
  type: types.searchPhysicianSuccess,
  payload: physician,
});

export const searchPhysicianFailure = (errorMessage: string) => ({
  type: types.searchPhysicianFailure,
  payload: errorMessage,
});
