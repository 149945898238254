import axios from "axios";
import { AdminUpdateDTO } from "../../types/type";
import * as actions from "./actions";

export const getAdminProfile = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.adminProfileCalling());
  try {
    const response = await axios.get(`/admins`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.adminProfileSuccess(response.data));
    } else throw new Error("Unable to get admin Profile");
  } catch (err: any) {
    dispatch(actions.adminProfileFailure(err.message));
  }
};

export const updateAdminProfile = (adminUpdateDTO: AdminUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.adminProfileUpdating());
  try {
    const response = await axios.patch(`/admin`, adminUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.adminProfileUpdateSuccess(response.data));
    } else throw new Error("Unable to update admin Profile");
  } catch (err: any) {
    dispatch(actions.adminProfileUpdateFailure(err.message));
  }
};

export const denycenterRequest = (centerID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateCenterRequest());
  try {
    const response = await axios.patch(
      `/admins/deny/${centerID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.adminProfileSuccess(response.data));
      dispatch(actions.updateCenterRequestSuccess());
    } else throw new Error("Unable to update Provider request status");
  } catch (err: any) {
    dispatch(actions.updateCenterRequestFailure(err.message));
  }
};
export const approveCenterRequest = (centerID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateCenterRequest());
  try {
    const response = await axios.patch(
      `/admins/approve/${centerID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.adminProfileSuccess(response.data));
      dispatch(actions.updateCenterRequestSuccess());
    } else throw new Error("Unable to update Provider request status");
  } catch (err: any) {
    dispatch(actions.updateCenterRequestFailure(err.message));
  }
};

//////update file privacy////
export const updateAdminFilePrivacy = (fileId: string, fileState: boolean) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateAdminFilePrivacyCalling());
  try {
    const response = await axios.patch(
      `/files-manager/change-privacy/${fileId}`,
      { isPublic: fileState ? 1 : 0 },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updateAdminFilePrivacySuccess(response.data));
    } else throw new Error("Unable to update Provider request status");
  } catch (err: any) {
    dispatch(actions.updateAdminFilePrivacyFailure(err.message));
  }
};
