export const physicianProfileCalling = "@/physician/@profileCalling";
export const physicianProfileSuccess = "@/physician/@profileSuccess";
export const physicianProfileFailure = "@/physician/@profileFailure";

export const physicianProfileUpdating = "@/physician/@physicianProfileUpdating";
export const physicianProfileUpdateSuccess = "@/physician/@physicianProfileUpdateSuccess";
export const physicianProfileUpdateFailure = "@/physician/@physicianProfileUpdateFailure";

export const professionalNumberUpdating = "@/physician/@professionalNumberUpdating";
export const professionalNumberUpdateSuccess = "@/physician/@professionalNumberUpdateSuccess";
export const professionalNumberUpdateFailure = "@/physician/@professionalNumberUpdateFailure";

export const professionalNumberVerificationUpdating =
  "@/physician/@professionalNumberVerificationUpdating";
export const professionalNumberVerificationUpdateSuccess =
  "@/physician/@professionalNumberVerificationUpdateSuccess";
export const professionalNumberVerificationUpdateFailure =
  "@/physician/@professionalNumberVerificationUpdateFailure";

export const updatePhysicianFilePrivacyCalling = "@/Physician/@updatePhysicianFilePrivacyCalling";
export const updatePhysicianFilePrivacySuccess = "@/Physician/@updatePhysicianFilePrivacySuccess";
export const updatePhysicianFilePrivacyFailure = "@/Physician/@updatePhysicianFilePrivacyFailure";

export const registerInUser = "@physician/registerInUser";
export const registerInUserSuccess = "@physician/registerInUserSuccess";
export const registerInUserFailure = "@physician/registerInUserFailure";
