import { Box } from "@material-ui/core";
import React from "react";

interface Props {
  handleClick: () => void;
  text: string;
  margin: string;
}
export default function TextHoverElevated(props: Props) {
  const [shadowed, setShadowed] = React.useState(0);
  return (
    <Box
      boxShadow={shadowed}
      width={"fit-content"}
      margin={props.margin}
      onMouseOver={() => setShadowed(3)}
      onMouseLeave={() => setShadowed(0)}
      onClick={props.handleClick}
    >
      {props.text}
    </Box>
  );
}
