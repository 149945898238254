import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  Dialog,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import CloseIcon from "@material-ui/icons/Close";
import ChatIcon from "@material-ui/icons/Chat";
import { connect } from "react-redux";
import { feedCalls, feedSelectors } from "../../../store/feed";
import { patientProfileSelectors } from "../../../store/patients/profile";
import { IChat, IFeed, IPatient } from "../../../store/types";
import { useHistory } from "react-router-dom";
import { onlineCalls, onlineSelectors } from "../../../store/online";

function TabPanel(props: { [x: string]: any; index: any; children?: any; value?: any }) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}
const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tab: {
    flexGrow: 1,
  },
  paper: {
    margin: 20,
    height: 180,
    width: 260,
  },
  pCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  avatar: {
    width: 60,
    height: 60,
  },
  media: {
    height: "400px",
  },
  card: {
    maxWidth: 550,
    margin: "0 auto",
  },
  multiline: {
    whiteSpace: "pre-line",
  },
}));

interface StateProps {
  getOnlineUsers: () => Promise<void>;
  getAllFeeds: () => Promise<void>;
  isOnlineSuccess: string[];
  patientProfile?: IPatient;
  getAllFeedsSuccess?: IFeed[] | undefined;
}
type Props = StateProps;
export const PinProviderProfessional = (props: Props) => {
  let history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [feedData, setFeedData] = React.useState<IFeed[]>([]);

  const centerInfo = props.patientProfile?.pinProvider?.map((centerID) => {
    return props.getAllFeedsSuccess?.filter((feed) => {
      return feed.center._id === centerID;
    });
  });
  const handleClickOpen = (id: any) => {
    props.getAllFeeds();
    setOpen(true);
    setFeedData(
      props.getAllFeedsSuccess!.filter((feed) => {
        if (feed.center._id === id.center._id) {
          return [feed];
        } else {
          return null;
        }
      })
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChat = () => {
    if ((props.patientProfile?.chats.length as unknown) as IChat[]) {
      history.push("/patient/portal/messages");
    } else {
      history.push("/patient/portal/selectCenter");
    }
  };

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    props.getAllFeeds();
    props.getOnlineUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const classes = useStyles();
  const handleTabChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };
  const onlineNow = props.isOnlineSuccess;
  return (
    <Paper className={classes.tab}>
      <Tabs
        value={value}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Provider Pin" {...a11yProps(0)} />
        <Tab label="Professional Pin" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.pCenter}>
              {centerInfo?.map((center: any) => {
                return (
                  <Grid alignContent="center" item>
                    <Paper className={classes.paper} elevation={3}>
                      <CardHeader
                        avatar={
                          onlineNow.includes(center._id) ? (
                            <StyledOnlineBadge
                              variant={onlineNow.includes(center._id) ? "dot" : "standard"}
                            >
                              <Avatar
                                src={
                                  center
                                    ?.find(
                                      (feed: { center: { _id: any } }) =>
                                        feed.center._id === center[0].center._id
                                    )
                                    ?.center.files.filter((f: { isProfile: any }) => f.isProfile)[0]
                                    ?.url || `https://via.placeholder.com/150?text=Profile`
                                }
                              ></Avatar>
                            </StyledOnlineBadge>
                          ) : (
                            <StyledOffLineBadge variant="dot">
                              <Avatar
                                src={
                                  center
                                    ?.find(
                                      (feed: { center: { _id: any } }) =>
                                        feed.center._id === center[0].center._id
                                    )
                                    ?.center.files.filter((f: { isProfile: any }) => f.isProfile)[0]
                                    ?.url || `https://via.placeholder.com/150?text=Profile`
                                }
                              ></Avatar>
                            </StyledOffLineBadge>
                          )
                        }
                        onClick={() =>
                          handleClickOpen(
                            center?.find(
                              (feed: { center: { _id: any } }) =>
                                feed.center._id === center[0].center._id
                            )
                          )
                        }
                        title={
                          center?.find(
                            (feed: { center: { _id: any } }) =>
                              feed.center._id === center[0].center._id
                          )?.center.name
                        }
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        // eslint-disable-next-line no-new-wrappers
                        subheader={
                          // eslint-disable-next-line no-new-wrappers
                          new String(
                            center?.find(
                              (feed: { center: { _id: any } }) =>
                                feed.center._id === center[0].center._id
                            )?.center.bio
                          ).length > 70 ? (
                            <Tooltip
                              title={
                                center?.find(
                                  (feed: { center: { _id: any } }) =>
                                    feed.center._id === center[0].center._id
                                )?.center.bio
                              }
                            >
                              {
                                <p> hover to show bio </p>
                                // eslint-disable-next-line no-new-wrappers
                              }
                            </Tooltip>
                          ) : // eslint-disable-next-line no-new-wrappers
                          new String(
                              center?.find(
                                (feed: { center: { _id: any } }) =>
                                  feed.center._id === center[0].center._id
                              )?.center.bio
                            ).length < 0 ? (
                            center.bio
                          ) : (
                            "No Bio"
                          )
                        }
                        style={{ cursor: "pointer" }}
                      />
                      <CardContent>
                        <Typography variant="body2">{`Provider Phone : ${
                          center?.find(
                            (feed: { center: { _id: any } }) =>
                              feed.center._id === center[0].center._id
                          )?.center.phoneNumber
                        }`}</Typography>
                        <Typography variant="body2">{`Provider Address : ${
                          center?.find(
                            (feed: { center: { _id: any } }) =>
                              feed.center._id === center[0].center._id
                          )?.center.address
                        }`}</Typography>
                      </CardContent>
                    </Paper>
                    <Dialog fullScreen open={open} onClose={handleClose}>
                      <AppBar
                        style={{
                          backgroundColor: "#1a8c1e",
                          color: "white",
                        }}
                      >
                        <Toolbar style={{ display: "flex", justifyContent: "space-around" }}>
                          <div>
                            <IconButton
                              edge="start"
                              color="inherit"
                              onClick={handleClose}
                              aria-label="close"
                            >
                              <CloseIcon />
                            </IconButton>
                          </div>
                          <div>
                            <Typography
                              style={{
                                display: "flex",
                              }}
                              variant="h6"
                              component="div"
                            >
                              Bio Page
                            </Typography>
                          </div>
                          <div>
                            <Button
                              autoFocus
                              variant="outlined"
                              color="inherit"
                              endIcon={<ChatIcon />}
                              onClick={handleChat}
                            >
                              chat
                            </Button>
                          </div>
                        </Toolbar>
                      </AppBar>
                      <Container>
                        <Grid container>
                          <Grid
                            item
                            xs={12}
                            style={{
                              marginTop: "75px",
                              marginInlineStart: "auto",
                            }}
                          >
                            <Grid xs={12}>
                              <Card
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "12px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-evenly",
                                    borderBottom: "4px solid #ccc",
                                    paddingBottom: "40px",
                                    marginBottom: "30px",
                                  }}
                                >
                                  <Avatar
                                    style={{
                                      width: "120px",
                                      height: "120px",
                                      boxShadow:
                                        "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
                                    }}
                                    alt="provider pic"
                                    src={
                                      feedData[0]?.center?.files.filter((f) => f.isProfile)[0]
                                        ?.url || `https://via.placeholder.com/150?text=Profile`
                                    }
                                  />
                                  <div>
                                    <Typography
                                      variant="h5"
                                      component="h2"
                                      style={{
                                        fontWeight: "bold",
                                        marginTop: "20px",
                                      }}
                                    >
                                      {`Provider Name : ${feedData[0]?.center?.name}`}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      {`Provider Address : ${feedData[0]?.center?.address}`}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      {`Provider Phone : ${feedData[0]?.center?.phoneNumber}`}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      color="textSecondary"
                                      component="p"
                                      style={{
                                        marginTop: "20px",
                                      }}
                                    >
                                      {`Provider Bio : ${
                                        feedData[0]?.center?.bio
                                          ? feedData[0]?.center?.bio
                                          : "No Bio"
                                      }`}
                                    </Typography>
                                  </div>
                                </div>
                                <div>
                                  {feedData.map((feed, i) => {
                                    return (
                                      <Card className={classes.card}>
                                        <CardHeader
                                          avatar={
                                            onlineNow.includes(feed.center._id) ? (
                                              <StyledOnlineBadge
                                                variant={
                                                  onlineNow.includes(feed.center._id)
                                                    ? "dot"
                                                    : "standard"
                                                }
                                              >
                                                <Avatar
                                                  src={
                                                    feed?.center?.files.filter((f: any) => {
                                                      return f.isProfile;
                                                    })[0]?.url ||
                                                    `https://via.placeholder.com/150?text=Profile`
                                                  }
                                                ></Avatar>
                                              </StyledOnlineBadge>
                                            ) : (
                                              <StyledOffLineBadge variant="dot">
                                                <Avatar
                                                  src={
                                                    feed?.center?.files.filter((f: any) => {
                                                      return f.isProfile;
                                                    })[0]?.url ||
                                                    `https://via.placeholder.com/150?text=Profile`
                                                  }
                                                ></Avatar>
                                              </StyledOffLineBadge>
                                            )
                                          }
                                          title={feed?.title}
                                          subheader={`Provider : ${
                                            feed?.center?.name
                                          }-${feed?.createdAt.slice(0, 10)}`}
                                        />
                                        {feed?.file &&
                                          (feed?.file.url?.includes("mp4") ? (
                                            <video
                                              src={feed?.file.url}
                                              width="100%"
                                              height="100%"
                                              controls={true}
                                            />
                                          ) : (
                                            <CardMedia
                                              className={classes.media}
                                              component="img"
                                              image={feed?.file.url}
                                              title={feed?.file.name}
                                            />
                                          ))}
                                        <CardContent>
                                          <Typography
                                            variant="body2"
                                            className={classes.multiline}
                                            dangerouslySetInnerHTML={{
                                              __html: feed?.description as any,
                                            }}
                                          />
                                        </CardContent>
                                      </Card>
                                    );
                                  })}
                                </div>
                              </Card>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Container>
                    </Dialog>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Grid container className={classes.root}>
          <Grid item xs={12}>
            <Grid container spacing={2} className={classes.pCenter}>
              {/* {props.patientProfile?.pinProvider?.map((value) => (
                <Grid key={value} alignContent="center" item>
                  <Paper className={classes.paper} elevation={3}>
                    <CardHeader
                      avatar={
                        <StyledBadge
                          // anchorOrigin={{
                          //   vertical: "bottom",
                          //   horizontal: "right",
                          // }}
                          variant="dot"
                        >
                          <Avatar>R</Avatar>
                        </StyledBadge>
                      }
                      title="Shrimp and Chorizo Paella"
                      subheader="September 14, 2016"
                    />
                    <CardContent>
                      <Typography>DSFDSF</Typography>
                    </CardContent>
                  </Paper>
                </Grid>
              ))} */}
            </Grid>
          </Grid>
        </Grid>
      </TabPanel>
    </Paper>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAllFeeds: () => dispatch(feedCalls.getAllFeeds()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
});

const mapStateToProps = (state: any) => ({
  getAllFeedsSuccess: feedSelectors.getAllFeedsSuccess(state.feeds),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
});

export default connect(mapStateToProps, mapDispatchToProps)(PinProviderProfessional);
