import axios from "axios";
import { CenterUpdateDTO, NumberUpdateDTO, VerifyUpdatedNumberDTO } from "../../types/type";
import * as actions from "./actions";

export const getCenterProfile = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.centerProfileCalling());
  try {
    const response = await axios.get(`/center`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.centerProfileSuccess(response.data));
    } else throw new Error("Unable to get Provider Profile");
  } catch (err: any) {
    dispatch(actions.centerProfileFailure(err.message));
  }
};

export const updateCenterProfile = (centerUpdateDTO: CenterUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.centerProfileUpdating());
  try {
    const response = await axios.patch(`/center`, centerUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.centerProfileUpdateSuccess(response.data));
    } else throw new Error("Unable to update Provider Profile");
  } catch (err: any) {
    dispatch(actions.centerProfileUpdateFailure(err.message));
  }
};

export const updateNumber = (numberUpdateDTO: NumberUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.centerNumberUpdating());
  try {
    const response = await axios.patch(`/auth/update-number`, numberUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.centerNumberUpdateSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.centerNumberUpdateFailure(err.message));
  }
};

export const verifyUpdatedNumber = (verifyUpdatedNumberDTO: VerifyUpdatedNumberDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.verifyNumberUpdating());
  try {
    const response = await axios.post(`/auth/verify-update-number`, verifyUpdatedNumberDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.verifyNumberUpdatingSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.verifyNumberUpdatingFailure(err.message));
  }
};

export const denyPhysicianRequest = (physicianID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updatePhysicianRequest());
  try {
    const response = await axios.patch(
      `/management/deny-physician/${physicianID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.centerProfileSuccess(response.data));
      dispatch(actions.updatePhysicianRequestSuccess());
    } else throw new Error("Unable to update physician request status");
  } catch (err: any) {
    dispatch(actions.updatePhysicianRequestFailure(err.message));
  }
};
export const approvePhysicianRequest = (physicianID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updatePhysicianRequest());
  try {
    const response = await axios.patch(
      `/management/approve-physician/${physicianID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.centerProfileSuccess(response.data));
      dispatch(actions.updatePhysicianRequestSuccess());
    } else throw new Error("Unable to update physician request status");
  } catch (err: any) {
    dispatch(actions.updatePhysicianRequestFailure(err.message));
  }
};

//////update file privacy////
export const updateCenterFilePrivacy = (fileId: string, fileState: boolean) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateCenterFilePrivacyCalling());
  try {
    const response = await axios.patch(
      `/files-manager/change-privacy/${fileId}`,
      { isPublic: fileState ? 1 : 0 },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updateCenterFilePrivacySuccess(response.data));
    } else throw new Error("Unable to update physician request status");
  } catch (err: any) {
    dispatch(actions.updateCenterFilePrivacyFailure(err.message));
  }
};
