/* eslint-disable array-callback-return */
import React, { useEffect, useState } from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { adminItems, centerItems, patientItems, physicianItems, superItems } from "./_nav";
import { Badge, Collapse } from "@material-ui/core";
import io from "socket.io-client";
import "./sidebar.css";
import { useSelector } from "react-redux";
const drawerWidth = "55%";

const URL = `${process.env.REACT_APP_API_URL}/chat`;
// const socket = io(URL);
const socket = io(URL, {
  transports: ["websocket"],
  multiplex: false,
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      marginTop: "3em",

      backgroundColor: "#2F4050",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      padding: theme.spacing(3),
      // width: 240,
      flexShrink: 0,

      backgroundColor: "#2F4050",
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: "black",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: "theme.palette.background.default",
      padding: theme.spacing(3),
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    nested: {
      paddingLeft: theme.spacing(4),
      color: "white",
    },
    listItem: {
      color: "white",
      padding: "1rem",
      fontSize: "2rem",
    },
    navList: {
      maxHeight: "auto",
      overflow: "scroll",
      "@media (max-width: 600px)": {
        maxHeight: "520px",
      },
    },
  })
);

export default function SimpleList(props: any) {
  const currId = localStorage.getItem("_id");
  const [messagesNotifications, setMessagesNotifications] = useState(0);
  const [pingNotifications, setPingNotifications] = useState(0);
  const {
    chat: { chats },
  } = useSelector((state: any) => state.physicians);

  useEffect(() => {
    socket.on("messagesNotifications", (payload: any) => {
      try {
        const patientMessages = payload?.filter((item: any) =>
          Object.keys(item).includes("fromPatient")
        );
        const chatID = patientMessages[0]?.chatID;
        const found = chats?.some((chat: any) => chat._id === chatID);
        if (found) {
          setMessagesNotifications(patientMessages.length);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, [chats, currId]);

  useEffect(() => {
    socket.on("pingNotifications", (payload: any) => {
      try {
        let proMessages;
        if (payload[0].current === "PHYSICIAN") {
          proMessages = payload?.filter((item: any) => Object.keys(item).includes("fromPhysician"));
        }
        if (payload[0].current === "CENTER") {
          proMessages = payload?.filter((item: any) => Object.keys(item).includes("fromCenter"));
        }
        const chatID = proMessages[0]?.chatID;
        const currID = proMessages[0]?.currID;
        const found = chats?.some((chat: any) => chat._id === chatID);
        if (found) {
          if (currID !== currId) setPingNotifications(proMessages.length);
        }
      } catch (error) {
        console.log(error);
      }
    });
  }, [chats, currId]);

  const classes = useStyles();
  const items =
    props.type === "patient"
      ? patientItems
      : props.type === "physician"
      ? physicianItems
      : props.type === "admin"
      ? adminItems
      : props.type === "super"
      ? superItems
      : centerItems;
  const [nestedItemsOpenStatus, setNestedItemsOpenStatus] = React.useState<{
    [routeName: string]: boolean;
  }>(items.reduce((obj, item) => Object.assign(obj, { [item.name]: false }), {}));

  return (
    <div className={classes.toolbar}>
      <Divider
        style={{
          backgroundColor: "white",
          width: "95%",
          marginLeft: "0.2em",
          marginBottom: "1em",
        }}
      />

      <List component="nav" aria-label="main mailbox folders" className={classes.navList}>
        {items.map((item: any, i: number): JSX.Element | undefined => {
          if (!item.nestedItems?.length) {
            return (
              <ListItem
                key={i}
                className={classes.listItem}
                button
                onClick={() => {
                  props.history?.push(item.url);
                  if (item.name === "Messages") {
                    setMessagesNotifications(0);
                    socket.emit("clearMessagesNotifications");
                  }
                }}
              >
                {item.name === "Messages" ? (
                  <Badge badgeContent={messagesNotifications} color="primary">
                    <ListItemIcon>
                      <item.icon style={{ color: "white" }} />
                    </ListItemIcon>
                  </Badge>
                ) : (
                  <ListItemIcon>
                    <item.icon style={{ color: "white" }} />
                  </ListItemIcon>
                )}

                <ListItemText
                  primary={item.name}
                  onClick={() =>
                    props.handleDrawerOpen && window.screen.width <= 768
                      ? props.handleDrawerOpen()
                      : ""
                  }
                />
              </ListItem>
            );
          } else {
            return (
              <>
                <ListItem
                  style={{ color: "white" }}
                  className={classes.listItem}
                  button
                  onClick={() => {
                    props.history?.push(item.url);
                    setNestedItemsOpenStatus({
                      ...nestedItemsOpenStatus,
                      [item.name]: !nestedItemsOpenStatus[item.name],
                    });
                  }}
                >
                  {item.name === "Ping" ? (
                    <Badge badgeContent={pingNotifications} color="primary">
                      <ListItemIcon>
                        <item.icon style={{ color: "white" }} />
                      </ListItemIcon>
                    </Badge>
                  ) : (
                    <ListItemIcon>
                      <item.icon style={{ color: "white" }} />
                    </ListItemIcon>
                  )}

                  <ListItemText primary={item.name} />
                </ListItem>
                <Collapse in={nestedItemsOpenStatus[item.name]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {item.nestedItems.map((nestedI: any, i: number) => {
                      return (
                        <ListItem
                          key={i}
                          className={classes.nested}
                          style={{ paddingLeft: "2rem" }}
                          button
                          onClick={() => {
                            props.history?.push(nestedI.url);
                            if (item.name === "Ping") {
                              setPingNotifications(0);
                              socket.emit("clearPingNotifications");
                            }
                          }}
                        >
                          <ListItemIcon>
                            <nestedI.icon style={{ color: "white" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={nestedI.name}
                            onClick={() =>
                              props.handleDrawerOpen && window.screen.width <= 768
                                ? props.handleDrawerOpen()
                                : ""
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Collapse>
              </>
            );
          }
        })}
      </List>
    </div>
  );
}

export function NestedItems(item: any) {
  item.nestedItems.map((i: any, key: number) => {
    return (
      <ListItem button key={key}>
        <ListItemIcon>
          <i.icon />
        </ListItemIcon>
        <ListItemText primary={i.name} />
      </ListItem>
    );
  });
}
