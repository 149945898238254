/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { connect } from "react-redux";
import {
  Button,
  createStyles,
  makeStyles,
  Theme,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  TextField,
  Container,
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardHeader,
  Avatar,
  Switch,
  Grid,
  Paper,
  Tabs,
  Tab,
  LinearProgress,
  withStyles,
  Badge,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import PublishIcon from "@material-ui/icons/Publish";
import { feedCalls, feedSelectors } from "../../../../store/feed";
import { IFeed } from "../../../../store/types";
import axios from "axios";
import { Alert, AlertTitle } from "@material-ui/lab";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { UserCard } from "../../../UserCard";
import { onlineCalls, onlineSelectors } from "../../../../store/online";
interface OwnProps {}
interface DispatchProps {
  getAllFeeds: () => Promise<void>;
  getOnlineUsers: () => Promise<void>;
  updateCenterFileIsMass: (id: string, fileState: boolean) => Promise<void>;
  removeOneFeedSuccess: (feedId: string, fileId?: string) => Promise<void>;
}

interface StateProps {
  getAllFeedsSuccess: IFeed[];
  isOnlineSuccess: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    media: {
      height: 400,
      objectFit: "fill",
    },
    container: {
      marginTop: 30,
    },
    button: {
      margin: theme.spacing(1),
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      width: "200px",
      height: "44px",
      borderRadius: "28px",
    },
    card: {
      maxWidth: 550,
      margin: "0 auto",
    },
    removeIcon: {
      marginTop: "10px",
      color: "#d01616",
    },
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
    multiline: {
      whiteSpace: "pre-line",
    },
    feedTitle: {
      fontWeight: "bold",
    },
  })
);
const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);

type Props = OwnProps & StateProps & DispatchProps;
function FeedsUpload(props: Props) {
  useEffect(() => {
    props.getAllFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useMemo(() => {
    props.getOnlineUsers();
    return props.isOnlineSuccess;
  }, []);
  const getID = localStorage.getItem("_id");

  const myFeeds = props?.getAllFeedsSuccess.filter((isCenter) => {
    return isCenter?.center?._id === getID;
  });

  const otherFeed = props?.getAllFeedsSuccess.filter((isCenter) => {
    if (isCenter.isMass === true && isCenter?.center?._id !== getID) {
      return isCenter;
    } else {
      return false;
    }
  });
  const [open, setOpen] = useState(false);

  const [file, setFile] = useState<File>();
  const [title, setTitle] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [status, setStatus] = useState<"FAIL" | "SUCCESS" | "PENDING" | "REQUIRE" | "LIMIT" | null>(
    null
  );
  const [value, setValue] = React.useState(1);
  const [uploadPercent, setUploadPercent] = useState<number>(0);
  const [disable, setDisable] = React.useState(false);

  const modules = {
    toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["link"]],
  };

  const formats = ["bold", "italic", "underline", "list", "link"];

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: { [x: string]: any; index: any; children?: any; value?: any }) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  const uploadFeedFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(e.currentTarget.files && e.currentTarget.files.length > 0)) return;
    const file = e.currentTarget.files[0];

    if (file.size > 3145728000) {
      setStatus("LIMIT");
      setDisable(true);
    }
    setFile(file);
  };

  const handleChangeFile = async (event: { currentTarget: { name: string } }, checked: boolean) => {
    await props.updateCenterFileIsMass(event.currentTarget.name, checked);
    await props.getAllFeeds();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setFile(undefined);
    setTitle("");
    setDescription("");
    setOpen(false);
    setStatus(null);
    setDisable(false);
  };

  const handleRemoveFile = async (feedId: string, fileId?: string) => {
    await props.removeOneFeedSuccess(feedId, fileId);
    await props.getAllFeeds();
  };
  const handleSubmit = async () => {
    try {
      setDisable(true);
      setStatus("PENDING");
      const formData = new FormData();
      if (file) {
        formData.append("fileField", file);
      }
      formData.append("description", description);
      formData.append("title", title);

      await axios
        .post("/feeds/new", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
          },
          timeout: 0,
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("content-length") ||
                progressEvent.target.getResponseHeader("x-decompressed-content-length");
            console.log("onUploadProgress", totalLength);
            if (totalLength !== null) {
              setUploadPercent(Math.round((progressEvent.loaded * 100) / totalLength));
            }
          },
        })
        .then((res) => {
          if (res.status === 201) {
            setStatus("SUCCESS");
            props.getAllFeeds();
            setTimeout(() => {
              setFile(undefined);
              setTitle("");
              setDescription("");
              setStatus(null);
              setOpen(false);
              setDisable(false);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(".catch ", err);
          setStatus("FAIL");
        });
    } catch (error) {
      console.log(error);
      setStatus("FAIL");
    }
  };

  const classes = useStyles();
  const onlineNow = props.isOnlineSuccess;
  return (
    <div>
      {/* <Typography variant="h6" align={"center"} style={{ color: "#000" }}>
        Delores Digital Feed
      </Typography> */}
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="public feeds" {...a11yProps(0)} />
          <Tab label="my feeds" {...a11yProps(1)} />
          <Tab label="bio" {...a11yProps(2)} />
        </Tabs>
      </Paper>
      <TabPanel value={value} index={0}>
        <div style={{ height: "85vh", overflowY: "scroll" }}>
          {otherFeed.map((data: IFeed, i) => {
            return (
              <Container className={classes.container} key={i}>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      onlineNow.includes(data.center._id) ? (
                        <StyledOnlineBadge
                          variant={onlineNow.includes(data.center._id) ? "dot" : "standard"}
                        >
                          <Avatar
                            alt={data?.file?.name}
                            src={
                              data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                              `https://via.placeholder.com/150?text=Profile`
                            }
                          />
                        </StyledOnlineBadge>
                      ) : (
                        <StyledOffLineBadge variant="dot">
                          <Avatar
                            alt={data?.file?.name}
                            src={
                              data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                              `https://via.placeholder.com/150?text=Profile`
                            }
                          />
                        </StyledOffLineBadge>
                      )
                    }
                    title={data.title}
                    subheader={`Provider :${data?.center?.name}-${data.createdAt.slice(0, 10)}`}
                    classes={{
                      title: classes.feedTitle,
                    }}
                  />
                  {data?.file &&
                    (data?.file.url?.includes("mp4") ? (
                      <video src={data.file.url} width="100%" height="100%" controls={true} />
                    ) : (
                      <CardMedia
                        className={classes.media}
                        component="img"
                        image={data.file.url}
                        title={data.file.name}
                      />
                    ))}
                  <CardContent>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      className={classes.multiline}
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </CardContent>
                </Card>
              </Container>
            );
          })}
        </div>
      </TabPanel>

      <TabPanel value={value} index={1}>
        <div style={{ height: "85vh", overflowY: "scroll" }}>
          {myFeeds?.map((data, i) => {
            return (
              <Container className={classes.container} key={i}>
                <Card className={classes.card}>
                  <CardHeader
                    avatar={
                      onlineNow.includes(data.center._id) ? (
                        <StyledOnlineBadge
                          variant={onlineNow.includes(data.center._id) ? "dot" : "standard"}
                        >
                          <Avatar
                            alt={data?.file?.name}
                            src={
                              data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                              `https://via.placeholder.com/150?text=Profile`
                            }
                          />
                        </StyledOnlineBadge>
                      ) : (
                        <StyledOffLineBadge variant="dot">
                          <Avatar
                            alt={data?.file?.name}
                            src={
                              data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                              `https://via.placeholder.com/150?text=Profile`
                            }
                          />
                        </StyledOffLineBadge>
                      )
                    }
                    action={
                      <Grid container>
                        <Grid>
                          <label>{data.isMass ? "Mass" : "Personal"}</label>
                          <Switch
                            checked={data.isMass}
                            color="primary"
                            name={data._id}
                            onChange={handleChangeFile}
                          />
                        </Grid>
                        <Grid>
                          <DeleteForeverIcon
                            className={classes.removeIcon}
                            onClick={() => {
                              handleRemoveFile(data?._id, data?.file?._id);
                            }}
                          />
                        </Grid>
                      </Grid>
                    }
                    title={data.title}
                    subheader={`Provider :${data?.center?.name}-${data.createdAt.slice(0, 10)}`}
                    classes={{
                      title: classes.feedTitle,
                    }}
                  />
                  {data?.file &&
                    (data?.file.url?.includes("mp4") ? (
                      <video src={data.file.url} width="100%" height="100%" controls={true} />
                    ) : (
                      <CardMedia
                        style={{ objectFit: "fill" }}
                        className={classes.media}
                        component="img"
                        image={data.file.url}
                        title={data.file.name}
                      />
                    ))}
                  <CardContent>
                    <Typography
                      variant="body2"
                      className={classes.multiline}
                      dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                  </CardContent>
                </Card>
              </Container>
            );
          })}
        </div>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CloudUploadIcon />}
          onClick={handleClickOpen}
        >
          Compose New Feed post
        </Button>
      </TabPanel>

      <TabPanel value={value} index={2}>
        <UserCard />
      </TabPanel>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          BackdropProps={{
            classes: {
              root: classes.backDrop,
            },
          }}
          disableBackdropClick={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title" style={{ padding: "16px 24px" }}>
            Update New Feed
          </DialogTitle>
          <DialogContent style={{ padding: "0px 24px", minWidth: "500px" }}>
            <TextField
              margin="dense"
              id="title"
              label="Title"
              type="text"
              fullWidth
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              required
            />
            {/* <TextField
              margin="dense"
              id="description"
              label="Description"
              type="text"
              fullWidth
              multiline
              spellCheck={true}
              rows={2}
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            /> */}
            <ReactQuill
              style={{ width: "100%", height: "100%", marginBottom: "16px", marginTop: "15px" }}
              value={description}
              onChange={(value) => {
                setDescription(value);
              }}
              modules={modules}
              formats={formats}
            />
            <Button
              variant="contained"
              color="primary"
              component="label"
              startIcon={<PublishIcon />}
              style={{ marginBottom: "12px" }}
            >
              File Upload
              <input hidden type="file" accept="video/*,image/*" onChange={uploadFeedFile} />
            </Button>
            {status === "PENDING" ? (
              <>
                <Alert severity="info">
                  Please wait.. ! while file is uploading....{uploadPercent}%
                </Alert>
                <LinearProgress variant="determinate" value={uploadPercent} />
              </>
            ) : status === "SUCCESS" ? (
              <Alert severity="success">Successfully Upload</Alert>
            ) : status === "FAIL" ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Uploading Failed <strong>Retry maybe Its due to slow internet!</strong>
              </Alert>
            ) : status === "LIMIT" ? (
              <Alert severity="info">
                <AlertTitle>Limiting</AlertTitle>
                <strong>Your file size is not more then 300MB</strong>
              </Alert>
            ) : (
              ""
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit} type="submit" disabled={disable}>
              Post Feed
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getAllFeeds: () => dispatch(feedCalls.getAllFeeds()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  updateCenterFileIsMass: (id: string, fileState: boolean) =>
    dispatch(feedCalls.updateCenterFileIsMass(id, fileState)),
  removeOneFeedSuccess: (feedId: string, fileId?: string) =>
    dispatch(feedCalls.removeOneFeed(feedId, fileId)),
});

const mapStateToProps = (state: any) => ({
  getAllFeedsSuccess: feedSelectors.getAllFeedsSuccess(state.feeds),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedsUpload);
