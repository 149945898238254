import { IAdmin } from "../../types";
import * as types from "./types";

export const gettingAllAdmins = () => ({
  type: types.gettingAdmins,
});
export const getAllAdminsSuccess = (admins: IAdmin[]) => ({
  type: types.getAdminsSuccess,
  payload: admins,
});

export const getAllAdminsFailure = (errorMessage: string) => ({
  type: types.getAdminsFailure,
  payload: errorMessage,
});
