import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

interface Iprops {
  openCrisisPopup: boolean;
  setOpenCrisisPopup: any;
}

const CrisisPopup = ({ openCrisisPopup, setOpenCrisisPopup }: Iprops) => {
  const helpUrl = "https://www.deloresdigital.com/help.html";
  return (
    <Dialog
      open={openCrisisPopup}
      onClose={() => setOpenCrisisPopup(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          If you are in a crisis or any other person may be in danger - don't use this site.{" "}
          <a style={{ color: "red" }} href={helpUrl}>
            These resources{" "}
          </a>
          can provide you with immediate help.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenCrisisPopup(false)} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CrisisPopup;
