import { connect } from "react-redux";
import { basicCalls, basicSelectors } from "../../../../store/supers/basicCalls";
import { IAdmin, ICenter } from "../../../../store/types";

import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import React from "react";

interface OwnProps {}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
  removeCenterFromAdminRequest: (adminID: string, removeCenterID: string) => Promise<void>;
}

interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;

  removeCenterFromAdminRequestSuccess: IAdmin;
}
type Props = OwnProps & StateProps & DispatchProps;
function RemoveCenters(props: Props) {
  const admins = props.fetchAdmins;
  const handleRemoveCenter = async (adminID: string, removeCenterID: string) => {
    await props.removeCenterFromAdminRequest(adminID, removeCenterID);
    await props.getAdmins();
    await props.getCenters();
  };
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      {admins.map((adm, i) => {
        return (
          <Card style={{ marginTop: "10px" }} key={i}>
            <CardActionArea>
              <CardContent style={{ paddingTop: "0px", paddingBottom: "0px" }}>
                <List style={{ padding: "0px" }}>
                  {adm.name}
                  <ListItem>
                    <ListItemText>
                      {adm.centers.map((center) => {
                        return <ListItemText>ProviderName:{center.name}</ListItemText>;
                      })}
                    </ListItemText>
                    <ListItemText>
                      {adm.centers.map((center) => {
                        return (
                          <ListItemText>
                            {" "}
                            {` ProviderNPI :${center.npi ? center.npi : "N/A"}`}
                          </ListItemText>
                        );
                      })}
                    </ListItemText>
                    <ListItemText>
                      {adm.centers.map((center) => {
                        return <ListItemText> {` ProviderEmail :${center.email}`}</ListItemText>;
                      })}
                    </ListItemText>
                    <ListItemText>
                      {adm.centers.map((center) => {
                        return <ListItemText>ProviderAddress:{center.address}</ListItemText>;
                      })}
                    </ListItemText>
                    <ListItemText>
                      {adm.centers.map((center) => (
                        <Button
                          style={{ display: "flex", flexDirection: "column", marginBottom: "5px" }}
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            handleRemoveCenter(adm._id, center._id);
                          }}
                        >
                          Remove
                        </Button>
                      ))}
                    </ListItemText>
                  </ListItem>
                </List>
              </CardContent>
            </CardActionArea>
          </Card>
        );
      })}
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  getCenters: () => dispatch(basicCalls.getCenters()),
  removeCenterFromAdminRequest: (adminID: string, removeCenterID: string) =>
    dispatch(basicCalls.removeCenterFromAdminRequest(adminID, removeCenterID)),
});

const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(RemoveCenters);
