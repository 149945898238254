import { green, lightGreen } from "@material-ui/core/colors";

import { createMuiTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "25ch",
    },

    container: {
      padding: theme.spacing(3),
      paddingLeft: "0px",
      marginLeft: "0px",
    },

    gridContainer: {
      padding: theme.spacing(3),
      paddingLeft: "0px",
      marginLeft: "0px",
      maxWidth: "80%",
    },
    spinnercontainer: {
      textAlign: "center",
      padding: theme.spacing(3),
      width: "500px",
    },
    margin: {
      margin: theme.spacing(1),
      width: "50%",
    },
    disabledInput: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: "#272737",
      },
    },
    result: {
      textAlign: "center",
      margin: "1em 0",
      fontSize: "1.3rem",
      textShadow:
        "0px 4px 3px rgb(119 119 119 / 40%), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1)",
    },
    styleButton: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      border: "1px solid #0d5d18",
      color: "#0d5d18",
      height: "80px",
      width: "400px",
      textAlignLast: "center",
      borderRadius: "50px",
      fontSize: "1.3rem",
      cursor: "pointer",
      background: "white",
      transition: "800ms ease all",
      "&:hover": {
        background: "#0d5d18",
        color: "white",
      },
      "@media (max-width: 576px)": {
        width: "300px",
        fontSize: "1rem",
        height: "60px",
      },
      "@media (max-width: 475px)": {
        fontSize: "1rem",
        height: "50px",
      },
    },
  })
);

export const theme = createMuiTheme({
  palette: {
    primary: lightGreen,
    secondary: green,
  },
});
