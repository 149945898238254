import React from "react";
import Button from "@material-ui/core/Button";
import Dialog, { DialogProps } from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { Checkbox, Grid, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { IFileData, IPatient } from "../../../store/types";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkbox: {
      color: green[400],
      "&$checked": {
        color: green[600],
      },
    },
    errorText: {
      fontSize: "0.9rem",
      marginLeft: "2em",
      color: "red",
    },
  })
);

export default function CustomDialogBox(props: {
  title: string;
  open: boolean;
  setOpen: any;
  textA: any;
  textB: any;
  handleSubmit: any;
  handleChange: any;
  checked: boolean;
  files?: IFileData[];
  errorMessage?: string;
  patient: IPatient;
}) {
  // eslint-disable-next-line
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  // const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
  //   props.setOpen(true);
  //   setScroll(scrollType);
  // };
  const handleClose = () => {
    props.setOpen(false);
  };

  const RequestFrom = props.patient?.fileRequestedByPhysicians?.map((info) => {
    return info.fromPhysician;
  });
  const RequestTo = props.patient?.fileRequestedByPhysicians?.map((info) => {
    return info.toPhysician;
  });
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (props.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [props.open]);
  const classes = useStyles();
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={handleClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{props.title}</DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText>
            <Grid container>
              <Grid sm={6} xl={6}>
                <Typography style={{ color: "black", fontSize: "1em" }} gutterBottom>
                  Requesting to send
                </Typography>
                <Typography style={{ color: "black", fontSize: "0.75em" }} gutterBottom>
                  {`Doctor Name : ${RequestFrom?.map((phy) => {
                    return phy.firstName;
                  })}`}
                </Typography>
                <Typography style={{ color: "black", fontSize: "0.75em" }} gutterBottom>
                  {`Practice name : ${RequestFrom?.map((phy) => {
                    return phy.practice;
                  })}`}
                </Typography>
                <Typography style={{ color: "black", fontSize: "0.75em" }} gutterBottom>
                  {`Practice Address : ${RequestFrom?.map((phy) => {
                    return phy.practiceAddress;
                  })}`}
                </Typography>
              </Grid>
              <Grid sm={6} xl={6}>
                <Typography style={{ color: "black", fontSize: "1em" }} gutterBottom>
                  Requesting to Receive
                </Typography>
                <Typography style={{ color: "black", fontSize: "0.75em" }} gutterBottom>
                  {`Doctor Name : ${RequestTo?.map((phy) => {
                    return phy.firstName;
                  })}`}
                </Typography>
                <Typography style={{ color: "black", fontSize: "0.75em" }} gutterBottom>
                  {`Practice name : ${RequestTo?.map((phy) => {
                    return phy.practice;
                  })}`}
                </Typography>
                <Typography style={{ color: "black", fontSize: "0.75em" }} gutterBottom>
                  {`Practice Address : ${RequestTo?.map((phy) => {
                    return phy.practiceAddress;
                  })}`}
                </Typography>
              </Grid>
            </Grid>
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.textA}
          </DialogContentText>
          <DialogContentText>
            {props.files?.map((file) => (
              <DialogContentText key={file._id}>{file.name} </DialogContentText>
            ))}
          </DialogContentText>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.textB}
          </DialogContentText>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.checked}
                onChange={props.handleChange}
                name="checked"
                value="checked"
                inputProps={{ "aria-label": "Checkbox A" }}
                className={classes.checkbox}
                color="primary"
              />
            }
            label="I Agree to these terms and conditions."
          />
          <div className={classes.errorText}>{props.errorMessage}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={props.handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
