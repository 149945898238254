/* eslint-disable no-new-wrappers */
import { Avatar, Box, Grid, Button, Paper, Typography } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import { USER_TYPE } from "../../../store/types";

type ownProps = {
  classes?: any;
  centerRes: any;
  cardClass: any;
  handleClickCenterOpen?: any;
  handleSendMessage?: any;
  single?: any;
};

type Props = ownProps;
function PingCenterCards(props: Props) {
  return (
    <div className={props.cardClass.cardContentInnerDiv}>
      {props?.centerRes?.map((center: any, i: any) => {
        // eslint-disable-next-line array-callback-return
        if (props.single && i > 0) return;
        return (
          <div style={{ width: "100%", margin: "20px auto" }}>
            <Paper className={props.cardClass.paper}>
              <Grid container spacing={2}>
                <Grid item sm={9} md={9} xs={8} lg={10} xl={10}>
                  <Avatar
                    alt={center?.name}
                    src={center?.files[0]?.url || `https://via.placeholder.com/150?text=Profile`}
                    style={{ width: 130, height: 130, alignSelf: "center" }}
                  />
                </Grid>
                <Grid item sm={3} md={3} xs={4} lg={2} xl={2}>
                  <div className={props.cardClass.messageBtns}>
                    <Typography className={props.cardClass.title}> {"practice"}</Typography>
                  </div>
                  <div className={props.cardClass.messageBtns}>
                    <Button
                      autoFocus
                      variant="outlined"
                      color="primary"
                      endIcon={<ChatIcon />}
                      onClick={() => props.handleClickCenterOpen(center?._id, USER_TYPE.CENTER)}
                      className={props.cardClass.singlebtn}
                    >
                      message
                    </Button>
                  </div>
                </Grid>
                <Grid item sm={9} md={9} container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>Name :</span> {center?.name}
                        </Typography>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>Address :</span> {center?.address}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>
                            My Background and Approach :{" "}
                          </span>{" "}
                          {center?.bio ? center?.bio : "no data"}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>
                            My Techniques and Specialties :
                          </span>{" "}
                          {center?.specialties.map((spec: any, ind: any) => {
                            return <span key={ind}>{spec},</span>;
                          })}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}> Phone Number : </span>{" "}
                          {center?.publicNumber ? center?.publicNumber : "No Public Number"}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>State : </span>{" "}
                          {center?.state ? center?.state : "No State"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      })}
    </div>
  );
}

export default PingCenterCards;
