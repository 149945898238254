import React, { Component } from "react";
import List from "./Items";
import "./sidebar.css";
import { Drawer, IconButton, Link } from "@material-ui/core";
import Logo from "../../staticResources/logos/logo.png";

export class Sidebar extends Component<any> {
  constructor(props: any) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        {this.props.opendrawer && (
          <div className="Sidebar">
            <Drawer variant="permanent" anchor="left">
              <Link href="/">
                <IconButton
                  style={{
                    opacity: 1,
                    marginTop: "-0.5em",
                    marginLeft: "0.3em",
                  }}
                >
                  <img
                    src={Logo}
                    alt={"delores"}
                    style={{ objectFit: "contain", width: "100%", height: "auto" }}
                  />
                </IconButton>
              </Link>
              <List {...this.props} />
            </Drawer>
          </div>
        )}
      </>
    );
  }
}

export default Sidebar;
