import React, { Component } from "react";
import { connect } from "react-redux";
import { physicianProfileSelectors } from "../../store/physician/profile";
import { ICenter } from "../../store/types/center";
import { IPhysician } from "../../store/types/physician";
import { NotificationDTO, PingRequestDTO } from "../../store/types/type";
import { CustomButtonGroup } from "../base/Components/CustomButtonGroup";

import { basicCalls, basicSelectors } from "../../store/centers/basicCalls";
import { PingRequestsCalls, PingRequestsSelectors } from "../../store/Ping";
import { notificationSMSCalls } from "../../store/notification/sms";
import { PingMain } from "../base/Ping/PingMain";
import { centerProfileCalls } from "../../store/centers/profile";
interface StateProps {
  pinging: boolean;
  pingError: string;
  gettingProfile: boolean;
  getProfileError: string;
  profile: IPhysician;
  gettingCenters: boolean;
  getCentersError: string;
  allCenters: ICenter[];
  pingSuccess: boolean;
}
interface DispatchProps {
  ping: (pingDto: PingRequestDTO) => Promise<void>;
  getProfile: () => Promise<void>;
  getAllCenters: () => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}
interface OwnProps {
  type: string;
}
interface State {
  internalSelected?: boolean;
  Message?: boolean;
}
interface InvisibleProps {
  history: any;
}
type Props = StateProps & DispatchProps & OwnProps & InvisibleProps;
export class PingProviderComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    await this.props.getProfile();
  };
  handleClickInternal = () => {
    this.setState({
      ...this.state,
      internalSelected: true,
      Message: false,
    });
  };
  handleClickExternal = () => {
    this.setState({
      ...this.state,
      internalSelected: false,
      Message: false,
    });
  };
  handleClickMessage = () => {
    this.setState({
      ...this.state,
      Message: true,
    });
  };
  render() {
    return (
      <>
        <CustomButtonGroup
          buttonType="horizontal"
          values={["Message Delores Digital", "Internal", "External"]}
          handleClicks={[
            this.handleClickMessage,
            this.handleClickInternal,
            this.handleClickExternal,
          ]}
          style={{ textAlign: "center", marginTop: "3%" }}
        />
        {this.state.Message && (
          <PingMain
            message={this.state.Message}
            internalSelected={this.state.internalSelected}
            type="Center"
          />
        )}

        {this.state.internalSelected && !this.state.Message ? (
          <PingMain
            message={this.state.Message}
            internalSelected={this.state.internalSelected}
            type="Center"
          />
        ) : (
          !this.state.Message && (
            <PingMain
              message={this.state.Message}
              internalSelected={this.state.internalSelected}
              type="Center"
            />
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  pinging: PingRequestsSelectors.isCreatingPingRequest(state.ping),
  pingError: PingRequestsSelectors.errorWhileCreatingPingRequest(state.ping),
  pingSuccess: PingRequestsSelectors.createPingRequestSuccess(state.ping),
  gettingProfile: physicianProfileSelectors.isCallingProfile(state.physicians.physicianProfile),
  getProfileError: physicianProfileSelectors.isProfileFailure(state.physicians.physicianProfile),
  profile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.centers.basicCalls),
  allCenters: basicSelectors.fetchCenters(state.centers.basicCalls),
});
const mapDispatchToProps = (dispatch: any) => ({
  getAllCenters: () => dispatch(basicCalls.getAllCenters()),
  ping: (pingDto: PingRequestDTO) => dispatch(PingRequestsCalls.createPingRequest(pingDto)),
  getProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PingProviderComponent);

// import React, { Component } from "react";
// import { connect } from "react-redux";
// import { centerProfileCalls, centerProfileSelectors } from "../../store/centers/profile";
// import { chatCalls, chatSelectors } from "../../store/centers/chat";
// import { ICenter } from "../../store/types/center";
// import { USER_TYPE } from "../../store/types";
// import { NewChatDTO, NotificationDTO } from "../../store/types/type";
// import SelectAPhysician from "../base/Components/SelectAPhysician";
// import { notificationSMSCalls } from "../../store/notification/sms";

// interface StateProps {
//   pingingPhysician: boolean;
//   pingPhysicianError: string;
//   profile: ICenter;
// }
// interface DispatchProps {
//   pingPhysician: (chatDto: NewChatDTO) => Promise<void>;
//   getProfile: () => Promise<void>;
//   ping: (startChatDto: NewChatDTO) => Promise<void>;
//   sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
// }
// interface OwnProps {}
// interface State {}
// type Props = StateProps & DispatchProps & OwnProps;
// export class StartChatForCenter extends Component<Props, State> {
//   componentDidMount = async () => {
//     await this.props.getProfile();
//   };
//   pingPhysician = async (newChatDto: NewChatDTO) => {
//     await this.props.ping(newChatDto);
//   };
//   render() {
//     return (
//       <div>
//         {this.props.profile ? (
//           <SelectAPhysician
//             handlePhysicianSelectPing={this.pingPhysician}
//             physicians={this.props.profile?.physicians || []}
//             pingError={this.props.pingPhysicianError}
//             pinging={this.props.pingingPhysician}
//             profile={this.props.profile}
//             type={USER_TYPE.CENTER}
//             sendSMS={this.props.sendSMS}
//           />
//         ) : null}
//       </div>
//     );
//   }
// }

// const mapStateToProps = (state: any) => ({
//   pingingPhysician: chatSelectors.isStartingNewChat(state.centers.chat),
//   pingPhysicianError: chatSelectors.hasStartNewChatError(state.centers.chat),
//   profile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
// });
// const mapDispatchToProps = (dispatch: any) => ({
//   pingPhysician: (chatDto: NewChatDTO) => dispatch(chatCalls.startChat(chatDto)),
//   getProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
//   ping: (startChatDto: NewChatDTO) => dispatch(chatCalls.startChatwithFile(startChatDto)),
//   sendSMS: (notificationDTO: NotificationDTO) =>
//     dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),
// });
// export default connect(mapStateToProps, mapDispatchToProps)(StartChatForCenter);
