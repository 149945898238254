import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { PrimaryButton } from "../../../base/Components/PrimaryButton";
import { PhysicianProfile } from "../../PhysicianProfile";
// import { useStyles } from "../muiStyling";
import { physicianProfileCalls } from "../../../../store/physician/profile";
import { connect } from "react-redux";
import { IPhysician } from "../../../../store/types/physician";
import { ProfilePopup } from "../../../popup/ProfilePopup";
import {
  NumberUpdateDTO,
  PhysicianUpdateDTO,
  VerifyUpdatedNumberDTO,
  RateUpdateDTO,
} from "../../../../store/types/type";
import { UpdateNumberPopup } from "../../../popup/UpdateNumberPopup";
import { UpdateRatePopup, holdrating, trate1, trate2,trate3 } from "../../../popup/UpdateRatePopup";
import { OtpPopup } from "../../../popup/OtpPopup";
import { Link } from "react-router-dom";


interface StateProps {
  // physicianProfile: IPhysician;
}
interface DispatchProps {
  // getPhysicianProfile: () => Promise<any>;
  updatePhysicianProfile: (updatePhysicianData: PhysicianUpdateDTO) => Promise<any>;
  updateProfileNumber: (numberUpdateDTO: NumberUpdateDTO) => Promise<any>;
  updateProfileRate: (rateUpdateDTO: RateUpdateDTO) => Promise<any>;
  verifyUpdatedNumber: (verifyUpdatedNumberDTO: VerifyUpdatedNumberDTO) => Promise<any>;
}
interface OwnProps {
  read?: boolean;
  physicianProfile?: IPhysician;
  getPhysicianProfile: () => Promise<any>;
}
type Props = OwnProps & StateProps & DispatchProps;


export const _ProfileCard = (props: Props) => {
  const [openPopupProfile, setOpenPopupProfile] = useState(false);
  const [openPopupNumber, setOpenPopupNumber] = useState(false);
  const [openPopupRate, setOpenPopupRate] = useState(false);
  const [openPopupOtp, setOpenPopupOtp] = useState(false);
  const useRate = Object.freeze(holdrating)
  // const classes = useStyles();
  React.useEffect(() => {
    props.getPhysicianProfile();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "3em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            FirstName : {props.physicianProfile?.firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "3em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            Last Name : {props.physicianProfile?.lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "2em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            Practice : {props.physicianProfile?.practice}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "2em",
              fontSize: "1rem",
              lineHeight: "1.2",
            }}
          >
            Phone number for login OTP : {props.physicianProfile?.phoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "2em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            Practice Address : {props.physicianProfile?.practiceAddress}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {props.read ? (
            ""
          ) : (
            <PrimaryButton
              onClick={() => setOpenPopupNumber(!openPopupNumber)}
              title="Edit Number"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {props.read ? (
            ""
          ) : (
            <PrimaryButton
              onClick={() => setOpenPopupProfile(!openPopupProfile)}
              title="Edit Profile"
            />
          )}
        </Grid>


      </Grid>
      <ProfilePopup openPopupProfile={openPopupProfile} setOpenPopupProfile={setOpenPopupProfile}>
        <PhysicianProfile
          updatePhysicianProfile={props.updatePhysicianProfile}
          getPhysicianProfile={props.getPhysicianProfile}
          setOpenPopupProfile={setOpenPopupProfile}
        />
      </ProfilePopup>

      <UpdateNumberPopup
        openPopupNumber={openPopupNumber}
        setOpenPopupNumber={setOpenPopupNumber}
        updateProfileNumber={props.updateProfileNumber}
        getPatientProfile={props.getPhysicianProfile}
        setOpenPopupOtp={() => setOpenPopupOtp(!openPopupOtp)}
      />
      <UpdateRatePopup
        openPopupRate={openPopupRate}
        setOpenPopupRate={setOpenPopupRate}
        updateProfileRate={props.updateProfileRate}
        getPatientProfile={props.getPhysicianProfile}
        getPhysicianProfile={props.getPhysicianProfile}
      //  setOpenPopupOtp={() => setOpenPopupOtp(!openPopupOtp)}
      />
      <OtpPopup
        openPopupOtp={openPopupOtp}
        setOpenPopupOtp={setOpenPopupOtp}
        verifyUpdatedNumber={props.verifyUpdatedNumber}
        getPatientProfile={props.getPhysicianProfile}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  // physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
});

const mapDispatchToProps = (dispatch: any) => ({
  // getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  updatePhysicianProfile: (physicianUpdateDTO: PhysicianUpdateDTO) =>
    dispatch(physicianProfileCalls.updatePhysicianProfile(physicianUpdateDTO)),
  updateProfileRate: (rateUpdateDTO: RateUpdateDTO) =>
    dispatch(physicianProfileCalls.updateRate(rateUpdateDTO)),
  updateProfileNumber: (numberUpdateDTO: NumberUpdateDTO) =>
    dispatch(physicianProfileCalls.updateNumber(numberUpdateDTO)),
  verifyUpdatedNumber: (verifyUpdatedNumber: VerifyUpdatedNumberDTO) =>
    dispatch(physicianProfileCalls.verifyUpdatedNumber(verifyUpdatedNumber)),
});

export const ProfileCard = connect(mapStateToProps, mapDispatchToProps)(_ProfileCard);
