import React, { Component } from "react";
import axios from "axios";
import { Main } from "./Main";
import DeniedOrWaitingForApprovalCenter from "../../authComponents/DeniedCenter";
import Appbar from "../../navbars/Appbar";
import { USER_TYPE } from "../../../store/types";

interface State {
  approved?: boolean;
  denied?: boolean;
}
interface Props {
  history: any;
}
export class RestrictUnApprovedCenter extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      approved: undefined,
      denied: undefined,
    };
  }
  checkCenterApprovalState = async () => {
    try {
      const response = await axios.get(`/center`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      });
      if (response.status === 200) {
        this.setState({
          ...this.state,
          approved: response.data.approved,
          denied: response.data.denied,
        });
      } else throw new Error("Unable to get Provider Profile");
    } catch (err) {
      this.setState({
        ...this.state,
        approved: false,
      });
    }
  };
  componentDidMount = async () => {
    document.body.style.background = "none";
    document.body.style.backgroundColor = "none";
    await this.checkCenterApprovalState();
  };
  render() {
    return this.state.approved ? (
      <Main {...this.props} />
    ) : (
      <div style={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <header
          style={{
            top: "0",
            left: "auto",
            position: "sticky",
            display: "flex",
            justifyContent: "center",
            width: "80%",
            zIndex: 999,
          }}
        >
          <Appbar type={USER_TYPE.CENTER} />
        </header>
        {/* <p>Please wait for you Admin to approve your request and try again</p> */}
        <DeniedOrWaitingForApprovalCenter
          checkCenterApprovalState={this.checkCenterApprovalState}
          denied={this.state.denied === true}
          message={
            this.state.denied === true
              ? "Your Admin denied your request, Please  wait for its approval"
              : "Please wait for you Admin to approve your request and try again."
          }
        />
      </div>
    );
  }
}

export default RestrictUnApprovedCenter;
