import { Menu, MenuItem } from "@material-ui/core";
import React from "react";

export const ProfileMenu = (props: {
  anchorEl: any;
  menuId: any;
  isMenuOpen: any;
  handleMenuClose: any;
  handleLogout: any;
  handleProfile: any;
}) => {
  return (
    <>
      <Menu
        anchorEl={props.anchorEl}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        id={props.menuId}
        keepMounted
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        open={props.isMenuOpen}
        onClose={props.handleMenuClose}
      >
        <MenuItem onClick={props.handleProfile}>Profile</MenuItem>
        <MenuItem onClick={props.handleLogout}>Logout</MenuItem>
      </Menu>
    </>
  );
};
