import React, { useState } from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
// import { CustomButtonGroup } from "../../../../base/Components/CustomButtonGroup";
// import { ICenter } from "../../../../../store/types/center";
// import { IPhysician } from "../../../../../store/types/physician";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IAdmin, ICenter } from "../../../store/types";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";

interface Props {
  approveCenterRequest: (id: string) => Promise<void>;
  denyCenterRequest: (id: string) => Promise<void>;
  updatingCenterRequest: boolean;
  updatingCenterError: string;
  profile: IAdmin;
  getProfile: () => Promise<void>;
}
export default function ApprovalCenter(props: Props) {
  const [showApproveOrDenyErrorOrSuccess, setShowApproveOrDenyErrorOrSuccess] = React.useState(
    false
  );
  const [filtered, setFiltered] = useState<ICenter[]>([]);

  React.useEffect(() => {
    setShowApproveOrDenyErrorOrSuccess(props.updatingCenterError ? true : false);
    const copy = props?.profile?.centers?.filter((cen) => !cen.approved);
    if (copy) setFiltered(copy);
  }, [props.updatingCenterError, props.profile]);
  const handleAccept = async (centerID: string) => {
    setShowApproveOrDenyErrorOrSuccess(false);
    await props.approveCenterRequest(centerID);
    await props.getProfile();
    setShowApproveOrDenyErrorOrSuccess(true);
  };
  const handleReject = async (centerID: string) => {
    setShowApproveOrDenyErrorOrSuccess(false);
    await props.denyCenterRequest(centerID);
    setShowApproveOrDenyErrorOrSuccess(true);
  };
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      <List>
        {filtered.map((value: ICenter, i: number) => {
          const labelId = `checkbox-list-label-${value}`;
          return (
            <>
              <ListItem
                alignItems="center"
                key={i}
                role={undefined}
                dense
                button
                // onClick={handleToggle(value)}
                style={{
                  height: "12vh",
                  margin: "0.5rem 0",
                }}
              >
                <ListItemText id={labelId} primary={`${value?.name}`} />
                <ListItemSecondaryAction>
                  <CustomButtonGroup
                    buttonType="vertical"
                    values={["Accept", "Reject"]}
                    handleClicks={[() => handleAccept(value?._id), () => handleReject(value?._id)]}
                  />
                </ListItemSecondaryAction>
              </ListItem>
              <Divider />
              <Snackbar
                open={showApproveOrDenyErrorOrSuccess}
                autoHideDuration={10000}
                onClose={(e, reason) => {
                  if (reason === "timeout") setShowApproveOrDenyErrorOrSuccess(false);
                }}
              >
                <Alert severity={props.updatingCenterError ? "error" : "success"}>
                  {props.updatingCenterError || "Approved Professional"}
                </Alert>
              </Snackbar>
            </>
          );
        })}
        {!Boolean(filtered.length) && (
          <Alert severity="info">
            <AlertTitle>Provider List</AlertTitle>
            You don't have any<strong> Provider</strong> to Accept or Reject
            <strong> at the movement!</strong>
          </Alert>
        )}
      </List>
    </div>
  );
}
