import { combineReducers } from "redux";
import physiciansReducer from "./physician/reducer";
import patientsReducer from "./patients/reducer";
import centersReducer from "./centers/reducer";
import adminsReducer from "./admins/reducer";
import { notesReducer } from "./Notes/reducers";
import { PingRequestsReducer } from "./Ping/reducers";
import supersReducer from "./supers/reducer";
import { feedReducer } from "./feed";
import { onlineReducer } from "./online";
export default combineReducers({
  admins: adminsReducer,
  centers: centersReducer,
  physicians: physiciansReducer,
  patients: patientsReducer,
  notes: notesReducer,
  ping: PingRequestsReducer,
  supers: supersReducer,
  feeds: feedReducer,
  online: onlineReducer,
});
