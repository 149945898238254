/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import {
  patientProfileCalls,
  patientProfileSelectors,
} from "../../../../../store/patients/profile";
import { IPatient } from "../../../../../store/types";
import PersonIcon from "@material-ui/icons/Person";
import WcIcon from "@material-ui/icons/Wc";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { PrimaryButton } from "../../../../base/Components/PrimaryButton";
import { PatientUpdateDTO } from "../../../../../store/types/type";
import { Autocomplete } from "@material-ui/lab";
import { genderList, useStateList } from "../../../../../store/types/states";

interface DispatchProps {
  getPatientProfile: () => Promise<void>;
  updatePatientProfile: (patientUpdateDTO: PatientUpdateDTO) => Promise<void>;
}
interface OwnProps {
  patientProfile?: IPatient;
}

type Props = OwnProps & DispatchProps;
function _ProfileInfoUser(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [updateProfile, setUpdateProfile] = React.useState<PatientUpdateDTO>({
    firstName: "",
    lastName: "",
    age: "",
    state: "",
    gender: "",
    race: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = async () => {
    console.log(updateProfile);
    await props.updatePatientProfile(updateProfile);
    await props.getPatientProfile();
    setUpdateProfile({
      firstName: "",
      lastName: "",
      age: "",
      state: "",
      gender: "",
      race: "",
    });
    setOpen(false);
  };
  React.useEffect(() => {
    props.getPatientProfile();
  }, []);

  return (
    <>
      <Grid item xs={12} sm container>
        <Grid item xs container direction="column" spacing={2}>
          <Grid item xs>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <PersonIcon />
              <Typography variant="body2" style={{ marginLeft: "8px" }}>
                {props.patientProfile?.firstName} {props.patientProfile?.lastName}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <AccountCircleIcon />
              <Typography variant="body2" style={{ marginLeft: "8px" }}>
                {`Age: ${props.patientProfile?.age ? props.patientProfile?.age : "N/A"}`}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <WcIcon />
              <Typography variant="body2" style={{ marginLeft: "8px" }}>
                {`Gender: ${props.patientProfile?.gender ? props.patientProfile?.gender : "N/A"}`}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <PersonPinIcon />
              <Typography variant="body2" style={{ marginLeft: "8px" }}>
                {`State: ${props.patientProfile?.state ? props.patientProfile?.state : "N/A"}`}
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "8px",
              }}
            >
              <WcIcon />
              <Typography variant="body2" style={{ marginLeft: "8px" }}>
                {`Race: ${props.patientProfile?.race ? props.patientProfile?.race : "N/A"}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          marginTop: "20px",
          paddingLeft: "15px",
        }}
      >
        <PrimaryButton onClick={handleClickOpen} title="Edit Info" />
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Profile Information</DialogTitle>
        <DialogContent>
          <TextField
            id="margin-normal"
            margin="normal"
            style={{ margin: 8 }}
            size="small"
            fullWidth
            label="First Name"
            type="string"
            name="firstname"
            onChange={(e) => setUpdateProfile({ ...updateProfile, firstName: e.target.value })}
          />
          <TextField
            id="margin-normal"
            margin="normal"
            style={{ margin: 8 }}
            size="small"
            fullWidth
            label="Last Name"
            type="string"
            name="lastname"
            onChange={(e) => setUpdateProfile({ ...updateProfile, lastName: e.target.value })}
          />
          <TextField
            id="margin-normal"
            margin="normal"
            style={{ margin: 8 }}
            size="small"
            fullWidth
            label="Age"
            type="number"
            name="age"
            onChange={(e) => setUpdateProfile({ ...updateProfile, age: e.target.value })}
          />
          <Autocomplete
            id="tags-outlined"
            size="small"
            style={{ margin: 8 }}
            options={useStateList}
            getOptionLabel={(option: any) => option}
            renderInput={(params) => (
              <TextField
                // style={{ zIndex: 2000 }}
                {...params}
                variant="standard"
                label="States"
                fullWidth
                placeholder="States"
                name="state"
              />
            )}
            onChange={(e, value) => {
              setUpdateProfile({ ...updateProfile, state: value });
            }}
          />
          <Autocomplete
            id="tags-outlined"
            size="small"
            style={{ margin: 8 }}
            options={genderList}
            getOptionLabel={(option: any) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Gender"
                fullWidth
                placeholder="Gender"
                name="gender"
              />
            )}
            onChange={(e, value) => {
              setUpdateProfile({ ...updateProfile, gender: value });
            }}
          />
          <TextField
            id="margin-normal"
            margin="normal"
            style={{ margin: 8 }}
            size="small"
            fullWidth
            label="race"
            type="string"
            name="race"
            onChange={(e) => setUpdateProfile({ ...updateProfile, race: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <PrimaryButton onClick={handleClose} title="close" />
          <PrimaryButton onClick={handleSubmit} title="Update Profile" />
        </DialogActions>
      </Dialog>
    </>
  );
}
const mapStateToProps = (state: any) => ({
  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
});

const mapDispatchToProps = (dispatch: any) => ({
  getPatientProfile: () => dispatch(patientProfileCalls.getPatientProfile()),
  updatePatientProfile: (patientUpdateDTO: PatientUpdateDTO) =>
    dispatch(patientProfileCalls.updatePatientProfile(patientUpdateDTO)),
});

export const ProfileInfoUser = connect(mapStateToProps, mapDispatchToProps)(_ProfileInfoUser);
