import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { basicReducer } from "./basicCalls";
import { adminProfileReducer } from "./profile/reducers";
const appReducer = combineReducers({
  auth: authReducer,
  basicCalls: basicReducer,
  adminProfile: adminProfileReducer,
  // chat: chatReducer,
});

export default appReducer;
