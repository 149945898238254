export const loggingIn = "@/patient/@loginStart";
export const loginSuccess = "@/patient/@loginSuccess";
export const loginFailure = "@/patient/@loginFailure";

export const verifyingToken = "@/patient/@verifyTokenStart";
export const verifyTokenSuccess = "@/patient/@verifyTokenSuccess";
export const verifyTokenFailure = "@/patient/@verifyTokenFailure";

export const loggedOut = "@/patient/@loggedOut";
export const registerIn = "@/patient/@registerStart";
export const registerSuccess = "@/patient/@registerSuccess";
export const registerFailure = "@/patient/@registerFailure";

export const updateRegisterIn = "@/patient/@updateRegisterStart";
export const updateRegisterSuccess = "@/patient/@updateRegisterSuccess";
export const updateRegisterFailure = "@/patient/@updateRegisterFailure";
