import axios from "axios";
import {
  NumberUpdateDTO,
  PhysicianAddPatientDTO,
  PhysicianUpdateDTO,
  VerifyUpdatedNumberDTO,
  RateUpdateDTO,
} from "../../types/type";
import * as actions from "./actions";

export const getPhysicianProfile = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.physicianProfileCalling());
  try {
    const response = await axios.get(`/physician`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.physicianProfileSuccess(response.data));
    } else throw new Error("Unable to get physician Profile");
  } catch (err: any) {
    dispatch(actions.physicianProfileFailure(err.message));
  }
};

export const updatePhysicianProfile = (physicianUpdateDTO: PhysicianUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.physicianProfileUpdating());
  try {
    const response = await axios.patch(`/physician`, physicianUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.physicianProfileSuccess(response.data));
      dispatch(actions.physicianProfileUpdateSuccess(true));
    } else throw new Error("Unable to update physician Profile");
  } catch (err: any) {
    dispatch(actions.physicianProfileUpdateFailure(err.message));
  }
};

export const updateNumber = (numberUpdateDTO: NumberUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.professionalNumberUpdating());
  try {
    const response = await axios.patch(`/auth/update-number`, numberUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.professionalNumberUpdateSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.professionalNumberUpdateFailure(err.message));
  }
};

export const updateRate = (rateUpdateDTO: RateUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
  ) => {
  dispatch(actions.physicianProfileUpdating());
  try {
    const response = await axios.patch(`/physician`, rateUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.physicianProfileSuccess(response.data));
      dispatch(actions.physicianProfileUpdateSuccess(true));
    } else throw new Error("Unable to update physician Profile");
  } catch (err: any) {
    dispatch(actions.physicianProfileUpdateFailure(err.message));
  }
};

export const verifyUpdatedNumber = (verifyUpdatedNumberDTO: VerifyUpdatedNumberDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.verifyNumberUpdating());
  try {
    const response = await axios.post(`/auth/verify-update-number`, verifyUpdatedNumberDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.verifyNumberUpdatingSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.verifyNumberUpdatingFailure(err.message));
  }
};
//////update file privacy of physicain////
export const updatePhysicianFilePrivacy = (fileId: string, fileState: boolean) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updatePhysicianFilePrivacyCalling());
  try {
    const response = await axios.patch(
      `/files-manager/change-privacy/${fileId}`,
      { isPublic: fileState ? 1 : 0 },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updatePhysicianFilePrivacySuccess(response.data));
    } else throw new Error("Unable to update physician request status");
  } catch (err: any) {
    dispatch(actions.updatePhysicianFilePrivacyFailure(err.message));
  }
};

export const createUser = (user: PhysicianAddPatientDTO) => async (
  dispatch: (arg0: any) => void
) => {
  dispatch(actions.registerInUser());
  try {
    const res = await axios.post(`/physician/createUser`, user, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (res.status === 200) {
      dispatch(actions.registerInUserSuccess(res.data));
    }
  } catch (error: any) {
    dispatch(actions.registerInUserFailure(error.response.data.message));
  }
};
