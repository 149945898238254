import { Button, ButtonGroup } from "@material-ui/core";
import React from "react";
import "../style.css";
import { useStyles } from "../muiStyling";

interface Props {
  buttonType: "vertical" | "horizontal";
  values: string[];
  handleClicks: any[];
  style?: any;
}
export const CustomButtonGroup = (props: Props) => {
  const classes = useStyles();
  const btnClass =
    props.buttonType === "vertical" ? classes.groupbtnVertical : classes.groupbtnHorizontal;
  return (
    <div style={props.style}>
      <ButtonGroup
        orientation={props.buttonType}
        color="primary"
        aria-label="outlined primary button group"
        className={classes.responsiveWidth}
      >
        {props.values.map((val, i) => {
          return (
            <Button className={btnClass} onClick={props.handleClicks[i]} key={i}>
              {val}
            </Button>
          );
        })}

        {/* <Button className={btnClass} onClick={props.handleClickB}>{props.valueB}</Button> */}
      </ButtonGroup>
    </div>
  );
};
