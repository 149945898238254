import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { IMessage } from "../../store/types/message";
import { USER_TYPE } from "../../store/types";
import { FormControlLabel, IconButton, Switch } from "@material-ui/core";
import ChatBar from "./ChatBar";
import { IChat } from "../../store/types/chat";
import { ICenter } from "../../store/types/center";
import { NotificationDTO, RetryMessageDTO } from "../../store/types/type";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IPhysician } from "../../store/types/physician";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflowY: "auto",
    },
    inline: {
      display: "inline",
    },
  })
);
interface Props {
  messages: IMessage[];
  retryMessages: RetryMessageDTO[];
  retrySending: (text: string, messageIndex: number) => void;
  userType: USER_TYPE;
  currentChat?: IChat;
  centersAndPhysicians: ICenter[];
  getAllCentersAndPhysicians: () => Promise<void>;
  gettingCenters: boolean;
  getCentersError: string;
  changeChatPrivacy: (id: string, newPrivacy: boolean) => Promise<void>;
  changeMessagePrivacy: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  getChatsAfterAdd: (chat: IChat) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  physicianProfile: IPhysician;
  physicianProfileCalling: boolean;
  physicianProfileFailure: string;
}
export default function ConversationComponent(props: Props) {
  const classes = useStyles();

  const ownCheck = (msg: IMessage | RetryMessageDTO) => {
    if (props.userType === USER_TYPE.PATIENT && msg.fromPatient) return true;
    else if (
      props.userType === USER_TYPE.PHYSICIAN &&
      msg.fromPhysician &&
      msg.fromPhysician._id === localStorage.getItem("_id")
    )
      return true;
    return false;
  };

  const [showPrivacy, setShowPrivacy] = React.useState(false);

  return props.currentChat ? (
    <>
      <div className="chatBarWrapper">
        <ChatBar
          sendSMS={props.sendSMS}
          currentChat={props.currentChat}
          setShowPrivacy={setShowPrivacy}
          showPrivacy={showPrivacy}
          changeChatPrivacy={props.changeChatPrivacy}
          userType={props.userType}
          getAllCentersAndPhysicians={props.getAllCentersAndPhysicians}
          centersAndPhysicians={props.centersAndPhysicians}
          getCentersError={props.getCentersError}
          gettingCenters={props.gettingCenters}
          getChatsAfterAdd={props.getChatsAfterAdd}
          physicianProfile={props.physicianProfile}
          physicianProfileCalling={props.physicianProfileCalling}
          physicianProfileFailure={props.physicianProfileFailure}
          centerProfile={undefined}
          centerProfileCalling={false}
          centerProfileFailure={""}
        />
      </div>
      <div className="convWrapper">
        <List className={classes.root}>
          {[...props.messages, ...props.retryMessages]?.map((m, i) => {
            return (
              <div key={i}>
                <Divider component="li" />
                {ownCheck(m) ? (
                  <ListItem alignItems="flex-start" style={{ opacity: m._id ? 1 : 0.5 }}>
                    {showPrivacy ? (
                      <FormControlLabel
                        label="Make Private"
                        labelPlacement="start"
                        control={
                          <Switch
                            checked={m.isPrivate || false}
                            onChange={props.changeMessagePrivacy}
                            name={m._id}
                            color="secondary"
                          />
                        }
                      />
                    ) : (
                      ""
                    )}
                    {!m._id ? (
                      <IconButton
                        onClick={() =>
                          props.retrySending(m.text as string, i - props.messages.length)
                        }
                      >
                        <RefreshIcon />
                        <span style={{ fontSize: "15px", marginLeft: "5px" }}>Retry</span>
                      </IconButton>
                    ) : null}
                    <ListItemText
                      style={{ textAlign: "right" }}
                      primary={m.text}
                      secondary={`${new Date(m.createdAt!).toLocaleTimeString()}, ${new Date(
                        m.createdAt!
                      ).toLocaleDateString()}`}
                    />
                    <ListItemAvatar>
                      <Avatar
                        style={{ float: "right" }}
                        alt={m.fromPatient?.firstName || m.fromPhysician?.firstName}
                        src={
                          props.currentChat?.patient?._id === m.fromPatient?._id
                            ? props.currentChat?.patient?.files?.filter((f) => f.isProfile)[0]?.url
                            : "https://via.placeholder.com/150?text=Profile" ||
                              props.currentChat?.physicians[0]?._id === m.fromPhysician?._id
                            ? props.currentChat?.physicians[0]?.files.filter((f) => f.isProfile)[0]
                                ?.url
                            : "https://via.placeholder.com/150?text=Profile"
                        }
                      />
                    </ListItemAvatar>
                  </ListItem>
                ) : (
                  <ListItem selected alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={m.fromPatient?.firstName || m.fromPhysician?.firstName}
                        src={
                          props.currentChat?.patient?._id === m.fromPatient?._id
                            ? props.currentChat?.patient?.files.filter((f) => f.isProfile)[0]?.url
                            : "https://via.placeholder.com/150?text=Profile" ||
                              props.currentChat?.physicians[0]?._id === m.fromPhysician?._id
                            ? props.currentChat?.physicians[0]?.files.filter((f) => f.isProfile)[0]
                                ?.url
                            : "https://via.placeholder.com/150?text=Profile"
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ wordBreak: "break-all" }}
                      primary={m.text}
                      secondary={`${new Date(m.createdAt!).toLocaleTimeString()}, ${new Date(
                        m.createdAt!
                      ).toLocaleDateString()}`}
                    />
                  </ListItem>
                )}
              </div>
            );
          })}
        </List>
      </div>
    </>
  ) : (
    <>{""}</>
  );
}
