import { Avatar, IconButton } from "@material-ui/core";
import React, { useState } from "react";
import axios from "axios";
import { IFileData, IPhysician } from "../../../../store/types";
import { useStyles } from "../../../authComponents/muiStyling";
import { PrimaryButton } from "../../../base/Components/PrimaryButton";
import CircleSpinner from "../../../CircleSpinner";

interface Props {
  profile?: IPhysician;
  getPhysicianProfile: () => Promise<void>;
  setOpenPopupAvatar: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PhysicianAvatar = (props: Props) => {
  const classes = useStyles();

  const [state, setState] = useState<{
    file?: File;
    preview?: string | ArrayBuffer | null;
    error?: string;
    uploading?: boolean;
  }>();
  const handleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(e.currentTarget.files && e.currentTarget.files.length > 0)) return;
    const maxAllowedSize = 1 * 1024 * 1024;
    if (e.currentTarget.files[0].size > maxAllowedSize) {
      setState({ error: "File size limit is 1 MB" });
      e.currentTarget.value = "";
      return;
    }
    const file = e.currentTarget.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setState({
        file: file,
        preview: reader.result,
        error: "",
      });
    };
    reader.readAsDataURL(file);
  };
  const handleClick = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (state?.file) {
      const data = new FormData();
      data.append("fileField", state.file);
      setState({ ...state, uploading: true });

      try {
        const res = await axios.post<IFileData>("/files-manager/upload-profile", data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("patientToken") ||
              localStorage.getItem("physicianToken") ||
              localStorage.getItem("centerToken")
            }`,
          },
        });
        setState({ ...state, uploading: false });
        if (res.status === 201) {
          await props.getPhysicianProfile();
          props.setOpenPopupAvatar(false);
        }
      } catch (error) {
        setState({ ...state, uploading: false });
      }
    }
  };
  return (
    <div className={classes.root}>
      {state?.uploading ? <CircleSpinner /> : ""}
      <div style={{ width: "110px", height: "110px", margin: "0 13em 0" }}>
        <label htmlFor="contained-button-file">
          <IconButton>
            <Avatar
              style={{
                width: "100px",
                height: "100px",
              }}
              alt="Professional Photo"
              src={
                (state?.preview as any) ||
                props.profile?.files.filter((f) => f.isProfile)[0]?.url ||
                `https://via.placeholder.com/150?text=Profile`
              }
            />
          </IconButton>
        </label>
        <div style={{ margin: "1em", marginBottom: "2em" }}>
          <input accept="image/*" id="contained-button-file" type="file" onChange={handleFile} />
        </div>
      </div>
      <div style={{ marginTop: "4em" }}>
        <PrimaryButton onClick={handleClick} title="Update Avatar" />
        {state?.error}
      </div>
    </div>
  );
};
