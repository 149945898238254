/* eslint-disable no-new-wrappers */
import { Avatar, Box, Button, Grid, Paper, Typography } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import { useEffect } from "react";
import { USER_TYPE } from "../../../store/types";

type ownProps = {
  classes?: any;
  searchRes: any;
  cardClass: any;
  handleClickOpen?: any;
};

type Props = ownProps;
function PingProfessionalCards(props: Props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.searchRes]);
  return (
    <>
      {props.searchRes?.map((specialistProfile: any, i: any) => {
        return (
          <div className={props.cardClass.professionalMain}>
            <Paper className={props.cardClass.paper}>
              <Grid container spacing={2} key={i}>
                <Grid item sm={9} md={9} xs={8} lg={10} xl={10}>
                  <Avatar
                    alt={specialistProfile?.firstName}
                    src={
                      specialistProfile?.files[0]?.url ||
                      `https://via.placeholder.com/150?text=Profile`
                    }
                    style={{ width: 130, height: 130, alignSelf: "center" }}
                  />
                </Grid>
                <Grid item sm={3} md={3} xs={4} lg={2} xl={2}>
                  <div className={props.cardClass.messageBtns}>
                    <Typography className={props.cardClass.title}>
                      {" "}
                      {specialistProfile?.practice}
                    </Typography>
                  </div>
                  <div className={props.cardClass.messageBtns}>
                    <Button
                      autoFocus
                      variant="outlined"
                      color="primary"
                      endIcon={<ChatIcon />}
                      onClick={() =>
                        props.handleClickOpen(specialistProfile._id, USER_TYPE.PHYSICIAN)
                      }
                      className={props.cardClass.singlebtn}
                    >
                      message
                    </Button>
                  </div>
                </Grid>
                <Grid item sm={9} md={9} container>
                  <Grid item xs container direction="column" spacing={2}>
                    <Grid item xs={12}>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>Name :</span>{" "}
                          {specialistProfile?.firstName}
                        </Typography>
                      </Box>

                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>Address :</span>{" "}
                          {specialistProfile?.practiceAddress}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>
                            My Background and Approach :{" "}
                          </span>{" "}
                          {specialistProfile?.bio ? specialistProfile?.bio : "no data"}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>
                            My Techniques and Specialties :
                          </span>{" "}
                          {specialistProfile?.specialties.map((spec: any, ind: any) => {
                            return <span key={ind}>{spec},</span>;
                          })}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}> Phone Number : </span>{" "}
                          {specialistProfile?.publicNumber
                            ? specialistProfile?.publicNumber
                            : "No Public Number"}
                        </Typography>
                      </Box>
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "8px",
                        }}
                      >
                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          <span className={props.cardClass.title}>State : </span>{" "}
                          {specialistProfile?.state ? specialistProfile?.state : "No State"}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </div>
        );
      })}
    </>
  );
}

export default PingProfessionalCards;
