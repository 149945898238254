import { Box, TableCell, TableRow } from "@material-ui/core";
import React from "react";
import { IChat } from "../../../../../store/types/chat";
import { IPatient } from "../../../../../store/types/patient";
import { IPhysician } from "../../../../../store/types/physician";
import TextHoverElevated from "../../../../base/Components/TextHoverElevated";

interface Props {
  chat: IChat;
  lookingAtGroupDiscussion: Boolean;
  handleShowPhysicianModal: (val: boolean, physician: IPhysician) => void;
  handleShowPatientModal: (val: boolean, patient?: IPatient) => void;
}

export default function SingleChatInsight(props: Props) {
  return props.chat?.physicians.length ? (
    <TableRow>
      <TableCell style={{ textAlign: "center" }}>
        <TextHoverElevated
          margin={"0 auto"}
          handleClick={() => props.handleShowPatientModal(true, props.chat.patient)}
          text={
            props.chat.patient
              ? `${props.chat.patient.firstName} ${props.chat.patient.lastName}`
              : "None"
          }
        ></TextHoverElevated>
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>
        {!props.lookingAtGroupDiscussion ? (
          <TextHoverElevated
            margin={"0 auto"}
            handleClick={() => props.handleShowPhysicianModal(true, props.chat.physicians[0])}
            text={`${props.chat.physicians[0].firstName} ${props.chat.physicians[0].lastName}`}
          />
        ) : (
          <Box flexDirection={"row"}>
            <ul style={{ width: "fit-content", margin: "0 auto" }}>
              {props.chat.physicians.map((phy, i) => {
                return (
                  <li key={i}>
                    <TextHoverElevated
                      margin={"initial"}
                      handleClick={() => props.handleShowPhysicianModal(true, phy)}
                      text={` ${phy.firstName} ${phy.lastName} `}
                    />
                  </li>
                );
              })}
            </ul>
          </Box>
        )}
      </TableCell>
      <TableCell style={{ textAlign: "center" }}>{`${props.chat.messages.length}`}</TableCell>
    </TableRow>
  ) : null;
}
