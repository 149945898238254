import axios from "axios";
import { IAdmin, ICenter, IPhysician, IPatient } from "../../types";
import { FreezeDTO } from "../../types/type";
import * as actions from "./actions";

export const getAdmins = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.fetchAdmins());
  try {
    const response = await axios.get<IAdmin[]>(`/supers/get-all-admins`);
    if (response.status === 200) dispatch(actions.getAllAdminsSuccess(response.data));
    else throw new Error("Could not fetch admins list");
  } catch (err: any) {
    dispatch(actions.getAllAdminsFailure(err.message));
  }
};

export const getCenters = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.fetchCenters());
  try {
    const response = await axios.get<ICenter[]>(`/supers/get-all-centers`);
    if (response.status === 200) dispatch(actions.getAllCentersSuccess(response.data));
    else throw new Error("Could not fetch centers list");
  } catch (err: any) {
    dispatch(actions.getAllCentersFailure(err.message));
  }
};

export const getPhysicians = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.fetchPhysicians());
  try {
    const response = await axios.get<IPhysician[]>(`/supers/get-all-physician`);
    if (response?.status === 200) dispatch(actions.getAllPhysiciansSuccess(response?.data));
    else throw new Error("Could not fetch providers list");
  } catch (err: any) {
    dispatch(actions.getAllPhysiciansFailure(err.message));
  }
};

export const getPatients = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.fetchPatients());
  try {
    const response = await axios.get<IPatient[]>(`/supers/get-all-patient`);
    if (response.status === 200) dispatch(actions.getAllPatientsSuccess(response.data));
    else throw new Error("Could not fetch Provider list");
  } catch (err: any) {
    dispatch(actions.getAllPatientsFailure(err.message));
  }
};

export const approveAdminRequest = (adminID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateAdminRequest());
  try {
    const response = await axios.patch(
      `/supers/approve/admin/${adminID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("superToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updateAdminRequestSuccess(response.data));
    } else throw new Error("Unable to update Admin request status");
  } catch (err: any) {
    dispatch(actions.updateAdminRequestFailure(err.message));
  }
};

export const denyAdminRequest = (adminID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateAdminRequest());
  try {
    const response = await axios.patch(
      `/supers/deny/admin/${adminID}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("superToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updateAdminRequestSuccess(response.data));
    } else throw new Error("Unable to update Admin request status");
  } catch (err: any) {
    dispatch(actions.updateAdminRequestFailure(err.message));
  }
};
export const assignCenterRequest = (centerID: string, adminID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.assignCenterRequest());
  try {
    const response = await axios.patch(
      `/supers/assign-centers/${centerID}`,
      { userId: adminID },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("superToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.assignCenterRequestSuccess(response.data));
    } else throw new Error("Unable to Assign Provider Request");
  } catch (err: any) {
    dispatch(actions.assignCenterRequestFailure(err.message));
  }
};
// API call (patch) to freeze/unfreeze the account
export const freezeAccount = (Id: string, freezeDTO: FreezeDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updatedAccount());
  try {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const response = await axios.patch(`/supers/freeze-account/${Id}`, freezeDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("superToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.updateAccountSuccess(response.data));
    } else throw new Error("Unable to update Admin request status");
  } catch (err: any) {
    dispatch(actions.updateAccountFailure(err.message));
  }
};

export const removeCenterFromAdminRequest = (adminID: string, removeCenterID: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.removeCenterFromAdminRequest());
  try {
    const response = await axios.patch(
      `/supers/remove-centersID/${adminID}`,
      { userId: removeCenterID },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("superToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.removeCenterFromAdminRequestSuccess(response.data));
    } else throw new Error("Unable to Assign Provider Request");
  } catch (err: any) {
    dispatch(actions.removeCenterFromAdminRequestFailure(err.message));
  }
};

export const removeFeedsById = (feedId: string, fileId?: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.removeCenterFeed());
  try {
    const response = await axios.patch(
      `/supers/remove/${feedId}/${fileId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("superToken")}`,
        },
      }
    );
    if (response.status === 200) dispatch(actions.removeCenterFeedSuccess(response.data));
    else throw new Error("Unable to remove provider feed");
  } catch (err: any) {
    dispatch(actions.removeCenterFeedFailure(err.message));
  }
};

export const getAllCenterFeeds = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.getAllCenterFeeds());
  try {
    const response = await axios.get(`/supers/getAllFeeds`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("superToken")}`,
      },
    });
    if (response.status === 200) dispatch(actions.getAllCenterFeedsSuccess(response.data));
    else throw new Error("Could not fetch centers feeds list");
  } catch (err: any) {
    dispatch(actions.getAllCenterFeedsFailure(err.message));
  }
};

export const getScheduleTime = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.fetchScheduleTime());
  try {
    const response = await axios.get<ICenter[]>(`/appointment`);
    if (response.status === 200) dispatch(actions.getAllScheduleTimeSuccess(response.data));
    else throw new Error("Could not fetch centers list");
  } catch (err: any) {
    dispatch(actions.getScheduleTimeFailure(err.message));
  }
};
