import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button, CircularProgress, Container, Grid, InputAdornment } from "@material-ui/core";
import { CenterSignUpDTO, NotificationDTO } from "../../store/types/type";
import { useStyles } from "./muiStyling";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../base/ColorPalete";
import { IAdmin, ICenter } from "../../store/types";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useStateList } from "../../store/types/states";

interface StateProps {
  admins: IAdmin[];
  gettingAdmins: boolean;
  errorWhileGetAdmins: string;
  registeringCenter: boolean;
  centerRegistered: boolean;
  errorWhileCenterRegister: string;
  whenRegisterSuccessCenter: string;
  centerSignUp: (centerLogin: CenterSignUpDTO) => Promise<ICenter>;
  changeType: (type: string) => void;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}

type Props = StateProps;
export function CenterSignupCard(props: Props) {
  const [centerData, setCenterData] = React.useState<CenterSignUpDTO>({
    npi: "",
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: "",
    physicianName: "",
    state: "",
    physicianNpi: "",
    admin: props.admins[0]?._id as string,
  });
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [inputState, setInputState] = React.useState<CenterSignUpDTO>({
    email: "",
    npi: "",
    name: "",
    password: "",
    phoneNumber: "",
    address: "",
    physicianName: "",
    state: "",
    physicianNpi: "",
    admin: props.admins[0]?._id as string,
  });
  const [errorState, setErrorState] = React.useState({
    npi: "",
    name: "",
    email: "",
    password: "",
    phoneNumber: "",
    address: "",
    physicianName: "",
    physicianNpi: "",
    admin: props.admins[0]?._id as string,
  });
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setInputState(centerData);

    if (isValidated(event)) {
      const phone = "+1" + centerData?.phoneNumber;
      props.centerSignUp({ ...centerData, phoneNumber: phone }).then(() => {
        props.changeType("login");
      });
      // get admin for phone
      const admin: any = props?.admins?.filter((ad: any) => ad.id !== centerData.admin);
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New provider signup\n\n" +
        "A new provider is registering with your admin account. Accept or Deny the request in your admin account. "
      }${url}\n`;

      await props.sendSMS({ message: messageSentText, phone: admin[0]?.phoneNumber });

      // setDisableButton(true);
      window.open("https://www.deloresdigital.com/contact-us.html", "_blank");
    }
  };

  const isValidated = (e: any) => {
    e.preventDefault();
    // let input = inputState;
    let errors = {
      npi: "",
      name: "",
      password: "",
      email: "",
      phoneNumber: "",
      address: "",
      state: "",
      physicianName: "",
      physicianNpi: "",
      admin: "",
    };
    errors.name = centerData.name ? "" : "Please Enter Name";
    errors.password = centerData.password ? "" : "Please Enter Password";
    errors.phoneNumber = centerData.phoneNumber ? "" : "Please Enter Phone Number";
    errors.email = centerData.email ? "" : "Please Enter Email";
    errors.address = centerData.address ? "" : "Please Enter your address";
    errors.state = centerData.state ? "" : "Please Select State";
    errors.physicianName = centerData.physicianName ? "" : "Please Enter Name";
    errors.admin = centerData.admin ? "" : "Please Select Admin";
    if (centerData.email && centerData.email !== "") {
      const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/);
      if (!pattern.test(centerData.email)) {
        errors.email = "Please Enter Valid Email";
      }
    }
    if (centerData.npi) {
      errors.npi = centerData.npi && centerData.npi?.length > 5 ? "" : "Please Enter NPI(5-digits)";
    }
    if (centerData.phoneNumber && centerData.phoneNumber !== "") {
      const phone = "+1" + centerData?.phoneNumber;
      const pattern = new RegExp(/^\+[1-9]\d{10,14}$/);
      if (!pattern.test(phone)) {
        errors.phoneNumber = "Please enter a valid phone number start with +1**********";
      }
    }
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };
  // React.useEffect(() => {
  //   console.log("admins", props.admins);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  const classes = useStyles();
  return props.registeringCenter ? (
    <CircularProgress />
  ) : (
    <Container className={classes.gridContainer} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  type="string"
                  placeholder={`No capitals in email `}
                  name="email"
                  onChange={(e) =>
                    setCenterData({ ...centerData, email: e.target.value.toLocaleLowerCase() })
                  }
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  type="string"
                  name="phone"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                  placeholder={`Start number without "+1" `}
                  onChange={(e) => setCenterData({ ...centerData, phoneNumber: e.target.value })}
                  error={errorState["phoneNumber"] ? true : false}
                  helperText="Phone Number to receive notifications"
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Expert Name"
                  type="string"
                  name="doctorName"
                  onChange={(e) => setCenterData({ ...centerData, physicianName: e.target.value })}
                  error={errorState["physicianName"] ? true : false}
                  helperText={errorState["physicianName"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="NPI (if any)"
                  type="string"
                  name="npi"
                  onChange={(e) => setCenterData({ ...centerData, npi: e.target.value })}
                  error={errorState["npi"] ? true : false}
                  helperText={errorState["npi"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Expert NPI"
                  type="string"
                  name="doctorNpi"
                  onChange={(e) => setCenterData({ ...centerData, physicianNpi: e.target.value })}
                  error={errorState["physicianNpi"] ? true : false}
                  helperText={errorState["physicianNpi"]}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Address"
                  type="string"
                  name="address"
                  onChange={(e) => setCenterData({ ...centerData, address: e.target.value })}
                  error={errorState["address"] ? true : false}
                  helperText={errorState["address"]}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Provider Name"
                  type="string"
                  name="name"
                  onChange={(e) => setCenterData({ ...centerData, name: e.target.value })}
                  error={errorState["name"] ? true : false}
                  helperText={errorState["name"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <Autocomplete
                  id="state"
                  fullWidth
                  // freeSolo
                  value={centerData?.state}
                  options={useStateList.map((item: any) => item)}
                  onChange={(e, value) => {
                    setCenterData({ ...centerData, state: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      placeholder="Select State"
                      name="state"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  onChange={(e) => setCenterData({ ...centerData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              <Grid container>
                <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginBottom: "0.5em" }}>
                  <ThemeProvider theme={theme}>
                    <Button
                      onClick={handleSubmit}
                      color="secondary"
                      fullWidth
                      type="submit"
                      variant="contained"
                      // disabled={disableButton}
                    >
                      Apply Now
                    </Button>
                  </ThemeProvider>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {props.errorWhileCenterRegister && (
                  <Alert severity="error">{props.errorWhileCenterRegister}</Alert>
                )}
                {props.whenRegisterSuccessCenter && (
                  <Alert severity="success">{props.whenRegisterSuccessCenter}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
