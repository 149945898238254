import axios from "axios";
import * as actions from "./actions";

export const getAllFeeds = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.gettingAllFeeds());
  try {
    const response = await axios.get(`/feeds/all`);
    if (response.status === 200) {
      dispatch(actions.getAllFeedsSuccess(response?.data));
    } else throw new Error("Could not fetch chats");
  } catch (err: any) {
    dispatch(actions.getAllFeedsFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};

export const updateCenterFileIsMass = (fileId: string, fileState: boolean) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateCenterIsMassCalling());
  try {
    const response = await axios.patch(
      `/feeds/${fileId}`,
      { isMass: fileState ? 1 : 0 },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updateCenterIsMassSuccess(response.data));
    } else throw new Error("Unable to update status");
  } catch (err: any) {
    dispatch(actions.updateCenterIsMassFailure(err.message));
  }
};

export const removeOneFeed = (feedId: string, fileId?: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.removeOneFeed());
  try {
    const response = await axios.patch(
      `/feeds/remove/${feedId}/${fileId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.removeOneFeedSuccess(response.data));
    } else throw new Error("Unable to update status");
  } catch (err: any) {
    dispatch(actions.removeOneFeedFailure(err.message));
  }
};

export const handleDrawerOpen = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.setDrawerOpen());
};
