import * as types from "./types";

export const notificationSMSCalling = () => ({
  type: types.notificationSMSCalling,
});
export const notificationSMSSuccess = () => ({
  type: types.notificationSMSSuccess,
});

export const notificationSMSFailure = (errorMessage: string) => ({
  type: types.notificationSMSFailure,
  payload: errorMessage,
});
