import { Avatar, Box, Container, Grid, Paper, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { centerProfileCalls } from "../../../../store/centers/profile";
import { patientProfileCalls } from "../../../../store/patients/profile";
import {
  physicianProfileCalls,
  physicianProfileSelectors,
} from "../../../../store/physician/profile";
import { IPhysician, USER_TYPE } from "../../../../store/types";
import { PrimaryButton } from "../../../base/Components/PrimaryButton";
import { useStyles } from "../../main/muiStyling";
import { UploadDocumentComp } from "../../main/ProfileComponents/UploadDocument/UploadDocumentComp";
import PersonIcon from "@material-ui/icons/Person";
import WcIcon from "@material-ui/icons/Wc";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
interface RouteParams {
  id: any;
}

interface OwnProps {
  user: USER_TYPE;
}
interface DispatchProps {
  getCenterProfile: () => Promise<any>;
  getPhysicianProfile: () => Promise<any>;
  getPatientProfile: () => Promise<any>;
}
interface StateProps {
  physicianProfile: IPhysician;
}
type Props = OwnProps & StateProps & DispatchProps;

function ItemPatientProfile(props: Props) {
  const history = useHistory();
  const classes = useStyles();
  let { id } = useParams<RouteParams>();
  const [patient, setPatient]: any = useState({
    email: "",
    firstName: "",
    lastName: "",
    age: "",
    gender: "",
    state: "",
    race: "",
    image: "",
  });
  React.useEffect(() => {
    props.getPhysicianProfile();
    props.physicianProfile?.patients?.map((patient) =>
      patient._id === id
        ? setPatient({
            email: patient.email,
            firstName: patient.firstName,
            lastName: patient.lastName,
            age: patient.age,
            gender: patient.gender,
            state: patient.state,
            race: patient.race,
            image: patient.files.filter((f: any) => f.isProfile)[0]?.url,
          })
        : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePatientList = () => {
    history.push("/physician/patients-profile");
  };
  return (
    <div className={classes.main}>
      <div className={classes.root}>
        <Container maxWidth="md" style={{ marginTop: "5rem" }}>
          <Paper style={{ padding: "16px", margin: "auto" }}>
            <Grid container spacing={2}>
              <Grid item>
                <Avatar
                  src={patient.image || `https://via.placeholder.com/150?text=Profile`}
                  style={{ width: 150, height: 150, alignSelf: "center" }}
                />
              </Grid>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <PersonIcon />
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                      {patient?.firstName} {patient?.lastName}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <MailOutlineIcon />
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                      {patient?.email}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <AccountCircleIcon />
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                      {`Age: ${patient?.age ? patient?.age : "N/A"}`}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <WcIcon />
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                      {`Gender: ${patient?.gender ? patient?.gender : "N/A"}`}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <PersonPinIcon />
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                      {`State: ${patient?.state ? patient?.state : "N/A"}`}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <WcIcon />
                    <Typography variant="body2" style={{ marginLeft: "8px" }}>
                      {`Race: ${patient?.race ? patient?.race : "N/A"}`}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={10} style={{ margin: "auto" }}>
            <Grid item xs={12} sm container></Grid>
          </Grid>
          <Grid item xs={12} sm={10} style={{ margin: "auto" }}>
            <UploadDocumentComp
              read={true}
              userType={USER_TYPE.PATIENT}
              patientProfile={patient}
              getCenterProfile={props.getCenterProfile}
              getPatientProfile={props.getPatientProfile}
              getPhysicianProfile={props.getPhysicianProfile}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3} style={{ paddingTop: "4rem", justifyContent: "center" }}>
          <PrimaryButton onClick={handlePatientList} title="Back to All Patients" />
        </Grid>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  getPatientProfile: () => dispatch(patientProfileCalls.getPatientProfile()),
  getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
});
const mapStateToProps = (state: any) => ({
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
});
export default connect(mapStateToProps, mapDispatchToProps)(ItemPatientProfile);
