import { green } from "@material-ui/core/colors";

import { createMuiTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "1em",
      // marginTop:"1em"
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "25ch",
    },
    container: {
      padding: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(1),
      width: "50%",
    },
    main: {
      display: "block",
      width: "100%",
      height: "93vh",
      overflowY: "hidden",
      backgroundColor: "#eeeeee",
    },
    primaryButton: {
      color: "white",
      textShadow: "0 0 lawngreen",
      fontWeight: "bolder",
    },
  })
);

export const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: green,
  },
});
