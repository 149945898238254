export const startNewChat = "@/patient/@startNewChat";
export const startNewChatSuccess = "@/patient/@startNewChatSuccess";
export const startNewChatFailure = "@/patient/@startNewChatFailure";
export const gettingAllChats = "@/patient/@gettingAllChats";
export const getAllChatsSuccess = "@/patient/@getAllChatsSuccess";
export const getAllChatsFailure = "@/patient/@getAllChatsFailure";

// export const registerIn = "@/patient/@registerStart";
// export const registerSuccess = "@/patient/@registerSuccess";
// export const registerFailure = "@/patient/@registerFailure";
