export const usRedListStates = [
  "Alabama",
  "Arkansas",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Louisiana",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Virginia",
  "DC",
  "West Virginia",
  "Wisconsin",
] as any;

export const specialties = [
  "Anxiety / Stress",
  "Depression",
  "Gender / Sexuality",
  "Professional / Career Support",
  "Sexual Health",
  "Disease / Illness",
  "Diabetes",
  "Chronic Disease",
  "Relationships",
  "Family Matters",
  "PTSD/Trauma",
  "Health & Fitness",
  "Substance Abuse",
  "Nutrition / Diet",
  "Holistic Medicine",
  "Venting",
  "General Clinical Advice",
  "General Mental Advice",
  "Spiritual Guidance",
  "Holistic Coaching",

] as any;

export const useStateList = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "DC",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Puerto Rico",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
] as any;
export const greenList = [
  "Alaska",
  "Arizona",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Kansas",
  "Kentucky",
  "Maine",
  "Maryland",
  "New Mexico",
  "New York",
  "Puerto Rico",
  "Utah",
  "Vermont",
  "Virgin Islands",
  "Washington",
  "Wyoming",
];

export const genderList = ["Male", "Female", "Other"] as any;
