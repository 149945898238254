export const isLoggingIn = (state: any) => state.loggingIn;
export const isLoggedOut = (state: any) => state.login;
export const hasLoginError = (state: any) => state.loginError;
export const isSigningUp = (state: any) => state.registering;
export const hasSignUpError = (state: any) => state.registerError;
// export const hasAuthToken = (state: any) => localStorage.getItem("physicianToken") || undefined;
export const hasAuthToken = (state: any) => state.authToken;
export const signUpSuccess = (state: any) => state.signedUp || undefined;
export const verifyToken = (state: any) => localStorage.getItem("physicianToken") || undefined;
export const successMessage = (state: any) => state.success || undefined;
