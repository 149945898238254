import React from "react";
import { Redirect, Route } from "react-router-dom";

// interface props {
//   component: Component<T, T>;
//   isAuthenticated: boolean;
// }

const ProtectedPhysician = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    localStorage.getItem("physicianToken") ? (
      React.createElement(component, props)
    ) : (
      <Redirect to={{ pathname: "/auth" }} />
    );

  return <Route {...rest} render={routeComponent} />;
};
export default ProtectedPhysician;
