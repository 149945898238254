export const adminProfileCalling = "@/center/@profileCalling";
export const adminProfileSuccess = "@/center/@profileSuccess";
export const adminProfileFailure = "@/admin/@profileFailure";

export const adminProfileUpdating = "@/admin/@adminProfileUpdating";
export const adminProfileUpdateSuccess = "@/admin/@adminProfileUpdateSuccess";
export const adminProfileUpdateFailure = "@/admin/@adminProfileUpdateFailure";

export const updateCenterRequest = "@/admin/@updateCenterRequest";
export const updateCenterRequestSuccess = "@/admin/@updateCenterRequestSuccess";
export const updateCenterRequestFailure = "@/admin/@updateCenterRequestFailure";
// adding type of file
export const updateAdminFilePrivacyCalling = "@/admin/@updateAdminFileCalling";
export const updateAdminFilePrivacySuccess = "@/admin/@updateAdminFileSusccess";
export const updateAdminFilePrivacyFailure = "@/admin/@updateAdminFileFailure";
