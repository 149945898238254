import React from "react";
import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Divider from "@material-ui/core/Divider";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import { IMessage } from "../../../store/types/message";
import { USER_TYPE } from "../../../store/types";
import { IconButton } from "@material-ui/core";
import ChatBar from "../../chat/ChatBar";
import { IChat } from "../../../store/types/chat";
import { ICenter } from "../../../store/types/center";
import { NotificationDTO, RetryMessageDTO } from "../../../store/types/type";
import RefreshIcon from "@material-ui/icons/Refresh";
import { IPhysician } from "../../../store/types/physician";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      backgroundColor: theme.palette.background.paper,
      overflowY: "auto",
    },
    inline: {
      display: "inline",
    },
  })
);
interface Props {
  messages: IMessage[];
  retryMessages: RetryMessageDTO[];
  retrySending: (text: string, messageIndex: number) => void;
  userType: USER_TYPE;
  currentChat?: IChat;
  centersAndPhysicians: ICenter[];
  getAllCentersAndPhysicians: () => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  gettingCenters: boolean;
  getCentersError: string;
  getChatsAfterAdd: (chat: IChat) => Promise<void>;
  physicianProfile: IPhysician;
  physicianProfileCalling: boolean;
  physicianProfileFailure: string;
  centerProfile: ICenter;
  centerProfileCalling: boolean;
  centerProfileFailure: string;
}
export default function CenterPhysicianConversation(props: Props) {
  const classes = useStyles();

  const ownCheck = (msg: IMessage | RetryMessageDTO) => {
    if (props?.userType === USER_TYPE.CENTER && msg.fromCenter) return true;
    else if (
      props.userType === USER_TYPE.PHYSICIAN &&
      msg.fromPhysician &&
      msg.fromPhysician?._id === props.physicianProfile?._id
    )
      return true;
    return false;
  };

  return props.currentChat ? (
    <>
      <div className="chatBarWrapper">
        <ChatBar
          sendSMS={props.sendSMS}
          currentChat={props.currentChat}
          userType={props.userType}
          getAllCentersAndPhysicians={props.getAllCentersAndPhysicians}
          centersAndPhysicians={props.centersAndPhysicians}
          getCentersError={props.getCentersError}
          gettingCenters={props.gettingCenters}
          getChatsAfterAdd={props.getChatsAfterAdd}
          physicianProfile={props.physicianProfile}
          physicianProfileCalling={props.physicianProfileCalling}
          physicianProfileFailure={props.physicianProfileFailure}
          centerProfile={props.centerProfile}
          centerProfileCalling={props.centerProfileCalling}
          centerProfileFailure={props.centerProfileFailure}
          showPrivacy={false}
          setShowPrivacy={() => {}}
          changeChatPrivacy={async (id, toggle) => {}}
        />
      </div>
      <div className="convWrapper">
        <List className={classes.root} style={{ backgroundColor: "rgb(225, 226, 225)" }}>
          {[...props.messages, ...props.retryMessages]?.map((m, i) => {
            return (
              <div key={i}>
                <Divider component="li" />
                {ownCheck(m) ? (
                  <ListItem alignItems="flex-start" style={{ opacity: m._id ? 1 : 0.5 }}>
                    {!m._id ? (
                      <IconButton
                        onClick={() =>
                          props.retrySending(m.text as string, i - props.messages.length)
                        }
                      >
                        <RefreshIcon />
                        <span style={{ fontSize: "15px", marginLeft: "5px" }}>Retry</span>
                      </IconButton>
                    ) : null}
                    <ListItemText
                      style={{ textAlign: "right", wordBreak: "break-all" }}
                      primary={m.text}
                      secondary={m.createdAt || ""}
                    />
                    <ListItemAvatar>
                      <Avatar
                        style={{ float: "right" }}
                        alt={
                          m.fromPatient?.firstName ||
                          m.fromPhysician?.firstName ||
                          m.fromCenter?.name
                        }
                        src={
                          (m.fromPatient || m.fromPhysician || m.fromCenter)?.files?.filter(
                            (f) => f.isProfile
                          )[0]?.url || "https://via.placeholder.com/150?text=Profile"
                        }
                      />
                    </ListItemAvatar>
                  </ListItem>
                ) : (
                  <ListItem selected alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar
                        alt={
                          m.fromPatient?.firstName ||
                          m.fromPhysician?.firstName ||
                          m.fromCenter?.name
                        }
                        src={
                          (m.fromPatient || m.fromPhysician || m.fromCenter)?.files?.filter(
                            (f) => f.isProfile
                          )[0]?.url || "https://via.placeholder.com/150?text=Profile"
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      style={{ wordBreak: "break-all" }}
                      primary={m.text}
                      secondary={m.createdAt}
                    />
                  </ListItem>
                )}
              </div>
            );
          })}
        </List>
      </div>
    </>
  ) : (
    <>{""}</>
  );
}
