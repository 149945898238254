import "@progress/kendo-theme-default/dist/all.css";
import { useCallback, useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import axios from "axios";
import DatePicker from "./DatePicker";
import TimeSlots from "./TimeSlots";
import { useStyles } from "./muiStyles";
import { Alert } from "@material-ui/lab";
import { IPhysician } from "../../../../store/types";

interface OwnProps {
  userType: any;
  physicianProfile: IPhysician;
  phyId: any;
}
type Props = OwnProps;

function Appointment(props: Props) {
  const [bookingDate, setBookingDate] = useState<any>(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>([]);
  const [allSelectedTimeSlot, setAllSelectedTimeSlot] = useState<any>([]);
  const [phyProf, setPhyProf] = useState<any>([]);
  const [bookingTimes, setBookingTimes] = useState([]);
  const [slots, setSlots] = useState([]);
  const [bookingDay, setBookingDay] = useState("Select Date");
  // const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const handleSlots = useCallback(
    (sl) => {
      const times: any = [
        { time: "12:00PM - 12:30PM", available: false },
        { time: "12:30PM - 01:00PM", available: false },
        { time: "01:00PM - 01:30PM", available: false },
        { time: "01:30PM - 02:00PM", available: false },
        { time: "02:00PM - 02:30PM", available: false },
        { time: "02:30PM - 03:00PM", available: false },
        { time: "03:00PM - 03:30PM", available: false },
        { time: "03:30PM - 04:00PM", available: false },
        { time: "04:00PM - 04:30PM", available: false },
        { time: "04:30PM - 05:00PM", available: false },
        { time: "05:00PM - 05:30PM", available: false },
        { time: "05:30PM - 06:00PM", available: false },
        { time: "06:00PM - 06:30PM", available: false },
        { time: "07:30PM - 08:00PM", available: false },
        { time: "08:00PM - 08:30PM", available: false },
        { time: "08:30PM - 09:00PM", available: false },
        { time: "09:00PM - 09:30PM", available: false },
        { time: "09:30PM - 10:00PM", available: false },
        { time: "10:00PM - 10:30PM", available: false },
        { time: "10:30PM - 11:00PM", available: false },
        { time: "11:00PM - 11:30PM", available: false },
        { time: "11:30PM - 12:00AM", available: false },
        { time: "12:00AM - 12:30AM", available: false },
        { time: "12:30AM - 01:00AM", available: false },
        { time: "01:00AM - 01:30AM", available: false },
        { time: "01:30AM - 02:00AM", available: false },
        { time: "02:00AM - 02:30AM", available: false },
        { time: "02:30AM - 03:00AM", available: false },
        { time: "03:00AM - 03:30AM", available: false },
        { time: "03:30AM - 04:00AM", available: false },
        { time: "04:00AM - 04:30AM", available: false },
        { time: "04:30AM - 05:00AM", available: false },
        { time: "05:00AM - 05:30AM", available: false },
        { time: "05:30AM - 06:00AM", available: false },
        { time: "06:00AM - 06:30AM", available: false },
        { time: "07:30AM - 08:00AM", available: false },
        { time: "08:00AM - 08:30AM", available: false },
        { time: "08:30AM - 09:00AM", available: false },
        { time: "09:00AM - 09:30AM", available: false },
        { time: "09:30AM - 10:00AM", available: false },
        { time: "10:00AM - 10:30AM", available: false },
        { time: "10:30AM - 11:00AM", available: false },
        { time: "11:00AM - 11:30AM", available: false },
        { time: "11:30AM - 12:00PM", available: false },
      ];
      if (sl?.length <= 0) return;
      const res = times.reduce((acc: any, curr: any) => {
        const index = sl?.findIndex((item: any) => item.time === curr.time);
        if (index > -1) {
          curr.available = true;
        }
        acc.push(curr);
        return acc;
      }, []);
      setBookingTimes(res);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slots]
  );
  useEffect(() => {
    setPhyProf(props.physicianProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTimeSlot = (time: any) => {
    if (!allSelectedTimeSlot.includes(time)) {
      setSelectedTimeSlot([...selectedTimeSlot, time]);
      setAllSelectedTimeSlot([...allSelectedTimeSlot, time]);
    } else {
      console.log("TimeSlot is already taken");
    }
  };

  const handleScheduleTime = async () => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    //@ts-ignore
    var newDate = new Date(bookingDate);
    newDate.setHours(0, 0, 0, 0);

    if (newDate && newDate >= today) {
      const slots = selectedTimeSlot.map((time: any) => {
        return { time: time, available: true };
      });
      const scheduleTime = {
        date: bookingDate,
        slots: slots,
      };
      try {
        const res = await axios.post(`/physician/sessionAvailability`, scheduleTime, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
          },
        });
        if (res.status === 201) {
          console.log("API Response", res);
        }
        setSuccess(true);
      } catch (err) {
        console.log("error", err);
        setError(true);
      }
    } else {
      console.log("Start time must be less then end time");
    }
  };
  function formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onDateChange = async (e: any) => {
    setSelectedTimeSlot([]);
    setBookingDate(formatDate(e));
    setAllSelectedTimeSlot([]);
    // setOpen(true);
    if (phyProf?.availabilities) {
      let storeDate = phyProf?.availabilities?.filter(
        (a: any) =>
          new Date(a.date).toISOString().split("T")[0] === new Date(e).toISOString().split("T")[0]
      );
      handleSlots(storeDate[0]?.slots);
      setSlots(storeDate[0]?.slots);
    }

    switch (e?.getDay()) {
      case 0:
        setBookingDay("Sunday");
        break;
      case 1:
        setBookingDay("Monday");
        break;
      case 2:
        setBookingDay("Tuesday");
        break;
      case 3:
        setBookingDay("Wednesday");
        break;
      case 4:
        setBookingDay("Thursday");
        break;
      case 5:
        setBookingDay("Friday");
        break;
      case 6:
        setBookingDay("Saturday");
        break;
      default:
        console.log("Select date");
    }
  };
  return (
    <Container>
      {props.userType === "Physician" && (
        //Patient Date
        <Grid container>
          <Grid item md={3} lg={3} sm={3} xs={12} className={classes.grid}>
            <TimeSlots
              bookingDay={bookingDay}
              bookingTimes={bookingTimes}
              allSelectedTimeSlot={allSelectedTimeSlot}
              selectTimeSlot={selectTimeSlot}
              userType={props.userType}
            />
          </Grid>

          <Grid item md={9} lg={9} sm={9} xs={12} className={classes.grid}>
            {error && <Alert severity="error">Error</Alert>}
            {success && <Alert severity="success">Success</Alert>}
            <DatePicker bookingDate={bookingDate + 1} onDateChange={onDateChange} />
            <div className={classes.scheduleButton}>
              <Button
                autoFocus
                variant="contained"
                color="primary"
                onClick={() => handleScheduleTime()}
              >
                Schedule Time
              </Button>
            </div>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}

export default Appointment;
