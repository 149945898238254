import React, { Component } from "react";

import ExploreIcon from "@material-ui/icons/Explore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
// // import { Profile } from '../Profile';
// import { ProfileCard } from './ProfileCard';
import { IconButton, Typography, withStyles } from "@material-ui/core";
// import { Edit } from "@material-ui/icons";
// import "./styles.css";

const styles: any = {
  content: {
    marginTop: "3em",
    textAlign: "center",
    overflowX: "auto",
    minHeight: "130px",
  },
  div: {
    // marginLeft: "1em",
    "&:hover": {
      boxShadow: "5px 5px 5px 0px rgba(194,194,194,1)",
    },
  },
  container: {
    maxHeight: 200,
  },
  top: {
    backgroundColor: "#018c1f",
    borderRadius: "10px",
    textAlign: window.screen.width >= 425 ? "center" : "left",
    color: "#fff",
    padding: window.screen.width >= 668 ? "10px" : "8px",
    boxShadow: "5px 5px 5px 0px rgba(194,194,194,1)",
    fontSize: window.screen.width <= 668 ? "10px" : "16px",
  },
  head: {
    fontSize: window.screen.width >= 668 ? "18px" : "6px",
  },
  actionButton: {
    marginTop: "10px",
    paddingLeft: "5px",
    marginLeft: window.screen.width >= 668 ? "-34px" : "-1em",
    color: "white",
    display: "flex",
    alignItems: "center",
    float: "right",
  },
};

type Props = {
  componentRoute?: (arg0: string) => any;
  classes: any;
  title: string;
  desc: string;
};
class ElevatedTop extends Component<Props> {
  render() {
    const { componentRoute, classes, title, desc }: any = this.props;
    function renderRoute() {
      componentRoute(title);
    }
    return (
      <div className={classes.div} style={{ cursor: "pointer" }}>
        <CardHeader
          title={title}
          classes={{ content: classes.top }}
          // className={classes.head}
          // avatar={
          //   <Avatar aria-label="recipe" style={{marginRight: "-5em",
          //     backgroundColor: "#018c1f"}}>
          //     <ExploreIcon/>
          //   </Avatar>
          // }
          style={{ padding: "0px" }}
          action={
            <IconButton aria-label="settings" className={classes.actionButton}>
              <ExploreIcon />
            </IconButton>
          }
        />
        <Card
          style={{ marginTop: "-3em", backgroundColor: "#E1E2E1", color: "black" }}
          onClick={renderRoute}
        >
          <CardContent className={classes.content}>
            <Typography style={{ fontSize: "18px" }} variant="h4" gutterBottom>
              {desc}
            </Typography>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const WrappedCards = withStyles(styles)(ElevatedTop);

export default WrappedCards;
