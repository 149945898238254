import { Button, Container, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { theme } from "../base/ColorPalete";
import { ThemeProvider } from "@material-ui/core/styles";
import CircleSpinner from "../CircleSpinner";
import { PatientSignUpDTO, PatientSignUpInfo } from "../../store/types/type";
import { Alert, Autocomplete } from "@material-ui/lab";
import { IPatient } from "../../store/types";
import { useStyles } from "./muiStyling";
import { IRegister } from "../../store/types/jwt";
import { useSelector } from "react-redux";
import GoogleLogin from "react-google-login";
import { useStateList } from "../../store/types/states";
import { Link } from "react-router-dom";


interface StateProps {
  registeringPatient: boolean;
  patientRegistered: boolean;
  errorWhilePatientRegister: string;
  whenRegisterSuccessPatient: IRegister;
  updateRegisterSuccess: string;
  updateRegisterFailure: string;
}

interface DispatchProps {
  patientSignUp: (patientData: PatientSignUpDTO, type: string) => Promise<IPatient>;
  updatePatientRegister: (patientData: PatientSignUpInfo) => Promise<IPatient>;
  changeType: (type: string) => void;
  queryParams?: any;
  setPatientDetails: (type: string) => void;
}
interface OwnProps {
  step: any;
  setStep: any;
}
type Props = StateProps & DispatchProps & OwnProps;
export default function PatientSignUpCard(props: Props) {
  const queryParams = props.queryParams;

  const { auth } = useSelector((state: any) => state.patients);

  const [patientData, setPatientData] = React.useState<PatientSignUpDTO>({
    email: "",
    firstName: "",
    lastName: "",
    state: "",
    password: "",
    phoneNumber: "",
  });
  const [errorState, setErrorState] = React.useState<PatientSignUpDTO>({
    email: "",
    firstName: "",
    lastName: "",
    state: "",
    password: "",
    phoneNumber: "",
  });
  useEffect(() => {
    if (auth.success && props.step === 2) {
      props.changeType("paypal");
      // @ts-ignore
      props.setPatientDetails(patientData?.email);
    }
    if (auth.success && props.step === 1) {
      props.changeType("number");
    }
  }, [auth.success, patientData.email, props]);
  // @ts-ignore
  const handleSubmit = (event: any) => {
    if (isValidated(event)) {
      if (!queryParams.id && !queryParams.hash) {
        const phone = "+1" + patientData.phoneNumber;

        props.patientSignUp(
          {
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            email: patientData.email,
            state: patientData.state,
            password: patientData.password,
            phoneNumber: phone,
          },
          "email"
        );
      }
      if (
        (queryParams.id as string) &&
        (queryParams.email as string) &&
        (queryParams.firstName as string) &&
        (queryParams.lastName as string) &&
        (queryParams.hash as string)
      ) {
      }
    }
    props.setStep(2);
  };

  const isValidated = (e: any) => {
    e.preventDefault();
    let errors = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      phoneNumber: "",
    };

    if (!queryParams.id) {
      errors.firstName = patientData.firstName ? "" : "Please Enter First Name";
      errors.lastName = patientData.lastName ? "" : "Please Enter Last Name";
      errors.password = patientData.password ? "" : "Please Enter Password";
      errors.phoneNumber = patientData.phoneNumber ? "" : "Please Enter Phone Number";
      errors.email = patientData.email ? "" : "Please Enter Email";
      if (patientData.email && patientData.email !== "") {
        const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/);
        if (pattern.test(patientData.email) === false) {
          errors.email = "Please Enter Valid Email";
        }
      }

      if (patientData.phoneNumber && patientData.phoneNumber !== "") {
        const pattern = new RegExp(/^\+[1-9]\d{10,14}$/);
        const phone = "+1" + patientData.phoneNumber;
        if (!pattern.test(phone)) {
          errors.phoneNumber = "Please enter a valid phone number start with +1**********";
        }
      }
      setErrorState({ ...errors });
      return Object.values(errors).every((error) => error === "");
    }
    if (queryParams.id && queryParams.hash) {
      errors.password = patientData.password ? "" : "Please Enter Password";
      errors.phoneNumber = patientData.phoneNumber ? "" : "Please Enter Phone Number";
      if (patientData.phoneNumber && patientData.phoneNumber !== "") {
        const pattern = new RegExp(/^\+[1-9]\d{10,14}$/);
        if (!pattern.test(patientData.phoneNumber)) {
          errors.phoneNumber = "Please enter a valid phone number start with +1**********";
        }
      }
      setErrorState({ ...errors });
      return Object.values(errors).every((error) => error === "");
    }
  };

  const classes = useStyles();
  const handleFailure = (response: any) => {
    console.log(response);
  };
  const handleLogin = async (googleData: any) => {
    setPatientData({
      ...patientData,
      firstName: googleData.profileObj.givenName,
      lastName: googleData.profileObj.familyName,
      email: googleData.profileObj.email,
    });
    await props.patientSignUp(googleData, "google");

    props.setStep(1);
  };
  return props.registeringPatient ? (
    <CircleSpinner />
  ) : (
    <Container className={classes.container} maxWidth="xs">
    <Grid item xs={10} lg={12}>
      {/* signUp with google */}
      <div style={{ textAlign: "center", padding: "1rem" }}>
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_CLIENT || ""}
          buttonText="Sign up with Google"
          onSuccess={handleLogin}
          onFailure={handleFailure}
          cookiePolicy={"single_host_origin"}
          style={{ marginTop: "100px", width: "100%" }}
        />
      </div>
            <div style={{ textAlign: "center", paddingBottom: "1rem", paddingTop: "1rem" }}>OR</div>
    </Grid>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  type="string"
                  name="email"
                  placeholder={`No capitals in email `}
                  disabled={Boolean(queryParams.hash)}
                  value={queryParams.email || patientData.email}
                  onChange={(e) =>
                    setPatientData({ ...patientData, email: e.target.value.toLocaleLowerCase() })
                  }
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                  className={classes.disabledInput}
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="First Name"
                  type="string"
                  name="firstName"
                  disabled={Boolean(queryParams.hash)}
                  value={queryParams.firstName || patientData.firstName}
                  onChange={(e) => setPatientData({ ...patientData, firstName: e.target.value })}
                  error={errorState["firstName"] ? true : false}
                  helperText={errorState["firstName"]}
                  className={classes.disabledInput}
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Last Name"
                  type="string"
                  name="lastName"
                  disabled={Boolean(queryParams.hash)}
                  value={queryParams.lastName || patientData.lastName}
                  onChange={(e) => setPatientData({ ...patientData, lastName: e.target.value })}
                  error={errorState["lastName"] ? true : false}
                  helperText={errorState["lastName"]}
                  className={classes.disabledInput}
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  type="string"
                  name="phone"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                  placeholder={`Phone Number`}
                  onChange={(e) => setPatientData({ ...patientData, phoneNumber: e.target.value })}
                  error={errorState["phoneNumber"] ? true : false}
                  helperText="Phone Number to receive two-factor-authentication code"
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <Autocomplete
                  id="state"
                  fullWidth
                  // freeSolo
                  value={patientData?.state}
                  options={useStateList.map((item: any) => item)}
                  onChange={(e, value) => {
                    setPatientData({ ...patientData, state: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      placeholder="Select State"
                      name="state"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  name="password"
                  onChange={(e) => setPatientData({ ...patientData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              <div style={{ textAlign: "center", paddingBottom: "1rem", paddingTop: ".3rem", fontStyle:"italic", fontSize:".6rem", fontFamily:"calibri"}}>
              By submitting this form you consent to receive text messages from Delores Digital.

              We we will only send text messages for two-factor-authentication when you login to your profile.

              Message & data rates may apply. Unsubscribe at any time by replying "STOP" or "CANCEL".
              </div>

              <Grid item xs={6} style={{ marginBottom: "0.5em" }}>
                <ThemeProvider theme={theme}>

                  <Button
                    onClick={handleSubmit}
                    color="secondary"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    Sign up
                  </Button>

                </ThemeProvider>
              </Grid>
              <div style={{ textAlign: "center", paddingBottom: "1rem", fontStyle:"italic", fontSize:".3rem", fontFamily:"calibri"}}>
              <Typography component="p" variant="subtitle1"  align="left" style={{ fontSize:".6rem"}}>
                By signing up you agree to the{" "}
                <Link
                  to={{
                    pathname: "https://www.deloresdigital.com/terms",
                    target: "_blank",
                  }}
                  onClick={() => {
                    window.open("https://www.deloresdigital.com/terms", "_blank");
                  }}
                >
                  terms of use
                </Link>{" "}
                & {" "}
                <Link
                  to={{
                    pathname: "https://www.deloresdigital.com/privacy.html",
                    target: "_blank",
                  }}
                  onClick={() => {
                    window.open("https://www.deloresdigital.com/privacy.html", "_blank");
                  }}
                >
                   privacy policy
                </Link>
              </Typography>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </form>
      <Grid item xs={12}>
        {props.errorWhilePatientRegister && (
          <Alert severity="error">{props.errorWhilePatientRegister}</Alert>
        )}
        {props.whenRegisterSuccessPatient && (
          <Alert severity="success">{props.whenRegisterSuccessPatient.msg}</Alert>
        )}
        {props.updateRegisterSuccess && (
          <Alert severity="success">{props.updateRegisterSuccess}</Alert>
        )}
        {props.updateRegisterFailure && (
          <Alert severity="error">{props.updateRegisterFailure}</Alert>
        )}
      </Grid>
    </Container>
  );
}
