export const loggingIn = "@/admin/@loginStart";
export const loginSuccess = "@/admin/@loginSuccess";
export const loginFailure = "@/admin/@loginFailure";

export const verifyingToken = "@/admin/@verifyTokenStart";
export const verifyTokenSuccess = "@/admin/@verifyTokenSuccess";
export const verifyTokenFailure = "@/admin/@verifyTokenFailure";

export const loggedOut = "@/admin/@loggedOut";
export const registerIn = "@/admin/@registerStart";
export const registerSuccess = "@/admin/@registerSuccess";
export const registerFailure = "@/admin/@registerFailure";
