/* eslint-disable react-hooks/exhaustive-deps */

import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { onlineCalls, onlineSelectors } from "../../../store/online";
import { patientProfileSelectors } from "../../../store/patients/profile";
import { IPatient, IPhysician } from "../../../store/types";
import { SearchPhyDTO, SearchPhyDTO2 } from "../../../store/types/type";
import PhoneIcon from "@material-ui/icons/Phone";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import ChatIcon from "@material-ui/icons/Chat";
import { useHistory } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import { searchPhysician } from "../../../store/patients/profile/apiCalls";
//import { searchPhysician2 } from "../../../store/patients/profile/apiCalls2";
import { useStyles } from "./muiStyling";
import Logo from "../../../staticResources/logos/logo.png";
import Anxiety from "../../../staticResources/UserDashboard/Anxiety_Stress.jpg";
import Depression from "../../../staticResources/UserDashboard/Depression.jpg";
import Gender from "../../../staticResources/UserDashboard/Gender_Sexuality.jpg";
import Professional from "../../../staticResources/UserDashboard/ReligiousCounseling.jpg";
import Sexual from "../../../staticResources/UserDashboard/SexualHealth.jpg";
import Disease from "../../../staticResources/UserDashboard/Disease_Illness.jpg";
import Diabetes from "../../../staticResources/UserDashboard/Diabetes.jpg";
import Chronic from "../../../staticResources/UserDashboard/ChronicDisease.jpg";
import Relationships from "../../../staticResources/UserDashboard/Relationships.jpg";
import Family from "../../../staticResources/UserDashboard/FamilyMatters.jpg";
import PTSD from "../../../staticResources/UserDashboard/PTSD_Tramus.jpg";
import Health from "../../../staticResources/UserDashboard/Health&Fitness.jpg";
import Substance from "../../../staticResources/UserDashboard/SubstanceAbuse.jpg";
import Nutrition from "../../../staticResources/UserDashboard/Nutrition_Diet.jpg";
import Holistic from "../../../staticResources/UserDashboard/HolisticMedicine.jpg";
import Venting from "../../../staticResources/UserDashboard/JustVenting.jpg";
import Career from "../../../staticResources/UserDashboard/Professional.jpg";
import Coaching from "../../../staticResources/UserDashboard/Holistic_Coaching.jpg";

import GeneralClinical from "../../../staticResources/UserDashboard/GeneralClinicalAdvice.jpg";
import GeneralMental from "../../../staticResources/UserDashboard/GeneralMentalAdvice.jpg";
import DashboardChip from "../../base/Components/DashboardChip";

const specialties = [
  { title: "General Clinical Advice", url: GeneralClinical },
  { title: "Depression", url: Depression },
  { title: "General Mental Advice", url: GeneralMental },
  { title: "Spiritual Guidance", url: Professional },
  { title: "Sexual Health", url: Sexual },
  { title: "& More", url: Disease },
//  { title: "Diabetes", url: Diabetes },
//  { title: "Chronic Disease", url: Chronic },
//  { title: "Relationships", url: Relationships },
//  { title: "Family Matters", url: Family },
//  { title: "PTSD / Trauma", url: PTSD },
//  { title: "Health & Fitness", url: Health },
//  { title: "Substance Abuse", url: Substance },
//  { title: "Nutrition / Diet", url: Nutrition },
//  { title: "Holistic Medicine", url: Holistic },
//  { title: "Venting", url: Venting },
//  { title: "Anxiety / Stress", url: Anxiety },
//  { title: "Gender / Sexuality", url: Gender },
//  { title: "Professional / Career Support", url: Career },
  //{ title: "Holistic Coaching", url: Coaching },
] as any;

/*  Insert the beloow at cell #215
  <div
        onClick={() => hanldeSpecialityClick("General Clinical Advice")}
        style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
      >
        <CardHeader
          className={cardClass.cardHeader}
          title="Diverse care. Affordable rates. Expert support. Measurable impact."
          titleTypographyProps={{ variant: "body1" }}
          style={{textAlign: "center"}}
       ></CardHeader>
      </div>
  */
interface OwnProps {}
interface DispatchProps {
  getOnlineUsers: () => Promise<void>;
  searchPhysician2: (search: SearchPhyDTO2) => Promise<void>;
}

interface StateProps {
  isOnlineSuccess: string[];
  searchPhysicianSuccess: IPhysician[] | null;
  searchPhysicianFailure: string;
  profile?: IPatient;
}

type Props = OwnProps & DispatchProps & StateProps;
export const _PingProfessioanl2 = (props: Props) => {
  let history = useHistory();
  const [specialtiesDate, setSpecialtiesDate] = React.useState<any>({
    specialties: "",
  });
  const [search, setSearch] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [phyId] = React.useState<string | null>(null);
  React.useEffect(() => {
    const asyncAwait = async () => {
      await props.getOnlineUsers();
    };
    asyncAwait();
  }, []);

  React.useMemo(() => {
    setSearch(props.searchPhysicianSuccess);
  }, [props.searchPhysicianSuccess, props.isOnlineSuccess, props.profile]);
  // const classes = useStyle();
  const cardClass = useStyles();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.searchPhysician2(specialtiesDate);
    setSpecialtiesDate({
      specialties: "",
    });
  };
 

  const handleClickIntro = () => {
       history.push("/authenticate");
       //history.push("../../../views/auth/MainScreen3");
   }

  const handleClose = () => {
    setOpen(false);
  };
  const hanldeSpecialityClick = (specialtyTitle: any) => {
    setSpecialtiesDate({ specialties: specialtyTitle });
    history.push(`patient/specialties2`, { state: specialtyTitle });
  };

  const handleChat = () => {
    if (phyId && props.profile?.physicians?.find((phy) => phy._id === phyId)) {
      history.push("/patient/portal/messages");
    } else {
      history.push("/patient/portal/selectCenter");
    }
  };
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img src={Logo} alt={"delores"} style={{ width: "300px" }} />
      </div>
      <div className={cardClass.cardMainDiv}>
      <Button autoFocus variant="outlined" color="primary" onClick={handleClickIntro}>
        Account Login & Registration
        <span> &nbsp; </span>
      </Button>
        <div className={cardClass.cardHeaderMainDiv}>
          <div
            onClick={() => hanldeSpecialityClick("General Clinical Advice")}
            style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
          >
            <CardHeader
              className={cardClass.cardHeader}
              title="Find An Expert For Your Situation"
              titleTypographyProps={{ variant: "h6" }}
            ></CardHeader>
          </div>
        </div>
        <CardContent className={cardClass.cardContent}>
          <Box
          // style={{
          //   flexGrow: 1,
          // }}
          >
            <form onSubmit={handleSubmit}>
              <Grid container xs={12} justify="center">
                {specialties?.map((specialty: any, index: number) => {
                  return (
                    <Grid
                      item
                      xs={4}
                      sm={4}
                      md={4}
                      lg={4}
                      xl={3}
                      key={index}
                      className={cardClass.gridPadding}
                    >
                      <DashboardChip
                        specialityTitle={specialty.title}
                        url={specialty.url}
                        onClick={() => {
                          hanldeSpecialityClick(specialty.title);
                        }}
                      />

                      {}
                    </Grid>
                  );
                })}
              </Grid>
              {}
            </form>
          </Box>
          {}
        </CardContent>

      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
        <DialogTitle id="responsive-dialog-title">
          {"Professional Profile Info"}
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            endIcon={<ChatIcon />}
            onClick={handleChat}
            style={{
              float: "right",
              marginTop: "8px",
              marginRight: -10,
            }}
          >
            message
          </Button>
        </DialogTitle>
        <Paper
          style={{
            padding: "16px",
            margin: "auto",
            width: "80%",
            height: "auto",
            backgroundColor: "#f5f5f5",
            borderRadius: 10,
            boxShadow: "0px 0px 10px #00000029",
            border: "1px solid #e0e0e0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "10%",
            marginTop: "10px",
          }}
        >
          {search &&
            search.map((physicianProfile: any, i: any) => {
              return physicianProfile._id === phyId ? (
                <>
                  <Grid container spacing={2} key={i}>
                    <Grid item>
                      <Avatar
                        alt={physicianProfile?.firstName}
                        src={
                          physicianProfile?.files?.filter((f: any) => f.isProfile)[0]?.url ||
                          `https://via.placeholder.com/150?text=Profile`
                        }
                        style={{ width: 150, height: 150, alignSelf: "center" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            {physicianProfile?.firstName}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <HomeIcon />
                            <Typography
                              style={{ marginLeft: "10px" }}
                              component="div"
                              variant="subtitle2"
                            >
                              Address:
                              {physicianProfile?.practiceAddress}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <AccountCircleIcon />
                            <Typography
                              style={{ marginLeft: "10px" }}
                              component="div"
                              variant="subtitle2"
                            >
                              Bio: {physicianProfile?.bio ? physicianProfile?.bio : "no bio"}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <PhoneIcon />
                            <Typography
                              style={{ marginLeft: "10px" }}
                              component="div"
                              variant="subtitle2"
                            >
                              Mobile Number:{" "}
                              {physicianProfile?.publicNumber
                                ? physicianProfile?.publicNumber
                                : "No Public Number"}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <PersonPinCircleIcon />
                            State : {physicianProfile?.state ? physicianProfile?.state : "No State"}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null;
            })}
        </Paper>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            endIcon={<Close />}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const mapStateToProps = (state: any) => ({
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  searchPhysicianSuccess: patientProfileSelectors.searchPhysicianSuccess(
    state.patients.patientProfile
  ),
  searchPhysicianFailure: patientProfileSelectors.searchPhysicianFailure(
    state.patients.patientProfile
  ),
  profile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
});

export const mapDispatchToProps = (dispatch: any) => ({
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  searchPhysician: (search: SearchPhyDTO) => dispatch(searchPhysician(search)),
  searchPhysician2: (search: SearchPhyDTO2) => dispatch(searchPhysician(search)),
});
export const PingProfessioanl2 = connect(mapStateToProps, mapDispatchToProps)(_PingProfessioanl2);
