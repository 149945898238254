import { IFileData } from "../../types";
import { ICenter } from "../../types/center";
import * as types from "./types";

export const centerProfileCalling = () => ({
  type: types.centerProfileCalling,
});
export const centerProfileSuccess = (profile: ICenter) => ({
  type: types.centerProfileSuccess,
  payload: profile,
});

export const centerProfileFailure = (errorMessage: string) => ({
  type: types.centerProfileFailure,
  payload: errorMessage,
});

export const centerProfileUpdating = () => ({
  type: types.centerProfileUpdating,
});
export const centerProfileUpdateSuccess = (updatedProfile: ICenter) => ({
  type: types.centerProfileUpdateSuccess,
  payload: updatedProfile,
});
export const centerProfileUpdateFailure = (errorMessage: string) => ({
  type: types.centerProfileUpdateFailure,
  payload: errorMessage,
});

export const centerNumberUpdating = () => ({
  type: types.centerNumberUpdating,
});
export const centerNumberUpdateSuccess = (updatedProfile: ICenter) => ({
  type: types.centerNumberUpdateSuccess,
  payload: updatedProfile,
});
export const centerNumberUpdateFailure = (errorMessage: string) => ({
  type: types.centerNumberUpdateFailure,
  payload: errorMessage,
});

export const verifyNumberUpdating = () => ({
  type: types.centerNumberVerificationUpdating,
});
export const verifyNumberUpdatingSuccess = (updatedProfile: ICenter) => ({
  type: types.centerNumberVerificationUpdateSuccess,
  payload: updatedProfile,
});
export const verifyNumberUpdatingFailure = (errorMessage: string) => ({
  type: types.centerNumberVerificationUpdateFailure,
  payload: errorMessage,
});

export const updatePhysicianRequest = () => ({
  type: types.updatePhysicianRequest,
});
export const updatePhysicianRequestSuccess = () => ({
  type: types.updatePhysicianRequestSuccess,
  // payload: updatedProfile
});
export const updatePhysicianRequestFailure = (errorMessage: string) => ({
  type: types.updatePhysicianRequestFailure,
  payload: errorMessage,
});
// update the privacy of uploaded file
export const updateCenterFilePrivacyCalling = () => ({
  type: types.updateCenterFilePrivacyCalling,
});
export const updateCenterFilePrivacySuccess = (updatedCenterFile: IFileData) => ({
  type: types.updateCenterFilePrivacySuccess,
  payload: updatedCenterFile,
});

export const updateCenterFilePrivacyFailure = (errorMessage: string) => ({
  type: types.updateCenterFilePrivacyFailure,
  payload: errorMessage,
});
