export const gettingAdmins = "@/super/@gettingAllAdmins";
export const fetchAdmins = "@/super/@getAdminsSuccess";
export const getAdminsError = "@/super/@getAdminsFailure";

export const gettingCenters = "@/super/@gettingAllCenters";
export const fetchCenters = "@/super/@getCentersSuccess";
export const getCentersError = "@/super/@getCentersFailure";

export const gettingPatients = "@/super/@gettingAllPatients";
export const fetchPatients = "@/super/@getPatientsSuccess";
export const getPatientsError = "@/super/@getPatientsFailure";

export const gettingPhysicians = "@/super/@gettingAllPhysicians";
export const fetchPhysicians = "@/super/@getPhysiciansSuccess";
export const getPhysiciansError = "@/super/@getPhysiciansFailure";

export const updateAdminRequest = "@/super/@updateAdminRequest";
export const updateAdminRequestSuccess = "@/super/@updateAdminRequestSuccess";
export const updateAdminRequestFailure = "@/super/@updateAdminRequestFailure";

export const updatingAccount = "@/super/@updatingAccount";
export const updateAccount = "@/super/@updateAccountSuccess";
export const updateAccountError = "@/super/@updateAccountFailure";

export const assignCenterRequest = "@/super/@assignCenterRequest";
export const assignCenterRequestSuccess = "@/super/@assignCenterRequestSuccess";
export const assignCenterRequestFailure = "@/super/@assignCenterRequestFailure";

export const removeCenterFromAdminRequest = "@super/removeCenterFromAdminRequest";
export const removeCenterFromAdminRequestSuccess = "@super/removeCenterFromAdminRequestSuccess";
export const removeCenterFromAdminRequestFailure = "@super/removeCenterFromAdminRequestFailure";

export const removeCenterFeed = "@super/removeCenterFeed";
export const removeCenterFeedSuccess = "@super/removeCenterFeedSuccess";
export const removeCenterFeedFailure = "@super/removeCenterFeedFailure";

export const getAllCenterFeeds = "@super/getAllCenterFeeds";
export const getAllCenterFeedsSuccess = "@super/getAllCenterFeedsSuccess";
export const getAllCenterFeedsFailure = "@super/getAllCenterFeedsFailure";

export const gettingScheduleTime = "@/super/@gettingScheduleTime";
export const fetchScheduleTime = "@/super/@fetchScheduleTime";
export const getScheduleTimeError = "@/super/@getScheduleTimeError";
