import { connect } from "react-redux";
import React, { useState } from "react";
import { basicCalls, basicSelectors } from "../../../../store/supers/basicCalls";
import { IAdmin, ICenter } from "../../../../store/types";
import PersonIcon from "@material-ui/icons/Person";
import {
  Button,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  DialogTitle,
  Dialog,
  ListItemAvatar,
  Avatar,
  makeStyles,
  Grid,
  Typography,
} from "@material-ui/core";
import blue from "@material-ui/core/colors/blue";

interface OwnProps {}
interface DispatchProps {
  getCenters: () => Promise<void>;
  getAdmins: () => Promise<void>;
  assignCenterRequestSuccess: (centerID: string, adminID: string) => Promise<void>;
}

interface StateProps {
  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;

  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  assignCenterRequest: ICenter[];
}

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

type Props = OwnProps & StateProps & DispatchProps;
function AssignCenter(props: Props) {
  const centers = props.fetchCenters.filter((cen) => {
    return cen?.approved;
  });
  const admins = props.fetchAdmins.filter((adm) => {
    return adm?.approved;
  });
  const [open, setOpen] = useState(false);
  const [selectedCenterId, setSelectedCenterId] = useState("");
  const handleClose = async (adminID: string) => {
    await props.assignCenterRequestSuccess(selectedCenterId, adminID);
    await props.getAdmins();
    await props.getCenters();
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
      {centers?.map((cen, i) => {
        return (
          <Grid style={{ padding: "10px", width: "20%" }} key={i} item xs={12} sm={6} md={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography
                  className={classes.title}
                  style={{ color: "black", fontSize: "2em" }}
                  gutterBottom
                >
                  {`${cen.name}`}
                </Typography>
                <Typography
                  className={classes.title}
                  style={{ color: "black", fontSize: "1em" }}
                  gutterBottom
                >
                  {`NPI :${cen.npi ? cen.npi : "N/A"}`}
                </Typography>
                <Typography
                  className={classes.title}
                  style={{ color: "black", fontSize: "1em" }}
                  gutterBottom
                >
                  {`Email : ${cen.email}`}
                </Typography>
                <Typography
                  className={classes.title}
                  style={{ color: "black", fontSize: "1em" }}
                  gutterBottom
                >
                  {`Address : ${cen.address}`}
                </Typography>
                <Typography
                  className={classes.title}
                  style={{ color: "black", fontSize: "1em" }}
                  gutterBottom
                >
                  {`Current Admin:${cen.admin?.name}`}
                </Typography>
                <Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setOpen(true);
                      setSelectedCenterId(cen._id);
                    }}
                  >
                    Assign
                  </Button>
                </Typography>
              </CardContent>
            </Card>
            <Dialog fullWidth onClose={handleClose} open={open}>
              <DialogTitle id="simple-dialog-title">
                Choose an Admin to Register this Provider Under
              </DialogTitle>
              <List>
                {admins?.map((admin, i) => (
                  <ListItem button onClick={() => handleClose(admin._id)} key={i}>
                    <ListItemAvatar>
                      <Avatar className={classes.avatar}>
                        <PersonIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={admin?.name} />
                  </ListItem>
                ))}
              </List>
            </Dialog>
          </Grid>
        );
      })}
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getCenters: () => dispatch(basicCalls.getCenters()),
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  assignCenterRequestSuccess: (centerID: string, adminID: string) =>
    dispatch(basicCalls.assignCenterRequest(centerID, adminID)),
});

const mapStateToProps = (state: any) => ({
  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),

  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignCenter);
