import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  makeStyles,
  TextField,
  ThemeProvider,
  Typography,
} from "@material-ui/core";
import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  physicianProfileCalls,
  physicianProfileSelectors,
} from "../../../../store/physician/profile";
import { IPatient, IPhysician } from "../../../../store/types";
import { PrimaryButton } from "../../../base/Components/PrimaryButton";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import { theme } from "../muiStyling";
import { PhysicianAddPatientDTO } from "../../../../store/types/type";
import { Alert, AlertTitle } from "@material-ui/lab";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
  },
  container: {
    padding: theme.spacing(3),
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});
interface OwnProps {}
interface DispatchProps {
  getPhysicianProfile: () => Promise<any>;
  createPatient: (patient: PhysicianAddPatientDTO) => Promise<any>;
}
interface StateProps {
  physicianProfile: IPhysician;
  registerInUserSuccess: boolean;
  registerInUserFailure: string;
}
type Props = OwnProps & StateProps & DispatchProps;

function PatientList(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [patientData, setPatientData] = React.useState<PhysicianAddPatientDTO>({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [errorState, setErrorState] = React.useState<PhysicianAddPatientDTO>({
    email: "",
    firstName: "",
    lastName: "",
  });
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setPatientData({
      firstName: "",
      lastName: "",
      email: "",
    });
    setErrorState({
      email: "",
      firstName: "",
      lastName: "",
    });
  };

  const viewRecord = (id: any) => {
    history.push(`/physician/patient-profile/${id}`);
  };
  React.useEffect(() => {
    const profileCall = async () => await props.getPhysicianProfile();
    if (profileCall) profileCall();
    else history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (event: any) => {
    if (isValidated(event)) {
      props.createPatient(patientData);
    }
  };

  const isValidated = (e: any) => {
    e.preventDefault();

    let errors = {
      email: "",
      firstName: "",
      lastName: "",
    };

    errors.firstName = patientData.firstName ? "" : "Please Enter First Name";
    errors.lastName = patientData.lastName ? "" : "Please Enter Last Name";
    errors.email = patientData.email ? "" : "Please Enter Email";
    if (patientData.email && patientData.email !== "") {
      const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/);
      if (pattern.test(patientData.email) === false) {
        errors.email = "Please Enter Valid Email";
      }
    }
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };
  return (
    <>
      {props.physicianProfile ? (
        <div style={{ width: "90%", margin: "0 auto", paddingTop: "3rem" }}>
          <Grid container justify="flex-end">
            <Button
              variant="contained"
              startIcon={<PersonAddIcon style={{ color: "#0d5d18" }} />}
              onClick={handleClickOpen}
            >
              Create New User
            </Button>
          </Grid>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={true}
          >
            <DialogTitle id="alert-dialog-title">{"Create New User"}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <Container className={classes.container} maxWidth="xs">
                  <form>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              label="Email"
                              type="string"
                              name="email"
                              onChange={(e) =>
                                setPatientData({
                                  ...patientData,
                                  email: e.target.value.toLocaleLowerCase(),
                                })
                              }
                              error={errorState["email"] ? true : false}
                              helperText={errorState["email"]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              label="First Name"
                              type="string"
                              name="firstName"
                              onChange={(e) =>
                                setPatientData({ ...patientData, firstName: e.target.value })
                              }
                              error={errorState["firstName"] ? true : false}
                              helperText={errorState["firstName"]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              size="small"
                              variant="outlined"
                              label="Last Name"
                              type="string"
                              name="lastName"
                              onChange={(e) =>
                                setPatientData({ ...patientData, lastName: e.target.value })
                              }
                              error={errorState["lastName"] ? true : false}
                              helperText={errorState["lastName"]}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <ThemeProvider theme={theme}>
                              <Button
                                onClick={handleSubmit}
                                color="primary"
                                fullWidth
                                type="submit"
                                variant="contained"
                              >
                                Create
                              </Button>
                            </ThemeProvider>
                          </Grid>
                          <Grid item xs={12}>
                            {props.registerInUserSuccess ? (
                              <Alert severity="success">"Successfully create New User"</Alert>
                            ) : props.registerInUserFailure ? (
                              <Alert severity="error">
                                <AlertTitle>Unable To create New User</AlertTitle>
                                {props.registerInUserFailure}
                              </Alert>
                            ) : null}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </form>
                </Container>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                {props.registerInUserSuccess ? "Close Now" : "Cancel"}
              </Button>
            </DialogActions>
          </Dialog>
          <Grid container spacing={3}>
            {props.physicianProfile.patients.map((value: IPatient) => {
              // const labelId = `checkbox-list-label-${value}`;
              return (
                <>
                  {value ? (
                    <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
                      <Card className={classes.root}>
                        <CardContent>
                          <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {`${value.firstName} ${value.lastName}`}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <PrimaryButton
                            onClick={() => viewRecord(value._id)}
                            title="View Records"
                          />
                        </CardActions>
                      </Card>
                    </Grid>
                  ) : null}
                </>
              );
            })}
          </Grid>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  createPatient: (data: any) => dispatch(physicianProfileCalls.createUser(data)),
});
const mapStateToProps = (state: any) => ({
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  registerInUserSuccess: physicianProfileSelectors.isRegisterInUserSuccess(
    state.physicians.physicianProfile
  ),
  registerInUserFailure: physicianProfileSelectors.isRegisterInUserFailure(
    state.physicians.physicianProfile
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(PatientList);
