import React from "react";
import { USER_TYPE } from "../../../store/types";
import { Home as PhysicianHome } from "../main/Home";
import FeedForPhysician from "./FeedForPhysician";

export default function Home() {
  const ping =
    "Send files and securely start conversations with doctors inside and outside of your practice";
  const profile = "Edit your Profile and settings. Upload files ans control file privacy";
  const msg = "Securely Chat with Users , patients and your team with HIPAA compliant messaging";
  const Items = [
    { title: "Messages", desc: msg },
    { title: "Ping", desc: ping },
    { title: "Your Profile", desc: profile },
  ];
  return (
    <>
      <div>
        <PhysicianHome type={USER_TYPE.PHYSICIAN} physicianItems={Items} />
        <FeedForPhysician />
      </div>
    </>
  );
}
