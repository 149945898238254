export const loggingIn = "@/physicians/@loginStart";
export const loginSuccess = "@/physicians/@loginSuccess";
export const loginFailure = "@/physicians/@loginFailure";
export const loggedOut = "@/physicians/@loggedOut";

export const verifyingToken = "@/physicians/@verifyTokenStart";
export const verifyTokenSuccess = "@/physicians/@verifyTokenSuccess";
export const verifyTokenFailure = "@/physicians/@verifyTokenFailure";

export const registerIn = "@/physicians/@registerStart";
export const registerSuccess = "@/physicians/@registerSuccess";
export const registerFailure = "@/physicians/@registerFailure";
