import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
// import InputBase from "@material-ui/core/InputBase";
import Badge from "@material-ui/core/Badge";
// import MenuItem from "@material-ui/core/MenuItem";
// import Menu from "@material-ui/core/Menu";
import MenuIcon from "@material-ui/icons/Menu";
// import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { connect } from "react-redux";
import { ProfileMenu } from "./ProfileMenu";
import {
  authCalls as physicianAuthCalls,
  authSelectors as physicianAuthSelectors,
} from "../../store/physician/auth";
import {
  authCalls as patientAuthCalls,
  authSelectors as patientAuthSelectors,
} from "../../store/patients/auth";
import {
  authCalls as centerAuthCalls,
  authSelectors as centerAuthSelectors,
} from "../../store/centers/auth";
import {
  authCalls as adminAuthCalls,
  authSelectors as adminAuthSelectors,
} from "../../store/admins/auth";
import {
  authCalls as superAuthCalls,
  authSelectors as superAuthSelectors,
} from "../../store/supers/auth";
import { useHistory } from "react-router-dom";
import { useStyles } from "./muiStyling";
import { NotificationMenu } from "./notifications/NotificationMenu";
import { IPhysician, USER_TYPE } from "../../store/types";
import { patientProfileCalls, patientProfileSelectors } from "../../store/patients/profile";
import { basicCalls, basicSelectors } from "../../store/supers/basicCalls";
import { feedCalls, feedSelectors } from "../../store/feed";

import Button from "@material-ui/core/Button";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SpecialtiesIcon from "@material-ui/icons/ListRounded";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PersonIcon from "@material-ui/icons/Person";
import CallMadeIcon from "@material-ui/icons/CallMade";
import { useGoogleLogout } from "react-google-login";

/* Note under "Toolbar" i removed the following:
<Button
  variant="outlined"
  className={classes.appbarButton}
  startIcon={<CallMadeIcon className={classes.buttonIcon} />}
  onClick={() => handleFeedOrMessageClick()}
>
  {props.type === USER_TYPE.PHYSICIAN ? "Messages" : "Feed"}
</Button> */

function Appbar(props: {
  physicianLogout: () => Promise<void>;
  patientLogout: () => Promise<void>;
  centerLogout: () => Promise<void>;
  adminLogout: () => Promise<void>;
  superLogout: () => Promise<void>;
  getPatientProfile: () => Promise<void>;
  getPhysicians: () => Promise<void>;
  setDrawerOpen: () => Promise<void>;
  hasAuthTokenPhysician?: string;
  hasAuthTokenPatient: string | undefined;
  hasAuthTokenCenter: string | undefined;
  hasAuthTokenAdmin: string | undefined;
  hasAuthTokenSuper: string | undefined;
  fetchPhysicians: IPhysician[];
  gettingPhysicians: boolean;
  getPhysiciansError: string | undefined;
  patientProfile: any;
  type: USER_TYPE;
  deloresTheme?: boolean;
  handleTheme?: any;
  handleDrawerOpen?: any;
}) {
  const classes = useStyles();
  // Profile state
  const [profileAnchorEl, setprofileAnchorEl] = React.useState<null | HTMLElement>(null);
  // Notification state
  const [notificationAnchorEl, setnotificationAnchorEl] = React.useState<any>(false);
  // const [mobileMoreAnchorEl,setMobileMoreAnchorEl,
  // ] = React.useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = React.useState(0);
  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isNotificationMenuOpen = Boolean(notificationAnchorEl);
  // const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { signOut } = useGoogleLogout({
    // jsSrc,
    // onFailure,
    // clientId:process.env.CLIENT_ID,
    clientId: "1006747696663-npinecp4eac09af62an9go7js77qpfor.apps.googleusercontent.com",
    // cookiePolicy,
    // loginHint,
    // hostedDomain,
    // fetchBasicProfile,
    // discoveryDocs,
    // uxMode,
    // redirectUri,
    // scope,
    // accessType,
    // onLogoutSuccess
  });
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setprofileAnchorEl(event.currentTarget);
  };

  const handleNotificationMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setnotificationAnchorEl(true);
  };

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  const handleProfileMenuClose = () => {
    setprofileAnchorEl(null);
    // handleMobileMenuClose();
  };

  const handleNotificationMenuClose = () => {
    setnotificationAnchorEl(false);
    // handleMobileMenuClose();
  };

  const handleLogout = async () => {
    switch (props.type) {
      case USER_TYPE.PHYSICIAN:
        await props.physicianLogout();
        break;
      case USER_TYPE.PATIENT:
        await props.patientLogout();
        signOut();
        break;
      case USER_TYPE.CENTER:
        await props.centerLogout();
        break;
      case USER_TYPE.ADMIN:
        await props.adminLogout();
        break;
      case USER_TYPE.SUPER:
        await props.superLogout();
        break;
      default:
        break;
    }
  };

  const handleProfile = async () => {
    switch (props.type) {
      case USER_TYPE.PHYSICIAN:
        history.push("/physician/profile");
        handleProfileMenuClose();
        break;
      case USER_TYPE.PATIENT:
        history.push("/patient/profile");
        handleProfileMenuClose();
        break;
      case USER_TYPE.CENTER:
        history.push("/center/profile");
        handleProfileMenuClose();
        break;
      case USER_TYPE.SUPER:
        history.push("/super/profile");
        handleProfileMenuClose();
        break;
      default:
        break;
    }
  };

  const handleMyProfileClick = () => {
    history.push(`/${props.type?.toLowerCase()}/profile`);
  };
  const handleChatClick = () => {
    if (props.type === USER_TYPE.PATIENT) history.push("/patient/portal/messages");
    else if (props.type === USER_TYPE.PHYSICIAN) history.push(`/${props.type?.toLowerCase()}/physician/portal/messages`);
    else history.push(`/${props.type?.toLowerCase()}/physician/portal/messages`);
  };
  const handleDiscussionClick = () => {
    history.push(`/${props.type?.toLowerCase()}/patient`);
  };
  const handleFeedOrMessageClick = () => {
    history.push(`/${props.type?.toLowerCase()}`);
    if (props.type === USER_TYPE.PHYSICIAN) history.push("/physician/portal/message");
    else history.push(`/${props.type?.toLowerCase()}`);
  };

  // const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const history = useHistory();

  // React.useEffect(() => {
  //   if (
  //     // !props.hasAuthTokenPhysician ||
  //     // !props.hasAuthTokenPatient ||
  //     !props.hasAuthTokenCenter
  //   ) {
  //     history.push("/auth");
  //   }
  // }, [
  //   // props.hasAuthTokenPhysician ||
  //   //   props.hasAuthTokenPatient ||
  //   props.hasAuthTokenCenter,
  // ]);

  // React.useEffect(() => {
  //   if (!props.hasAuthTokenPhysician) {
  //     history.push("/auth");
  //   }
  // }, [props.hasAuthTokenPhysician]);

  React.useEffect(() => {
    if (
      !props.hasAuthTokenAdmin &&
      !props.hasAuthTokenPatient &&
      !props.hasAuthTokenCenter &&
      !props.hasAuthTokenPhysician &&
      !props.hasAuthTokenSuper
    ) {
      history.push("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.hasAuthTokenPatient,
    props.hasAuthTokenPhysician,
    props.hasAuthTokenCenter,
    props.hasAuthTokenAdmin,
    props.hasAuthTokenSuper,
  ]);

  const menuId = "primary-search-account-menu";
  const renderProfileMenu = (
    <ProfileMenu
      anchorEl={profileAnchorEl}
      menuId={menuId}
      isMenuOpen={isProfileMenuOpen}
      handleMenuClose={handleProfileMenuClose}
      handleLogout={handleLogout}
      handleProfile={handleProfile}
    />
  );

  const renderNotificationMenu = (
    <NotificationMenu
      anchorEl={notificationAnchorEl}
      // menuId={menuId}
      isMenuOpen={isNotificationMenuOpen}
      handleMenuClose={handleNotificationMenuClose}
      type={props.type}
      patientProfile={props.patientProfile}
      getPatientProfile={props.getPatientProfile}
      setNotifications={setNotifications}
      getPhysicians={props.getPhysicians}
      fetchPhysicians={props.fetchPhysicians}
    />
  );

  // const mobileMenuId = "primary-search-account-menu-mobile";
  // const renderMobileMenu = (
  //   <MenuHandle
  //     mobileMoreAnchorEl={mobileMoreAnchorEl}
  //     mobileMenuId={mobileMenuId}
  //     isMobileMenuOpen={isMobileMenuOpen}
  //     handleMobileMenuClose={handleMobileMenuClose}
  //     handleProfileMenuOpen={handleProfileMenuOpen}
  //     handleNotifications={handleNotifications}
  //   />
  // );

  return (
    <div
      className={classes.grow}
      style={{
        width: "100%",
      }}
    >
      <AppBar position="static" style={{ background: "#d8d8d8" }}>
        <Toolbar className={classes.toolbar}>
          {/* <div className={classes.search} style={{ color: "#2f4050" }}> */}

          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => props.setDrawerOpen()}
            edge="start"
            style={{ color: "#D8D8D8" }}
            // className={clsx(classes.menuButton, open && classes.hide)}
          >
            <MenuIcon />
          </IconButton>

          {/* <div className={classes.searchIcon}>
              <SearchIcon style={{ color: "#2f4050" }} />
            </div> */}
          {/* <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ "aria-label": "search" }}
            /> */}
          {/* <Switch
            checked={props.deloresTheme}
            onChange={props.handleTheme}
            inputProps={{ "aria-label": "primary checkbox" }}
          /> */}
          {/* </div> */}
          <div className={classes.gridFlex} />
          <div className={classes.sectionDesktop}>
            <>
              {props.type !== USER_TYPE.SUPER && props.type !== USER_TYPE.ADMIN && (
                <div className={classes.buttonsDiv}>
                  <Button
                    variant="outlined"
                    className={classes.appbarButton}
                    startIcon={<PersonIcon className={classes.buttonIcon} />}
                    onClick={() => handleMyProfileClick()}
                  >
                    My Profile
                  </Button>
                  <Button
                    variant="outlined"
                    className={classes.appbarButton}
                    startIcon={<ChatBubbleOutlineIcon className={classes.buttonIcon} />}
                    onClick={() => handleChatClick()}
                  >
                    {props.type === USER_TYPE.PATIENT ? "Sessions" : "Dashboard"}
                  </Button>

                  {props.type === USER_TYPE.PATIENT && (
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.appbarButton}
                      startIcon={<HomeWorkIcon className={classes.buttonIcon} />}
                      onClick={() => handleDiscussionClick()}
                    >
                      Home
                    </Button>
                  )}


                </div>
              )}
            </>

            <IconButton
              aria-label="new notifications"
              color="inherit"
              onClick={handleNotificationMenuOpen}
            >
              <Badge badgeContent={notifications} color="primary">
                <NotificationsIcon style={{ width: "30px", height: "30px", color: "#2f4050" }} />
              </Badge>
            </IconButton>
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle style={{ width: "30px", height: "30px", color: "#2f4050" }} />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {/* Render Mobile Menu Items */}
      {/* {renderMobileMenu} */}
      {/* Render Profile Menu Items */}
      {renderProfileMenu}
      {/* Render Notification Menu */}
      {renderNotificationMenu}
    </div>
  );
}
const mapStateToProps = (state: any) => ({
  hasAuthTokenPhysician: physicianAuthSelectors.verifyToken(state.physicians.auth),
  hasAuthTokenPatient: patientAuthSelectors.verifyToken(state.patients.auth),
  hasAuthTokenCenter: centerAuthSelectors.verifyToken(state.centers.auth),
  hasAuthTokenAdmin: adminAuthSelectors.verifyToken(state.admins.auth),
  hasAuthTokenSuper: superAuthSelectors.verifyToken(state.supers.auth),

  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  drawerOpen: feedSelectors.drawerOpen(state.feeds.drawerOpen),

  fetchPhysicians: basicSelectors.fetchPhysicians(state.supers.basicCalls),
  gettingPhysicians: basicSelectors.gettingPhysicians(state.supers.basicCalls),
  getPhysiciansError: basicSelectors.getPhysiciansError(state.supers.basicCalls),
});
const mapDispatchToProps = (dispatch: any) => ({
  physicianLogout: () => dispatch(physicianAuthCalls.logout()),
  patientLogout: () => dispatch(patientAuthCalls.logout()),
  centerLogout: () => dispatch(centerAuthCalls.logout()),
  adminLogout: () => dispatch(adminAuthCalls.logout()),
  superLogout: () => dispatch(superAuthCalls.logout()),

  getPatientProfile: () => dispatch(patientProfileCalls.getPatientProfile()),
  getPhysicians: () => dispatch(basicCalls.getPhysicians()),
  setDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);
