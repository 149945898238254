export const gettingMyNotes = "@/notes/@gettingMyNotes";
export const getMyNotesSuccess = "@/notes/@profileSuccess";
export const getMyNotesFailure = "@/notes/@getMyNotesFailure";

export const updatingNote = "@/notes/@updatingNote";
export const updateNoteSuccess = "@/notes/@updatingNoteSuccess";
export const updateNoteFailure = "@/notes/@updateNoteFailure";

export const creatingNote = "@/notes/@creatingNote";
export const createNoteSuccess = "@/notes/@createNoteSuccess";
export const createNoteFailure = "@/notes/@createNoteFailure";
