import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core";
import { topstyles } from "../../muiStyling";
import { ProfileCard as PhysicianProfileCard } from "../../../physician/ProfileComponents/ProfileCard";
import { ProfileCard as CenterProfileCard } from "../../../center/ProfileComponents/ProfileCard";
import { ProfileCard as PatientProfileCard } from "../../../patient/ProfileComponents/ProfileCard";
import { ICenter, IPatient, IPhysician, USER_TYPE } from "../../../../../store/types";

interface Props {
  classes: any;
  userType: USER_TYPE;
  read?: boolean;
  centerProfile?: ICenter;
  physicianProfile?: IPhysician;
  patientProfile?: IPatient | undefined;
  getCenterProfile: () => Promise<void>;
  getPatientProfile: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
}
class ElevatedTop extends Component<Props> {
  render() {
    //destructing of props
    const { classes, userType, read } = this.props;
    //render profile wrt type
    const renderAuthButton = () => {
      if (userType === USER_TYPE.PHYSICIAN) {
        return (
          <PhysicianProfileCard
            read={read}
            physicianProfile={this.props.physicianProfile}
            getPhysicianProfile={this.props.getPhysicianProfile}
          />
        );
      } else if (userType === USER_TYPE.CENTER) {
        return (
          <CenterProfileCard
            read={read}
            centerProfile={this.props.centerProfile}
            getCenterProfile={this.props.getCenterProfile}
          />
        );
      } else if (userType === USER_TYPE.PATIENT) {
        return (
          <PatientProfileCard
            read={read}
            patientProfile={this.props.patientProfile}
            getPatientProfile={this.props.getPatientProfile}
          />
        );
      } else {
        return <></>;
      }
    };
    return (
      <>
        <CardHeader title="Current Profile" classes={{ content: classes.top }} />
        <Card
          style={{
            backgroundColor: "#E1E2E1",
            color: "black",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            // width: window.screen.width <= 668 ? "405px" : "100%",
            margin: "0 auto",
            marginBottom: "20px",
            marginTop: "-3em",
          }}
        >
          <CardContent className={classes.content}>{renderAuthButton()}</CardContent>
        </Card>
      </>
    );
  }
}

const ProfileComp = withStyles(topstyles)(ElevatedTop);

export default ProfileComp;
