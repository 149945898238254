import React from "react";
// import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { CustomButtonGroup } from "../../../../base/Components/CustomButtonGroup";
import { ICenter } from "../../../../../store/types/center";
import { IPhysician } from "../../../../../store/types/physician";
import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

interface Props {
  approvePhysicianRequest: (id: string) => Promise<void>;
  denyPhysicianRequest: (id: string) => Promise<void>;
  updatingPhysicianRequest: boolean;
  updatingPhysicianError: string;
  type?: string;
  profile: ICenter;
}
export default function ApprovalList(props: Props) {
  const [showApproveOrDenyErrorOrSuccess, setShowApproveOrDenyErrorOrSuccess] = React.useState(
    false
  );

  React.useEffect(() => {
    setShowApproveOrDenyErrorOrSuccess(props.updatingPhysicianError ? true : false);
  }, [props.updatingPhysicianError]);
  const handleAccept = async (phyID: string) => {
    setShowApproveOrDenyErrorOrSuccess(false);
    await props.approvePhysicianRequest(phyID);
    setShowApproveOrDenyErrorOrSuccess(true);
  };
  const handleReject = async (phyID: string) => {
    setShowApproveOrDenyErrorOrSuccess(false);
    await props.denyPhysicianRequest(phyID);
    setShowApproveOrDenyErrorOrSuccess(true);
  };
  return (
    <div style={{ width: "90%", margin: "0 auto" }}>
      <List>
        {props.profile.physicians
          .filter((phy) => !phy.approved)
          .map((value: IPhysician, i: number) => {
            const labelId = `checkbox-list-label-${value}`;

            return (
              <>
                <ListItem
                  alignItems="center"
                  key={i}
                  role={undefined}
                  dense
                  button
                  // onClick={handleToggle(value)}
                  style={{
                    height: "12vh",
                    margin: "0.5rem 0",
                  }}
                >
                  <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName}`} />
                  <ListItemSecondaryAction>
                    <CustomButtonGroup
                      buttonType="vertical"
                      values={["Accept", "Reject"]}
                      handleClicks={[() => handleAccept(value._id), () => handleReject(value._id)]}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                <Snackbar
                  open={showApproveOrDenyErrorOrSuccess}
                  autoHideDuration={10000}
                  onClose={(e, reason) => {
                    if (reason === "timeout") setShowApproveOrDenyErrorOrSuccess(false);
                  }}
                >
                  <Alert severity={props.updatingPhysicianError ? "error" : "success"}>
                    {props.updatingPhysicianError || "Approved Professional"}
                  </Alert>
                </Snackbar>
              </>
            );
          })}
      </List>
    </div>
  );
}
