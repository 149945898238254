import axios from "axios";
import { basicCalls } from "../../centers/basicCalls";
import { IOtp } from "../../types";
import { PhysicianSignUpDTO, UserLoginDTO } from "../../types/type";
import * as actions from "./actions";

export const login = (userLoginDTO: UserLoginDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.loggingIn());
  try {
    const response = await axios.post(`/auth/signin-physician`, userLoginDTO);
    if (response.status === 201) {
      dispatch(actions.loginSuccess(response.data));
      localStorage.setItem("_id", response.data._id);
      localStorage.setItem("current", "PHYSICIAN");
    } else throw new Error("Could not login");
  } catch (err: any) {
    dispatch(actions.loginFailure(err.response?.data.message));
  }
};

export const verifyToken = (phyOtp: IOtp) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.verifyingToken());
  try {
    const response = await axios.post(`/auth/verify-otp`, phyOtp);
    if (response.status === 201) {
      dispatch(actions.verifyTokenSuccess(response.data.access_token));
      localStorage.setItem("physicianToken", response.data.access_token);
      localStorage.removeItem("password");
    } else throw new Error("Could not verify token");
  } catch (err: any) {
    dispatch(actions.verifyTokenFailure(err.response?.data.message || "Unexpected Error Occurred"));
  }
};

export const signUp = (physicianSignUpDTO: PhysicianSignUpDTO) => async (
  dispatch: (arg0: any) => void
) => {
  dispatch(actions.registerIn());
  try {
    const response = await axios.post(`/auth/signup-physician`, physicianSignUpDTO);
    if (response.status === 201) {
      dispatch(actions.registerSuccess(response.data));
      dispatch(basicCalls.getAllCenters());
    } else throw new Error("Could not sign up");
  } catch (err: any) {
    dispatch(actions.registerFailure(err.response?.data.message || "Unexpected Error Occurred"));
  }
};

export const logout = () => async (dispatch: (arg0: {}) => void) => {
  localStorage.removeItem("physicianToken");
  localStorage.removeItem("_id");
  localStorage.removeItem("current");
  dispatch(actions.loggedOut());
};
