import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  createStyles,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Typography,
  withStyles,
} from "@material-ui/core";
import { IFeed, IPhysician } from "../../../store/types";
import { feedCalls, feedSelectors } from "../../../store/feed";
import { UserCard } from "../../UserCard";
import { onlineCalls, onlineSelectors } from "../../../store/online";

import Appointment from "./Appointment/Appointment";
import { physicianProfileSelectors } from "../../../store/physician/profile";
interface OwnProps {}
interface DispatchProps {
  getAllFeeds: () => Promise<void>;
  getOnlineUsers: () => Promise<void>;
}

interface StateProps {
  getAllFeedsSuccess: IFeed[];
  isOnlineSuccess: string[];
  physicianProfile: IPhysician;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    media: {
      height: "400px",
    },
    container: {
      marginTop: 30,
    },
    card: {
      maxWidth: 550,
      margin: "0 auto",
      // margin: theme.spacing(2),
    },
    multiline: {
      whiteSpace: "pre-line",
    },
    feedTitle: {
      fontWeight: "bold",
    },
  })
);
const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);
type Props = OwnProps & StateProps & DispatchProps;
export const FeedForPhysician = (props: Props) => {
  const [value, setValue] = React.useState(1);

  const classes = useStyles();
  useEffect(() => {
    if (props.getAllFeedsSuccess) return;
    props.getAllFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myId = localStorage.getItem("_id");
  const allFeed = props?.getAllFeedsSuccess.filter((onlyMass: IFeed) => {
    if (
      onlyMass.isMass === true ||
      onlyMass.file?.center?.physicians.toString().includes(myId as any)
    ) {
      return onlyMass;
    } else {
      return false;
    }
  });
  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  function TabPanel(props: { [x: string]: any; index: any; children?: any; value?: any }) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <div>{children}</div>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `scrollable-auto-tab-${index}`,
      "aria-controls": `scrollable-auto-tabpanel-${index}`,
    };
  }
  const onlineNow = props.isOnlineSuccess;
  return (
    <>
      {/* <Typography variant="h6" align={"center"} style={{ marginBottom: 10, color: "#000" }}>
        Delores Digital Feed
      </Typography> */}
      <Paper className={classes.root}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab label="bio" {...a11yProps(0)} />
          <Tab label="Session Availability" {...a11yProps(1)} />
        </Tabs>
      </Paper>

      <TabPanel value={value} index={0}>
        <UserCard />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Paper style={{ padding: "16px", margin: "auto", width: "auto", marginTop: "2rem" }}>
          <div style={{ textAlign: "center" }}>
            <h4>Select Date and Time for Available Appointment slots</h4>
          </div>
          <Appointment
            userType={"Physician"}
            physicianProfile={props.physicianProfile}
            phyId={""}
          />
        </Paper>
      </TabPanel>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAllFeeds: () => dispatch(feedCalls.getAllFeeds()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
});

const mapStateToProps = (state: any) => ({
  getAllFeedsSuccess: feedSelectors.getAllFeedsSuccess(state.feeds),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedForPhysician);
