import * as types from "./types";

const initialState = {
  online: [],
  isOnlineSuccess: false,
  isOnlineFailure: false,
};

export const onlineReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.isOnline:
      return {
        ...state,
        isOnlineSuccess: true,
        isOnlineFailure: false,
      };
    case types.isOnlineSuccess:
      return {
        ...state,
        online: action.payload,
        isOnlineSuccess: true,
        isOnlineFailure: false,
      };
    case types.isOnlineFailure:
      return {
        ...state,
        isOnlineSuccess: false,
        isOnlineFailure: true,
      };
    default: {
      return state;
    }
  }
};
