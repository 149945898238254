import axios from "axios";
import { NotificationDTO } from "../../types/type";
import * as actions from "./actions";

export const sendSMSNotification = (notificationDTO: NotificationDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.notificationSMSCalling());
  try {
    const response = await axios.post(
      `/notifications/send-sms`,
      {
        message: notificationDTO.message,
        phone: notificationDTO.phone,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            `${localStorage.getItem("current")?.toLowerCase()}Token`
          )}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.notificationSMSSuccess());
    } else throw new Error("Unable to send SMS");
  } catch (err: any) {
    dispatch(actions.notificationSMSFailure(err.message));
  }
};
