import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { TextField } from "@material-ui/core";
import { useStyles } from "./muiStyles";

interface OwnProps {
  bookingDate: any;
  onDateChange: any;
}
type Props = OwnProps;
function DatePicker(props: Props) {
  const classes = useStyles();

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          className={classes.calendar}
          displayStaticWrapperAs="mobile"
          value={props.bookingDate}
          onChange={(e: any) => props.onDateChange(e)}
          minDate={new Date()}
          renderInput={(params: any) => <TextField {...params} />}
        />
      </LocalizationProvider>
    </div>
  );
}

export default DatePicker;
