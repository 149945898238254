/* eslint-disable @typescript-eslint/no-unused-expressions */
import { connect } from "react-redux";
import MaterialTable from "material-table";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { basicSelectors, basicCalls } from "../../../../store/supers/basicCalls";
import { IAdmin, USER_TYPE } from "../../../../store/types";
import { useEffect, useState } from "react";
import AlertPopup from "../../../base/Components/AlertPopup";
import { FreezeDTO } from "../../../../store/types/type";

interface OwnProps {}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  freezeAdmins: (id: string, body: FreezeDTO) => Promise<void>;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;
  updatingAdmins: boolean;
  updatedAdmins: IAdmin[];
  updateAdminsError: string;
}

type Props = OwnProps & StateProps & DispatchProps;
function AdminsControl(props: Props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [reason, setReason] = useState("");
  const [userID, setUserID] = useState("");

  const admins = props.fetchAdmins.filter((adm) => {
    return adm?.approved;
  });

  useEffect(() => {
    props.fetchAdmins;
  }, [props.fetchAdmins]);

  const handlePopup = () => {
    setOpenPopup(false);
    if (userID) {
      props.freezeAdmins(userID, { frozen: "1", frozenReason: reason, user: USER_TYPE.ADMIN });
      props.getAdmins();
    }
    props.getAdmins();
  };
  const handleFreeze = (e: { preventDefault: () => void }, data: any) => {
    e.preventDefault();
    if (data?._id) {
      setOpenPopup(true);
      setUserID(data._id);
    }
  };
  const handleUnfreeze = (e: { preventDefault: () => void }, data: any) => {
    e.preventDefault();
    if (data?._id) {
      props.freezeAdmins(data?._id, { frozen: "0", user: USER_TYPE.ADMIN });
    }
    props.getAdmins();
  };
  const handleInput = (e: { target: { value: any } }) => {
    setReason(e.target.value);
  };
  const renderFreezeIcon = () => {
    return <BlockIcon style={{ color: "red" }} />;
  };
  const renderUnFreezeIcon = () => {
    return <CheckCircleIcon style={{ color: "green" }} />;
  };
  return (
    <>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          columns={[
            { title: "id", field: "id", type: "string", hidden: true },
            { title: "Name", field: "name", type: "string" },
            { title: "Email", field: "email", type: "string" },
            { title: "Address", field: "address", type: "string" },
            {
              title: "Freeze",
              field: "freeze",
              type: "boolean",
              render: (rowData) => (rowData.frozen ? "true" : "false"),
            },
            { title: "Phone", field: "phoneNumber", type: "string" },
            {
              title: "LoginCount",
              field: "signedInCount",
              type: "numeric",
              headerStyle: { textAlign: "center", width: 30 },
              cellStyle: { textAlign: "center", width: 30 },
            },
            {
              title: "Last SignIn",
              field: "lastLoginTime",
              type: "date",
              render: (rowData) => new Date(rowData.lastLoginTime).toLocaleDateString(),
            },
            {
              title: "Date of Joining",
              field: "createdAt",
              type: "datetime",
              render: (rowData) => new Date(rowData.createdAt).toLocaleDateString(),
            },
          ]}
          actions={[
            (rowData) => ({
              icon: renderFreezeIcon,
              tooltip: "Freeze Account",
              onClick: (e, rowData) => {
                handleFreeze(e, rowData);
              },
              disabled: rowData.frozen,
            }),
            (rowData) => ({
              icon: renderUnFreezeIcon,
              tooltip: "UnFreeze",
              onClick: (e, rowData) => {
                handleUnfreeze(e, rowData);
                props.getAdmins();
              },
              disabled: !rowData.frozen,
            }),
          ]}
          title="Admin Accounts"
          options={{
            actionsColumnIndex: 8,
            exportButton: true,
            showEmptyDataSourceMessage: true,
            searchAutoFocus: true,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
          data={admins}
        />
      </div>
      <AlertPopup
        title="Freeze Account"
        text={"Enter the reason for freezing this Account."}
        open={openPopup}
        handleOpenPopup={handlePopup}
        buttonText="Submit"
        input={true}
        inputValue={reason}
        handleInputValue={handleInput}
      />
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  freezeAdmins: (id: string, body: FreezeDTO) => dispatch(basicCalls.freezeAccount(id, body)),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),

  updatedAdmins: basicSelectors.updatedAccount(state.supers.basicCalls),
  updatingAdmins: basicSelectors.updatingAccount(state.supers.basicCalls),
  updateAdminsError: basicSelectors.updateAccountError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminsControl);
