import { createStyles, fade, makeStyles, Theme } from "@material-ui/core";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    grow: {
      // marginLeft: "270px",
      // flexGrow: 1,
      width: "100%",
    },
    gridFlex: {
      display: "flex",
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      display: "none",
      [theme.breakpoints.up("sm")]: {
        display: "block",
      },
    },
    search: {
      position: "relative",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: fade(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: fade(theme.palette.common.white, 0.35),
      },
      marginRight: theme.spacing(1),
      //   marginLeft: '10em',
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: "100%",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    inputRoot: {
      color: "inherit",
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create("width"),
      width: "100%",
      [theme.breakpoints.up("md")]: {
        width: "20ch",
      },
    },
    sectionDesktop: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.up("md")]: {
        display: "flex",
      },
    },
    sectionMobile: {
      display: "flex",
      float: "right",
      [theme.breakpoints.up("md")]: {
        display: "none",
      },
    },
    toolbar: {
      justifyContent: "space-between",
      "& .MuiToolbar-gutters": {
        paddingRight: "4rem",
      },
      "& .MuiIconButton-edgeStart": {
        paddingLeft: "25px",
      },
      "& .MuiIconButton-edgeEnd": {
        marginRight: "25px",
      },
      "& .MuiIconButton-root": {
        padding: "12px",
      },
      "@media (max-width:568px)": {
        paddingRight: "0.5rem",
        paddingLeft: "0.5rem",
        "& .MuiIconButton-root": {
          paddingLeft: "3px",
          padding: "0px",
        },
        "& .MuiIconButton-edgeStart": {
          paddingLeft: "7px",
        },
        "& .MuiIconButton-edgeEnd": {
          marginRight: "0px",
        },
      },
    },
    buttonsDiv: {
      width: "100%",
      display: "flex",
      "@media (max-width:960px)": {
        marginLeft: "50px",
        paddingLeft: "50px",
      },
      "@media (max-width:768px)": {
        marginLeft: "0px",
        paddingLeft: "0px",
      },
    },
    buttonIcon: {
      fontSize: "13px",
    },
    appbarButton: {
      border: "1px solid green",
      borderRadius: "20px",
      margin: "0px 5px",
      width: "auto",
      minWidth: "60px",
      height: "auto",
      fontSize: "0.9rem",
      textTransform: "none",
      backgroundColor: "white",

      "@media (max-width:992px)": {
        width: "115px",
        lineHeight: "12px",
      },
      "@media (max-width:680px)": {
        fontSize: "0.6rem",
        width: "58px",
        margin: "0px 3px",
        padding: "5px",
        "& .MuiButton-startIcon": {
          marginLeft: "0px",
          marginRight: "0px",
        },
        "& .MuiButton-label": {
          display: "block",
          lineHeight: "9px",
          letterSpacing: "0px",
        },
        "& .MuiButton-outlined": {
          padding: "5px 5px",
        },
      },
    },
  })
);
