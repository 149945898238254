/* eslint-disable array-callback-return */
import { connect } from "react-redux";
import { basicSelectors, basicCalls } from "../../../../store/supers/basicCalls";
import MaterialTable from "material-table";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  makeStyles,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@material-ui/core";
import dayjs from "dayjs";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";

interface IDormantTime {
  startTime?: any;
  endTime?: any;
  totalTime?: any;
}
interface IData {
  _id: string;
  firstName: string;
  npi?: string;
  email: string;
  name: string;
  approved: boolean;
  dormantTime: IDormantTime[];
  activeTime: any;
}

interface OwnProps {}
interface DispatchProps {
  getCenters: () => Promise<void>;
}
interface StateProps {
  fetchCenters: IData[];
  fetchPhysicians: IData[];
}
const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
  "@media (max-width 650px)": {
    "& .MuiTableCell-root": {
      padding: "2px!important" as any,
    },
  },
});

type Props = OwnProps & StateProps & DispatchProps;
function ControleTiming(props: Props) {
  const { pathname } = useLocation();
  const res = pathname.split("/");
  const classes = useStyles();

  const type = res[res.length - 1];
  const inRange = (out: any, startTime: any, endTime: any) => {
    //@ts-ignore
    const range = out?.split(" - ");
    const min = range[0];
    const max = range[1];
    const format2 = "MM/DD/YYYY";
    const current = moment(new Date(endTime)).format(format2);
    if (current >= min && current <= max) {
      return true;
    }
    return false;
  };
  const formatActiveEndTime = (start: any, end: any) => {
    var a = start.split(":");
    var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
    var b = end.split(":");
    var seconds2 = +b[0] * 60 * 60 + +b[1] * 60 + +b[2];

    var date = new Date(1970, 0, 1);
    date.setSeconds(seconds + seconds2);

    var c = date.toTimeString().replace(/.*(\d{2}:\d{2}:\d{2}).*/, "$1");
    return c;
  };
  function formatAMPM(date: any) {
    let d = new Date(0, 0, 0, date?.split(":")[0], date.split(":")[1], date.split(":")[2]);
    var hours = d.getHours();
    var minutes: any = d.getMinutes();
    var seconds = d.getSeconds();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return strTime;
  }

  const convertToNumber = (number: any) => {
    try {
      const clearedNumber = String(number).replace(/0+/, ""); // Clear leading 0
      return Number(clearedNumber);
    } catch (error) {
      // In case of number converting error return 0
      return 0;
    }
  };

  useEffect(() => {
    if (type === "CENTER") {
      props.getCenters();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const physicians = props.fetchPhysicians.filter((phy) => {
    return phy?.approved;
  });
  const centers = props.fetchCenters.filter((cen) => {
    return cen?.approved;
  });

  const toSeconds = (str: any) => {
    str = str.split(":");
    return +str[0] * 60 + +str[1];
  };

  const toHHss = (seconds: number) => {
    //@ts-ignore
    let minutes = parseInt(seconds / 60);
    seconds = seconds - minutes * 60;
    return minutes + ":" + seconds;
  };
  return (
    <>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          columns={[
            { title: "id", field: "id", type: "string", hidden: true },
            {
              title: "Name",
              field: `${type === "centers" ? "name" : "firstName"}`,
              type: "string",
            },
            { title: "Email", field: "email", type: "string" },
            {
              title: "NPI",
              field: "npi",
              type: "string",
              render: (rowData) => (rowData.npi ? rowData.npi : "N/A"),
            },
          ]}
          title={`${type === "centers" ? "Provider Accounts" : "Professional Accounts"}`}
          options={{
            actionsColumnIndex: 8,
            exportButton: true,
            showEmptyDataSourceMessage: true,
            searchAutoFocus: true,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
          data={type === "physician" ? physicians : centers}
          detailPanel={[
            {
              tooltip: "Show Dormant Time and Active Time",
              render: (rowData) => {
                const output: any = {};
                const aTime: any = {};
                rowData?.dormantTime?.map((time) => {
                  const startDate = dayjs(time.startTime).startOf("week").format("MM/DD/YYYY");
                  const endDate = dayjs(time.startTime).endOf("week").format("MM/DD/YYYY");
                  const [hours, mins, secs] = time.totalTime
                    .split(":")
                    .map((number: any) => convertToNumber(number));
                  const totalTimeInSeconds = hours * 60 * 60 + mins * 60 + secs;
                  output[`${startDate} - ${endDate}`] =
                    (output[`${startDate} - ${endDate}`] || 0) + totalTimeInSeconds;
                });
                let dormant: any = [];
                Object.keys(output)?.map((out: any) => {
                  let arrTime: any = [];
                  rowData?.dormantTime?.forEach((time) => {
                    let check = inRange(out, time?.startTime, time?.endTime);
                    if (check) {
                      const end = formatAMPM(time?.endTime?.split(" ")[4]?.split(" GMT")[0]);
                      const start = formatAMPM(time?.startTime?.split(" ")[4]?.split(" GMT")[0]);
                      return arrTime.push(start + " - " + end);
                    }
                  });
                  return dormant.push({ key: out, time: arrTime });
                });

                let active: any = [];
                Object.keys(output)?.map((out: any) => {
                  let actTime: any = [];
                  rowData?.activeTime?.forEach((time: any) => {
                    const endTime = formatActiveEndTime(
                      time.startTime?.toString()?.split(" ")[4]?.split(" GMT")[0],
                      time.totalTime
                    );

                    // let check = inRange(out, time?.startTime ,endTime);
                    // if (check) {
                    const end = formatAMPM(endTime);
                    const start = formatAMPM(
                      time?.startTime?.toString().split(" ")[4].split(" GMT")[0]
                    );
                    return actTime.push(start + " - " + end);
                    // }
                  });
                  return active.push({ key: out, time: actTime });
                });

                const arr: any = [];
                // eslint-disable-next-line array-callback-return
                rowData?.activeTime?.forEach((time: any) => {
                  arr.push(time?.totalTime);
                  const startDate = dayjs(time?.startTime).startOf("week").format("MM/DD/YYYY");
                  const endDate = dayjs(time?.startTime).endOf("week").format("MM/DD/YYYY");
                  aTime[`${startDate} - ${endDate}`] = aTime[`${startDate} - ${endDate}`] || 0;
                });

                return (
                  <>
                    <div>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell component="td">Week of</TableCell>
                              <TableCell component="td" align="center">
                                Dormant Time
                              </TableCell>
                              <TableCell component="td" align="center">
                                Active Time
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.keys(output)?.map((key) => (
                              <TableRow key={key} style={{ width: "100%" }}>
                                <TableCell component="td">
                                  <Accordion>
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                    >
                                      <Typography>{key}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "space-between ",
                                        }}
                                      >
                                        <div>
                                          <Typography>
                                            {dormant?.map(
                                              (slot: any) =>
                                                slot?.key === key && (
                                                  <div key={slot.key}>
                                                    <div>Dormant Time</div>
                                                    <div>
                                                      {slot.time?.map((time: any) => {
                                                        return (
                                                          <Chip
                                                            label={time}
                                                            variant="outlined"
                                                            style={{
                                                              background: "grey",
                                                              color: "white",
                                                              margin: "5px",
                                                            }}
                                                          />
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                )
                                            )}
                                          </Typography>
                                        </div>
                                        <div>
                                          <Typography>
                                            {active?.map(
                                              (slot: any) =>
                                                slot?.key === key && (
                                                  <div key={slot?.key}>
                                                    <div>Active Time</div>
                                                    <div>
                                                      {slot?.time?.map((time: any) => {
                                                        return (
                                                          <Chip
                                                            label={time}
                                                            variant="outlined"
                                                            style={{
                                                              background: "grey",
                                                              color: "white",
                                                              margin: "5px",
                                                            }}
                                                          />
                                                        );
                                                      })}
                                                    </div>
                                                  </div>
                                                )
                                            )}
                                          </Typography>
                                        </div>
                                      </div>
                                    </AccordionDetails>
                                  </Accordion>
                                </TableCell>
                                <TableCell component="td" align="center">
                                  {formatAMPM(
                                    new Date(output[key] * 1000).toISOString().substr(11, 8)
                                  )}
                                </TableCell>
                                <TableCell component="td" align="center">
                                  {Object.keys(aTime).includes(key)
                                    ? toHHss(
                                        arr.reduce((r: any, elem: any) => r + toSeconds(elem), 0)
                                      )
                                    : "0:0"}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                );
              },
            },
          ]}
        />
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getCenters: () => dispatch(basicCalls.getCenters()),
  // centerFeeds: () => dispatch(basicCalls.getAllCenterFeeds()),
  getPhysicians: () => dispatch(basicCalls.getPhysicians()),
});
const mapStateToProps = (state: any) => ({
  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  fetchPhysicians: basicSelectors.fetchPhysicians(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(ControleTiming);
