import React from "react";
import "../authComponents/style.css";
import { Grid, TextField } from "@material-ui/core";
import { useStyles } from "../authComponents/muiStyling";
import { PrimaryButton } from "../base/Components/PrimaryButton";
import { CenterUpdateDTO } from "../../store/types/type";
import { Autocomplete } from "@material-ui/lab";
import { specialties } from "../../store/types/states";
export const CenterProfile = (props: {
  updateCenterProfile: (updateCenterData: CenterUpdateDTO) => {};
  getCenterProfile: () => Promise<any>;
  setOpenPopupProfile: any;
}) => {
  const classes = useStyles();
  const [post, setPost] = React.useState(false);
  const [updateProfile, setUpdateProfile] = React.useState({
    name: "",
    npi: "",
    password: "",
    bio: "",
    rates: "",
    specialties: [],
  });

  const handleSubmit = () => {
    setPost(true);
    console.log(updateProfile);
    props.updateCenterProfile(updateProfile);
    props.getCenterProfile();
    props.setOpenPopupProfile(false);
    setPost(false);
  };

  return (
    <div className={classes.root}>
      <div>
        <Grid item xs={12}>
          <TextField
            // disabled
            id="filled-full-width"
            style={{ margin: 8 }}
            fullWidth
            size="small"
            label="Provider Name"
            type="string"
            name="name"
            onChange={(e) => setUpdateProfile({ ...updateProfile, name: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled
            id="filled-full-width"
            style={{ margin: 8 }}
            fullWidth
            size="small"
            label="Provider NPI"
            type="string"
            name="npi"
            onChange={(e) => setUpdateProfile({ ...updateProfile, npi: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // disabled
            fullWidth
            id="filled-full-width"
            style={{ margin: 8 }}
            size="small"
            label="Password"
            type="password"
            onChange={(e) => setUpdateProfile({ ...updateProfile, password: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            // disabled
            fullWidth
            id="filled-full-width"
            style={{ margin: 8 }}
            size="small"
            multiline
            rows={3}
            label="Description"
            type="string"
            onChange={(e) => setUpdateProfile({ ...updateProfile, bio: e.target.value })}
          />
        </Grid>
        <Grid item xs={12}>
        <TextField
          // disabled
          fullWidth
          id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Rate per 30 minutes: $"
          onChange={(e) => setUpdateProfile({ ...updateProfile, rates: e.target.value })}
          type="string"
        />
        </Grid>
        <Autocomplete
          multiple
          id="size-small-standard-multi"
          size="small"
          options={specialties}
          getOptionLabel={(option: any) => option}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Specialties &
          Demographic"
              placeholder="Specialties &
          Demographic "
            />
          )}
          onChange={(e, newValue) => {
            setUpdateProfile({ ...updateProfile, specialties: newValue as any });
          }}
        />
        <PrimaryButton onClick={handleSubmit} title="Edit Profile" />
        {post ? "..Loading" : ""}
      </div>
    </div>
  );
};
