// import { green } from "@material-ui/core/colors";

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      "@media (max-width: 476px)": {
        "& .MuiTab-root": {
          fontSize: "0.7rem",
          padding: "6px 6px",
        },
      },
    },
    cardMainDiv: {
      margin: "3rem 1rem",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      "@media (max-width: 800px)": {
        fontSize: "10px",
        padding: "5px",
      },
    },
    prof: {
      "@media (max-width: 600px)": {
        margin: "0px",
      },
    },
    cardHeaderMainDiv: {
      width: "700px",
      "@media (max-width: 700px)": {
        width: "100%",
      },
    },
    cardHeader: {
      backgroundColor: "#018C1F",
      color: "#fff",
      borderRadius: 25,
      boxShadow: "0px 0px 10px #00000029",
      border: "1px solid #e0e0e0",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 800px)": {
        width: "100%",
      },
      "@media (max-width: 600px)": {
        width: "100%",
        "& .MuiTypography-h6 ": {
          fontSize: "1rem",
        },
      },
    },

    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "25ch",
    },
    container: {
      padding: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(1),
      width: "50%",
    },
    main: {
      display: "block",
      width: "100%",
      marginTop: window.screen.width > 768 ? "0em" : "-1em",
    },
    primaryButton: {
      color: "white",
      textShadow: "0 0 lawngreen",
      fontWeight: "bolder",
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
  })
);

export const cardStyles = makeStyles(({ spacing }) => ({
  card: {
    marginTop: 40,
    borderRadius: spacing(0.5),
    transition: "0.3s",
    width: "90%",
    overflow: "initial",
    background: "#ffffff",
  },
  content: {
    paddingTop: 0,
    textAlign: "left",
    overflowX: "auto",
    "& table": {
      marginBottom: 0,
    },
  },
}));

export const topstyles: any = {
  content: {
    textAlign: "left",
    overflowX: "auto",
    minHeight: "200px",
  },
  container: {
    maxHeight: 200,
  },
  top: {
    backgroundColor: "#4caf50;",
    borderRadius: "10px",
    textAlign: "justify",
    color: "#fff",
    padding: "10px",
    boxShadow: "5px 5px 5px 0px rgba(194,194,194,1)",
  },
};

// export const theme = createMuiTheme({
//   palette: {
//     primary: green,
//     secondary: green,
//   },
// });
