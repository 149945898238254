export const isCallingProfile = (state: any) => state.centerProfileCalling;
export const isCenterProfile = (state: any) => state.centerProfile || undefined;
export const isProfileFailure = (state: any) => state.centerProfileFailure || undefined;

export const isUpdatingProfile = (state: any) => state.centerProfileUpdating;
export const isCenterProfileUpdated = (state: any) => state.centerProfileUpdateSuccess || undefined;
export const isCenterProfileUpdatedFail = (state: any) =>
  state.centerProfileUpdateFailure || undefined;

export const updatingPhysicianRequest = (state: any) => state.updatingPhysicianRequst;
// export const physicianRequestUpdated= (state:any) => state.updatePhysicianRequestSuccess
export const physicianRequestFailed = (state: any) => state.updatePhysicianRequestFailure;

export const isCallingCenterFileUpdate = (state: any) =>
  state.updateCenterFilePrivacyCalling || undefined;
export const isCenterFileUpdated = (state: any) => state.updateCenterFilePrivacy || undefined;
export const isCenterFileUpdatedFail = (state: any) =>
  state.updateCenterFilePrivacyFailure || undefined;
