export const gettingAllFeeds = (state: any) => state.gettingAllFeeds;
export const getAllFeedsSuccess = (state: any) => state.feeds;
export const getAllFeedsFailure = (state: any) => state.getAllFeedsFailure;

export const callingIsMass = (state: any) => state.updateCenterIsMassCalling || undefined;
export const callingIsMassUpdated = (state: any) => state.updateCenterIsMass || undefined;
export const callingIsMassUpdatedFail = (state: any) =>
  state.updateCenterIsMassFailure || undefined;

export const removeOneFeed = (state: any) => state.removeOneFeed;
export const removeOneFeedSuccess = (state: any) => state.removeOneFeedSuccess;
export const removeOneFeedFailure = (state: any) => state.removeOneFeedFailure;

export const drawerOpen = (state: any) => state.drawerOpen;
