import { Chip } from "@material-ui/core";
import { useStyles } from "../../physician/Appointment/muiStyles";

interface OwnProps {
  userType: any;
  bookingDay: any;
  bookingTimes: any;
  allSelectedTimeSlot: any;
  selectTimeSlot: any;
}
type Props = OwnProps;

const PatientTimeSlots = (props: Props) => {
  const classes = useStyles();
  return (
    <div>
      {" "}
      <div style={{ marginTop: "20px" }}>
        <h3>{props.bookingDay}</h3>
        <hr />
        <div className={classes.slots}>
          {props.bookingTimes?.length === 0 || props.bookingTimes === undefined ? (
            <div>
              <p>No free Slots Available on this date</p>
            </div>
          ) : (
            props.bookingTimes?.map((slot: any, index: any) => {
              return (
                <Chip
                  label={slot.time}
                  variant="outlined"
                  key={index}
                  style={
                    !slot.available
                      ? { background: "green", margin: "5px" }
                      : props.allSelectedTimeSlot.includes(slot.time)
                      ? { background: "green", color: "white", margin: "5px" }
                      : { margin: "5px" }
                  }
                  onClick={() => props.selectTimeSlot(slot.time)}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default PatientTimeSlots;
