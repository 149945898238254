import { combineReducers } from "redux";
import { authReducer } from "./auth";
import { chatReducer } from "./chat";
import { patientProfileReducer } from "./profile/reducers";
import { WPCReducer } from "./wpc/reducers";

const appReducer = combineReducers({
  auth: authReducer,
  chat: chatReducer,
  patientProfile: patientProfileReducer,
  WPC: WPCReducer,
});

export default appReducer;
