import {
  AppBar,
  Button,
  createStyles,
  Dialog,
  IconButton,
  makeStyles,
  Slide,
  Theme,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions";
import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import { IFileData } from "../../store/types";
import DwvComponent from "../base/Components/dwv/DwvComponent";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: "relative",
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 0.3,
    },
  })
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  file: IFileData;
}
export default function FileView(props: Props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      {props.file.isPublic && (
        <Button color="secondary" onClick={handleClickOpen}>
          {props.file.isPublic && props.file.name}
        </Button>
      )}
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {props.file.name}
            </Typography>
          </Toolbar>
        </AppBar>
        {props.file.mimetype === "application/dicom" ? (
          function DwvComponent(props:{file: IFileData, toggleFunction: any}) {}
          ///<DwvComponent file={props?.file} />
        ) : (
          <iframe title={props.file._id} height="100%" width="100%" src={props.file.url} />
        )}
      </Dialog>
    </>
  );
}
