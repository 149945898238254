import { IFileData } from "../../types";
import { IPhysician } from "../../types/physician";
import * as types from "./types";

export const physicianProfileCalling = () => ({
  type: types.physicianProfileCalling,
});
export const physicianProfileSuccess = (profile: IPhysician) => ({
  type: types.physicianProfileSuccess,
  payload: profile,
});

export const physicianProfileFailure = (errorMessage: string) => ({
  type: types.physicianProfileFailure,
  payload: errorMessage,
});

export const physicianProfileUpdating = () => ({
  type: types.physicianProfileUpdating,
});
export const physicianProfileUpdateSuccess = (updatedProfile: boolean) => ({
  type: types.physicianProfileUpdateSuccess,
  payload: updatedProfile,
});
export const physicianProfileUpdateFailure = (errorMessage: string) => ({
  type: types.physicianProfileUpdateFailure,
  payload: errorMessage,
});

export const professionalNumberUpdating = () => ({
  type: types.professionalNumberUpdating,
});
export const professionalNumberUpdateSuccess = (updatedProfile: IPhysician) => ({
  type: types.professionalNumberUpdateSuccess,
  payload: updatedProfile,
});
export const professionalNumberUpdateFailure = (errorMessage: string) => ({
  type: types.professionalNumberUpdateFailure,
  payload: errorMessage,
});

export const verifyNumberUpdating = () => ({
  type: types.professionalNumberVerificationUpdating,
});
export const verifyNumberUpdatingSuccess = (updatedProfile: IPhysician) => ({
  type: types.professionalNumberVerificationUpdateSuccess,
  payload: updatedProfile,
});
export const verifyNumberUpdatingFailure = (errorMessage: string) => ({
  type: types.professionalNumberVerificationUpdateFailure,
  payload: errorMessage,
});
// update the privacy of uploaded file
export const updatePhysicianFilePrivacyCalling = () => ({
  type: types.updatePhysicianFilePrivacyCalling,
});
export const updatePhysicianFilePrivacySuccess = (updatedPhysicianFile: IFileData) => ({
  type: types.updatePhysicianFilePrivacySuccess,
  payload: updatedPhysicianFile,
});

export const updatePhysicianFilePrivacyFailure = (errorMessage: string) => ({
  type: types.updatePhysicianFilePrivacyFailure,
  payload: errorMessage,
});

export const registerInUser = () => ({
  type: types.registerInUser,
});

export const registerInUserSuccess = (success: string) => ({
  type: types.registerInUserSuccess,
  payload: success,
});

export const registerInUserFailure = (err: string) => ({
  type: types.registerInUserFailure,
  payload: err,
});
