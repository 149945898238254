import axios from "axios";
import { CenterLoginDTO, CenterSignUpDTO } from "../../types/type";
import * as actions from "./actions";
import { basicCalls } from "../basicCalls";
import { IOtp } from "../../types";

export const login = (centerLoginDTO: CenterLoginDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.loggingIn());
  try {
    const response = await axios.post(`/auth/signin-center`, centerLoginDTO);
    if (response.status === 201) {
      dispatch(actions.loginSuccess(response.data));
      localStorage.setItem("current", "CENTER");
      localStorage.setItem("_id", response.data._id);
    } else throw new Error("Could not login");
  } catch (err: any) {
    dispatch(actions.loginFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};

export const verifyToken = (centerOtp: IOtp) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.verifyingToken());
  try {
    const response = await axios.post(`/auth/verify-otp`, centerOtp);
    if (response.status === 201) {
      dispatch(actions.verifyTokenSuccess(response.data.access_token));
      localStorage.setItem("centerToken", response.data.access_token);
      localStorage.removeItem("password");
    } else throw new Error("Could not verify token");
  } catch (err: any) {
    dispatch(actions.verifyTokenFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};

export const signUp = (centerSignUpDTO: CenterSignUpDTO) => async (
  dispatch: (param: any) => void
) => {
  dispatch(actions.registerIn());
  try {
    const response = await axios.post(`/auth/signup-center`, centerSignUpDTO);
    if (response.status === 201) {
      dispatch(actions.registerSuccess(response.data));
      dispatch(basicCalls.getAllCenters());
    } else throw new Error("Could not sign up");
  } catch (err: any) {
    dispatch(actions.registerFailure(err.response?.data.message || "Unexpected Error Occured"));
  }
};

export const logout = () => async (dispatch: (arg0: {}) => void) => {
  localStorage.removeItem("centerToken");
  localStorage.removeItem("_id");
  localStorage.removeItem("current");
  dispatch(actions.loggedOut());
};
