import * as types from "./types";

const initialState = {
  gettingAdmins: false,
  admins: [],
  getAdminsError: "",
  gettingCenters: false,
  centers: [],
  getCentersError: "",
  gettingPatients: false,
  patients: [],
  getPatientsError: "",
  gettingPhysicians: false,
  physicians: [],
  getPhysiciansError: "",
  updatingAdmins: false,
  updatedAdmins: [],
  updateAdminsError: "",

  // updatingAdminRequst: true,
  // updateAdminRequestSuccess: undefined,
  // updateAdminRequestFailure: "",

  // updatingCenterRequst: true,
  // updateCenterRequestSuccess: undefined,
  // updateCenterRequestFailure: "",

  assignCenterRequest: false,
  assignCenterRequestSuccess: undefined,
  assignCenterRequestFailure: "",

  removeCenterFeed: false,
  removeCenterFeedSuccess: undefined,
  removeCenterFeedFailure: "",

  getAllCenterFeedsSuccess: [],
  getCenterFeedsError: "",
  gettingCenterFeeds: false,
};

export const basicReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.fetchAdmins:
      return {
        ...state,
        gettingAdmins: true,
        getAdminsError: "",
        admins: [],
      };
    case types.getAdminsError:
      return {
        ...state,
        gettingAdmins: false,
        getAdminsError: action.payload,
      };
    case types.gettingAdmins:
      return {
        ...state,
        gettingAdmins: false,
        admins: action.payload,
      };
    case types.fetchCenters:
      return {
        ...state,
        gettingCenters: true,
        getCentersError: "",
        centers: [],
      };
    case types.getCentersError:
      return {
        ...state,
        gettingCenters: false,
        getCentersError: action.payload,
      };
    case types.gettingCenters:
      return {
        ...state,
        gettingCenters: false,
        centers: action.payload,
      };
    case types.fetchPatients:
      return {
        ...state,
        gettingPatients: true,
        getPatientsError: "",
        patients: [],
      };
    case types.getPatientsError:
      return {
        ...state,
        gettingPatients: false,
        getPatientsError: action.payload,
      };
    case types.gettingPatients:
      return {
        ...state,
        gettingPatients: false,
        patients: action.payload,
      };
    case types.fetchPhysicians:
      return {
        ...state,
        gettingPhysicians: true,
        getPhysiciansError: "",
        physicians: [],
      };
    case types.getPhysiciansError:
      return {
        ...state,
        gettingPhysicians: false,
        getPhysiciansError: action.payload,
      };
    case types.gettingPhysicians:
      return {
        ...state,
        gettingPhysicians: false,
        physicians: action.payload,
      };
    case types.updateAdminRequest:
      return {
        ...state,
        updatingAdminRequst: true,
        updateAdminRequestSuccess: undefined,
        updateAdminRequestFailure: "",
      };
    case types.updateAdminRequestSuccess:
      return {
        ...state,
        updatingAdminRequst: false,
        updateAdminRequestSuccess: false,
        updateAdminRequestFailure: false,
      };
    case types.updateAdminRequestFailure:
      return {
        ...state,
        updatingAdminRequst: false,
        updateAdminRequestSuccess: false,
        updateAdminRequestFailure: action.payload,
      };
    // freeze/unfreeze account
    case types.updateAccount:
      return {
        ...state,
        updatingAccount: true,
        updateAccountError: "",
        updatedAccount: [],
      };
    case types.updateAccountError:
      return {
        ...state,
        updatingAccount: false,
        updateAccountError: action.payload,
      };
    case types.updatingAccount:
      return {
        ...state,
        updatingAccount: false,
        updatedAccount: action.payload,
      };
    case types.assignCenterRequest:
      return {
        ...state,
        assignCenterRequest: true,
        assignCenterRequestSuccess: undefined,
        assignCenterRequestFailure: "",
      };
    case types.assignCenterRequestSuccess:
      return {
        ...state,
        assignCenterRequest: true,
        assignCenterRequestSuccess: "",
        assignCenterRequestFailure: "",
      };
    case types.assignCenterRequestFailure:
      return {
        ...state,
        assignCenterRequest: true,
        assignCenterRequestSuccess: undefined,
        assignCenterRequestFailure: "",
      };
    case types.removeCenterFromAdminRequest:
      return {
        ...state,
        removeCenterFromAdminRequest: true,
        removeCenterFromAdminRequestSuccess: "",
        removeCenterFromAdminRequestFailure: [],
      };
    case types.removeCenterFromAdminRequestSuccess:
      return {
        ...state,
        removeCenterFromAdminRequest: true,
        removeCenterFromAdminRequestSuccess: "",
        removeCenterFromAdminRequestFailure: "",
      };
    case types.removeCenterFromAdminRequestFailure:
      return {
        ...state,
        removeCenterFromAdminRequest: false,
        removeCenterFromAdminRequestSuccess: false,
        removeCenterFromAdminRequestFailure: action.payload,
      };
    case types.removeCenterFeed:
      return {
        ...state,
        removeCenterFeed: true,
        removeCenterFeedSuccess: undefined,
        removeCenterFeedFailure: "",
      };
    case types.removeCenterFeedSuccess:
      return {
        ...state,
        removeCenterFeed: false,
        removeCenterFeedSuccess: action.payload,
        removeCenterFeedFailure: "",
      };
    case types.removeCenterFeedFailure:
      return {
        ...state,
        removeCenterFeed: false,
        removeCenterFeedSuccess: undefined,
        removeCenterFeedFailure: action.payload,
      };
    case types.getAllCenterFeeds:
      return {
        ...state,
        getAllCenterFeeds: true,
        getAllCenterFeedsSuccess: undefined,
        getAllCenterFeedsFailure: "",
      };
    case types.getAllCenterFeedsSuccess:
      return {
        ...state,
        getAllCenterFeeds: false,
        getAllCenterFeedsSuccess: action.payload,
        getAllCenterFeedsFailure: "",
      };
    case types.getAllCenterFeedsFailure:
      return {
        ...state,
        getAllCenterFeeds: false,
        getAllCenterFeedsSuccess: undefined,
        getAllCenterFeedsFailure: action.payload,
      };

    case types.fetchScheduleTime:
      return {
        ...state,
        fetchScheduleTime: true,
        gettingScheduleTime: action.payload,
        getScheduleTimeError: "",
      };
    case types.gettingScheduleTime:
      return {
        ...state,
        fetchScheduleTime: false,
        gettingScheduleTime: undefined,
        getScheduleTimeError: action.payload,
      };

    case types.getScheduleTimeError:
      return {
        ...state,
        fetchScheduleTime: false,
        gettingScheduleTime: undefined,
        getScheduleTimeError: action.payload,
      };
    default: {
      return state;
    }
  }
};
