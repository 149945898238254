import axios from "axios";
import { IOtp } from "../../types";
import { PatientSignUpDTO, PatientSignUpInfo, UserLoginDTO } from "../../types/type";
import * as actions from "./actions";

export const login = (userLoginDTO: UserLoginDTO, type: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.loggingIn());
  let response: any = null;
  try {
    if (type === "email") {
      response = await axios.post(`/auth/signin-patient`, userLoginDTO);
    }
    if (type === "google") {
      response = await axios.post(`/auth/signin-patient-google`, userLoginDTO);
      // console.log(response);
      // localStorage.setItem("patientEmail", response.data.email);
    }
    if (response.status === 201) {
      dispatch(actions.loginSuccess(response.data));
      localStorage.setItem("_id", response.data._id);
      localStorage.setItem("current", "PATIENT");
      if (response && response.data.pay) {
        return response.data;
      }
    } else throw new Error("Could not login");
  } catch (err: any) {
    if (err.response?.data.message === "You need Pay First") {
      localStorage.setItem("patientEmail", userLoginDTO.email);
    }
    dispatch(actions.loginFailure(err.response?.data.message));
  }
};

export const verifyToken = (patientOtp: IOtp) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.verifyingToken());
  try {
    const response = await axios.post(`/auth/verify-otp`, patientOtp);
    if (response.status === 201) {
      dispatch(actions.verifyTokenSuccess(response.data.access_token));
      localStorage.setItem("patientToken", response.data.access_token);
      localStorage.removeItem("password");
    } else throw new Error("Could not verify token");
  } catch (err: any) {
    dispatch(actions.verifyTokenFailure(err.response?.data.message || "Unexpected Error Occurred"));
  }
};

export const signUp = (patientSignUpDTO: PatientSignUpDTO, type: string) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.registerIn());
  try {
    let response: any = null;
    if (type === "email") {
      response = await axios.post(`/auth/signup-patient`, patientSignUpDTO);
    }
    if (type === "google") {
      response = await axios.post(`/auth/signup-patient-google`, patientSignUpDTO);
      localStorage.setItem("patientEmail", response.data.email);
    }
    if (response.status === 201) {
      dispatch(actions.registerSuccess(response.data));
    } else throw new Error("Could not sign up");
  } catch (err: any) {
    dispatch(actions.registerFailure(err.response?.data.message || "Unexpected Error Occurred"));
  }
};

export const updatePatientRegister = (patientSignUpInfo: PatientSignUpInfo) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updateRegisterIn());
  try {
    const response = await axios.patch(`auth/signup-patient-update`, patientSignUpInfo);
    if (response.status === 200) {
      dispatch(actions.updateRegisterSuccess(response.data));
    }
  } catch (err: any) {
    dispatch(
      actions.updateRegisterFailure(err.response?.data.message || "Unexpected Error Occurred")
    );
  }
};

export const logout = () => async (dispatch: (arg0: {}) => void) => {
  localStorage.removeItem("patientToken");
  localStorage.removeItem("_id");
  localStorage.removeItem("current");
  dispatch(actions.loggedOut());
};
