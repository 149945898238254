import { IFileData } from "../../types";
import { IAdmin } from "../../types/admin";
import * as types from "./types";

export const adminProfileCalling = () => ({
  type: types.adminProfileCalling,
});
export const adminProfileSuccess = (profile: IAdmin) => ({
  type: types.adminProfileSuccess,
  payload: profile,
});

export const adminProfileFailure = (errorMessage: string) => ({
  type: types.adminProfileFailure,
  payload: errorMessage,
});

export const adminProfileUpdating = () => ({
  type: types.adminProfileUpdating,
});
export const adminProfileUpdateSuccess = (updatedProfile: IAdmin) => ({
  type: types.adminProfileUpdateSuccess,
  payload: updatedProfile,
});
export const adminProfileUpdateFailure = (errorMessage: string) => ({
  type: types.adminProfileUpdateFailure,
  payload: errorMessage,
});

export const updateCenterRequest = () => ({
  type: types.updateCenterRequest,
});
export const updateCenterRequestSuccess = () => ({
  type: types.updateCenterRequestSuccess,
  // payload: updatedProfile
});
export const updateCenterRequestFailure = (errorMessage: string) => ({
  type: types.updateCenterRequestFailure,
  payload: errorMessage,
});
// update the privacy of uploaded file
export const updateAdminFilePrivacyCalling = () => ({
  type: types.updateAdminFilePrivacyCalling,
});
export const updateAdminFilePrivacySuccess = (updatedAdminFile: IFileData) => ({
  type: types.updateAdminFilePrivacySuccess,
  payload: updatedAdminFile,
});

export const updateAdminFilePrivacyFailure = (errorMessage: string) => ({
  type: types.updateAdminFilePrivacyFailure,
  payload: errorMessage,
});
