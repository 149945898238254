import { IFileData, IPhysician } from "../../types";
import * as types from "./types";

interface IPhyProState {
  physicianProfileCalling: boolean;
  physicianProfile: IPhysician | undefined;
  physicianProfileFailure: string;
  physicianProfileUpdating: boolean;
  physicianProfileUpdateFailure: string;
  physicianProfileUpdateSuccess: boolean;
  updatePhysicianFilePrivacyCalling: boolean;
  updatePhysicianFilePrivacyFailure: string;
  registerInUserSuccess: boolean;
  registerInUserFailure: string;
}

const initialState: IPhyProState = {
  physicianProfileCalling: false,
  physicianProfile: undefined,
  physicianProfileFailure: "",
  physicianProfileUpdating: false,
  physicianProfileUpdateFailure: "",
  physicianProfileUpdateSuccess: false,
  updatePhysicianFilePrivacyCalling: true,
  updatePhysicianFilePrivacyFailure: "",
  registerInUserSuccess: false,
  registerInUserFailure: "",
};
export const physicianProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.physicianProfileCalling:
      return {
        ...state,
        physicianProfileCalling: true,
        physicianProfile: null,
        physicianProfileFailure: "",
      };
    case types.physicianProfileSuccess:
      return {
        ...state,
        physicianProfileCalling: false,
        physicianProfile: action.payload,
      };
    case types.physicianProfileFailure:
      return {
        ...state,
        physicianProfileCalling: false,
        physicianProfileFailure: action.payload,
      };

    case types.physicianProfileUpdating:
      return {
        ...state,
        physicianProfileUpdating: true,
        physicianProfileUpdateSuccess: false,
        physicianProfileUpdateFailure: "",
      };
    case types.physicianProfileUpdateSuccess:
      return {
        ...state,
        physicianProfileUpdating: false,
        physicianProfileUpdateSuccess: action.payload,
      };
    case types.physicianProfileUpdateFailure:
      return {
        ...state,
        physicianProfileUpdating: false,
        physicianProfileUpdateFailure: action.payload,
      };
    case types.updatePhysicianFilePrivacyCalling:
      return {
        ...state,
        updatephysicianFilePrivacyCalling: true,
        physicianProfileFailure: "",
      };
    case types.updatePhysicianFilePrivacySuccess:
      return {
        ...state,
        physicianProfile: {
          ...state.physicianProfile,
          files: state.physicianProfile?.files.map((f: IFileData) => {
            if (f._id === action.payload._id) {
              return { ...f, isPublic: action.payload.isPublic };
            } else return f;
          }),
        },
        updatephysicianFilePrivacyCalling: false,
      };
    case types.updatePhysicianFilePrivacyFailure:
      return {
        ...state,
        updatePhysicianFilePrivacyCalling: false,
        updatePhysicianFilePrivacyFailure: action.payload,
      };
    case types.registerInUser:
      return {
        ...state,
        registerInUserSuccess: true,
        registerInUserFailure: "",
      };
    case types.registerInUserSuccess:
      return {
        ...state,
        registerInUserSuccess: true,
        registerInUserFailure: action.payload,
      };
    case types.registerInUserFailure:
      return {
        ...state,
        registerInUserSuccess: false,
        registerInUserFailure: action.payload,
      };
    default: {
      return state;
    }
  }
};
