import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import SideBar from "../../sidebars/Sidebar";
import Appbar from "../../navbars/Appbar";
import { useStyles } from "../routesMuiStyling";
import { IAdmin, USER_TYPE } from "../../../store/types";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../../theme/ColorPalette";
import Home from "../../dashboardComponents/admins/Home";
import AdminManagement from "../../dashboardComponents/admins/AdminManagement";
// import CustomBackdrop from "../../base/Components/CustomBackdrop";
import { connect } from "react-redux";
import { adminProfileCalls, adminProfileSelectors } from "../../../store/admins/profile";
import FeedforAdmin from "../../dashboardComponents/admins/FeedforAdmin";
import { feedSelectors } from "../../../store/feed";
import { feedCalls } from "../../../store/feed";

interface OwnProps {
  history: any;
  children?: any;
}

interface DispatchProps {
  getAdminProfile: () => Promise<void>;
  handleDrawerOpen: () => Promise<void>;
  // getAll: () => Promise<void>;
}
interface StateProps {
  adminProfile: IAdmin;
  adminProfileCalling: boolean;
  adminProfileFetchError: string;
  drawerOpen: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

export function _Main(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  // const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };
  // const handleDrawerOpen = () => {
  //   setOpen(!open);
  //   // if (open === true) {
  //   //   document.body.style.marginLeft = "0px";
  //   // } else {
  //   //   document.body.style.marginLeft = "280px";
  //   // }
  // };
  useEffect(() => {
    props.getAdminProfile();
    // eslint-disable-next-line
  }, []);

  return (
    <ThemeProvider theme={deloresTheme ? lightTheme : darkTheme}>
      {/* <CustomBackdrop open={props.adminProfile?.freeze} message={props.adminProfile?.message} /> */}
      <div className={classes.main}>
        <SideBar
          history={history}
          type={"admin"}
          opendrawer={window.screen.width <= 668 ? props.drawerOpen : !props.drawerOpen}
          handleDrawerOpen={props.handleDrawerOpen}
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: deloresTheme ? "white" : "#272822",
            color: deloresTheme ? "black" : "white",
          }}
        >
          <header className={classes.header}>
            <Appbar
              type={USER_TYPE.ADMIN}
              handleTheme={handleTheme}
              deloresTheme={deloresTheme}
              handleDrawerOpen={props.drawerOpen}
            />
          </header>
          <div className={classes.routes}>
            <Switch>
              <Route exact path="/admin" component={Home} />
              <Route path="/admin/management" component={AdminManagement} />
              <Route path="/admin/profile" />
              <Route path="/admin/feeds" component={FeedforAdmin} />
              {/* <Route exact path="/center/explore" component={Explore} />
              <Route exact path="/center/settings" component={Setting} />
              <Route
                exact
                path="/center/profile"
                render={() => <Profile user={USER_TYPE.CENTER} />}
              /> */}
              {/* <Redirect to="/admin" /> */}
            </Switch>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getAdminProfile: () => dispatch(adminProfileCalls.getAdminProfile()),
  handleDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});

const mapStateToProps = (state: any) => ({
  adminProfileCalling: adminProfileSelectors.isCallingProfile(state.admins.adminProfile),
  adminProfile: adminProfileSelectors.isAdminProfile(state.admins.adminProfile),
  adminProfileFetchError: adminProfileSelectors.isProfileFailure(state.admins.adminProfile),
  drawerOpen: feedSelectors.drawerOpen(state.feeds),
});

export const Main = connect(mapStateToProps, mapDispatchToProps)(_Main);
