import React from "react";
import "../authComponents/style.css";
import TextField from "@material-ui/core/TextField";
import { useStyles } from "../authComponents/muiStyling";
import { PrimaryButton } from "../base/Components/PrimaryButton";
import { PhysicianUpdateDTO } from "../../store/types/type";

export const PhysicianProfile = (props: {
  updatePhysicianProfile: (updatePhysicianData: PhysicianUpdateDTO) => {};
  getPhysicianProfile: () => Promise<any>;
  setOpenPopupProfile: any;
}) => {
  const classes = useStyles();
  const [updateProfile, setUpdateProfile] = React.useState({
    firstName: "",
    lastName: "",
    practice: "",
    rates: "",
    practiceAddress: "",
    password: "",
    link: "",
    specialties: [],
  });
  const handleSubmit = () => {
    props.updatePhysicianProfile(updateProfile);
    props.getPhysicianProfile();
    props.setOpenPopupProfile(false);
  };
  return (
    <div className={classes.root}>
      <div>
        <TextField
          id="margin-normal"
          margin="normal"
          style={{ margin: 8 }}
          size="small"
          label="First Name"
          type="string"
          name="firstname"
          onChange={(e) => setUpdateProfile({ ...updateProfile, firstName: e.target.value })}
        />
        <TextField
          id="margin-normal"
          margin="normal"
          //     id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Last Name"
          type="text"
          onChange={(e) => setUpdateProfile({ ...updateProfile, lastName: e.target.value })}
        />
        <TextField
          id="margin-normal"
          margin="normal"
          // fullWidth
          // id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Password"
          type="password"
          onChange={(e) => setUpdateProfile({ ...updateProfile, password: e.target.value })}
        />
        <TextField
          id="margin-normal"
          margin="normal"
          style={{ margin: 8 }}
          size="small"
          label="Primary Payment Link"
          type="text"
          onChange={(e) => setUpdateProfile({ ...updateProfile, link: e.target.value })}
        />
        <TextField
          id="margin-normal"
          margin="normal"
          //     id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Practice"
          type="text"
          onChange={(e) => setUpdateProfile({ ...updateProfile, practice: e.target.value })}
        />


        <TextField
          id="margin-normal"
          margin="normal"
          //     id="filled-full-width"
          style={{ margin: 8 }}
          size="small"
          label="Practice Address"
          type="text"
          onChange={(e) =>
            setUpdateProfile({
              ...updateProfile,
              practiceAddress: e.target.value,
            })
          }
        />
        <div style={{ marginTop: "1em" }}>
          <PrimaryButton onClick={handleSubmit} title="Update Profile" />
        </div>
      </div>
    </div>
  );
};
