import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { authCalls as physicianAuthCalls } from "../store/physician/auth";
import { authCalls as patientAuthCalls } from "../store/patients/auth";
import { authCalls as centerAuthCalls } from "../store/centers/auth";
import { authCalls as adminAuthCalls } from "../store/admins/auth";
import { authCalls as superAuthCalls } from "../store/supers/auth";
import { useDispatch } from "react-redux";
import { USER_TYPE } from "../store/types";

const SESSION_IDLE_MINUTES = 3;

const AutoLogout = (props: any) => {
  const { ComposedClass } = props;
  const dispatch = useDispatch();

  const handleOnIdle = (event: any) => {
    console.log("user is idle", event);
    console.log("last active", getLastActiveTime());
    // logout user
    const currentUser = localStorage.getItem("current");
    if (!currentUser) return;
    switch (currentUser) {
      case USER_TYPE.PATIENT:
        dispatch(patientAuthCalls.logout());
        break;
      case USER_TYPE.PHYSICIAN:
        dispatch(physicianAuthCalls.logout());
        break;
      case USER_TYPE.CENTER:
        dispatch(centerAuthCalls.logout());
        break;
      case USER_TYPE.ADMIN:
        dispatch(adminAuthCalls.logout());
        break;
      case USER_TYPE.SUPER:
        dispatch(superAuthCalls.logout());
        break;
      default:
        break;
    }
  };

  const { getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * SESSION_IDLE_MINUTES,
    // @ts-ignore
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return <ComposedClass />;
};

export default AutoLogout;
