import { green } from "@material-ui/core/colors";

import { createMuiTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      marginLeft: "1em",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "25ch",
    },
    container: {
      padding: theme.spacing(3),
    },
    margin: {
      margin: theme.spacing(1),
      width: "50%",
    },
    formSearch: {
      flexDirection: "row",
      width: "50%",
      "@media (max-width : 600px )": {
        width: "100%",
        // display: "flex",
        // justifyContent: "space-evenly",
        flexDirection: "column",
      },
    },
    main: {
      display: "block",
      width: "100%",
      height: "100vh",
      backgroundColor: "#eeeeee",
    },
    link: {
      textDecoration: "none",
      color: "white",
      width: "70%",
      textAlign: "center",
    },
    primaryButton: {
      color: "white",
      textShadow: "0 0 lawngreen",
      fontWeight: "bolder",
    },
    cardHeaderMainDiv: {
      width: "70%",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    cardHeader: {
      backgroundColor: "#018C1F",
      color: "#fff",
      borderRadius: 25,
      boxShadow: "0px 0px 10px #00000029",
      border: "1px solid #e0e0e0",
      padding: 10,
      display: "flex",
      flexDirection: "column",
      "@media (max-width: 800px)": {
        width: "100%",
      },
      "@media (max-width: 600px)": {
        width: "100%",
        "& .MuiTypography-h6 ": {
          fontSize: "1rem",
        },
      },
    },
    cardContent: {
      width: "100%",
      textAlign: "center",
    },
    professionalMain: {
      width: "70%",
      margin: "20px auto",
      "@media (max-width: 600px)": {
        width: "100%",
      },
    },
    messageBtns: {
      paddingRight: "10px",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
    },
    formDiv: {
      flexDirection: "row",
      width: "50%",
      "@media (max-width: 500px)": {
        width: "30%",
      },
    },
    singlebtn: {
      alignSelf: "center",
      float: "right",
      marginTop: "8px",
      minWidth: "45%",
      "@media (max-width: 800px)": {
        fontSize: "0.775rem",
      },
    },

    gridPadding: {
      padding: "15px",
      "@media (max-width: 890px)": {
        padding: "5px",
        paddingTop: "0px",
      },
    },

    //Specialties page styling
    specialtiesGrid: {
      justifyContent: "space-around",
      padding: "10px 0px",
      "@media (max-width: 700px)": {
        "& .MuiGrid-grid-xs-2": {
          maxWidth: "18.666667%",
          flexBasis: "none",
        },
      },
    },
    paper: {
      padding: "20px",
      fontSize: "14px",
      width: "100%",
      height: "auto",
      backgroundColor: "#f5f5f5",
      borderRadius: 10,
      border: "1px solid #e0e0e0",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      textAlign: "left",
      "@media (max-width: 700px)": {
        padding: "20px 10px",
      },
    },
    dropdown: {
      fontSize: "15px",
      "@media (max-width: 1380px)": {
        fontSize: 11,
        padding: "0px 5px",

        "& .MuiOutlinedInput-input": {
          padding: "0px 30px 0px 0px",
          height: "15px",
        },
      },
      "@media (max-width: 960px)": {
        padding: "15px 10px 0px 10px",
      },
    },
    cardMainDiv: {
      borderRadius: 10,
      padding: "20px",
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      "@media (max-width: 800px)": {
        fontSize: "10px",
        padding: "5px",
      },
    },

    cardContentInnerDiv: {
      margin: "20px 0px",
      width: "70%",
      "@media (max-width: 800px)": {
        width: "100%",
      },
    },
    chip: {
      borderRadius: 5,
      border: "1px solid black",
      height: "40px",
      fontSize: 15,
      textAlign: "left",
      "@media (max-width: 1380px)": {
        fontSize: 11,
        textAlign: "center",
        "& .MuiChip-label": {
          whiteSpace: "break-spaces",
          padding: "0px 10px",
        },
        "& .MuiSelect-selectMenu": {
          height: "0px",
        },
        "@media (max-width: 550px)": {
          fontSize: 10,
          "& .MuiChip-label": {
            whiteSpace: "break-spaces",
            padding: "0px 8px",
          },
        },
      },
    },
    title: {
      fontWeight: "bold",
      fontSize: "16px",
    },
  })
);

export const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: green,
  },
});
