export const isCallingProfile = (state: any) => state.physicianProfileCalling;
export const isPhysicianProfile = (state: any) => state.physicianProfile || undefined;
export const isProfileFailure = (state: any) => state.physicianProfileFailure || undefined;

export const isUpdatingProfile = (state: any) => state.physicianProfileUpdating;
export const isPhysicianProfileUpdated = (state: any) =>
  state.physicianProfileUpdateSuccess || undefined;
export const isPhysicianProfileUpdatedFail = (state: any) =>
  state.physicianProfileUpdateFailure || undefined;

export const isCallingPhysicianFileUpdate = (state: any) =>
  state.updatePhysicianFilePrivacyCalling || undefined;
export const isPhysicianFileUpdated = (state: any) => state.updatePhysicianFilePrivacy || undefined;
export const isPhysicianFileUpdatedFail = (state: any) =>
  state.updatePhysicianFilePrivacyFailure || undefined;

export const isRegisterInUserSuccess = (state: any) => state.registerInUserSuccess || undefined;
export const isRegisterInUserFailure = (state: any) => state.registerInUserFailure || undefined;
