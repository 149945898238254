export const isCallingProfile = (state: any) => state.patientProfileCalling;
export const isPatientProfile = (state: any) => state.patientProfile || undefined;
export const isProfileFailure = (state: any) => state.patientProfileFailure || undefined;

export const isUpdatingProfile = (state: any) => state.patientProfileUpdating;
export const isPatientProfileUpdated = (state: any) =>
  state.patientProfileUpdateSuccess || undefined;
export const isPatientProfileUpdatedFail = (state: any) =>
  state.patientProfileUpdateFailure || undefined;

export const isCallingPatientFileUpdate = (state: any) =>
  state.updatePatientFilePrivacyCalling || undefined;
export const isPatientFileUpdated = (state: any) => state.updatePatientFilePrivacy || undefined;
export const isPatientFileUpdatedFail = (state: any) =>
  state.updatePatientFilePrivacyFailure || undefined;

export const searchPhysicianCalling = (state: any) => state.searchPhysicianCalling;
export const searchPhysicianSuccess = (state: any) => state.searchPhysicianSuccess;
export const searchPhysicianFailure = (state: any) => state.searchPhysicianFailure;
