import { connect } from "react-redux";
import { basicSelectors, basicCalls } from "../../../../store/supers/basicCalls";
import { IPhysician, USER_TYPE } from "../../../../store/types";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MaterialTable from "material-table";
import { useState } from "react";
import { FreezeDTO } from "../../../../store/types/type";
import AlertPopup from "../../../base/Components/AlertPopup";

interface OwnProps {}
interface DispatchProps {
  getPhysicians: () => Promise<void>;
  freezePhysicians: (id: string, body: FreezeDTO) => Promise<void>;
}
interface StateProps {
  gettingPhysicians: boolean;
  fetchPhysicians: IPhysician[];
  getPhysiciansError: string;

  updatingPhysicians: boolean;
  updatedPhysician: IPhysician[];
  updatePhysiciansError: string;
}

type Props = OwnProps & StateProps & DispatchProps;
function PhysicianControl(props: Props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [reason, setReason] = useState("");
  const [userID, setUserID] = useState("");

  const physicians = props.fetchPhysicians.filter((phy) => {
    return phy?.approved;
  });

  const handlePopup = () => {
    setOpenPopup(false);
    if (userID) {
      props.freezePhysicians(userID, {
        frozen: "1",
        frozenReason: reason,
        user: USER_TYPE.PHYSICIAN,
      });
      props.getPhysicians();
    }
    props.getPhysicians();
  };
  const handleFreeze = (e: { preventDefault: () => void }, data: any) => {
    e.preventDefault();
    if (data?._id) {
      setOpenPopup(true);
      setUserID(data._id);
    }
  };
  const handleUnfreeze = (e: { preventDefault: () => void }, data: any) => {
    e.preventDefault();
    if (data?._id) {
      props.freezePhysicians(data?._id, { frozen: "0", user: USER_TYPE.PHYSICIAN });
    }
    props.getPhysicians();
  };
  const handleInput = (e: { target: { value: any } }) => {
    setReason(e.target.value);
  };
  const renderFreezeIcon = () => {
    return <BlockIcon style={{ color: "red" }} />;
  };
  const renderUnFreezeIcon = () => {
    return <CheckCircleIcon style={{ color: "green" }} />;
  };
  return (
    <>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          columns={[
            { title: "id", field: "id", type: "string", hidden: true },
            { title: "Name", field: "firstName", type: "string" },
            { title: "Email", field: "email", type: "string" },
            { title: "Practice Address", field: "practiceAddress", type: "string" },
            {
              title: "Freeze",
              field: "freeze",
              type: "boolean",
              render: (rowData) => (rowData.frozen ? "true" : "false"),
            },
            { title: "Phone", field: "phoneNumber", type: "string" },
            {
              title: "LoginCount",
              field: "signedInCount",
              type: "numeric",
              headerStyle: { textAlign: "center", width: 30 },
              cellStyle: { textAlign: "center", width: 30 },
            },
            {
              title: "Last SignIn",
              field: "lastLoginTime",
              type: "date",
              render: (rowData) => new Date(rowData.lastLoginTime).toLocaleDateString(),
            },
            {
              title: "Date of Joining",
              field: "createdAt",
              type: "datetime",
              render: (rowData) => new Date(rowData.createdAt).toLocaleDateString(),
            },
          ]}
          actions={[
            (rowData) => ({
              icon: renderFreezeIcon,
              tooltip: "Freeze Account",
              onClick: (e, rowData) => {
                handleFreeze(e, rowData);
                props.getPhysicians();
              },
              disabled: rowData.frozen,
            }),
            (rowData) => ({
              icon: renderUnFreezeIcon,
              tooltip: "Un Freeze",
              onClick: (e, rowData) => {
                handleUnfreeze(e, rowData);
                props.getPhysicians();
              },
              disabled: !rowData.frozen,
            }),
          ]}
          title="Professional Accounts"
          options={{
            actionsColumnIndex: 9,
            exportButton: true,
            showEmptyDataSourceMessage: true,
            searchAutoFocus: true,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
          data={physicians}
        />
      </div>
      <AlertPopup
        title="Freeze Account"
        text={"Enter the reason for freezing this Account."}
        open={openPopup}
        handleOpenPopup={handlePopup}
        buttonText="Submit"
        input={true}
        inputValue={reason}
        handleInputValue={handleInput}
      />
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getPhysicians: () => dispatch(basicCalls.getPhysicians()),
  freezePhysicians: (id: string, body: FreezeDTO) => dispatch(basicCalls.freezeAccount(id, body)),
});
const mapStateToProps = (state: any) => ({
  fetchPhysicians: basicSelectors.fetchPhysicians(state.supers.basicCalls),
  gettingPhysicians: basicSelectors.gettingPhysicians(state.supers.basicCalls),
  getPhysiciansError: basicSelectors.getPhysiciansError(state.supers.basicCalls),

  updatedPhysicians: basicSelectors.updatedAccount(state.supers.basicCalls),
  updatingPhysicians: basicSelectors.updatingAccount(state.supers.basicCalls),
  updatePhysiciansError: basicSelectors.updateAccountError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianControl);
