export const isCreatingPingRequest = (state: any) => state.creatingPingRequest;
export const errorWhileCreatingPingRequest = (state: any) => state.createPingRequestFailure;
export const createPingRequestSuccess = (state: any) => state.createPingRequestSuccess;

// export const myPingChats = (state: any) => state.myPingChats || [];

// export const isUpdatingPingChat = (state: any) => state.updatingPingChat;
// export const errorWhileUpdatingPingChat = (state: any) => state.updatePingChatFailure;

// export const isGettingMyPingChats = (state: any) => state.gettingMyPingChats;
// export const errorWhileGettingMyPingChats = (state: any) => state.getMyPingChatsFailure;
