/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-new-wrappers */
import {
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { AnyIfEmpty, connect } from "react-redux";
import React, { useEffect, useState } from "react";
import { useStyles } from "../../dashboardComponents/patient/muiStyling";
import { ICenter } from "../../../store/types/center";
import { basicCalls, basicSelectors } from "../../../store/centers/basicCalls";
import {
  basicCalls as superBasicCalls,
  basicSelectors as superBasicSelectors,
} from "../../../store/supers/basicCalls/index";
import {
  NewChatDTO,
  NewMessageDTO,
  NotificationDTO,
  SearchPhyDTO,
} from "../../../store/types/type";

import { patientProfileCalls, patientProfileSelectors } from "../../../store/patients/profile";
import { onlineCalls, onlineSelectors } from "../../../store/online";
import { searchPhysician } from "../../../store/patients/profile/apiCalls";
import { notificationSMSCalls } from "../../../store/notification/sms";
import { IChat, IMessage, IPatient, IPhysician, USER_TYPE } from "../../../store/types";
import PingSearchFields from "./PingSearchFields";
import PingProfessionalCards from "./PingProfessionalCards";
import PingCenterCards from "./PingCenterCards";
import { Alert } from "@material-ui/lab";
import { usRedListStates } from "../../../store/types/states";
import axios from "axios";
import { centerProfileSelectors } from "../../../store/centers/profile";
import CrisisPopup from "../../popup/CrisisPopup";
import io from "socket.io-client";
import { chatSelectors as patientChatSelector } from "../../../store/patients/chat";
import { chatSelectors as physicianChatSelector } from "../../../store/physician/chat";
import { chatSelectors as centerChatSelector } from "../../../store/centers/chat";
import { physicianProfileSelectors } from "../../../store/physician/profile";
import { chatCalls as physicianChatCalls } from "../../../store/physician/chat";

const useStyle = makeStyles((theme) => ({
  paper: {
    margin: 20,
    height: 180,
    width: 260,
  },
  select: {
    minWidth: "40%",
    margin: "0px 20px",
    "@media (max-width: 600px)": {
      width: "100%",
      margin: "0px",
      minWidth: "0px",
    },
  },
  btnStyle: {
    minWidth: "20%",
    borderRadius: "20px",
    fontSize: "0.9125rem",
    "@media (max-width: 600px)": {
      minWidth: "100%",
      fontSize: "0.7125rem",
    },
  },
  btnDiv: {
    alignSelf: "center",
    marginTop: "15px",
    minWidth: "35%",
    "@media (max-width: 600px)": {
      minWidth: "100%",
      // minWidth: "0px",
    },
  },
  selectDiv: {
    width: "100%",
    textAlign: "center",
    "@media (max-width: 600px)": {
      textAlign: "left",
      "& .MuiOutlinedInput-input": {
        padding: "8px 19px 13px 5px",
        fontSize: "0.8rem",
      },
    },
  },
  innerSelectDiv: {
    width: "70%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  label: {
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
}));
type mapStateProps = {
  centers: ICenter[];
  physicianProfile: IPhysician[];
  patientChats: IChat[];
  physicianChats: IChat[];
  centerChats: IChat[];
  physicians: IPhysician[] | null;
  centerProfile?: ICenter;
  patientProfile?: IPatient;
  searchPhysicianSuccess: IPhysician[] | null;
};
type mapDispatchProps = {
  getAllCenters: any;
  searchPhysician: any;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  getPhysicians: () => Promise<void>;
  getPhysicianAllChats: () => Promise<void>;
  physicianPing: (pingDto: NewChatDTO) => Promise<void>;
};
type ownProps = { message?: boolean; internalSelected?: boolean; type?: any; value?: any };

type Props = ownProps & mapDispatchProps & mapStateProps;

const _PingMain = (props: Props) => {
  const classes = useStyle();
  const cardClass = useStyles();
  const [selectedSpecialty, setSelectedSpecialty] = React.useState<any>("Any");
  const [selectedState, setSelectedState] = React.useState<any>("Any");
  const [selectedCenter, setSelectedCenter] = React.useState<string>("Any");
  const [searchRes, setSearchRes] = React.useState<any>([]);
  const [centerRes, setCenterRes] = React.useState<any>(null);
  const [openCrisisPopup, setOpenCrisisPopup] = useState(false);
  const [commentVal, setCommentVal] = useState("");
  const [rooms, setRooms] = useState("");
  const [phyId, setPhyId] = useState<string | null>(null);
  const [centerId, setCenterId] = useState<string | null>(null);
  const [msgType, setMsgType] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState<any>();

  const chat = props.type === "Center" ? props.centerChats : props.physicianChats;

  const handleSendMessage = async (specialistProfile: any) => {
    const URL = `${process.env.REACT_APP_API_URL}/chat`;

    const socket = io(URL, { transports: ["polling"] });
    socket.on("joinedRoom", (room: string) => {
      console.log("joined room ", room);
      setRooms(room);
    });

    // socket.on("connect", () => {
    //   console.log("connected");
    //   if (rooms) {
    //     socket.emit("leaveRoom", PatientChatId);
    //     socket.emit("joinRoom", PatientChatId);
    //   }
    // });

    if (commentVal === "") return;

    // find chat

    // await props.getPhysicianAllChats();
    console.log(props.centerChats, props.physicianChats);
    let chatsFilter: any;
    let chID: any;
    // if message sent to center
    if (props.physicianChats && msgType === USER_TYPE.CENTER) {
      // center chat
      chatsFilter = props?.physicianChats?.filter((ch: any) => {
        if (ch.center && ch.center._id === centerId && !ch.isGroup) {
          return ch;
        }
      });
      if (chatsFilter.length > 0) {
        chID = chatsFilter[0]._id;
      }
    }
    // if message sent to phy
    if (props.physicianChats && msgType === USER_TYPE.PHYSICIAN) {
      // phy chat
      chatsFilter = props?.physicianChats?.filter((ch: any) => {
        if (ch.physicians && !ch.isGroup) {
          const incOther = ch.physicians.includes(phyId);
          const incSelf = ch.physicians.includes(localStorage.getItem("_id"));
          if (incOther && incSelf) {
            return ch;
          }
        }
      });
      if (chatsFilter.length > 0) {
        chID = chatsFilter[0]._id;
      }
    }
    // create chat for phy with phy
    if (
      !chID &&
      localStorage.getItem("current") === USER_TYPE.PHYSICIAN &&
      msgType === USER_TYPE.PHYSICIAN
    ) {
      let physicianIDs = [];
      physicianIDs.push(phyId);
      physicianIDs.push(localStorage.getItem("_id"));
      // create new chat
      // setMsgType
      const obj: any = {
        physicianIDs,
        isPrivate: true,
      };
      const chats: any = await props.physicianPing(obj);
      if (chats.length > 0) {
        const newChat = chats[chats.length - 1];
        chID = newChat._id;
      }
    }

    // create chat for phy with center
    if (
      !chID &&
      localStorage.getItem("current") === USER_TYPE.PHYSICIAN &&
      msgType === USER_TYPE.PHYSICIAN
    ) {
      let physicianIDs = [];
      physicianIDs.push(localStorage.getItem("_id"));
      // create new chat
      const obj: any = {
        physicianIDs,
        centerID: centerId,
        isPrivate: true,
      };
      const chats: any = await props.physicianPing(obj);
      if (chats.length > 0) {
        const newChat = chats[chats.length - 1];
        chID = newChat._id;
      }
    }

    if (!chID) return;
    const newMessage: NewMessageDTO = {
      chatID: chID,
      text: commentVal,
    };

    try {
      const res = await axios.post<IMessage>(`/messages/new-message`, newMessage, {
        headers: {
          Authorization: `Bearer ${
            props.type === "Center"
              ? localStorage.getItem("centerToken")
              : localStorage.getItem("physicianToken")
          }`,
        },
      });
      socket.emit("send-message", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("handleMessagesNotifications", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("activeTime", newMessage);
      // if (specialistProfile) {
      let specialistProfile = chID;
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New message on Delores.\n\n" +
        `You have a new message from ${
          props.type === "Center" ? profile?.name : profile?.name
        }. To see the message, login to `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: specialistProfile.phoneNumber });
      // }
      if (res.status === 201) {
        setOpenCrisisPopup(true);
        setCommentVal("");
      }
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      socket.disconnect();
    } catch (err) {
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      console.log("error", err);
    }
  };

  const handleClickOpen = (id: string | null, user: any) => {
    setMsgType(user);
    if (user === USER_TYPE.PHYSICIAN) {
      setPhyId(id);
    }
    if (user === USER_TYPE.CENTER) {
      setCenterId(id);
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setPhyId("");
    setCommentVal("");

  };

  React.useEffect(() => {
    props.type === "Center" ? setProfile(props.centerProfile) : setProfile(props.physicianProfile);
    const asyncAwait = async () => {
      window.scrollTo(0, 0);
      await props.getAllCenters();
    };
    asyncAwait();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setSelectedSpecialty("Any");
    setSelectedCenter("Any");
    setSelectedState("Any");
    if (props.message) {
      GetDeloresDigital();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e?: { preventDefault: () => void }) => {
    e?.preventDefault();
    setSearchRes([]);
    if (selectedSpecialty !== "Any" || selectedState !== "Any") {
      props.searchPhysician({ specialties: selectedSpecialty, state: selectedState });
    } else {
      props.getPhysicians();
    }
  };

  const GetDeloresDigital = () => {
    setCenterRes(props?.centers);
  };
  const filterCenters = (phys: any) => {
    let result: any = [];
    // eslint-disable-next-line array-callback-return
    phys.map((phy: any) => {
      const res = props?.centers?.filter((center: any) => phy?.center === center?._id);
      const check = result.filter((r: any) => r?._id === res[0]?._id);
      if (!check.length) {
        result.push(res[0]);
      }
    });
    setCenterRes(result);
  };
  React.useMemo(() => {
    if (props.physicians && props.physicians?.length > 0) {
      filterCenters(props.physicians);
      setSearchRes(props.physicians);
    }
    if (props.searchPhysicianSuccess) {
      let arr = [];
      arr = props.searchPhysicianSuccess.filter(
        (value, index, self) => index === self.findIndex((t) => t._id === value._id)
      );
      filterCenters(arr);
      setSearchRes(arr);
    }
  }, [props.searchPhysicianSuccess, props.physicians]);

  return (
    <>
      <div className={cardClass.cardMainDiv}>
        <div className={cardClass.cardHeaderMainDiv}>
          <div style={{ textDecoration: "none", color: "white", cursor: "pointer" }}>
            <CardHeader
              className={cardClass.cardHeader}
              title={
                props.message
                  ? `Message the
                  Delores Management Team`
                  : props.internalSelected
                  ? `Search & message
                  a professional in your same
                  practice`
                  : `Search & message
                  a professional outside of your
                  practice`
              }
              titleTypographyProps={{ variant: "h6" }}
            ></CardHeader>
          </div>
        </div>
      </div>

      <PingSearchFields
        classes={classes}
        centers={props.centers}
        selectedSpecialty={selectedSpecialty}
        setSelectedSpecialty={setSelectedSpecialty}
        handleSubmit={handleSubmit}
        setSelectedState={setSelectedState}
        setSelectedCenter={setSelectedCenter}
        selectedState={selectedState}
        selectedCenter={selectedCenter}
      />
      <CardContent className={cardClass.cardContent}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          {props.value && props.value === "message" && (
            <PingCenterCards
              centerRes={centerRes}
              cardClass={cardClass}
              handleClickCenterOpen={handleClickOpen}
              single={true}
            />
          )}
          {searchRes && !props.value && (
            <PingProfessionalCards
              searchRes={searchRes}
              cardClass={cardClass}
              handleClickOpen={handleClickOpen}
            />
          )}

          {centerRes && !props.value && (
            <PingCenterCards
              centerRes={centerRes}
              cardClass={cardClass}
              handleClickCenterOpen={handleClickOpen}
            />
          )}

          {searchRes.length === 0 &&
            !props.message &&
            !usRedListStates.includes(selectedState[0]) && (
              <Alert severity="info">
                We haven't found the perfect professionals for this just yet. We promise they're
                coming soon.
              </Alert>
            )}
        </div>
      </CardContent>

      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
        <DialogTitle id="responsive-dialog-title">
          {`Send a message to the Professional`}
        </DialogTitle>
        <Box>
          <Box style={{ padding: "10px 30px" }} component="div">
            To:{" "}
            <b>
              {phyId &&
                `${
                  !props.message
                    ? searchRes?.find((o: any, i: any) => {
                        if (o._id === phyId) {
                          return o;
                        }
                        return null;
                      })?.firstName
                    : centerRes?.name
                }`}
            </b>
          </Box>
          <Box style={{ textAlign: "center" }} component="div">
            <TextField
              style={{ width: "90%" }}
              rowsMax="5"
              variant="outlined"
              multiline
              rows={4}
              label="Comment"
              required
              value={commentVal}
              onChange={(event) => setCommentVal(event?.target.value)}
            />
          </Box>
        </Box>
        <DialogActions style={{ padding: "10px 30px" }}>
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            onClick={() =>
              handleSendMessage(
                phyId && !props.message
                  ? searchRes?.find((o: any, i: any) => {
                      if (o._id === phyId) {
                        return o;
                      }
                      return null;
                    })
                  : centerRes?._id
              )
            }
          >
            Send
          </Button>
          <Button autoFocus variant="outlined" color="primary" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
        <CrisisPopup openCrisisPopup={openCrisisPopup} setOpenCrisisPopup={setOpenCrisisPopup} />
      </Dialog>
    </>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  getAllCenters: () => dispatch(basicCalls.getAllCenters()),
  getPhysicians: () => dispatch(superBasicCalls.getPhysicians()),
  searchPhysician: (search: SearchPhyDTO) => dispatch(searchPhysician(search)),
  getPatient: () => dispatch(patientProfileCalls.getPatientProfile()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),
  getPhysicianAllChats: () => dispatch(physicianChatCalls.getAllChats()),
  physicianPing: (startChatDto: NewChatDTO) => dispatch(physicianChatCalls.startChat(startChatDto)),
});
const mapStateToProps = (state: any) => ({
  centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  patientChats: patientChatSelector.patientChats(state.patients.chat),
  centerChats: centerChatSelector.centerChats(state.centers.chat),
  physicianChats: physicianChatSelector.physicianChats(state.physicians.chat),

  centers: basicSelectors.fetchCenters(state.centers.basicCalls),
  physicians: superBasicSelectors.fetchPhysicians(state.supers.basicCalls),
  errorWhileGetCenters: basicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  getPatientError: patientProfileSelectors.isProfileFailure(state.patients.patientProfile),
  gettingPatient: patientProfileSelectors.isCallingProfile(state.patients.patientProfile),
  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  searchPhysicianSuccess: patientProfileSelectors.searchPhysicianSuccess(
    state.patients.patientProfile
  ),

  searchPhysicianFailure: patientProfileSelectors.searchPhysicianFailure(state.patients),
});
export const PingMain = connect(mapStateToProps, mapDispatchToProps)(_PingMain);
