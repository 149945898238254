import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { authSelectors } from "../../store/centers/auth";

import { authCalls as superAuthCalls } from "../../store/supers/auth";

import SuperSignUp from "../../components/authComponents/SuperSignupCard";
import { SuperSignUpDTO } from "../../store/types/type";

export class SignupContainer extends Component {
  constructor(props: any) {
    super(props);
    this.state = {
      value: props.location.search
        ? 3
        : localStorage.getItem("tabB")
        ? Number(localStorage.getItem("tabB"))
        : 0,
    };
  }
  state: any;
  props: any;

  a11yProps = (index: any) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  render() {
    return (
      <>
        <SuperSignUp
          superSignUp={this.props.superSignUp}
          registeringSuper={this.props.registeringSuper}
          superRegistered={this.props.superRegistered}
          errorWhileSuperRegister={this.props.errorWhileSuperRegister}
          whenRegisterSuccessSuper={this.props.whenRegisterSuccessSuper}
        />
      </>
    );
  }
}
const mapDispatchToProps = (dispatch: any) => ({
  superSignUp: (superData: SuperSignUpDTO) => dispatch(superAuthCalls.signUp(superData)),
});

const mapStateToProps = (state: any) => ({
  registeringSuper: authSelectors.isSigningUp(state.supers.auth),
  superRegistered: authSelectors.signUpSuccess(state.supers.auth),
  errorWhileSuperRegister: authSelectors.hasSignUpError(state.supers.auth),
  whenRegisterSuccessSuper: authSelectors.successMessage(state.supers.auth),
});

const SignUpContainerWithRoutes = withRouter(SignupContainer);
export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainerWithRoutes);
