import axios from "axios";
import * as actions from "./actions";

export const getAllOnline = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.isOnline());
  try {
    const response = await axios.get(`/online`);
    if (response.status === 200) {
      dispatch(actions.isOnlineSuccess(response?.data));
    } else throw new Error("Something went wrong");
  } catch (err: any) {
    dispatch(actions.isOnlineFailure(err.response?.data.message));
  }
};
