import React from "react";
import { useStyles } from "../../main/muiStyling";
import ApprovalList from "./Approval/ApprovalList";
import { ICenter } from "../../../../store/types/center";

export const Approval = (props: {
  approvePhysicianRequest: (id: string) => Promise<void>;
  denyPhysicianRequest: (id: string) => Promise<void>;
  updatingPhysicianRequest: boolean;
  updatingPhysicianError: string;
  type?: string;
  profile: ICenter;
}) => {
  const classes = useStyles();
  // const handleClickA = () => {
  //   history.push("/center/management/approval");
  // };
  // const handleClickB = () => {
  //   history.push("/center/management/tracking");
  // };
  return (
    <>
      <div className={classes.root}>
        <ApprovalList {...props} />
      </div>
    </>
  );
};
