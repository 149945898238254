import * as types from "./types";

const initialState = {
  gettingCenters: false,
  centers: [],
  getCentersError: "",
};

export const basicReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.gettingCenters:
      return {
        ...state,
        gettingCenters: true,
        getCentersError: "",
        centers: [],
      };
    case types.getCentersFailure:
      return {
        ...state,
        gettingCenters: false,
        getCentersError: action.payload,
      };
    case types.getCentersSuccess:
      return {
        ...state,
        gettingCenters: false,
        centers: action.payload,
      };
    default: {
      return state;
    }
  }
};

// todo
// sign up 3 states
