import { Alert } from "@material-ui/lab";
import axios from "axios";
import React, { useState } from "react";
import PaymentForm from "./PaymentForm";

export const TrialEnd = (props: any) => {
  const [verified, setVerified] = useState(false);
  const [error, setError] = useState(false);
  //   const history=useHistory()
  React.useMemo(async () => {
    if (verified) {
      // unfreeze
      try {
        const email = !props.patientEmail
          ? localStorage.getItem("patientEmail")
          : props.patientEmail;
        if (!email) return;
        const res = await axios.post(`/auth/14-day-pay`, { email: email });
        if (res.status === 201) {
          props.setOpenSuccessPopup(true);
          props.setAuthType("userAuth");
          props.changeType("login");
          props.setTitleText("Sign In");
          // @ts-ignore
          window.location.reload(false);
        }
      } catch (error: any) {
        console.log("Error in pay", error);

        setError(true);
      }
    }
  }, [verified, props]);
  return (
    <div>
      <PaymentForm setVerified={setVerified} setError={setError} />
      {error && (
        <div style={{ paddingTop: "1em" }}>
          <Alert severity="error">An Error Occurred, Please try again!</Alert>
        </div>
      )}
      <div style={{ paddingTop: "2em" }}>
        <Alert severity="error">
          Unfortunately your 14-day free trial is over. Please upgrade your to account to keep using
          Delores. $40.99 per month
        </Alert>
      </div>
    </div>
  );
};
