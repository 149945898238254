import { Typography, Box, FormControl, InputLabel, Select, Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { basicCalls, basicSelectors } from "../../store/admins/basicCalls";
import { centerProfileCalls, centerProfileSelectors } from "../../store/centers/profile";
import { IAdmin } from "../../store/types/admin";
import { CenterUpdateDTO } from "../../store/types/type";
import CircleSpinner from "../CircleSpinner";

interface OwnProps {
  message: string;
  denied: boolean;
  checkCenterApprovalState: () => Promise<void>;
}
interface StateProps {
  admins: IAdmin[];
  gettingAdmins: boolean;
  getAdminsError: string;

  changingAdmin: boolean;
  changeAdminError: string;
  // adminChanged: boolean;
}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  changeAdmin: (updateObj: CenterUpdateDTO) => Promise<void>;
}
type Props = StateProps & DispatchProps & OwnProps;
interface State {
  selectedAdmin: string;
  changedAdmin: boolean;
}
export class DeniedOrWaitingForApprovalCenter extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedAdmin: "",
      changedAdmin: false,
    };
  }

  componentDidMount = async () => {
    await this.props.getAdmins();
  };
  handleAdminChange = async () => {
    if (this.state.selectedAdmin) {
      await this.props.changeAdmin({ admin: this.state.selectedAdmin });
      await this.props.checkCenterApprovalState();
      this.setState({
        ...this.state,
        changedAdmin: true,
      });
    }
  };
  render() {
    return (
      <div className="main" style={{}}>
        <div style={{ paddingTop: "1.5em" }}>
          <Typography
            style={{
              fontWeight: "bolder",
              textShadow: "white",
              textDecoration: "none",
            }}
            variant="h2"
            gutterBottom
          >
            <a href="/home" className="logo">
              Delores
            </a>
          </Typography>
        </div>
        <Box className="container" border={"2px solid black"}>
          <h5>{this.props.message}</h5>
        </Box>
        {this.props.denied && this.props.admins.length ? (
          this.props.changingAdmin ? (
            <CircleSpinner />
          ) : (
            <Box style={{ textAlign: "center", padding: "15px" }}>
              <FormControl>
                <InputLabel htmlFor="age-native-simple">Admins</InputLabel>
                <Select
                  style={{ minWidth: "350px" }}
                  fullWidth
                  native
                  defaultValue={this.props.admins[0]._id}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      selectedAdmin: e.target.value as string,
                    });
                  }}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  {this.props.admins.map((admin, i) => {
                    return (
                      <option key={i} value={admin._id}>
                        {admin.name}
                      </option>
                    );
                  })}
                </Select>
                <Button onClick={this.handleAdminChange}>Change</Button>
              </FormControl>
            </Box>
          )
        ) : null}

        {this.props.changeAdminError || this.state.changedAdmin
          ? `${this.state.selectedAdmin} has been selected and awaiting for approval`
          : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAllAdmins()),
  changeAdmin: (updateObj: CenterUpdateDTO) =>
    dispatch(centerProfileCalls.updateCenterProfile(updateObj)),
});
const mapStateToProps = (state: any) => ({
  gettingAdmins: basicSelectors.gettingAdmins(state.admins.basicCalls),
  admins: basicSelectors.fetchAdmins(state.admins.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.admins.basicCalls),

  changingAdmin: centerProfileSelectors.isUpdatingProfile(state.centers.centerProfile),
  changeAdminError: centerProfileSelectors.isCenterProfileUpdatedFail(state.centers.centerProfile),
  // adminChanged: centerProfileSelectors.isCenterProfileUpdated(state.centers.physicianProfile),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeniedOrWaitingForApprovalCenter);
