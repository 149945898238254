import axios from "axios";
import { ICenter } from "../../types/center";
import * as actions from "./actions";

export const getAllCenters = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.gettingAllCenters());
  try {
    const response = await axios.get<ICenter[]>(`/center/get-all`);
    if (response.status === 200) dispatch(actions.getAllCentersSuccess(response.data));
    else throw new Error("Could not fetch provider list");
  } catch (err: any) {
    dispatch(actions.getAllCentersFailure(err.message));
  }
};
