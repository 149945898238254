// import { PaletteType } from "@material-ui/core";
// import { blue, green, lightGreen, pink, purple } from "@material-ui/core/colors";
import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

export const deloresTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#20d641",
      light: "#20d642",
      dark: "#205AD6",
      contrastText: "",
    },
    secondary: {
      main: "#205AD6",
    },
  },
});

export const lightTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#20d641",
    },
    secondary: {
      main: "#cc4444",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#f5f5f5",
    },
    // titleBar: {
    //   main: "#eeeeee",
    //   contrastText: "#222222",
    // },
  },
});

export const darkTheme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#20d641",
      light: "rgb(81, 91, 95)",
      dark: "rgb(26, 35, 39)",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#FFB74D",
      light: "rgb(255, 197, 112)",
      dark: "rgb(200, 147, 89)",
      contrastText: "rgba(0, 0, 0, 0.87)",
    },
    // titleBar: {
    //   main: "#555555",
    //   contrastText: "#ffffff",
    // },
    error: {
      main: red.A400,
    },
  },
});
