import { ListItemText } from "@material-ui/core";
import MaterialTable from "material-table";
import React from "react";
import { IAdmin, ICenter } from "../../../store/types";

interface Props {
  adminProfile: IAdmin;
  denyCenterRequest: (id: string) => Promise<void>;
}
export default function TrackCenter(props: Props) {
  return (
    <>
      {/* <pre>{JSON.stringify(props.adminProfile.message.length)}</pre> */}
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          title="Track Providers"
          columns={[
            { title: "Name", field: "name" },
            { title: "Professionals", field: "physicians" },
            { title: "SignIn Count", field: "signedInCount" },
            {
              title: "Number Of Chat",
              field: "chats",
              render: (cen: any) => (cen?.chats?.length > 0 ? "no chats" : cen?.chats?.length),
            },
            {
              title: "Last SignIn",
              field: "lastLoginTime",
              type: "date",
              render: (rowData) => new Date(rowData.lastLoginTime).toLocaleDateString(),
            },
            {
              title: "Date of Joining",
              field: "createdAt",
              type: "datetime",
              render: (rowData) => new Date(rowData.createdAt).toLocaleDateString(),
            },
          ]}
          data={props?.adminProfile?.centers.map((center: ICenter) => {
            return {
              name: center.name,
              physicians: center.physicians.length,
              signedInCount: center.signedInCount,
              chat: center.chats.length,
              lastLoginTime: center.lastLoginTime,
              email: center.email,
              phoneNumber: center.phoneNumber,
              address: center.address,
              createdAt: center.createdAt,
            };
          })}
          detailPanel={[
            {
              tooltip: "Show Details",
              render: (rowData: any) => {
                return (
                  <div
                    style={{
                      fontSize: 100,
                      textAlign: "center",
                      backgroundColor: "#d8d8d8",
                    }}
                  >
                    <ListItemText>{`Email :${rowData.email}`}</ListItemText>
                    <ListItemText>{`Phone Number :${rowData.phoneNumber}`}</ListItemText>
                    <ListItemText>{`Address :${rowData.address}`}</ListItemText>
                  </div>
                );
              },
            },
          ]}
          options={{
            showEmptyDataSourceMessage: true,
            searchAutoFocus: true,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </>
  );
}
