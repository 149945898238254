import "@progress/kendo-theme-default/dist/all.css";
import { useCallback, useEffect, useState } from "react";
import { Button, Container, Grid } from "@material-ui/core";
import axios from "axios";
import DatePicker from "../../physician/Appointment/DatePicker";
// import TimeSlots from "../../physician/Appointment/TimeSlots";
import { useStyles } from "../../physician/Appointment/muiStyles";
import { Alert } from "@material-ui/lab";
import { IPhysician } from "../../../../store/types";
import PatientTimeSlots from "./PatientTimeSlots";
import { NotificationDTO } from "../../../../store/types/type";

interface OwnProps {
  patientId: any;
  profile: any;
  userType: any;
  physicianProfile: IPhysician;
  phyId: any;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}
type Props = OwnProps;

function Appointment(props: Props) {
  const [bookingDate, setBookingDate] = useState<any>(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<any>();
  const [allSelectedTimeSlot, setAllSelectedTimeSlot] = useState<any>([]);
  const [phyProf, setPhyProf] = useState<any>([]);
  const [bookingTimes, setBookingTimes] = useState([]);
  const [slots, setSlots] = useState([]);
  const [bookingDay, setBookingDay] = useState("Select Date");
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const classes = useStyles();

  const handleSlots = useCallback(
    (sl) => {
      if (sl?.length <= 0) return;
      let arr: any = [];
      if (sl?.length > 0) {
        sl?.filter((item: any) => (item.booked !== true ? arr.push(item) : ""));
      }
      setBookingTimes(arr);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slots]
  );
  useEffect(() => {
    setPhyProf(props.physicianProfile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectTimeSlot = (time: any) => {
    setSelectedTimeSlot(time);
    setAllSelectedTimeSlot([...allSelectedTimeSlot, time]);
  };

  const handleScheduleTime = async () => {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    //@ts-ignore
    var newDate = new Date(bookingDate);
    newDate.setHours(0, 0, 0, 0);

    if (newDate && newDate >= today) {
      const scheduleTime = {
        physician: props.phyId,
        patient: props.patientId,
        booked: true,
        time: {
          start: selectedTimeSlot.split(" - ")[0],
          end: selectedTimeSlot.split(" - ")[1],
        },
        date: bookingDate,
      };

      try {
        const res = await axios.post(`/appointment`, scheduleTime, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
          },
        });
        if (res.status === 201) {
          console.log("API Response", res);
          // send notification to professional
          const url = `https://app.deloresdigital.com/auth`;
          if (props.physicianProfile) {
            const messageSentText = `${
              "New Session Scheduled.\n\n" +
              `Someone has scheduled time to speak to you! A session time has been booked by a user.
              To see the more, login to `
            }${url}\n`;
            await props.sendSMS({
              message: messageSentText,
              phone: props?.physicianProfile.phoneNumber,
            });
          }
          // send notification to user
          const messageSentText = `${
            "Your Session is confirmed.\n\n" +
            `Your scheduled appointment is confirmed for ${bookingDate} ! A reminder will be sent to you 10 minutes before your scheduled appointment.
              To see the more, login to `
          }${url}\n`;
          await props.sendSMS({
            message: messageSentText,
            phone: props?.profile.phoneNumber,
          });
        }
        setSuccess(true);
      } catch (err) {
        console.log("error", err);
        setError(true);
      }
    } else {
      console.log("Start time must be less then end time");
    }
  };

  function formatDate(date: any) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const onDateChange = async (e: any) => {
    setSelectedTimeSlot([]);
    setBookingDate(formatDate(e));
    setAllSelectedTimeSlot([]);
    setOpen(true);
    if (phyProf?.availabilities) {
      let storeDate = phyProf?.availabilities?.filter(
        (a: any) =>
          new Date(a.date).toISOString().split("T")[0] === new Date(e).toISOString().split("T")[0]
      );
      handleSlots(storeDate[0]?.slots);
      setSlots(storeDate[0]?.slots);
    }

    switch (e?.getDay()) {
      case 0:
        setBookingDay("Sunday");
        break;
      case 1:
        setBookingDay("Monday");
        break;
      case 2:
        setBookingDay("Tuesday");
        break;
      case 3:
        setBookingDay("Wednesday");
        break;
      case 4:
        setBookingDay("Thursday");
        break;
      case 5:
        setBookingDay("Friday");
        break;
      case 6:
        setBookingDay("Saturday");
        break;
      default:
        console.log("Select date");
    }
  };
  return (
    <Container>
      {props.userType === "Patient" && (
        <Grid container>
          {open ? (
            <Grid item md={12} lg={12} sm={12} xs={12} className={classes.grid}>
              {error && <Alert severity="error">Error</Alert>}
              {success && <Alert severity="success">Success</Alert>}
              <PatientTimeSlots
                bookingDay={bookingDay}
                bookingTimes={bookingTimes}
                allSelectedTimeSlot={selectedTimeSlot}
                selectTimeSlot={selectTimeSlot}
                userType={props.userType}
              />
              <div className={classes.scheduleButton}>
                <Button
                  autoFocus
                  variant="contained"
                  color="primary"
                  onClick={() => handleScheduleTime()}
                >
                  Schedule Time
                </Button>
              </div>
            </Grid>
          ) : (
            <Grid item md={12} lg={12} sm={12} xs={12} className={classes.grid}>
              <DatePicker bookingDate={bookingDate} onDateChange={onDateChange} />
            </Grid>
          )}
        </Grid>
      )}
    </Container>
  );
}

export default Appointment;
