import {
  Button,
  createStyles,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  Theme,
  Toolbar,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import io from "socket.io-client";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ComposeComponent } from "../../chat/Compose";
import ContactsComponent from "../../chat/Contacts";
import "../../sidebars/sidebar.css";
import { chatSelectors as patientChatSelector } from "../../../store/patients/chat";
import { chatSelectors as physicianChatSelector } from "../../../store/physician/chat";
import { chatSelectors as centerChatSelector } from "../../../store/centers/chat";
import { IChat } from "../../../store/types/chat";
import { IMessage } from "../../../store/types/message";
import { chatCalls as physicianChatCalls } from "../../../store/physician/chat";
import { chatCalls as centerChatCalls } from "../../../store/centers/chat";
import { physicianProfileCalls } from "../../../store/physician/profile";
import { centerProfileCalls, centerProfileSelectors } from "../../../store/centers/profile";
import { notesCalls, notesSelectors } from "../../../store/Notes";
import {
  NewMessageDTO,
  NewNoteDTO,
  NotePatchDTO,
  NotificationDTO,
  RetryMessageDTO,
} from "../../../store/types/type";
import { IPatient, USER_TYPE } from "../../../store/types";
import { basicCalls, basicSelectors } from "../../../store/centers/basicCalls";
import { ICenter } from "../../../store/types/center";
import { physicianProfileSelectors } from "../../../store/physician/profile";
import { IPhysician } from "../../../store/types/physician";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Note from "../../ChatDetails/Note";
import FilesList from "../../ChatDetails/FilesList";
import { INote } from "../../../store/types/note";
import CenterPhysicianConversation from "./CenterPhysicianConversation";
import { UploadDocumentComp } from "../../dashboardComponents/main/ProfileComponents/UploadDocument/UploadDocumentComp";
import CloseIcon from "@material-ui/icons/Close";
import {
  basicSelectors as basicSuperSelectors,
  basicCalls as basicSuperCalls,
} from "../../../store/supers/basicCalls";
import { Alert } from "@material-ui/lab";
import { notificationSMSCalls } from "../../../store/notification/sms";
import VideoCall from "../../chat/VideoCall";
import BookFullSession from "../../popup/BookFullSession";
import { feedCalls, feedSelectors } from "../../../store/feed";

const styles = (theme: Theme) => {
  createStyles({
    root: {
      flexGrow: 1,
    },
    contacts: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    conversation: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    compose: {},
  });
};
interface StateProps {
  physicianChats: IChat[];
  centerChats: IChat[];

  centersAndPhysicians: ICenter[];
  gettingCenters: boolean;
  getCentersError: string;
  physicianProfile: IPhysician;
  physicianProfileCalling: boolean;
  physicianProfileFailure: string;

  centerProfile: ICenter;
  centerProfileCalling: boolean;
  centerProfileFailure: string;

  gettingMyNotes: boolean;
  getMyNotesError: string;

  creatingMyNotes: boolean;
  createMyError: string;

  updatingMyNote: boolean;
  updateMyNoteError: string;
  myNotes: INote[];
  fetchPatients: IPatient[];

  // ErrorWhileGettingChats
  chatGetError: string;
}
interface DispatchProps {
  getPhysicianAllChats: () => Promise<void>;
  getCenterAllChats: () => Promise<void>;
  getAllCentersAndPhysicians: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
  getCenterProfile: () => Promise<void>;
  getPatients: () => Promise<void>;
  getMyNotes: () => Promise<void>;
  updateMyNote: (noteUpdateDTO: NotePatchDTO) => Promise<void>;
  createMyNote: (createNoteDTO: NewNoteDTO) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  handleDrawerOpen: () => Promise<void>;
}

interface OwnProps extends WithStyles {
  userType: USER_TYPE;
  group: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  messages: IMessage[];
  retryMessages: RetryMessageDTO[];
  currentChat?: IChat;
  socket: SocketIOClient.Socket;
  rooms: string;
  chatExpanded: boolean;
  currentNote?: INote;
  uploading: boolean;
  chatClicked: boolean;
  open: boolean;
  currentPhy?: string;
  patient: string;
  patientRequesting: boolean;
  pending: boolean;
  openFullSessionPopup: boolean;
  openVideoCallPopup: boolean;
  drawerOpen: boolean;
}
const URL = `${process.env.REACT_APP_API_URL}/chat`;
export class _PingComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      messages: [],
      retryMessages: [],
      // TODO
      // socket: io(URL),   // Production App Engine Flex (With Sockets Support)
      socket: io(URL, { transports: ["polling"] }), // Production App Engine Standard (NO Sockets Support)
      rooms: "",
      chatExpanded: true,
      uploading: false,
      chatClicked: false,
      open: false,
      currentPhy: this.props.physicianProfile?._id,
      patient: this.props.physicianProfile?.patients[0]?._id as string,
      patientRequesting: false,
      pending: false,
      openFullSessionPopup: false,
      openVideoCallPopup: false,
      drawerOpen: false,
    };
    this.state.socket.on("catch-room-message", (message: IMessage) => {
      this.setState({
        ...this.state,
        messages: [...this.state.messages, message],
      });
    });
    this.state.socket.on("connect", () => {
      console.log("connected");
      if (this.state.rooms) {
        this.state.socket.emit("leaveRoom", this.state.rooms);
        this.state.socket.emit("joinRoom", this.state.rooms);
      }
    });
    this.state.socket.on("disconnect", (reason: any) => {
      console.log("disconnected", reason);
    });
    this.state.socket.on("joinedRoom", (room: string) => {
      this.setState({
        ...this.state,
        currentPhy: this.state.currentChat?.physicians[0]?._id,
      });
      console.log("joined room ", room);
      this.setState({
        ...this.state, 
        rooms: room,
      });
    });
    this.state.socket.on("book-full-session-popup", (data: any) => {
      // const { patientId } = data;
      console.log(data, "dataaaaaaaa");
      // if (patientId === this.props.patientProfile._id) {
      //   this.setState({
      //     ...this.state,
      //     openFullSessionPopup: true,
      //   });
      // }
    });
    this.state.socket.on("video-call-popup", (data: any) => {
      // const { patientId } = data;
      // if (patientId === this.props.patientProfile._id) {
      //   this.setState({
      //     ...this.state,
      //     openVideoCallPopup: true,
      //   });
      // }
    });
  }
  componentWillUnmount() {
    if (this.state.rooms) this.state.socket.emit("leaveRoom", this.state.rooms);
    this.state.socket.disconnect();
  }
  async componentDidMount() {
    await this.getChats();
    await this.props.getMyNotes();
    console.log("chats", this.state.currentChat);
    if (this.state.currentChat) this.state.socket.emit("joinRoom", this.state.currentChat._id);
    await this.props.getPhysicianProfile();
  }

  async getChats() {
    if (this.props.userType === USER_TYPE.PHYSICIAN) {
      await this.props.getPatients();
      await this.props.getPhysicianAllChats();
      const initialChat = this.props.physicianChats.filter(
        (pc) => pc.isGroup === this.props.group && !pc.patient
      )[0];
      let initialNote: any = this.props.myNotes.filter(
        (note) => note.chat?._id === initialChat?._id
      );
      initialNote = initialNote.length ? initialNote[0] : undefined;
      this.setState({
        ...this.state,
        currentChat: initialChat,
        currentNote: initialNote,
      });
      await this.props.getPhysicianProfile();
    } else if (this.props.userType === USER_TYPE.CENTER) {
      await this.props.getCenterAllChats();
      const initialChat = this.props.centerChats.filter((pc) => pc.isGroup === this.props.group)[0];
      let initialNote: any = this.props.myNotes.filter(
        (note) => note.chat?._id === initialChat?._id
      );
      initialNote = initialNote.length ? initialNote[0] : undefined;
      this.setState({
        ...this.state,
        currentChat: initialChat,
        currentNote: initialNote,
      });
      await this.props.getCenterProfile();
    }
  }

  render() {
    const handleClose = () => {
      this.setState({
        ...this.state,
        open: false,
      });
    };
    const handleCloseFullSessionPopup = () => {
      this.setState({
        ...this.state,
        openFullSessionPopup: false,
      });
    };
    const handleCloseVideoCallPopup = () => {
      this.setState({
        ...this.state,
        openVideoCallPopup: false,
      });
    };
    const sendVideoCall = async () => {
      const { socket, currentChat } = this.state;
      if (this.state.currentChat) {
        this.state.socket.emit("video-call", {
          socketId: socket.id,
          chatID: currentChat?._id,
          patientId: currentChat?.patient?._id,
        });
      }
    };

    const sendBookFullSessions = () => {
      const { socket, currentChat } = this.state;
      if (this.state.currentChat) {
        this.state.socket.emit("book-full-session", {
          socketId: socket.id,
          chatId: currentChat?._id,
          patientId: currentChat?.patient?._id,
        } as any);
      }
    };
    const handleUploadDialog = () => {
      this.setState({
        ...this.state,
        open: true,
      });
    };
    const convClick = async (chat: IChat) => {
      const res = await axios.get<IMessage[]>(`/chat/chat-messages/${chat._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            this.props.userType === USER_TYPE.CENTER ? "centerToken" : "physicianToken"
          )}`,
        },
      });
      if (this.state.rooms) this.state.socket.emit("leaveRoom", this.state.rooms);
      this.state.socket.emit("joinRoom", chat._id);
      let note: INote[] | INote | undefined = this.props.myNotes.filter(
        (note) => note.chat?._id === chat?._id
      );
      note = note.length ? note[0] : undefined;
      this.setState({
        ...this.state,
        messages: res.data,
        chatClicked: true,
        currentChat: chat,
        currentNote: note,
      });
    };

    const getChatsAfterAdd = async (chat: IChat) => {
      await this.getChats();
      convClick(chat);
    };
    const sendMessage = async (text: string) => {
      if (!this.state.currentChat) return;
      console.log(`Adding a new message: ${text} to Chat with ID: ${this.state.currentChat._id}`);
      const newMessage: NewMessageDTO = {
        chatID: this.state.currentChat._id,
        text,
      };
      try {
        const res = await axios.post<IMessage>(`/messages/new-message`, newMessage, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              this.props.userType === USER_TYPE.CENTER ? "centerToken" : "physicianToken"
            )}`,
          },
        });
        this.state.socket.emit("send-message", {
          chatID: newMessage.chatID,
          message: res.data,
        });
        this.state.socket.emit("handlePingNotifications", {
          chatID: newMessage.chatID,
          message: res.data,
          currId: localStorage.getItem("_id"),
          current: localStorage.getItem("current"),
        });

        this.setState({
          ...this.state,
          messages: [...this.state.messages, res.data],
        });
        const us =
          this.props.userType === USER_TYPE.CENTER
            ? this.state.currentChat?.physicians[0]
            : this.props.userType === USER_TYPE.PHYSICIAN
            ? this.state.currentChat?.center
            : null;
        const cus: any =
          this.props.userType === USER_TYPE.CENTER
            ? this.state.currentChat?.center
            : this.props.userType === USER_TYPE.PHYSICIAN
            ? this.state.currentChat?.physicians[0]
            : null;
        if (us && cus) {
          const url = `https://app.deloresdigital.com/auth`;
          const messageSentText = `${
            "New Ping message on Delores\n\n" +
            `You have a new message from ${
              this.props.userType === USER_TYPE.CENTER ? cus.name : cus.firstName
            }. To see the message, login to `
          }${url}\n`;
          await this.props.sendSMS({ message: messageSentText, phone: us.phoneNumber });
        }
      } catch (err) {
        this.setState({
          ...this.state,
          retryMessages: [
            ...this.state.retryMessages,
            {
              //TODO profile fname
              fromCenter:
                this.props.userType === USER_TYPE.CENTER
                  ? { name: "a", _id: localStorage.getItem("_id") || "" }
                  : undefined,
              fromPhysician:
                this.props.userType === USER_TYPE.PHYSICIAN
                  ? { firstName: "s", _id: localStorage.getItem("_id") || "" }
                  : undefined,
              isPrivate: false,
              ...newMessage,
            },
          ],
        });
      }
    };

    const uploadChatFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!(e.currentTarget.files && e.currentTarget.files.length > 0)) return;
      const data = new FormData();
      data.append("fileField", e.currentTarget.files[0]);
      if (!this.state.currentChat) return;
      data.append("chatID", this.state.currentChat._id);
      this.setState({ ...this.state, uploading: true });
      try {
        const res = await axios.post("/files-manager/upload-chat", data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("patientToken") ||
              localStorage.getItem("physicianToken") ||
              localStorage.getItem("centerToken")
            }`,
          },
        });
        this.setState({ ...this.state, uploading: false });
        if (res.status === 201) {
          this.getChats();
        }
      } catch (error) {
        this.setState({ ...this.state, uploading: false });
      }
    };
    const uploadPatientProfile = async () => {
      await this.props.getPhysicianProfile();
      // console.log("Patient ID ", this.state.patient);
      // console.log("to", this.state.currentPhy);
      // console.log("from", this.props.physicianProfile._id);

      // this.setState({ ...this.state, patientRequesting: true });
      // debugger;
      if (this.state.patient && this.state.currentPhy !== this.props.physicianProfile._id) {
        try {
          await axios.patch<IPatient>(
            `/patient/patient-approval/${this.state.patient}`,
            {
              toPhysician: this.state.currentPhy,
              fromPhysician: this.props.physicianProfile._id,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
              },
            }
          );
          this.setState({ ...this.state, patientRequesting: true });
        } catch (err) {
          this.setState({ ...this.state, patientRequesting: false });
          // console.log("error", err);
        }
      }
    };

    return (
      <div className="chatBox" style={{ backgroundColor: "white" }}>
        {this.props.physicianChats?.filter((pc) => pc.isGroup === this.props.group && !pc.patient)
          .length ||
        this.props.centerChats?.filter((cc) => cc.isGroup === this.props.group).length ? (
          <Grid container style={{ backgroundColor: "white" }}>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={4}
              style={{
                borderRight: "2px solid #12d43b",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%", background: "white" }}>
                <Accordion
                  onChange={(e, expanded) =>
                    this.setState({ ...this.state, chatExpanded: expanded })
                  }
                  expanded={this.state.chatExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Chats</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="contactList"
                      style={{ minHeight: "fit-content", maxHeight: "48vh" }}
                    >
                      <ContactsComponent
                        userType={this.props.userType}
                        chats={
                          this.props.userType === USER_TYPE.CENTER
                            ? this.props.centerChats.filter((cc) => cc.isGroup === this.props.group)
                            : this.props.physicianChats.filter(
                                (pc) => pc.isGroup === this.props.group && !pc.patient
                              )
                        }
                        profile={
                          this.props.userType === USER_TYPE.CENTER
                            ? this.props.centerProfile
                            : this.props.physicianProfile
                        }
                        convClick={convClick}
                        currentChat={this.state.currentChat}
                        setPhysician={(id: string) =>
                          this.setState({ ...this.state, currentPhy: id })
                        }
                        currentPhy={this.props.physicianProfile?._id}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {this.state.chatClicked ? (
                  <Accordion
                    expanded={!this.state.chatExpanded}
                    onChange={(e, expanded) =>
                      this.setState({ ...this.state, chatExpanded: !expanded })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Chat Tools</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Note
                        note={this.state.currentNote}
                        saveNote={this.props.updateMyNote}
                        errorWhileSavingNote={this.props.updateMyNoteError}
                        savingNote={this.props.updatingMyNote}
                      />
                      <div
                        style={{
                          overflow: "scroll",
                          overflowX: "hidden",
                          overflowY: "auto",
                          maxHeight: "300px",
                        }}
                      >
                        {this.state.currentChat && this.state.currentChat.files.length > 0 ? (
                          <>
                            <h5 style={{ textAlign: "center" }}>Chat Files</h5>
                            <FilesList files={this.state.currentChat.files} />
                          </>
                        ) : (
                          ""
                        )}

                        <h5 style={{ textAlign: "center" }}>Provider Public Files</h5>
                        {this.state.currentChat &&
                          this.state.currentChat.physicians.map((file) => {
                            return <FilesList files={file.center.files} />;
                          })}
                        <h5 style={{ textAlign: "center" }}>Professional Public Files</h5>
                        {this.state.currentChat &&
                          this.state?.currentChat?.physicians?.map((phy) => {
                            return <FilesList files={phy.files} />;
                          })}
                        <h5 style={{ textAlign: "center" }}>User Public Files</h5>
                        {this.state.currentChat &&
                          this.props.physicianProfile?.patientsApproval?.map((pat) => {
                            return <FilesList files={pat.files} />;
                          })}
                      </div>
                      {this.props.physicianProfile?.patients ? (
                        <FormControl>
                          <InputLabel htmlFor="age-native-simple">Users</InputLabel>
                          <Select
                            style={{ minWidth: "350px" }}
                            native
                            onChange={(e) => {
                              this.setState({ ...this.state, patient: e.target.value as string });
                            }}
                            inputProps={{
                              name: "age",
                              id: "age-native-simple",
                            }}
                          >
                            {this.props.physicianProfile?.patients?.map((pt, i) => {
                              return (
                                <option key={i} value={pt._id}>
                                  {pt.firstName}
                                </option>
                              );
                            })}
                          </Select>
                        </FormControl>
                      ) : (
                        ""
                      )}

                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        disabled={this.state.patientRequesting}
                        style={{ margin: "1rem 0" }}
                        onClick={uploadPatientProfile}
                      >
                        {this.state.pending ? "Pending" : "Attach User Profile"}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        disabled={this.state.uploading}
                      >
                        Upload File
                        <input type="file" hidden onChange={uploadChatFile} accept=".pdf,.dcm" />
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        disabled={this.state.uploading}
                        onClick={handleUploadDialog}
                      >
                        Add From Profile
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}
              </div>
              {(this.props.userType === "PHYSICIAN" || this.props.userType === "CENTER") &&
                this.state.chatClicked && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                      background: "white",
                      paddingBottom: "10px",
                      paddingTop: "10px",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px", marginRight: "10px" }}
                      onClick={() => {
                        this.setState({
                          ...this.state,
                          openVideoCallPopup: true,
                        });
                      }}
                    >
                      Request Video Call
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        this.setState({ ...this.state, openFullSessionPopup: true });
                      }}
                    >
                      Book A Full Session
                    </Button>
                  </div>
                )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={8}
              style={{ background: "antiquewhite", alignItems: "center" }}
            >
              <div className="conversationList">
                {this.state.chatClicked ? (
                  <>
                    <CenterPhysicianConversation
                      sendSMS={this.props.sendSMS}
                      messages={this.state.messages}
                      retryMessages={this.state.retryMessages}
                      retrySending={(text: string, messageIndex: number) => {
                        const tempArr = [...this.state.retryMessages];
                        tempArr.splice(messageIndex, 1);
                        this.setState({
                          ...this.state,
                          retryMessages: tempArr,
                        });
                        sendMessage(text);
                      }}
                      userType={this.props.userType}
                      currentChat={this.state.currentChat}
                      getAllCentersAndPhysicians={this.props.getAllCentersAndPhysicians}
                      centersAndPhysicians={this.props.centersAndPhysicians}
                      getCentersError={this.props.getCentersError}
                      gettingCenters={this.props.gettingCenters}
                      getChatsAfterAdd={getChatsAfterAdd}
                      physicianProfile={this.props.physicianProfile}
                      physicianProfileCalling={this.props.physicianProfileCalling}
                      physicianProfileFailure={this.props.physicianProfileFailure}
                      centerProfile={this.props.centerProfile}
                      centerProfileCalling={this.props.centerProfileCalling}
                      centerProfileFailure={this.props.centerProfileFailure}
                    />
                    <ComposeComponent sendMessage={sendMessage} />
                  </>
                ) : (
                  <div className="parent">
                    <div className="child">Select a chat from the chat menu on the left</div>
                  </div>
                )}
                {window.innerWidth < 768 && !this.state.chatClicked && (
                  <div>
                    <ComposeComponent sendMessage={sendMessage} />
                  </div>
                )}
              </div>
            </Grid>
            <Dialog fullScreen open={this.state.open} onClose={handleClose}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              <div>
                <UploadDocumentComp
                  getCenterProfile={this.props.getCenterProfile}
                  getPhysicianProfile={this.props.getPhysicianProfile}
                  centerProfile={this.props.centerProfile}
                  physicianProfile={this.props.physicianProfile}
                  userType={this.props.userType}
                  classes={"" as any}
                  getPatientProfile={async () => {}}
                />
              </div>
            </Dialog>
            <Dialog open={this.state.openFullSessionPopup} onClose={handleCloseFullSessionPopup}>
              <div>
                <BookFullSession
                  socket={this.state.socket}
                  currentChat={this.state.currentChat}
                  sendBookFullSessions={sendBookFullSessions}
                  handleCloseFullSessionPopup={handleCloseFullSessionPopup}
                  sendSMS={this.props.sendSMS}
                />
              </div>
            </Dialog>
            <Dialog
              open={this.state.openVideoCallPopup}
              disableBackdropClick={true}
              maxWidth={false}
            >
              <div>
                <VideoCall
                  sendSMS={this.props.sendSMS}
                  socketId={this.state.socket}
                  currentChat={this.state.currentChat}
                  sendVideoCall={sendVideoCall}
                  handleCloseVideoCallPopup={handleCloseVideoCallPopup}
                  handleDrawerOpen={this.props.handleDrawerOpen}
                  drawerOpen={true}
                />
              </div>
            </Dialog>
          </Grid>
        ) : (
          <Alert severity="info">
            You dont have any {this.props.group ? "group discussions" : "chats"} at the moment
          </Alert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  physicianChats: physicianChatSelector.physicianChats(state.physicians.chat),
  centersAndPhysicians: basicSelectors.fetchCenters(state.centers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  physicianProfileCalling: physicianProfileSelectors.isCallingProfile(
    state.physicians.physicianProfile
  ),
  physicianProfileFailure: physicianProfileSelectors.isProfileFailure(
    state.physicians.physicianProfile
  ),

  // centerProfile
  centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
  centerProfileCalling: centerProfileSelectors.isCallingProfile(state.centers.centerProfile),
  centerProfileFailure: centerProfileSelectors.isProfileFailure(state.centers.centerProfile),
  // getCenter'sAllChat
  centerChats: centerChatSelector.centerChats(state.centers.chat),
  // ErrorWhileGettingChats
  chatGetError:
    localStorage.getItem("current") === USER_TYPE.PATIENT
      ? patientChatSelector.getAllChatsFailure(state.patients.chat)
      : localStorage.getItem("current") === USER_TYPE.PHYSICIAN
      ? physicianChatSelector.getAllChatsFailure(state.physicians.chat)
      : centerChatSelector.getAllChatsFailure(state.centers.chat),
  // getAllNotes
  gettingMyNotes: notesSelectors.isGettingMyNotes(state.notes),
  getMyNotesError: notesSelectors.errorWhileGettingMyNotes(state.notes),
  //createANote
  creatingMyNotes: notesSelectors.isCreatingNote(state.notes),
  createMyError: notesSelectors.errorWhileCreatingNote(state.notes),
  //updateExistingNote
  updatingMyNote: notesSelectors.isUpdatingNote(state.notes),
  updateMyNoteError: notesSelectors.errorWhileUpdatingNote(state.notes),
  //myNotes
  myNotes: notesSelectors.myNotes(state.notes),

  fetchPatients: basicSuperSelectors.fetchPatients(state.supers.basicCalls),
  drawerOpen: feedSelectors.drawerOpen(state.feeds),
});
const mapDispatchToProps = (dispatch: any) => ({
  getPhysicianAllChats: () => dispatch(physicianChatCalls.getAllChats()),
  getCenterAllChats: () => dispatch(centerChatCalls.getAllChats()),
  getAllCentersAndPhysicians: () => dispatch(basicCalls.getAllCenters()),
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  getPatients: () => dispatch(basicSuperCalls.getPatients()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),

  getMyNotes: () => dispatch(notesCalls.getMyNotes()),
  updateMyNote: (noteUpdateDTO: NotePatchDTO) => dispatch(notesCalls.updateNote(noteUpdateDTO)),
  createMyNote: (createNoteDTO: NewNoteDTO) => dispatch(notesCalls.createNote(createNoteDTO)),
  handleDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});
export const PingComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(_PingComponent));
