import React from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Avatar, Divider, Typography } from "@material-ui/core";
import dummy from "../../../staticResources/avatar/2.jpg";
import { IPatient } from "../../../store/types/patient";

function getModalStyle() {
  const top = 45;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    background: "#33c156",
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      //   width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  })
);
interface Props {
  show: boolean;
  handleShowPatientModal: (val: boolean, Patient?: IPatient) => void;
  patient?: IPatient;
}
export default function PatientDisplayModal(props: Props) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <Avatar
        style={{
          margin: "10px auto",
          width: "95px",
          height: "95px",
          boxShadow:
            "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        }}
        alt="Remy Sharp"
        src={dummy}
      />
      <Divider style={{ margin: "10px 0px" }} />
      <Typography style={{ margin: "10px 0px" }}>
        Name: {`${props.patient?.firstName} ${props.patient?.lastName}`}
      </Typography>
      <Typography style={{ margin: "10px 0px" }}>Email: {`${props.patient?.email}`}</Typography>
      {/* <Typography style={{margin:'10px 0px'}}>
                Practice: {`${props.Patient?.physicians}`}
            </Typography> */}
    </div>
  );

  return (
    <div>
      <Modal open={props.show} onClose={() => props.handleShowPatientModal(false, undefined)}>
        {body}
      </Modal>
    </div>
  );
}
