import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button, CircularProgress, Container, Grid, InputAdornment } from "@material-ui/core";
import { AdminSignUpDTO } from "../../store/types/type";
import { useStyles } from "./muiStyling";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../base/ColorPalete";
import { Alert } from "@material-ui/lab";
import { IAdmin } from "../../store/types";

interface StateProps {
  registeringAdmin: boolean;
  adminRegistered: boolean;
  errorWhileAdminRegister: string;
  whenRegisterSuccessAdmin: string;
}

interface DispatchProps {
  adminSignUp: (adminLogin: AdminSignUpDTO) => Promise<IAdmin>;
  changeType: (type: string) => void;
}

type Props = StateProps & DispatchProps;
export default function AdminSignupCard(props: Props) {
  const [adminData, setAdminData] = React.useState<AdminSignUpDTO>({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const [errorState, setErrorState] = React.useState({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const handleSubmit = (event: any) => {
    if (isValidated(event)) {
      const phone = "+1" + adminData.phoneNumber;

      props.adminSignUp({ ...adminData, phoneNumber: phone }).then(() => {
        props.changeType("login");
      });
      // setDisableButton(true);
      window.open("https://www.deloresdigital.com/how-to-join.html", "_blank");
    }
  };
  const isValidated = (e: any) => {
    e.preventDefault();
    // let input = inputState;
    let errors = {
      name: "",
      password: "",
      email: "",
      phoneNumber: "",
      address: "",
    };
    errors.name = adminData.name ? "" : "Please Enter Name";
    errors.password = adminData.password ? "" : "Please Enter Password";
    errors.address = adminData.address ? "" : "Please Enter your address";
    errors.email = adminData.email ? "" : "Please Enter Email";
    errors.phoneNumber = adminData.phoneNumber ? "" : "Please Enter Phone Number";

    if (adminData.email && adminData.email !== "") {
      const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/);
      if (pattern.test(adminData.email) === false) {
        errors.email = "Please Enter Valid Email";
      }
    }
    if (adminData.phoneNumber && adminData.phoneNumber !== "") {
      const phone = "+1" + adminData.phoneNumber;

      const pattern = new RegExp(/^\+[1-9]\d{10,14}$/);
      if (!pattern.test(phone)) {
        errors.phoneNumber = "Please enter a valid phone number start with +1**********";
      }
    }
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const classes = useStyles();
  return props.registeringAdmin ? (
    <CircularProgress />
  ) : (
    <Container className={classes.container} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email Address"
                  type="string"
                  name="email"
                  onChange={(e) =>
                    setAdminData({ ...adminData, email: e.target.value.toLocaleLowerCase() })
                  }
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                />
              </Grid>

              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                  placeholder={`Start number without "+1" `}
                  type="string"
                  name="phone"
                  onChange={(e) => setAdminData({ ...adminData, phoneNumber: e.target.value })}
                  error={errorState["phoneNumber"] ? true : false}
                  helperText="Phone Number to receive notifications"
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Address"
                  type="string"
                  name="address"
                  onChange={(e) => setAdminData({ ...adminData, address: e.target.value })}
                  error={errorState["address"] ? true : false}
                  helperText={errorState["address"]}
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Admin Name"
                  type="string"
                  name="name"
                  onChange={(e) => setAdminData({ ...adminData, name: e.target.value })}
                  error={errorState["name"] ? true : false}
                  helperText={errorState["name"]}
                />
              </Grid>
              <Grid item xs={10} lg={12} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  onChange={(e) => setAdminData({ ...adminData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              <Grid item xs={6} sm={6} md={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <ThemeProvider theme={theme}>
                  <Button
                    onClick={handleSubmit}
                    color="secondary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    // disabled={disableButton}
                  >
                    Apply Now
                  </Button>
                </ThemeProvider>
              </Grid>
              <Grid item xs={10}>
                {props.errorWhileAdminRegister && (
                  <Alert severity="error">{props.errorWhileAdminRegister}</Alert>
                )}
                {props.whenRegisterSuccessAdmin && (
                  <Alert severity="success">{props.whenRegisterSuccessAdmin}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
