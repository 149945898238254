/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Avatar, Box, Chip, Container, Grid, Paper, Typography } from "@material-ui/core";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import EditIcon from "@material-ui/icons/Edit";
import { connect } from "react-redux";
import { CenterUpdateDTO, PhysicianUpdateDTO } from "../store/types/type";
import { centerProfileCalls, centerProfileSelectors } from "../store/centers/profile";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import PhoneIcon from "@material-ui/icons/Phone";
import AttachMoney from "@material-ui/icons/AttachMoney"
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DialogTitle from "@material-ui/core/DialogTitle";
import { ICenter, IPhysician } from "../store/types";
import { physicianProfileCalls, physicianProfileSelectors } from "../store/physician/profile";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import { Autocomplete } from "@material-ui/lab";
import { specialties, useStateList } from "../store/types/states";
import { UpdateRatePopup, holdrating } from "./popup/UpdateRatePopup";


interface DispatchProps {
  // getCenterProfile: () => Promise<any>;
  getCenterProfile: () => Promise<any>;
  getPhysicianProfile: () => Promise<void>;
  updateCenterProfile: (updateCenterData: CenterUpdateDTO) => Promise<any>;
  updatePhysicainProfile: (updatePhysicianData: PhysicianUpdateDTO) => Promise<any>;
}
interface OwnProps {
  read?: boolean;
  centerProfile?: ICenter;
  physicianProfile?: IPhysician;
  realrates?:IPhysician["rates"];
}
type Props = OwnProps & DispatchProps;

const _UserCard = (props: Props) => {
  const [open, setOpen] = useState(false);
  const useRate = Object.freeze(holdrating)
  const [formState, setFormState] = useState<CenterUpdateDTO>({
    name: "",
    npi: "",
    address: "",
    bio: "",
    specialties: [],
    state: "",
    rates: "",
    publicNumber: "",
  });
  const [fee,setFee] = useState("$0");

/*
  const handleChange  = async (e: any) => {
    setFee(e.target.value);
    localStorage.setItem("TextFieldValue", e.target.value);
  }; */


  const [npiError, setNpiError] = useState("");

  let newrate = '';

  let prayrate = "";

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getData = async () => {
    if (!props.centerProfile) {
      await props.getCenterProfile();
    }
    if (!props.physicianProfile) {
      await props.getPhysicianProfile();
    }
  };

  const { centerProfile } = props;
  const { physicianProfile } = props;
  const [prate, setprate] = useState('');


const handleSend = (e: any) => {
  setFormState({})
};



  const handleSubmit = async (e: any) => {
    e.preventDefault();
  //  let docRate = (formState.rates);
    console.log(String(formState.rates));
    const newrate = String(formState.rates);
    console.log(String(physicianProfile?.rates));
    console.log(String(physicianProfile?.firstName));
    console.log(String(physicianProfile?.bio));
    console.log(String(newrate));


  //  realrates = String(newrate);


    if (centerProfile) {
      if (formState.npi ? formState.npi.length < 6 : null) {
        setNpiError("npi must be greater than 5 digits");
        return;
      }
      await props.updateCenterProfile(formState).then(() => setOpen(false));
    } else if (physicianProfile) {
      const obj: PhysicianUpdateDTO = {
        firstName: formState.name,
        practiceAddress: formState.address,
        bio: formState.bio,
        rates: newrate,
        specialties: formState.specialties,
        publicNumber: formState.publicNumber,
        state: formState.state,
      };
      await props.updatePhysicainProfile(obj).then(() => setOpen(false));
  //    console.log(updateProfile);
      await props.getPhysicianProfile();
    /*  setFormState2({
        firstname: {formState.name},
        practiceAddress: {formState.address},
        bio: {formState.bio},
        rates: {formState.rates},
      }); */

    }
  };


  useEffect(() => {
    getData();
    //setFee(localStorage.getItem("inputValue"));
  }, [open, npiError]);

  return (
    <>
      <Container maxWidth="md" style={{ marginTop: "5rem" }}>
        <Paper style={{ padding: "16px", margin: "auto" }}>
          <Grid container spacing={2}>
            <Grid item>
              {physicianProfile ? (
                <Avatar
                  alt={physicianProfile?.firstName || physicianProfile?.firstName}
                  src={
                    physicianProfile?.files?.filter((f: any) => f.isProfile)[0]?.url ||
                    `https://via.placeholder.com/150?text=Profile`
                  }
                  style={{ width: 150, height: 150, alignSelf: "center" }}
                />
              ) : (
                <Avatar
                  alt={centerProfile?.name || centerProfile?.name}
                  src={
                    centerProfile?.files?.filter((f: any) => f.isProfile)[0]?.url ||
                    `https://via.placeholder.com/150?text=Profile`
                  }
                  style={{ width: 150, height: 150, alignSelf: "center" }}
                />
              )}
            </Grid>
            <Grid item xs={12} sm container>
              <Grid item xs container direction="column" spacing={2}>
                <Grid item xs>
                  <Typography gutterBottom variant="subtitle1" component="div">
                    {centerProfile?.name || physicianProfile?.firstName}
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    {centerProfile ? (
                      <>
                        <AccountCircleIcon />

                        <Typography
                          style={{ marginLeft: "10px" }}
                          component="div"
                          variant="subtitle2"
                        >
                          NPI: {centerProfile?.npi ? centerProfile?.npi : "no npi"}
                        </Typography>
                      </>
                    ) : null}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <HomeIcon />
                    <Typography style={{ marginLeft: "10px" }} component="div" variant="subtitle2">
                      Address:
                      {centerProfile?.address || physicianProfile?.practiceAddress}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                    <AccountCircleIcon />
                    <Typography style={{ marginLeft: "10px" }} component="div" variant="subtitle2">
                      Bio:{" "}
                      {centerProfile?.bio
                        ? centerProfile?.bio
                        : "no bio" || physicianProfile?.bio
                        ? physicianProfile?.bio
                        : "no bio"}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >
                </Box>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "8px",
                  }}
                >
                    <PhoneIcon />
                    {centerProfile ? (
                      <Typography
                        style={{ marginLeft: "10px" }}
                        component="div"
                        variant="subtitle2"
                      >
                        Mobile Number: {centerProfile?.publicNumber}
                      </Typography>
                    ) : (
                      <Typography
                        style={{ marginLeft: "10px" }}
                        component="div"
                        variant="subtitle2"
                      >
                        Mobile Number: {physicianProfile?.publicNumber}
                      </Typography>
                    )}
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "8px",
                    }}
                  >

                    <PersonPinCircleIcon />
                    State : {centerProfile?.state || physicianProfile?.state}
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <LocalOfferIcon />
                    Specialties:
                    {centerProfile?.specialties?.map((val, index) => {
                      return (
                        <Chip label={val} key={index} style={{ margin: "10px 0 10px 10px" }} />
                      );
                    }) ||
                      physicianProfile?.specialties?.map((val, index) => {
                        return (
                          <Chip label={val} key={index} style={{ margin: "10px 0 10px 10px" }} />
                        );
                      })}
                  </Box>
                </Grid>
              </Grid>
              <Grid item>
                <EditIcon style={{ cursor: "pointer" }} onClick={handleClickOpen} />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Container>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Update Profile</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              onChange={(e) => setFormState({ ...formState, name: e.target.value })}
              autoFocus
              margin="dense"
              label="Your Name"
              type="text"
              fullWidth
            />
            {centerProfile ? (
              <TextField
                onChange={(e) => setFormState({ ...formState, npi: e.target.value })}
                autoFocus
                error={formState.npi ? (formState.npi.length < 6 ? true : false) : false}
                helperText={formState.npi ? (formState.npi.length < 6 ? npiError : false) : false}
                margin="dense"
                label="NPI"
                type="number"
                fullWidth
              />
            ) : null}
            <TextField
              onChange={(e) => setFormState({ ...formState, address: e.target.value })}
              autoFocus
              margin="dense"
              label="Address"
              type="textarea"
              fullWidth
            />
            <TextField
              onChange={(e) => setFormState({ ...formState, bio: e.target.value })}
              autoFocus
              margin="dense"
              label="Bio"
              type="textarea"
              fullWidth
            />
            <TextField
              onChange={(e) => setFormState({ ...formState, publicNumber: e.target.value })}
              autoFocus
              margin="dense"
              label="Mobile Number"
              type="text"
              fullWidth
              helperText="include '+1' with phone number"
            />
          
            <Autocomplete
              id="tags-outlined"
              size="small"
              options={useStateList}
              getOptionLabel={(option: any) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="States"
                  fullWidth
                  placeholder="States"
                />
              )}
              onChange={(e, value) => {
                setFormState({ ...formState, state: value });
              }}
            />
            <Autocomplete
              multiple
              id="size-small-standard-multi"
              size="small"
              options={specialties}
              getOptionLabel={(option: any) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Specialties &
                Demographic"
                  placeholder="Specialties &
                Demographic "
                />
              )}
              onChange={(e, newValue) => {
                setFormState({ ...formState, specialties: newValue as any });
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
});

const mapDispatchToProps = (dispatch: any) => ({
  getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  updateCenterProfile: (centerUpdateDTO: CenterUpdateDTO) =>
    dispatch(centerProfileCalls.updateCenterProfile(centerUpdateDTO)),
  updatePhysicainProfile: (physicainUpdateDTO: PhysicianUpdateDTO) =>
    dispatch(physicianProfileCalls.updatePhysicianProfile(physicainUpdateDTO)),
});
export const UserCard = connect(mapStateToProps, mapDispatchToProps)(_UserCard);
