import { Tooltip } from "@material-ui/core";
import React from "react";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { IFileData } from "../../store/types/file-data";
import FileView from "./FileView";

interface ListProps {
  files?: IFileData[];
}

export default function FilesList(props: ListProps) {
  return (
    <div>
      {props.files &&
        props.files?.map((file, i) => {
          return (
            <>
              {file.isPublic && (
                <div key={i} style={{ display: "flex", justifyContent: "space-around" }}>
                  <Tooltip
                    title={
                      file.patient?.firstName ||
                      file.physician?.firstName ||
                      file.center?.name ||
                      "User Not Found"
                    }
                    placement="right"
                    key={i}
                  >
                    <InfoOutlinedIcon />
                  </Tooltip>

                  <FileView file={file} />
                  <br />
                </div>
              )}
            </>
          );
        })}
    </div>
  );
}
