import { Button, Dialog, DialogTitle, FormControl, InputLabel, Select } from "@material-ui/core";
import React from "react";
import axios from "axios";
import { IPhysician } from "../../store/types/physician";
import { IChat } from "../../store/types/chat";
// import { useHistory } from "react-router-dom";
import CircleSpinner from "../CircleSpinner";
import { NotificationDTO } from "../../store/types/type";

interface Props {
  open: boolean;
  handleClose: () => void;
  physicians: IPhysician[];
  chatID: string;
  gettingCenters: boolean;
  getCentersError: string;
  getChatsAfterAdd: (chat: IChat) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  userType: string;
  currentChat: any;
}

export default function AddPhysicianChatModal(props: Props) {
  const [addingPhysician, setaddingPhysician] = React.useState(false);
  const [addingPhysicianError, setaddingPhysicianError] = React.useState("");
  const [phySelected, setPhySelected] = React.useState("");
  React.useEffect(() => {
    if (props.physicians?.length) setPhySelected(props.physicians[0]._id);
  }, [props.physicians]);
  const handlePhySelected = (e: any) => {
    setPhySelected(e.target.value);
  };
  const createChat = async () => {
    if (!phySelected) return;

    setaddingPhysician(true);
    setaddingPhysicianError("");
    try {
      const res = await axios.patch<IChat>(
        `/chat/add-physician/${props.chatID}`,
        { physicianID: phySelected },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(`${props.userType.toLowerCase()}Token`)}`,
          },
        }
      );
      const phy = props.physicians.filter((phy) => phy._id === phySelected);

      // Add Professionals
      if (phy) {
        const url = `https://app.deloresdigital.com/auth`;
        const messageSentText = `${
          "You’ve been added to a group discussion.\n\n" +
          "A professional has added you to a group discussion. To see the group chat, log in to "
        }${url}\n`;
        await props.sendSMS({ message: messageSentText, phone: phy[0].phoneNumber });
      }
      props.getChatsAfterAdd(res.data);
      // const us =
      //   props.userType === USER_TYPE.CENTER
      //     ? props.currentChat?.physicians[0]
      //     : props.userType === USER_TYPE.PHYSICIAN
      //     ? props.currentChat?.center
      //     : null;
      // console.log(res.data);
      // history.push("physician/portal/messages");
      // if sucess & addingphysician false
      //   else if error show error here in dialog box using error state   & addingphysician false
    } catch (err: any) {
      setaddingPhysicianError(err.response?.data?.message || err.message);
    } finally {
      props.handleClose();
      setaddingPhysician(false);
    }
  };
  return (
    <div>
      <Dialog onClose={props.handleClose} aria-labelledby="simple-dialog-title" open={props.open}>
        <DialogTitle id="simple-dialog-title">Add Professionals</DialogTitle>
        {addingPhysician || props.gettingCenters ? (
          <CircleSpinner />
        ) : props.physicians?.length ? (
          <FormControl>
            <InputLabel htmlFor="age-native-simple">Professionals</InputLabel>
            <Select
              style={{ minWidth: "350px" }}
              fullWidth
              native
              onChange={handlePhySelected}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
            >
              {props.physicians.map((phy: any, i: number) => {
                return (
                  <option key={phy._id} value={phy._id}>
                    {`${phy.firstName} ${phy.lastName}`}
                  </option>
                );
              })}
            </Select>
            <Button
              variant="contained"
              color="primary"
              onClick={createChat}
              disabled={!Boolean(phySelected)}
            >
              Create new Chat
            </Button>
          </FormControl>
        ) : props.gettingCenters ? (
          `${props.getCentersError}`
        ) : (
          "No Professional found"
        )}
        <p>{addingPhysicianError}</p>
      </Dialog>
    </div>
  );
}
