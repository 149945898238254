import * as types from "./types";

const initialState = {
  login: false,
  loggingIn: false,
  loginError: "",
  authToken: "",
  registering: false,
  registerError: "",
  signedUp: false,
  verifyingToken: false,
  success: "",
};

export const authReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.loggingIn:
      return {
        ...state,
        loggingIn: true,
        loginError: "",
        authToken: "",
      };
    case types.loginFailure:
      return {
        ...state,
        loggingIn: false,
        loginError: action.payload,
      };
    case types.loginSuccess:
      return {
        ...state,
        loggingIn: false,
        authToken: action.payload,
      };
    case types.loggedOut:
      return {
        ...state,
        loggingIn: false,
        authToken: "",
      };
    case types.registerIn:
      return {
        ...state,
        registering: true,
        registerError: "",
        authToken: "",
      };
    case types.registerFailure:
      return {
        ...state,
        registering: false,
        registerError: action.payload,
      };
    case types.registerSuccess:
      return {
        ...state,
        registering: false,
        signedUp: true,
        success: action.payload,
      };
    case types.verifyingToken:
      return {
        ...state,
        verifyingToken: true,
        loginError: "",
        authToken: "",
      };
    case types.verifyTokenSuccess:
      return {
        ...state,
        verifyingToken: false,
        authToken: action.payload,
      };
    case types.verifyTokenFailure:
      return {
        ...state,
        verifyingToken: false,
        loginError: action.payload,
      };
    default: {
      return state;
    }
  }
};
