import { connect } from "react-redux";
import React from "react";
import { useHistory } from "react-router-dom";
import { basicSelectors, basicCalls } from "../../../store/supers/basicCalls";
import { IAdmin, ICenter } from "../../../store/types";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";
interface OwnProps {}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentesrError: string;
}
type Props = OwnProps & StateProps & DispatchProps;
function AssignCenters(props: Props) {
  const history = useHistory();
  const handleClickA = () => {
    props.getCenters();
    props.getAdmins();
    history.push(`/super/assignCenters/Admins`);
  };
  const handleClickB = () => {
    props.getCenters();
    props.getAdmins();
    history.push(`/super/assignCenters/center`);
  };

  return (
    <div style={{ textAlign: "center", marginTop: "2em" }}>
      <CustomButtonGroup
        buttonType="horizontal"
        values={["Provider", "Admins"]}
        handleClicks={[handleClickB, handleClickA]}
        style={{ textAlign: "center", marginTop: "3%" }}
      />
    </div>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  getCenters: () => dispatch(basicCalls.getCenters()),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),

  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(AssignCenters);
