import { Box, Button, Card, Paper, Snackbar, Typography } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import Axios from "axios";
import React from "react";
import { ICenter } from "../../../../../store/types/center";
import { USER_TYPE } from "../../../../../store/types";
import { IPhysician } from "../../../../../store/types/physician";

interface Props {
  sender: IPhysician;
  physician?: IPhysician;
  center?: ICenter;
  requestID: string;
  type: string;
  message: string;
  getProfile: () => Promise<void>;
}
export default function SinglePingRequest(props: Props) {
  const acceptRequest = async () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const res = await Axios.post(
        `/ping-request/accept-request`,
        {
          pingRequestID: props.requestID,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(`${props.type.toLowerCase()}Token`)}`,
          },
        }
      );
      await props.getProfile();
      setErrorAcceptingorDone("Done");
    } catch (err: any) {
      await props.getProfile();
      setErrorAcceptingorDone(err.message || "An unexpected error occurred");
    }
  };
  const [errorAcceptingOrDone, setErrorAcceptingorDone] = React.useState("");
  const [raised, setRaised] = React.useState(false);
  return (
    <Card
      raised={raised}
      onMouseOver={() => setRaised(true)}
      onMouseLeave={() => setRaised(false)}
      style={{ textAlign: "center", border: "3px solid orange" }}
    >
      <Paper variant="outlined" elevation={2}>
        <Box flexDirection="column">
          <h4>
            Name:
            <span> {`${props.sender?.firstName} ${props.sender?.lastName}`}</span>
          </h4>
          <h4>
            Email:<span> {`${props.sender?.email}`}</span>
          </h4>
        </Box>
        <Box flexDirection="column">
          <h4>
            Name:
            <span> {`${props.center?.name || props.physician?.firstName}`}</span>
          </h4>
          <h4>
            {props.center ? "NPI" : "Email"}:
            <span> {`${props.center?.npi || props.physician?.email}`}</span>
          </h4>
        </Box>
      </Paper>
      <Typography>{props.message}</Typography>

      <Button onClick={acceptRequest}>
        {props.type === USER_TYPE.CENTER ? "Allow" : "Accept"}
      </Button>
      <Snackbar
        open={errorAcceptingOrDone !== "" && errorAcceptingOrDone !== "Done"}
        autoHideDuration={3000}
        onClose={(e, r) => {
          if (r === "timeout") setErrorAcceptingorDone("");
        }}
      >
        <Alert
          severity={
            errorAcceptingOrDone !== "" && errorAcceptingOrDone !== "Done" ? "error" : "success"
          }
        >
          {errorAcceptingOrDone !== "" && errorAcceptingOrDone !== "Done"
            ? errorAcceptingOrDone
            : "Accepted Request"}
        </Alert>
      </Snackbar>
    </Card>
  );
}
