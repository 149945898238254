import { IAdmin, ICenter, IPhysician, IPatient, IFeed } from "../../types";
import * as types from "./types";

export const fetchAdmins = () => ({
  type: types.fetchAdmins,
});
export const getAllAdminsSuccess = (admins: IAdmin[]) => ({
  type: types.gettingAdmins,
  payload: admins,
});

export const getAllAdminsFailure = (errorMessage: string) => ({
  type: types.getAdminsError,
  payload: errorMessage,
});

export const fetchCenters = () => ({
  type: types.fetchCenters,
});
export const getAllCentersSuccess = (centers: ICenter[]) => ({
  type: types.gettingCenters,
  payload: centers,
});

export const getAllCentersFailure = (errorMessage: string) => ({
  type: types.getCentersError,
  payload: errorMessage,
});

export const fetchPhysicians = () => ({
  type: types.fetchPhysicians,
});
export const getAllPhysiciansSuccess = (physicians: IPhysician[]) => ({
  type: types.gettingPhysicians,
  payload: physicians,
});

export const getAllPhysiciansFailure = (errorMessage: string) => ({
  type: types.getPhysiciansError,
  payload: errorMessage,
});

export const fetchPatients = () => ({
  type: types.fetchPatients,
});
export const getAllPatientsSuccess = (patients: IPatient[]) => ({
  type: types.gettingPatients,
  payload: patients,
});

export const getAllPatientsFailure = (errorMessage: string) => ({
  type: types.getPatientsError,
  payload: errorMessage,
});

export const updateAdminRequest = () => ({
  type: types.updateAdminRequest,
});
export const updateAdminRequestSuccess = (updateProfile: IAdmin) => ({
  type: types.updateAdminRequestSuccess,
  payload: updateProfile,
});
export const updateAdminRequestFailure = (errorMessage: string) => ({
  type: types.updateAdminRequestFailure,
  payload: errorMessage,
});

export const updatedAccount = () => ({
  type: types.updateAccount,
});
export const updateAccountSuccess = (userAccount: any) => ({
  type: types.updatingAccount,
  payload: userAccount,
});

export const updateAccountFailure = (errorMessage: string) => ({
  type: types.updateAccountError,
  payload: errorMessage,
});

export const assignCenterRequest = () => ({
  type: types.assignCenterRequest,
});
export const assignCenterRequestSuccess = (center: ICenter) => ({
  type: types.assignCenterRequestSuccess,
  payload: center,
});
export const assignCenterRequestFailure = (errorMessage: string) => ({
  type: types.assignCenterRequestFailure,
  payload: errorMessage,
});

export const removeCenterFromAdminRequest = () => ({
  type: types.removeCenterFromAdminRequest,
});

export const removeCenterFromAdminRequestSuccess = (admin: IAdmin[]) => ({
  type: types.removeCenterFromAdminRequestSuccess,
  payload: admin,
});
export const removeCenterFromAdminRequestFailure = (errorMessage: string) => ({
  type: types.removeCenterFromAdminRequestFailure,
  payload: errorMessage,
});

export const removeCenterFeed = () => ({
  type: types.removeCenterFeed,
});

export const removeCenterFeedSuccess = (centerFeed: any) => ({
  type: types.removeCenterFeedSuccess,
  payload: centerFeed,
});

export const removeCenterFeedFailure = (errorMessage: string) => ({
  type: types.removeCenterFeedFailure,
  payload: errorMessage,
});

export const getAllCenterFeeds = () => ({
  type: types.getAllCenterFeeds,
});

export const getAllCenterFeedsSuccess = (getAllCenterFeedsSuccess: IFeed[]) => ({
  type: types.getAllCenterFeedsSuccess,
  payload: getAllCenterFeedsSuccess,
});

export const getAllCenterFeedsFailure = (errorMessage: string) => ({
  type: types.getAllCenterFeedsFailure,
  payload: errorMessage,
});

export const fetchScheduleTime = () => ({
  type: types.fetchScheduleTime,
});

export const getAllScheduleTimeSuccess = (centers: ICenter[]) => ({
  type: types.gettingScheduleTime,
  payload: centers,
});

export const getScheduleTimeFailure = (errorMessage: string) => ({
  type: types.getScheduleTimeError,
  payload: errorMessage,
});
