import React from "react";
import { Button, FormGroup, Grid, Switch, FormControlLabel, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { Folder } from "@material-ui/icons";

import {
  ICenter,
  IFolder,
  IFileData,
  IPatient,
  IPhysician,
  USER_TYPE,
} from "../../../../../store/types";
import { centerProfileCalls, centerProfileSelectors } from "../../../../../store/centers/profile";
import {
  patientProfileCalls,
  patientProfileSelectors,
} from "../../../../../store/patients/profile";
import {
  physicianProfileCalls,
  physicianProfileSelectors,
} from "../../../../../store/physician/profile";
import FolderModal from "../../../../base/Components/FolderModal";
import { ArrowBack, Attachment } from "@material-ui/icons";

interface Props {
  loggedUserType: USER_TYPE;
  profile: IPatient | IPhysician | ICenter | undefined;
  renderFileTitle: () => React.ReactNode;
  displayFiles: any;
  getProfile: () => Promise<void | undefined>;
  showPrivacy: boolean;
  fileType: string;
  uploadFile: any;
  renderUpload: any;
  uploading: boolean | undefined;
  error: any;
  updateCenterFilePrivacy: (id: string, fileState: boolean) => Promise<void>;
  updatePatientFilePrivacy: (id: string, fileState: boolean) => Promise<void>;
  updatePhysicainFilePrivacy: (id: string, fileState: boolean) => Promise<void>;
  centerUpdateFilePrivacyLoading: boolean;
  centerFilePrivacyError: string;
  patientUpdateFilePrivacyLoading: boolean;
  patientFilePrivacyError: string;
  physicianUpdateFilePrivacyLoading: boolean;
  physicianFilePrivacyError: string;
}

const FileUpload = (props: Props) => {
  const [showModal, setShowModal] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState<any>();
  const [showDetail, setShowDetail] = React.useState(false);
  const [folderModal, setFolderModal] = React.useState(false);
  const [showPrivacy] = React.useState(true);

  const handleChangeFile = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    if (props.loggedUserType === USER_TYPE.CENTER) {
      await props.updateCenterFilePrivacy(event.currentTarget.name, checked);
    } else if (props.loggedUserType === USER_TYPE.PHYSICIAN) {
      await props.updatePhysicainFilePrivacy(event.currentTarget.name, checked);
    } else if (props.loggedUserType === USER_TYPE.PATIENT) {
      await props.updatePatientFilePrivacy(event.currentTarget.name, checked);
      await props.getProfile();
    } else {
      return;
    }
  };

  return (
    <>
      <Grid container spacing={1} style={{ textAlign: "center" }}>
        <Grid
          item
          xs={12}
          sm={12}
          style={{ display: "grid", color: "#075d19", fontSize: "1.3rem" }}
        >
          {props.displayFiles ? props.renderFileTitle() : ""}
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display:
              showModal === true && props.fileType === "FOLDER"
                ? "grid"
                : props.fileType === "FOLDER"
                ? "contents"
                : props.fileType !== "FOLDER"
                ? "grid"
                : "contents",
            fontSize: "1rem",
            height: "180px",
            overflow: "scroll",
            overflowX: "hidden",
          }}
        >
          {props.displayFiles && props.fileType !== "FOLDER"
            ? props.profile?.files.map((file: IFileData) => {
                if (file.fileType === props.fileType && props.fileType !== "FOLDER") {
                  return (
                    <div
                      key={file._id}
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                        alignItems: "center",
                      }}
                    >
                      <a
                        style={{ color: "black" }}
                        target="_blank"
                        rel="noopener noreferrer"
                        href={file.url}
                      >
                        <p
                          style={{
                            color: file.isPublic ? "red" : "green",
                            fontWeight: "bold",
                          }}
                        >
                          {file.name}
                        </p>
                      </a>
                      {showPrivacy ? (
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={file.isPublic}
                                onChange={handleChangeFile}
                                name={file._id}
                              />
                            }
                            label={
                              window.screen.width >= 600
                                ? "Make file viewable in messages"
                                : "private"
                            }
                          />
                        </FormGroup>
                      ) : (
                        ""
                      )}
                    </div>
                  );
                } else {
                  return null;
                }
              })
            : props.profile?.folders && props.displayFiles
            ? props.profile?.folders.map((folder: IFolder, index: number) => {
                if (folder.files[0].fileType === props.fileType && props.fileType === "FOLDER") {
                  return (
                    <>
                      {showDetail === false ? (
                        <>
                          <div
                            key={folder._id}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                cursor: "pointer",
                                flexDirection: "column",
                              }}
                              onClick={() => {
                                setShowModal(true);
                                setCurrentIndex(index);
                                setShowDetail(true);
                              }}
                            >
                              <Folder style={{ fontSize: "50px", color: "#4caf50" }} />
                              <p>{folder.fName}</p>
                            </div>
                          </div>
                        </>
                      ) : (
                        showModal &&
                        currentIndex === index && (
                          <div>
                            <div
                              style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                              onClick={() => {
                                setShowDetail(false);
                                setShowModal(false);
                              }}
                            >
                              <IconButton>
                                <ArrowBack />
                              </IconButton>
                              <p>Back</p>
                            </div>
                            <div>
                              {folderModal && (
                                <FolderModal
                                  handleShowFolderModal={setFolderModal}
                                  show={folderModal}
                                  folderName={folder.fName}
                                  getProfile={props.getProfile}
                                />
                              )}
                              <Button
                                onClick={() => setFolderModal(true)}
                                style={{ backgroundColor: "#4caf50" }}
                              >
                                <Attachment />
                                Add File To Folder
                              </Button>
                            </div>
                            <div
                              style={{
                                height: "200px",
                                overflowY: "scroll",
                              }}
                            >
                              {folder.files.map((f: any, index: any) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                  }}
                                >
                                  <a
                                    style={{ color: "black" }}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={f.url}
                                  >
                                    <p
                                      style={{
                                        color: f.isPublic ? "red" : "green",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {f.name}
                                    </p>
                                  </a>
                                  {showPrivacy ? (
                                    <FormGroup style={{ display: "flex" }}>
                                      <FormControlLabel
                                        style={{ whiteSpace: "nowrap" }}
                                        control={
                                          <Switch
                                            checked={f.isPublic}
                                            onChange={handleChangeFile}
                                            name={f._id}
                                          />
                                        }
                                        label={
                                          window.screen.width >= 600
                                            ? "Make file viewable in messages"
                                            : "private"
                                        }
                                      />
                                    </FormGroup>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              ))}{" "}
                            </div>{" "}
                          </div>
                        )
                      )}
                    </>
                  );
                }
                return null;
              })
            : ""}
        </Grid>
        <Grid item xs={12} sm={12}>
          {props.displayFiles && props.fileType === "FOLDER" ? (
            ""
          ) : props.displayFiles ? (
            <Button
              variant="contained"
              color="primary"
              component="label"
              disabled={props?.uploading}
            >
              Upload File {props.fileType}
              <input type="file" hidden onChange={props.uploadFile} />
            </Button>
          ) : (
            ""
          )}
          {props?.error}
        </Grid>
      </Grid>
    </>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  updateCenterFilePrivacy: (id: string, fileState: boolean) =>
    dispatch(centerProfileCalls.updateCenterFilePrivacy(id, fileState)),
  updatePatientFilePrivacy: (id: string, fileState: boolean) =>
    dispatch(patientProfileCalls.updatePatientFilePrivacy(id, fileState)),
  updatePhysicainFilePrivacy: (id: string, fileState: boolean) =>
    dispatch(physicianProfileCalls.updatePhysicianFilePrivacy(id, fileState)),
});
const mapStateToProps = (state: any) => ({
  centerUpdateFilePrivacyLoading: state.updateCenterFilePrivacyCalling,
  centerFilePrivacyError: centerProfileSelectors.isCenterFileUpdatedFail(state),
  patientUpdateFilePrivacyLoading: state.updateCenterFilePrivacyCalling,
  patientFilePrivacyError: patientProfileSelectors.isPatientFileUpdatedFail(state),
  physicianUpdateFilePrivacyLoading: state.updatePhysicianFilePrivacyCalling,
  physicianFilePrivacyError: physicianProfileSelectors.isPhysicianFileUpdatedFail(state),
});
export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
