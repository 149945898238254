import React from "react";
import { connect } from "react-redux";
import { patientProfileCalls, patientProfileSelectors } from "../../store/patients/profile";
import { physicianProfileCalls, physicianProfileSelectors } from "../../store/physician/profile";
import { centerProfileCalls, centerProfileSelectors } from "../../store/centers/profile";
import { USER_TYPE } from "../../store/types";
import { PatientUpdateDTO, SearchPhyDTO } from "../../store/types/type";
import { DynamicProfile } from "./main/DynamicProfile";
import { IPatient } from "../../store/types/patient";
import { IPhysician } from "../../store/types/physician";
import { ICenter } from "../../store/types/center";
import { onlineCalls, onlineSelectors } from "../../store/online";
import { searchPhysician } from "../../store/patients/profile/apiCalls";

interface StateProps {
  patientProfile?: IPatient;
  physicianProfile?: IPhysician;
  centerProfile?: ICenter;
}

interface DispatchProps {
  getPatientProfile: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
  getCenterProfile: () => Promise<void>;
  getOnlineUsers: () => Promise<void>;
  searchPhysician: (search: SearchPhyDTO) => Promise<void>;
  isOnlineSuccess: string[];
  searchPhysicianSuccess: IPhysician[] | null;
  searchPhysicianFailure: string;
  updatePatientProfile: any;
  updatePhysicainProfile: any;
  updateCenterProfile: any;
}
interface OwnProps {
  userType: USER_TYPE;
}
export type ProfileProps = OwnProps & StateProps & DispatchProps;

export const _Profile = (props: ProfileProps) => {
  return <DynamicProfile {...props} />;
};

const mapStateToProps = (state: any) => ({
  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  searchPhysicianSuccess: patientProfileSelectors.searchPhysicianSuccess(
    state.patients.patientProfile
  ),
  searchPhysicianFailure: patientProfileSelectors.searchPhysicianFailure(
    state.patients.patientProfile
  ),
});

const mapDispatchToProps = (dispatch: any) => ({
  getPatientProfile: () => dispatch(patientProfileCalls.getPatientProfile()),
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  updatePatientProfile: (patientUpdateDTO: PatientUpdateDTO) =>
    dispatch(patientProfileCalls.updatePatientProfile(patientUpdateDTO)),
  updatePhysicainProfile: (patientUpdateDTO: PatientUpdateDTO) =>
    dispatch(physicianProfileCalls.updatePhysicianProfile(patientUpdateDTO)),
  updateCenterProfile: (patientUpdateDTO: PatientUpdateDTO) =>
    dispatch(centerProfileCalls.updateCenterProfile(patientUpdateDTO)),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  searchPhysician: (search: SearchPhyDTO) => dispatch(searchPhysician(search)),
});

export const Profile = connect(mapStateToProps, mapDispatchToProps)(_Profile);
