import { Button, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import React from "react";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles((theme) => ({
  dailog: {
    padding: theme.spacing(2),
    position: "absolute",
  },
}));

export const AvatarPopup = (props: {
  children: any;
  openPopupAvatar: boolean;
  setOpenPopupAvatar: any;
}) => {
  const classes = useStyles();
  return (
    <Dialog open={props.openPopupAvatar} classes={{ paper: classes.dailog }}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            style={{
              flexGrow: 1,
            }}
          >
            Update Avatar
          </Typography>
          <Button onClick={() => props.setOpenPopupAvatar(false)}>X</Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
    </Dialog>
  );
};

export const UserUpdateInfo = (props: {
  children: any;
  openPopupInfo: boolean;
  setOpenPopupInfo: any;
}) => {
  const classes = useStyles();
  return (
    <Dialog open={props.openPopupInfo} classes={{ paper: classes.dailog }}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            style={{
              flexGrow: 1,
            }}
          >
            Update Info
          </Typography>
          <Button onClick={() => props.setOpenPopupInfo(false)}>X</Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
    </Dialog>
  );
};
