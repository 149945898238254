import { IChat } from "../types/chat";
import * as types from "./types";

// export const gettingMyPingRequest = () => ({
//   type: types.gettingMyPingRequest,
// });
// export const getMyPingRequestSuccess = (PingRequest: IChat[]) => ({
//   type: types.getMyPingRequestSuccess,
//   payload: PingRequest,
// });

// export const getMyPingRequestFailure = (errorMessage: string) => ({
//   type: types.getMyPingRequestFailure,
//   payload: errorMessage,
// });

// export const updatingPingRequest = () => ({
//   type: types.updatingPingRequest,
// });
// export const updatePingRequestuccess = (PingRequest: IChat[]) => ({
//   type: types.updatePingRequestuccess,
//   payload: PingRequest,
// });
// export const updatePingRequestFailure = (errorMessage: string) => ({
//   type: types.updatePingRequestFailure,
//   payload: errorMessage,
// });

export const acceptPingRequestuccess = (PingRequest: IChat[]) => ({
  type: types.acceptPingRequestsuccess,
  payload: PingRequest,
});
export const acceptPingRequestFailure = (errorMessage: string) => ({
  type: types.acceptPingRequestFailure,
  payload: errorMessage,
});
export const acceptingPingRequest = () => ({
  type: types.acceptingPingRequest,
});
export const creatingPingRequest = () => ({
  type: types.creatingPingRequest,
});
export const createPingRequestuccess = (PingRequest: IChat[]) => ({
  type: types.createPingRequestsuccess,
  payload: PingRequest,
});
export const createPingRequestFailure = (errorMessage: string) => ({
  type: types.createPingRequestFailure,
  payload: errorMessage,
});
