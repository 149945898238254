import { Typography } from "@material-ui/core";
import React from "react";
import { useStyles } from "./muiStyling";

export const Explore = () => {
  const classes = useStyles();
  return (
    <div className={classes.main}>
      <div style={{ marginLeft: "20em" }}>
        <Typography variant="h3" component="h4">
          Wait Position Calculator
        </Typography>
      </div>
    </div>
  );
};
