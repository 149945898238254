import MenuBookIcon from "@material-ui/icons/MenuBook";
import DashboardIcon from "@material-ui/icons/Dashboard";
import SpecialtiesIcon from "@material-ui/icons/ListRounded";
import PersonIcon from "@material-ui/icons/Person";
import HistoryIcon from "@material-ui/icons/History";
// import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
// import { Card, Collapse, Grid, Paper } from '@material-ui/core';
import GroupIcon from "@material-ui/icons/Group";
import SettingsIcon from "@material-ui/icons/Settings";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import PostAddIcon from "@material-ui/icons/PostAdd";
// import ExploreIcon from "@material-ui/icons/Explore";
import CallMadeIcon from "@material-ui/icons/CallMade";
// import MailIcon from '@material-ui/icons/Mail';
import AddCommentIcon from "@material-ui/icons/AddComment";
import AssessmentIcon from "@material-ui/icons/Assessment";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PeopleOutlineIcon from "@material-ui/icons/PeopleOutline";
import PersonPinIcon from "@material-ui/icons/PersonPin";
// import CalendarMonthIcon from '@material-ui/icons/CalendarMonth';
export const physicianItems = [
  {
    name: "Dashboard",
    url: "/physician",
    icon: DashboardIcon,
    nestedItems: [],
  },
//  {
//    name: "Requests",
//    url: "/physician/management/tracking/requests",
//    icon: MenuBookIcon,
//    nestedItems: [],
//  },
//  {
//    name: "Ping",
//    url: "/physician/ping/",
//    icon: HomeWorkIcon,
//    nestedItems: [
//      {
//        name: "Send Ping",
//        url: "/physician/ping/",
//        icon: AddCommentIcon,
//      },
//      {
//        name: "Ping Messages",
//        url: "/physician/ping/single",
//        icon: ChatBubbleOutlineIcon,
//      },
//      {
//        name: "Group Ping Messages",
//        url: "/physician/ping/group-discussions",
//        icon: PeopleAltIcon,
//      },
//    ],
//  },
  {
    name: "Sessions",
    url: "/physician/portal/messages",
    icon: ChatBubbleOutlineIcon,
    nestedItems: [],
  },
//  {
//    name: "Group Discussions",
//    url: "/physician/portal/group-discussions",
//    icon: PeopleAltIcon,
//    nestedItems: [],
//  },
  // {
  //   name: "Explore",
  //   url: "/physician/explore",
  //   icon: ExploreIcon,
  //   nestedItems: [],
  // },
  // {
  //   name: "Preferences",
  //   url: "/physician/settings",
  //   icon: SettingsIcon,
  //   nestedItems: [],
  // },
  {
    name: "Client Profiles",
    url: "/physician/patients-profile",
    icon: PeopleAltIcon,
    nestedItems: [],
  },
  {
    name: "Bio & Availability",
    url: "/physician/feeds",
    icon: PostAddIcon,
    nestedItems: [],
  },
  //{
  //  name: "Time Keeping",
  //  url: "/physician/time-keeping",
  //  icon: HistoryIcon,
  //  nestedItems: [],
//  },
  {
    name: "My Profile",
    url: "/physician/profile",
    icon: PersonIcon,
    nestedItems: [],
  },
  {
    name: "Scheduling",
    url: "/physician/scheduling",
    icon: HistoryIcon,
    nestedItems: [],
  },
];

export const patientItems = [
  {
    name: "New Appointment",
    url: "/patient",
    icon: DashboardIcon,
    nestedItems: [],
  },
  {
    name: "Specialists",
    url: "/patient/specialties",
    icon: SpecialtiesIcon,
    nestedItems: [],
  },
  {
    name: "Join a Session",
    url: "/patient/portal/messages",
    icon: HomeWorkIcon,
    nestedItems: [],
  },
//  {
//    name: "Join a Session",
//    url: "/patient/portal",
//    icon: HomeWorkIcon,
//    nestedItems: [
//      {
//        name: "Contact a Provider",
//        url: "/patient/portal/selectCenter",
//        icon: CallMadeIcon,
//      },
//      {
//        name: "Sessions",
//        url: "/patient/portal/messages",
//        icon: ChatBubbleOutlineIcon,
//      },
    //  {
      //  name: "Group Discussions",
      //  url: "/patient/portal/group-discussions",
      //  icon: PeopleAltIcon,
    //  },
//    ],
//  },
  // {
  //   name: "Explore",
  //   url: "/patient/explore",
  //   icon: ExploreIcon,
  //   nestedItems: [],
  // },
  // {
  //   name: "Preferences",
  //   url: "/patient/settings",
  //   icon: SettingsIcon,
  //   nestedItems: [],
  // },
//  {
  //  name: "provider uploads",
  //  url: "/patient/feeds",
  //  icon: PostAddIcon,
  //  nestedItems: [],
//  },
  {
    name: "My Profile",
    url: "/patient/profile",
    icon: PersonIcon,
    nestedItems: [],
  },
//  {
  //  name: "Pinned Professionals & Providers",
  //  url: "/patient/portal/pin",
  //  icon: PersonPinIcon,
  //  nestedItems: [],
//  },
];
export const adminItems = [
  {
    name: "Dashboard",
    url: "/admin",
    icon: DashboardIcon,
    nestedItems: [],
  },
  {
    name: "Management",
    url: "/admin/management",
    icon: PeopleOutlineIcon,
    nestedItems: [
      {
        name: "Approvals",
        url: "/admin/management/approval",
        icon: AssignmentTurnedInIcon,
      },
      {
        name: "Tracking",
        url: "/admin/management/tracking",
        icon: AssessmentIcon,
      },
    ],
  },
  {
    name: "provider uploads",
    url: "/admin/feeds",
    icon: PostAddIcon,
    nestedItems: [],
  },
];
export const superItems = [
  {
    name: "Dashboard",
    url: "/super",
    icon: DashboardIcon,
    nestedItems: [],
  },
  {
    name: "Management",
    url: "/super/management",
    icon: PeopleOutlineIcon,
    nestedItems: [
      {
        name: "Admin",
        url: "/super/management/admin",
        icon: PersonIcon,
      },
      {
        name: "Provider",
        url: "/super/management/center",
        icon: PersonIcon,
      },
    ],
  },
  {
    name: "Assign Provider",
    url: "/super/assignCenters",
    icon: PersonIcon,
    nestedItems: [],
  },
  {
    name: "Control Accounts",
    url: "/super/control",
    icon: SettingsIcon,
    nestedItems: [],
  },
  {
    name: "Time Keeping",
    url: "/super/timekeeping",
    icon: HistoryIcon,
    nestedItems: [],
  },
  {
    name: "Scheduling",
    url: "/super/scheduling",
    icon: HistoryIcon,
    nestedItems: [],
  },
];
export const centerItems = [
  {
    name: "Dashboard",
    url: "/center",
    icon: DashboardIcon,
    nestedItems: [],
  },
  {
    name: "Management",
    url: "/center/management",
    icon: PeopleOutlineIcon,
    nestedItems: [
      {
        name: "Approvals",
        url: "/center/management/approval",
        icon: AssignmentTurnedInIcon,
      },
      {
        name: "Tracking",
        url: "/center/management/tracking",
        icon: AssessmentIcon,
      },
      {
        name: "All Professionals",
        url: "/center/management/physician-list",
        icon: GroupIcon,
      },
    ],
  },
  {
    name: "Ping",
    url: "/center/ping/start-chat",
    icon: HomeWorkIcon,
    nestedItems: [
      {
        name: "Send Ping",
        url: "/center/ping/start-chat",
        icon: AddCommentIcon,
      },
      {
        name: "Ping Messages",
        url: "/center/ping/single",
        icon: ChatBubbleOutlineIcon,
      },
      {
        name: "Group Ping Messages",
        url: "/center/ping/group-discussions",
        icon: PeopleAltIcon,
      },
    ],
  },

  // {
  //   name: "Explore",
  //   url: "/center/explore",
  //   icon: ExploreIcon,
  //   nestedItems: [],
  // },

  // {
  //   name: "Preferences",
  //   url: "/center/settings",

  //   icon: SettingsIcon,
  //   nestedItems: [],
  // },
  {
    name: "My Profile",
    url: "/center/profile",
    icon: PersonIcon,
    nestedItems: [],
  },
  {
    name: "Feed",
    url: "/center/feedUploads",
    icon: PostAddIcon,
    nestedItems: [],
  },
  {
    name: "Scheduling",
    url: "/center/scheduling",
    icon: HistoryIcon,
    nestedItems: [],
  },
];
