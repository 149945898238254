import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";
import { Approval } from "./ManagementComponents/Approval";
import { Tracking } from "./ManagementComponents/Tracking";
import { useStyles } from "./muiStyling";
import { centerProfileCalls, centerProfileSelectors } from "../../../store/centers/profile";
import { physicianProfileCalls, physicianProfileSelectors } from "../../../store/physician/profile";
import { USER_TYPE } from "../../../store/types";
import { ICenter } from "../../../store/types/center";
import { IPhysician } from "../../../store/types/physician";
import CircleSpinner from "../../CircleSpinner";
import { PhysicianList } from "./ManagementComponents/Physician-list/PhysicianList";
interface OwnProps {}
interface DispatchProps {
  getCenterProfile: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
  denyPhysicianRequest: (id: string) => Promise<void>;
  approvePhysicianRequest: (id: string) => Promise<void>;
}
interface StateProps {
  centerProfileCalling: boolean;
  centerProfile: ICenter;
  centerProfileFetchError?: string;
  physicianProfileCalling: boolean;
  physicianProfile: IPhysician;
  physicianProfileFetchError?: string;
  updatingPhysicianRequest: boolean;
  updatingPhysicianError: string;
}
type Props = OwnProps & StateProps & DispatchProps;
function Management(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  const type =
    localStorage.getItem("current") === USER_TYPE.PHYSICIAN
      ? USER_TYPE.PHYSICIAN
      : USER_TYPE.CENTER;
  const handleClickA = () => {
    history.push(`/${type?.toLowerCase()}/management/approval`);
  };
  const handleClickB = () => {
    history.push(`/${type?.toLowerCase()}/management/tracking`);
  };
  const handleClickC = () => {
    history.push(`/${type?.toLowerCase()}/management/physician-list`);
  };
  React.useEffect(() => {
    const profileCall =
      type === USER_TYPE.PHYSICIAN
        ? async () => await props.getPhysicianProfile()
        : type === USER_TYPE.CENTER
        ? async () => await props.getCenterProfile()
        : undefined;
    if (profileCall) profileCall();
    else history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {props.centerProfileCalling || props.physicianProfileCalling ? (
        <CircleSpinner />
      ) : // : props.centerProfileFetchError || props.physicianProfileFetchError ? (
      //   <p>{props.centerProfileFetchError || props.physicianProfileFetchError}</p>
      // )
      props.centerProfile || props.physicianProfile ? (
        <div className={classes.main}>
          {type === USER_TYPE.CENTER ? (
            <div style={{ textAlign: "center", marginTop: "3.5em" }}>
              <CustomButtonGroup
                buttonType="horizontal"
                values={["Approvals", "Tracking", "All Professional"]}
                handleClicks={[handleClickA, handleClickB, handleClickC]}
                style={{ textAlign: "center", marginTop: "3%" }}
              />
            </div>
          ) : null}
          <Switch>
            <Route
              exact
              path={`/${type?.toLowerCase()}/management/approval`}
              component={() => (
                <Approval
                  type={type}
                  profile={props.centerProfile}
                  approvePhysicianRequest={props.approvePhysicianRequest}
                  denyPhysicianRequest={props.denyPhysicianRequest}
                  updatingPhysicianRequest={props.updatingPhysicianRequest}
                  updatingPhysicianError={props.updatingPhysicianError}
                />
              )}
            />
            <Route
              path={`/${type?.toLowerCase()}/management/tracking`}
              component={() => (
                <Tracking
                  type={type}
                  profile={props.centerProfile || props.physicianProfile}
                  patientrequests={
                    type === USER_TYPE.CENTER
                      ? props.centerProfile?.patientRequests
                      : props.physicianProfile?.center?.patientRequests
                  }
                  pingrequests={
                    type === USER_TYPE.CENTER
                      ? [
                          ...props.centerProfile?.pingRequests,
                          ...props.centerProfile?.physicians
                            .map((phy) => phy.pingRequests || [])
                            .flat(),
                        ]
                      : props.physicianProfile?.pingRequests
                  }
                  physicians={
                    // Only used when center
                    type === USER_TYPE.CENTER
                      ? props.centerProfile?.physicians
                      : [props.physicianProfile]
                  }
                  getProfile={
                    type === USER_TYPE.CENTER ? props.getCenterProfile : props.getPhysicianProfile
                  }
                />
              )}
            />
            <Route
              exact
              path={`/${type?.toLowerCase()}/management/physician-list`}
              component={() => (
                <PhysicianList
                  centerProfile={props.centerProfile}
                  denyPhysicianRequest={props.denyPhysicianRequest}
                />
              )}
            />
            <Redirect to={`/${type?.toLowerCase()}/management/tracking`} />
          </Switch>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  denyPhysicianRequest: (id: string) => dispatch(centerProfileCalls.denyPhysicianRequest(id)),
  approvePhysicianRequest: (id: string) => dispatch(centerProfileCalls.approvePhysicianRequest(id)),
});
const mapStateToProps = (state: any) => ({
  centerProfileCalling: centerProfileSelectors.isCallingProfile(state.centers.centerProfile),
  centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
  centerProfileFetchError: centerProfileSelectors.isProfileFailure(state.centers.centerProfile),

  updatingPhysicianRequest: centerProfileSelectors.updatingPhysicianRequest(
    state.centers.centerProfile
  ),
  updatingPhysicianError: centerProfileSelectors.physicianRequestFailed(
    state.centers.centerProfile
  ),

  physicianProfileCalling: physicianProfileSelectors.isCallingProfile(
    state.physicians.physicianProfile
  ),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  physicianProfileFetchError: physicianProfileSelectors.isProfileFailure(
    state.physicians.physicianProfile
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(Management);
