import * as types from "./types";

const initialState = {
  feeds: [],
  getAllFeedsFailure: "",
  gettingAllFeeds: false,

  updateCenterIsMassCalling: false,
  updateCenterIsMassFailure: "",

  removeOneFeed: false,
  removeOneFeedSuccess: "",
  removeOneFeedFailure: "",
  drawerOpen: false,
};

export const feedReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.gettingAllFeeds: {
      return {
        ...state,
        gettingAllFeeds: true,
        getAllFeedsFailure: "",
        feeds: [],
      };
    }
    case types.getAllFeedsSuccess: {
      return {
        ...state,
        gettingAllFeeds: true,
        feeds: action.payload,
      };
    }
    case types.getAllFeedsFailure: {
      return {
        ...state,
        gettingAllFeed: false,
        getAllFeedsFailure: action.payload,
      };
    }
    case types.updateCenterIsMassCalling:
      return {
        ...state,
        updateCenterIsMassCalling: true,
        centerProfileFailure: "",
      };
    case types.updateCenterIsMassSuccess:
      return {
        ...state,
        isMass: "",
        updateCenterIsMassCalling: false,
      };
    case types.updateCenterIsMassFailure:
      return {
        ...state,
        updateCenterIsMassCalling: false,
        updateCenterIsMassFailure: action.payload,
      };
    case types.removeOneFeed:
      return {
        ...state,
        removeOneFeed: true,
        removeOneFeedSuccess: true,
        removeOneFeedFailure: "",
      };
    case types.removeOneFeedFailure:
      return {
        ...state,
        removeOneFeed: false,
        removeOneFeedSuccess: false,
        removeOneFeedFailure: action.payload,
      };
    case types.removeOneFeedSuccess:
      return {
        ...state,
        removeOneFeed: true,
        removeOneFeedFailure: "",
        removeOneFeedSuccess: action.payload,
      };
    case types.drawerOpen:
      return {
        ...state,
        drawerOpen: !state.drawerOpen,
      };
    default: {
      return state;
    }
  }
};
