import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { Button, Container, Grid } from "@material-ui/core";
import { CenterLoginDTO } from "../../store/types/type";
import { useStyles } from "./muiStyling";
import { ThemeProvider } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { theme } from "../base/ColorPalete";
import CircleSpinner from "../CircleSpinner";
import { Alert } from "@material-ui/lab";
import { IOtp, USER_TYPE } from "../../store/types";

interface StateProps {
  loggingInCenter: boolean;
  centerLoggedIn: boolean;
  errorWhileCenterLogin: string;
}

interface DispatchProps {
  centerLogin: (dto: CenterLoginDTO) => Promise<void>;
  verifyCenterOTP: (userOtp: IOtp) => Promise<void>;
}

type Props = StateProps & DispatchProps;
export default function CenterLoginCard(props: Props) {
  const history = useHistory();
  const [flag, setFlag] = React.useState(false);
  const [otp, setOtp] = React.useState<IOtp>({
    userType: undefined,
    _id: "",
    otp: "",
    password: "",
  });

  useEffect(() => {
    const password = localStorage.getItem("password");
    const decryptedPassword = window.atob(password as string);
    setOtp({
      userType: USER_TYPE.CENTER,
      _id: localStorage.getItem("_id") as string,
      otp: "",
      password: decryptedPassword,
    });
  }, [flag]);

  useEffect(() => {
    if (props.centerLoggedIn) {
      setFlag(true);
    }
  }, [props.centerLoggedIn]);

  const [centerData, setCenterData] = React.useState<CenterLoginDTO>({
    email: "",
    password: "",
  });
  const [errorState, setErrorState] = React.useState({
    email: "",
    password: "",
  });

  const handleSubmitOtp = async (e: any) => {
    e.preventDefault();
    await props.verifyCenterOTP(otp);
    history.push("/center");
  };
  const handleSubmit = async (event: any) => {
    // setInputState(centerData);
    if (isValidated(event)) {
      const encryptedPassword = window.btoa(centerData.password);
      localStorage.setItem("password", encryptedPassword);
      await props.centerLogin(centerData);
      // if (props.centerLoggedIn) {
      // history.push route
      //  history.push("/center");
    }
  };
  const isValidated = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // let input = inputState;
    let errors = {
      email: "",
      password: "",
    };
    // errors.npi = centerData.npi.length > 5 ? "" : "Please Enter NPI(5-digits)";
    errors.password = centerData.password ? "" : "Please Enter Password";
    errors.email = centerData.email ? "" : "Please Enter Email";
    // errors.email = (/$|.*@.*..*/).test(input["email"])? "" : "Required";
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };
  const classes = useStyles();

  return props.loggingInCenter ? (
    <Container className={classes.container} maxWidth="xs">
      <Grid container justify="center">
        <CircleSpinner />
      </Grid>
    </Container>
  ) : flag ? (
    <Container className={classes.container} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Otp"
                  type="string"
                  name="otp"
                  onChange={(e: any) =>
                    setOtp({
                      ...otp,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    onClick={handleSubmitOtp}
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                  >
                    log In
                  </Button>
                </ThemeProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  ) : (
    <Container className={classes.container} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  type="string"
                  name="email"
                  placeholder={`No capitals in email `}
                  onChange={(e) => setCenterData({ ...centerData, email: e.target.value })}
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="NPI Number (if any)"
                  type="string"
                  name="npi"
                  onChange={(e) => setCenterData({ ...centerData, npi: e.target.value })}
                  error={errorState["npi"] ? true : false}
                  helperText={errorState["npi"]}
                />
              </Grid> */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  onChange={(e) => setCenterData({ ...centerData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    style={{ borderRadius: "20px" }}
                  >
                    get token
                  </Button>
                </ThemeProvider>
              </Grid>
              <Grid item xs={12}>
                {props.errorWhileCenterLogin && (
                  <Alert severity="error">{props.errorWhileCenterLogin}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
