import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Button, TextField } from "@material-ui/core";

export default function AlertPopup(props: {
  title: string;
  text: string;
  open: boolean;
  handleOpenPopup: any;
  buttonText: string;
  input?: boolean;
  inputValue?: string;
  handleInputValue?: any;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleOpenPopup}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {props.text === "Request failed with status code 409"
              ? "conflict : chat already exist"
              : props.text}
            {props.input ? (
              <TextField
                style={{ width: "100%" }}
                id="standard-basic"
                value={props.inputValue}
                onChange={props.handleInputValue}
              />
            ) : null}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleOpenPopup} color="primary" autoFocus>
            {props.buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
