import React, { Component } from "react";
import { connect } from "react-redux";
import { physicianProfileCalls, physicianProfileSelectors } from "../../store/physician/profile";
import { ICenter } from "../../store/types/center";
import { IPhysician } from "../../store/types/physician";
import { NotificationDTO, PingRequestDTO } from "../../store/types/type";
import { CustomButtonGroup } from "../base/Components/CustomButtonGroup";
// import InternalPing from "../base/Components/InternalPing";
// import ExternalPing from "../base/Components/ExternalPing";
import { basicCalls, basicSelectors } from "../../store/centers/basicCalls";
import { PingRequestsCalls, PingRequestsSelectors } from "../../store/Ping";
import { notificationSMSCalls } from "../../store/notification/sms";
import { PingMain } from "../base/Ping/PingMain";
// import { SelectCenter } from "../dashboardComponents/main/PortalComponents/SelectCenter";
interface StateProps {
  pinging: boolean;
  pingError: string;
  gettingProfile: boolean;
  getProfileError: string;
  profile: IPhysician;
  gettingCenters: boolean;
  getCentersError: string;
  allCenters: ICenter[];
  pingSuccess: boolean;
}
interface DispatchProps {
  ping: (pingDto: PingRequestDTO) => Promise<void>;
  getProfile: () => Promise<void>;
  getAllCenters: () => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}
interface OwnProps {
  type: string;
}
interface State {
  internalSelected?: boolean;
  Message?: boolean;
}
interface InvisibleProps {
  history: any;
}
type Props = StateProps & DispatchProps & OwnProps & InvisibleProps;
export class PhysicianPingComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  componentDidMount = async () => {
    await this.props.getProfile();
  };
  handleClickInternal = () => {
    this.setState({
      ...this.state,
      internalSelected: true,
      Message: false,
    });
  };
  handleClickExternal = () => {
    this.setState({
      ...this.state,
      internalSelected: false,
      Message: false,
    });
  };
  handleClickMessage = () => {
    this.setState({
      ...this.state,
      Message: true,
    });
  };
  render() {
    return (
      <>
        <CustomButtonGroup
          buttonType="horizontal"
          values={["Message Delores Digital", "Internal", "External"]}
          handleClicks={[
            this.handleClickMessage,
            this.handleClickInternal,
            this.handleClickExternal,
          ]}
          style={{ textAlign: "center", marginTop: "3%" }}
        />
        {this.state.Message && (
          <PingMain
            message={this.state.Message}
            internalSelected={this.state.internalSelected}
            type="Physician"
            value={"message"}
          />
        )}

        {this.state.internalSelected && !this.state.Message ? (
          <PingMain
            message={this.state.Message}
            internalSelected={this.state.internalSelected}
            type="Physician"
          />
        ) : (
          // <InternalPing
          //   profile={this.props.profile}
          //   myCenter={this.props.profile.center}
          //   pingError={this.props.pingError}
          //   pinging={this.props.pinging}
          //   ping={this.props.ping}
          //   type={this.props.type}
          //   sendSMS={this.props.sendSMS}
          // />
          !this.state.Message && (
            <PingMain
              message={this.state.Message}
              internalSelected={this.state.internalSelected}
              type="Physician"
            />
            // <ExternalPing
            //   allCenters={this.props.allCenters}
            //   getAllCenters={this.props.getAllCenters}
            //   getAllCentersError={this.props.getCentersError}
            //   gettingAllCenters={this.props.gettingCenters}
            //   pinging={this.props.pinging}
            //   pingError={this.props.pingError}
            //   ping={this.props.ping}
            //   profile={this.props.profile}
            //   type={this.props.type}
            //   sendSMS={this.props.sendSMS}
            // />
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => ({
  pinging: PingRequestsSelectors.isCreatingPingRequest(state.ping),
  pingError: PingRequestsSelectors.errorWhileCreatingPingRequest(state.ping),
  pingSuccess: PingRequestsSelectors.createPingRequestSuccess(state.ping),
  gettingProfile: physicianProfileSelectors.isCallingProfile(state.physicians.physicianProfile),
  getProfileError: physicianProfileSelectors.isProfileFailure(state.physicians.physicianProfile),
  profile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.centers.basicCalls),
  allCenters: basicSelectors.fetchCenters(state.centers.basicCalls),
});
const mapDispatchToProps = (dispatch: any) => ({
  getAllCenters: () => dispatch(basicCalls.getAllCenters()),
  ping: (pingDto: PingRequestDTO) => dispatch(PingRequestsCalls.createPingRequest(pingDto)),
  getProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicianPingComponent);
