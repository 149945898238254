import { IFeed } from "../types";
import * as types from "./types";

export const gettingAllFeeds = () => ({
  type: types.gettingAllFeeds,
});
export const getAllFeedsSuccess = (feed: IFeed[]) => ({
  type: types.getAllFeedsSuccess,
  payload: feed,
});
export const getAllFeedsFailure = (errorMessage: string) => ({
  type: types.getAllFeedsFailure,
  payload: errorMessage,
});

export const updateCenterIsMassCalling = () => ({
  type: types.updateCenterIsMassCalling,
});
export const updateCenterIsMassSuccess = (updatedCenterFeed: IFeed) => ({
  type: types.updateCenterIsMassSuccess,
  payload: updatedCenterFeed,
});

export const updateCenterIsMassFailure = (errorMessage: string) => ({
  type: types.updateCenterIsMassFailure,
  payload: errorMessage,
});

export const removeOneFeed = () => ({
  type: types.removeOneFeed,
});

export const removeOneFeedSuccess = (isRemove: boolean) => ({
  type: types.removeOneFeedSuccess,
  payload: isRemove,
});

export const removeOneFeedFailure = (errorMessage: string) => ({
  type: types.removeOneFeedFailure,
  payload: errorMessage,
});

export const setDrawerOpen = () => ({
  type: types.drawerOpen,
});
