/* eslint-disable react-hooks/exhaustive-deps */

import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  Paper,
  Typography,
  // begin import imports (this upper section) for the bbuttom to send DD a message: cominng from SelectCenter
  FormControl,
  makeStyles,
  TextField,
  Badge,
  withStyles,
  // end import imports (this upper section) for the bbuttom to send DD a message: cominng from SelectCenter

} from "@material-ui/core";
import React, { useEffect }  from "react";
import { createWidget } from '@typeform/embed'
import { Widget } from '@typeform/embed-react'
import { PopupButton } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css'
import { connect } from "react-redux";
import { onlineCalls, onlineSelectors } from "../../../store/online";
import { patientProfileSelectors } from "../../../store/patients/profile";
import { IPatient, IPhysician } from "../../../store/types";
import { SearchPhyDTO } from "../../../store/types/type";
import PhoneIcon from "@material-ui/icons/Phone";
import HomeIcon from "@material-ui/icons/Home";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import PersonPinCircleIcon from "@material-ui/icons/PersonPinCircle";
import ChatIcon from "@material-ui/icons/Chat";
import { useHistory } from "react-router-dom";
import Close from "@material-ui/icons/Close";
import { searchPhysician } from "../../../store/patients/profile/apiCalls";
import { useStyles } from "./muiStyling";
import Logo from "../../../staticResources/logos/logo.png";
import Anxiety from "../../../staticResources/UserDashboard/Anxiety_Stress.jpg";
import Depression from "../../../staticResources/UserDashboard/Depression.jpg";
import Gender from "../../../staticResources/UserDashboard/Gender_Sexuality.jpg";
import Professional from "../../../staticResources/UserDashboard/ReligiousCounseling.jpg";
import Sexual from "../../../staticResources/UserDashboard/SexualHealth.jpg";
import Disease from "../../../staticResources/UserDashboard/Disease_Illness.jpg";
import Diabetes from "../../../staticResources/UserDashboard/Diabetes.jpg";
import Chronic from "../../../staticResources/UserDashboard/ChronicDisease.jpg";
import Relationships from "../../../staticResources/UserDashboard/Relationships.jpg";
import Family from "../../../staticResources/UserDashboard/FamilyMatters.jpg";
import PTSD from "../../../staticResources/UserDashboard/PTSD_Tramus.jpg";
import Health from "../../../staticResources/UserDashboard/Health&Fitness.jpg";
import Substance from "../../../staticResources/UserDashboard/SubstanceAbuse.jpg";
import Nutrition from "../../../staticResources/UserDashboard/Nutrition_Diet.jpg";
import Holistic from "../../../staticResources/UserDashboard/HolisticMedicine.jpg";
import Venting from "../../../staticResources/UserDashboard/JustVenting.jpg";
import GeneralClinical from "../../../staticResources/UserDashboard/GeneralClinicalAdvice.jpg";
import GeneralMental from "../../../staticResources/UserDashboard/GeneralMentalAdvice.jpg";
import Career from "../../../staticResources/UserDashboard/Professional.jpg";
import Coaching from "../../../staticResources/UserDashboard/Holistic_Coaching.jpg";

import DashboardChip from "../../base/Components/DashboardChip";

// begin import imports for the bbuttom to send DD a message: cominng from SelectCenter

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";

import { basicCalls, basicSelectors } from "../../../store/centers/basicCalls";
import {
  basicCalls as superBasicCalls,
  basicSelectors as superBasicSelectors,
} from "../../../store/supers/basicCalls/index";
//import { useStyles } from "../patient/muiStyling";
import { Alert, Autocomplete } from "@material-ui/lab";

import { ICenter } from "../../../store/types/center";
// import { chatCalls, chatSelectors } from "../../../../store/patients/chat";
import { patientProfileCalls } from "../../../store/patients/profile";
// import CircleSpinner from "../../../CircleSpinner";
//import { onlineCalls /* onlineSelectors*/, onlineSelectors } from "../../../store/online";
import CrisisPopup from "../../popup/CrisisPopup";
import { NotificationDTO, PingRequestDTO, /*SearchPhyDTO */} from "../../../store/types/type";
import { notificationSMSCalls } from "../../../store/notification/sms";
import { usRedListStates, useStateList, greenList } from "../../../store/types/states";
//import { specialties } from "../../../store/types/states";
import { IChat /*IPatient, IPhysician */} from "../../../store/types";
//import { searchPhysician } from "../../../store/patients/profile/apiCalls";
// import ChatIcon from "@material-ui/icons/Chat";
import { NewMessageDTO } from "../../../store/types/type";
import { IMessage } from "../../../store/types/message";
import PatientAppointment from "../patient/Appointment/PatientAppointment";
import axios from "axios";
import { chatSelectors as patientChatSelector } from "../../../store/patients/chat";
import io from "socket.io-client";
import PingCenterCards from "../../base/Ping/PingCenterCards";
import { PingRequestsCalls } from "../../../store/Ping";


// end import imports for the bbuttom to send DD a message: cominng from SelectCenter



const specialties = [
  { title: "General Clinical Advice", url: GeneralClinical },
  { title: "Depression", url: Depression },
  { title: "General Mental Advice", url: GeneralMental },
  { title: "Spiritual Guidance", url: Professional },
  { title: "Sexual Health", url: Sexual },
  { title: "Disease / Illness", url: Disease },
  { title: "Diabetes", url: Diabetes },
  { title: "Chronic Disease", url: Chronic },
  { title: "Relationships", url: Relationships },
  { title: "Family Matters", url: Family },
  { title: "PTSD / Trauma", url: PTSD },
  { title: "Health & Fitness", url: Health },
  { title: "Substance Abuse", url: Substance },
  { title: "Nutrition / Diet", url: Nutrition },
  { title: "Holistic Medicine", url: Holistic },
  { title: "Venting", url: Venting },
  { title: "Anxiety / Stress", url: Anxiety },
  { title: "Gender / Sexuality", url: Gender },
  { title: "Professional / Career Support", url: Career },
  { title: "Holistic Coaching", url: Coaching },
] as any;
// const StyledOnlineBadge = withStyles((theme) => ({
//   badge: {
//     backgroundColor: "#44b700",
//     color: "#44b700",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     overlap: "circular",
//     "&::after": {
//       position: "absolute",
//       top: 0,
//       left: 0,
//       width: "100%",
//       height: "100%",
//       borderRadius: "50%",
//       animation: "$ripple 1.2s infinite ease-in-out",
//       border: "1px solid currentColor",
//       content: '""',
//     },
//   },
// }))(Badge);
// const StyledOffLineBadge = withStyles((theme) => ({
//   badge: {
//     backgroundColor: "#ffcc00",
//     color: "#ffcc00",
//     boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
//     overlap: "circular",
//   },
// }))(Badge);

// const useStyle = makeStyles((theme) => ({
//   paper: {
//     margin: 20,
//     height: 180,
//     width: 260,
//   },
// }));''

// begin types and props import for the bbuttom to send DD a message: cominng from SelectCenter

const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      // content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);

const useStyle = makeStyles((theme) => ({
  paper: {
    margin: 20,
    height: 180,
    width: 260,
  },
  select: {
    minWidth: "40%",
    margin: "0px 20px",
    "@media (max-width: 600px)": {
      textAlign: "center",
      margin: "0px 10px",
      minWidth: "60%",
    },
  },
  btnStyle: {
    minWidth: "20%",
    borderRadius: "20px",
    fontSize: "0.9125rem",
    "@media (max-width: 600px)": {
      minWidth: "40%",
      fontSize: "0.7125rem",
    },
  },
  btnDiv: {
    alignSelf: "center",
    marginTop: "15px",
    minWidth: "35%",
    "@media (max-width: 600px)": {
      minWidth: "40%",
    },
  },
  selectDiv: {
    width: "100%",
    textAlign: "center",
    "@media (max-width: 600px)": {
      textAlign: "left",
      "& .MuiOutlinedInput-input": {
        padding: "8px 19px 13px 5px",
        fontSize: "0.8rem",
      },
    },
  },
  innerSelectDiv: {
    width: "70%",
  },
  label: {
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
}));
type mapStateProps = {
  centers: ICenter[];
  isOnlineSuccess: string[];
  initiateRequest: (selectedPhyscian: string, initText: string) => Promise<void>;
  patientChats: IChat[];
  errorWhileGetCenters: string;
  gettingCenters: boolean;
  searchPhysician: (search: SearchPhyDTO) => Promise<void>;
  searchPhysicianSuccess: IPhysician[] | null;
  physicians: IPhysician[];
  profile: IPatient;
  searchPhysicianFailure: string;
};
type mapDispatchProps = {
  getAllCenters: any;
  getPatient: () => Promise<void>;
  getPhysicians: () => Promise<void>;
  getOnlineUsers: () => Promise<void>;
  initiateRequest: (centerID: string, initText: string) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  searchPhysician: any;
  handlePhysicianSelectPing: (pingDto: any) => Promise<void>;
  ping: (pingDto: PingRequestDTO) => Promise<void>;
};
type ownProps = { isPingComp?: boolean };
type invisibleProps = {
  history?: {
    push(url: string): void;
  };
};

// begin self added import in this category for error resolution

type Props2 = ownProps & mapDispatchProps & mapStateProps & invisibleProps;

// end self added import in this category for error resolution

// end types and props import for the bbuttom to send DD a message: cominng from SelectCenter


interface OwnProps {}
interface DispatchProps {
  getOnlineUsers: () => Promise<void>;
  searchPhysician: (search: SearchPhyDTO) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;

}

interface StateProps {
  isOnlineSuccess: string[];
  searchPhysicianSuccess: IPhysician[] | null;
  searchPhysicianFailure: string;
  physicians: IPhysician[];
  profile?: IPatient;
}


type Props = OwnProps & DispatchProps & StateProps & invisibleProps;
export const _PingProfessioanl = (props: Props) => {
  let history = useHistory();
  const [specialtiesDate, setSpecialtiesDate] = React.useState<any>({
    specialties: "",
  });
  const [search, setSearch] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [phyId] = React.useState<string | null>(null);
  React.useEffect(() => {
    const asyncAwait = async () => {
      await props.getOnlineUsers();
    };
    asyncAwait();
  }, []);

  React.useMemo(() => {
    setSearch(props.searchPhysicianSuccess);
  }, [props.searchPhysicianSuccess, props.isOnlineSuccess, props.profile]);
  // const classes = useStyle();
  const cardClass = useStyles();
  const handleSubmit = (e: any) => {
    e.preventDefault();
    props.searchPhysician(specialtiesDate);
    setSpecialtiesDate({
      specialties: "",
    });
  };

  // const onlineNow = props.isOnlineSuccess;
  // const handleClickOpen = (id: string | null) => {
  //   setPhyId(id);
  //   setOpen(true);
  // };

  const [errorResponse, setErrorResponse] = React.useState("");
  const [successResponse, setSuccessResponse] = React.useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = React.useState(false);
  const [openErrorPopup, setOpenErrorPopup] = React.useState(false);

  const [openCrisisPopup, setOpenCrisisPopup] = React.useState(false);

  const [initMessage, setInitText] = React.useState<string>("");

  const [requesting, setRequesting] = React.useState<boolean>(false);

  const [requestSuccessfull, setRequestSuccessfull] = React.useState<{
    success: boolean;
    errorMessage: string;
  }>({ success: false, errorMessage: "" });
  const requestPhysician = async () => {
    setRequesting(true);
    setRequestSuccessfull({ success: false, errorMessage: "" });
    let Useid = "6168d80a6c3ba4000bda4be9"; /// DD Provider

    const response = await patientProfileCalls.initPatientRequest(Useid, initMessage);
    // send sms
    const phy = props.physicians.filter((phy) => phy._id === "6168d80a6c3ba4000bda4be9");
    if (phy) {
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New ping message on Delores\n\n" +
        "You have a new ping message from user. To see the message, login to "
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: phy[0].phoneNumber });
    }
    setRequesting(false);
    setRequestSuccessfull(response);
    setOpenCrisisPopup(true);
  };



  // begin constant importannt for the bbuttom to send DD a message: cominng from SelectCenter
const _SelectCenter = (props: Props2) => {
  const classes = useStyle();
  //const cardClass = useStyles();
  const [selectedSpecialty, setSelectedSpecialty] = React.useState<any>("Any");
  const [selectedState, setSelectedState] = React.useState<any>("Any");
  const [selectedCenter, setSelectedCenter] = React.useState<string>("Any");
  const [searchRes, setSearchRes] = React.useState<any>([]);
  const [centerRes, setCentersRes] = React.useState<ICenter>();
  const [messageVal, setMessageVal] = React.useState("");
  const [openScheduleDiag, setOpenScheduleDiag] = React.useState(false);

  const [phyAppointment, setPhyAppointment] = React.useState<any>([]);

  const [phyId2, setPhyId2] = React.useState<string | null>(null);
  const [centerId, setCenterId] = React.useState<string | null>(null);
//  const [/*open,*/ setOpen] = React.useState(false);
  const [rooms, setRooms] = React.useState("");
//  let [selectedPhyscian, setselectedPhyscian] = React.useState(
//  props.physicians.length ? props.physicians[0]._id : "");

  const asyncAwait = async () => {
    await props.getAllCenters();
    // setCenters(props.centers);
  };
  React.useEffect(() => {
    asyncAwait();
    filterCenter();
    // @ts-ignore
  }, []);

  useEffect(() => {
    setSelectedSpecialty("Any");
    setSelectedCenter("Any");
    setSelectedState("Any");
    window.scrollTo(0, 0);
    filterCenter();
  }, [searchRes]);

  const handleClickCenterOpen = (id: string | null) => {
    setCenterId(id);
    setOpen(true);
  //  setselectedPhyscian(specialistProfile._id as string);
  };
  const handleClose2 = () => {
    setOpen(false);
    setPhyId2("");
    setOpenScheduleDiag(false);
  };
  const handleScheduleOpen = (id: string | null) => {
    const phyDetail: any = props.physicians?.filter((phy) => {
      return phy._id === id;
    });

    setPhyAppointment(phyDetail[0]);

    setPhyId2(id);
    setOpenScheduleDiag(true);
  };
  const [commentVal, setCommentVal] = React.useState("");
//  const [initMessage, setInitText] = React.useState<string>("");
  //const [requesting, setRequesting] = React.useState<boolean>(false);

/*
  const [requestSuccessfull, setRequestSuccessfull] = React.useState<{
    success: boolean;
    errorMessage: string;
  }>({ success: false, errorMessage: "" });
  const requestPhysician = async () => {
    setRequesting(true);
    setRequestSuccessfull({ success: false, errorMessage: "" });
    if (selectedPhyscian = "6168d8766c3ba4000bda4bea") {
      var Useid = "6168d80a6c3ba4000bda4be9"; /// DD Provider
    } else {
      var Useid = selectedPhyscian
    }
    const response = await patientProfileCalls.initPatientRequest(Useid, initMessage);
    // send sms
    const phy = props.physicians.filter((phy) => phy._id === selectedPhyscian);
    if (phy) {
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New ping message on Delores\n\n" +
        "You have a new ping message from user. To see the message, login to "
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: phy[0].phoneNumber });
    }
    setRequesting(false);
    setRequestSuccessfull(response);
    setOpenCrisisPopup(true);
  }; */

  const handleSendMessage = async (specialistProfile: any) => {
    const URL = `${process.env.REACT_APP_API_URL}/chat`;
    const socket = io(URL, { transports: ["polling"] });
    socket.on("joinedRoom", (room: string) => {
      console.log("joined room ", room);
      setRooms(room);
    });
    console.log("props.patientChats==", props.patientChats);
    console.log(
      "props?.profile?._id==",
      props?.profile?._id,
      "specialistProfile?._id==",
      specialistProfile?._id
    );

    const initialChat = props.patientChats.filter((pc) => pc.isGroup === false)[0];
    console.log("initialChat==", initialChat);

    const PatientChatId = props.patientChats.filter(
      (pc) =>
        props?.profile?._id === pc.patient?._id &&
        pc.physicians?.map((phy: any) => specialistProfile?._id === phy?._id)
    )[0]?._id;

    console.log(
      "filter==",
      props.patientChats.map(
        (pc) =>
          props?.profile?._id === pc.patient?._id &&
          pc.physicians?.map((phy: any) => specialistProfile?._id === phy?._id && pc?._id)
      )
    );

    console.log("matchPatientId==", PatientChatId);
    socket.on("connect", () => {
      console.log("connected");
      if (rooms) {
        socket.emit("leaveRoom", PatientChatId);
        socket.emit("joinRoom", PatientChatId);
      }
    });

    if (messageVal === "") return;
    console.log(`Adding a new message: ${messageVal} to Chat with ID: ${specialistProfile._id}`);
    const newMessage: NewMessageDTO = {
      chatID: PatientChatId,
      text: messageVal,
    };

    try {
      const res = await axios.post<IMessage>(`/messages/new-message`, newMessage, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      });
      socket.emit("send-message", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("handleMessagesNotifications", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("activeTime", newMessage);
      // if (specialistProfile) {
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New message on Delores.\n\n" +
        `You have a new message from ${props?.profile?.firstName}. To see the message, login to `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: specialistProfile.phoneNumber });
      // }
      if (res.status === 201) {
        setOpenCrisisPopup(true);
        setMessageVal("");
      }
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      socket.disconnect();
    } catch (err) {
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      console.log("error", err);
    }
  };

  React.useMemo(() => {
    if (props.physicians && props.physicians?.length > 0) {
      setSearchRes(props.physicians);
    }
    if (props.searchPhysicianSuccess) {
      let arr = [];
      arr = props.searchPhysicianSuccess.filter(
        (value, index, self) => index === self.findIndex((t) => t._id === value._id)
      );
      setSearchRes(arr);
    }
  }, [props.searchPhysicianSuccess, props.physicians]);

  const filterCenter = () => {
    let result: any = [];
    // eslint-disable-next-line array-callback-return
    searchRes.map((phy: any) => {
      const res = props?.centers?.filter((center: any) => phy?.center === center?._id);
      const check = result?.filter((r: any) => r?._id === res[0]?._id);
      if (!check?.length) {
        result.push(res[0]);
      }
    });
    setCentersRes(result ? result : centerRes);
  };

//  const [openCrisisPopup, setOpenCrisisPopup] = React.useState(false);
  const onlineNow = props.isOnlineSuccess;

  const handleSendCenterMessage = async () => {
    if (centerId) {
      await props.ping({
        initText: messageVal ? messageVal : "",
        toCenter: centerId,
        file: undefined,
      });
    }
    // TODO : sendSMS
  };
};

  // ennd constant importannt for the bbuttom to send DD a message: cominng from SelectCenter
  const handleClose11 = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessPopup(false);
    setOpenErrorPopup(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const hanldeSpecialityClick = (specialtyTitle: any) => {
    setSpecialtiesDate({ specialties: specialtyTitle });
    history.push(`patient/specialties`, { state: specialtyTitle });
  };

  const handleChat = () => {
    if (phyId && props.profile?.physicians?.find((phy) => phy._id === phyId)) {
      history.push("/patient/portal/messages");
    } else {
      history.push("/patient/portal/selectCenter");
    }
  };
  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img src={Logo} alt={"delores"} style={{ width: "300px" }} />
      </div>
      <div className={cardClass.cardMainDiv}>
        <div className={cardClass.cardHeaderMainDiv}>
          <div
            onClick={() => hanldeSpecialityClick("General Clinical Advice")}
            style={{ textDecoration: "none", color: "white", cursor: "pointer" }}
          >
            <CardHeader
              className={cardClass.cardHeader}
              title="Find An Expert For Your Situation"
              titleTypographyProps={{ variant: "h6" }}
            ></CardHeader>
          </div>
          <div>
             <div style={{display: "flex"}}>
               <div style={{display: "flex", flex: 1}}>
               <Widget id="ArwE5PRA" style={{ width: '100%', height: '600px' }} className="AppSchedule2">
              </Widget>
               </div>
               <div style={{display: "flex", flex: 1}}>
               <Widget id="vC5NOk4Q" style={{ width: '100%', height: '600px' }} className="AppBilling">
              </Widget>
               </div>
             </div>
             <div /*this will be inside the top flexWrax - for adding a button to confirm a new session (comes from selectCenter [contact a provider]) */>

             <div className={cardClass.cardMainDiv}>
                <div className={cardClass.cardHeaderMainDiv}>
                  <div style={{ textDecoration: "none", color: "white", cursor: "pointer", textAlign: "center" }}>
                    <CardHeader
                      className={cardClass.cardHeader}
                      title="After booking a new session send a confirmation message to Delores to verify"
                      titleTypographyProps={{ variant: "h6" , align: "center"}}
                    ></CardHeader>
                  </div>
                </div>
              </div>
              <Button
                    autoFocus
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                      requestPhysician (); setOpenSuccessPopup (true);

                      // props.getPhysicianProfile()
                    }}
                    style={{
                      alignSelf: "center",
                      float: "right",
                      marginTop: "8px",
                      minWidth: "100%",
                    }}
                  >
                    Click to Confirm Scheduling Form Submission
                  </Button>
                  <Snackbar
                    open={openSuccessPopup}
                    autoHideDuration={7000}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleClose11}
                  >
                    <Alert onClose={handleClose11} severity="success">
                      Success. Your form submission request has been submitted, please allow up to 48 hours to confirm your schedule.
                    </Alert>
                  </Snackbar>
                  <Snackbar
                    open={openErrorPopup}
                    autoHideDuration={3000}
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    onClose={handleClose11}
                  >
                    <Alert onClose={handleClose11} severity="error">
                      {errorResponse}
                    </Alert>
                  </Snackbar>
             </div //this will close the div inside the top flexWrap - for adding a button to confirm a new session [contact a provider]
>
           </div>
        </div>

      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
        <DialogTitle id="responsive-dialog-title">
          {"Professional Profile Info"}
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            endIcon={<ChatIcon />}
            onClick={handleChat}
            style={{
              float: "right",
              marginTop: "8px",
              marginRight: -10,
            }}
          >
            message
          </Button>
        </DialogTitle>
        <Paper
          style={{
            padding: "16px",
            margin: "auto",
            width: "80%",
            height: "auto",
            backgroundColor: "#f5f5f5",
            borderRadius: 10,
            boxShadow: "0px 0px 10px #00000029",
            border: "1px solid #e0e0e0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: "10%",
            marginTop: "10px",
          }}
        >
          {search &&
            search.map((physicianProfile: any, i: any) => {
              return physicianProfile._id === phyId ? (
                <>
                  <Grid container spacing={2} key={i}>
                    <Grid item>
                      <Avatar
                        alt={physicianProfile?.firstName}
                        src={
                          physicianProfile?.files?.filter((f: any) => f.isProfile)[0]?.url ||
                          `https://via.placeholder.com/150?text=Profile`
                        }
                        style={{ width: 150, height: 150, alignSelf: "center" }}
                      />
                    </Grid>
                    <Grid item xs={12} sm container>
                      <Grid item xs container direction="column" spacing={2}>
                        <Grid item xs>
                          <Typography gutterBottom variant="subtitle1" component="div">
                            {physicianProfile?.firstName}
                          </Typography>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <HomeIcon />
                            <Typography
                              style={{ marginLeft: "10px" }}
                              component="div"
                              variant="subtitle2"
                            >
                              Address:
                              {physicianProfile?.practiceAddress}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <AccountCircleIcon />
                            <Typography
                              style={{ marginLeft: "10px" }}
                              component="div"
                              variant="subtitle2"
                            >
                              Bio: {physicianProfile?.bio ? physicianProfile?.bio : "no bio"}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <PhoneIcon />
                            <Typography
                              style={{ marginLeft: "10px" }}
                              component="div"
                              variant="subtitle2"
                            >
                              Mobile Number:{" "}
                              {physicianProfile?.publicNumber
                                ? physicianProfile?.publicNumber
                                : "No Public Number"}
                            </Typography>
                          </Box>
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginTop: "8px",
                            }}
                          >
                            <PersonPinCircleIcon />
                            State : {physicianProfile?.state ? physicianProfile?.state : "No State"}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null;
            })}
        </Paper>
        <DialogActions>
          <Button
            autoFocus
            variant="outlined"
            color="primary"
            endIcon={<Close />}
            onClick={handleClose}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export const mapStateToProps = (state: any) => ({
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  searchPhysicianSuccess: patientProfileSelectors.searchPhysicianSuccess(
    state.patients.patientProfile
  ),
  searchPhysicianFailure: patientProfileSelectors.searchPhysicianFailure(
    state.patients.patientProfile
  ),
  profile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  // begin export imports for the bbuttom to send DD a message: cominng from SelectCenter
  centers: basicSelectors.fetchCenters(state.centers.basicCalls),
  patientChats: patientChatSelector.patientChats(state.patients.chat),
  physicians: superBasicSelectors.fetchPhysicians(state.supers.basicCalls),
  errorWhileGetCenters: basicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  getPatientError: patientProfileSelectors.isProfileFailure(state.patients.patientProfile),
  gettingPatient: patientProfileSelectors.isCallingProfile(state.patients.patientProfile),

  // end export imports for the bbuttom to send DD a message: cominng from SelectCenter

});


export const mapDispatchToProps = (dispatch: any) => ({
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  searchPhysician: (search: SearchPhyDTO) => dispatch(searchPhysician(search)),
  // begin export imports (this upper section) for the bbuttom to send DD a message: cominng from SelectCenter
  getAllCenters: () => dispatch(basicCalls.getAllCenters()),
  getPhysicians: () => dispatch(superBasicCalls.getPhysicians()),
  getPatient: () => dispatch(patientProfileCalls.getPatientProfile()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),
  ping: (pingDto: PingRequestDTO) => dispatch(PingRequestsCalls.createPingRequest(pingDto)),
  // end export imports (this upper section) for the bbuttom to send DD a message: cominng from SelectCenter


});
export const PingProfessioanl = connect(mapStateToProps, mapDispatchToProps)(_PingProfessioanl);
