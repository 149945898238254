export const heartWPCCalling = "@/patient/@heartWPCCalling";
export const heartWPCSuccess = "@/patient/@heartWPCSuccess";
export const heartWPCFailure = "@/patient/@heartWPCFailure";

export const kidneyWPCCalling = "@/patient/@kidneyWPCCalling";
export const kidneyWPCSuccess = "@/patient/@kidneyWPCSuccess";
export const kidneyWPCFailure = "@/patient/@kidneyWPCFailure";

export const liverWPCCalling = "@/patient/@liverWPCCalling";
export const liverWPCSuccess = "@/patient/@liverWPCSuccess";
export const liverWPCFailure = "@/patient/@liverWPCFailure";
