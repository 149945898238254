import { IAccessToken } from "../../types";
import * as types from "./types";

export const loggingIn = () => ({
  type: types.loggingIn,
});
export const loginSuccess = (token: string) => ({
  type: types.loginSuccess,
  payload: token,
});

export const loginFailure = (errorMessage: string) => ({
  type: types.loginFailure,
  payload: errorMessage,
});
export const loggedOut = () => ({
  type: types.loggedOut,
});
export const registerIn = () => ({
  type: types.registerIn,
});
export const registerSuccess = (success: string) => ({
  type: types.registerSuccess,
  payload: success,
});
export const registerFailure = (errorMessage: string) => ({
  type: types.registerFailure,
  payload: errorMessage,
});
export const verifyingToken = () => ({
  type: types.verifyingToken,
});
export const verifyTokenSuccess = (token: IAccessToken) => ({
  type: types.verifyTokenSuccess,
  payload: token,
});
export const verifyTokenFailure = (errorMessage: string) => ({
  type: types.verifyTokenFailure,
  payload: errorMessage,
});
