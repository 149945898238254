export const loggingIn = "@/super/@loginStart";
export const loginSuccess = "@/super/@loginSuccess";
export const loginFailure = "@/super/@loginFailure";

export const verifyingToken = "@/super/@verifyTokenStart";
export const verifyTokenSuccess = "@/super/@verifyTokenSuccess";
export const verifyTokenFailure = "@/super/@verifyTokenFailure";

export const loggedOut = "@/super/@loggedOut";
export const registerIn = "@/super/@registerStart";
export const registerSuccess = "@/super/@registerSuccess";
export const registerFailure = "@/super/@registerFailure";
