export const centerProfileCalling = "@/center/@profileCalling";
export const centerProfileSuccess = "@/center/@profileSuccess";
export const centerProfileFailure = "@/center/@profileFailure";

export const centerProfileUpdating = "@/center/@centerProfileUpdating";
export const centerProfileUpdateSuccess = "@/center/@centerProfileUpdateSuccess";
export const centerProfileUpdateFailure = "@/center/@centerProfileUpdateFailure";

export const centerNumberUpdating = "@/center/@centerNumberUpdating";
export const centerNumberUpdateSuccess = "@/center/@centerNumberUpdateSuccess";
export const centerNumberUpdateFailure = "@/center/@centerNumberUpdateFailure";

export const centerNumberVerificationUpdating = "@/center/@centerNumberVerificationUpdating";
export const centerNumberVerificationUpdateSuccess =
  "@/center/@centerNumberVerificationUpdateSuccess";
export const centerNumberVerificationUpdateFailure =
  "@/center/@centerNumberVerificationUpdateFailure";

export const updatePhysicianRequest = "@/center/@updatePhysicianRequest";
export const updatePhysicianRequestSuccess = "@/center/@updatePhysicianRequestSuccess";
export const updatePhysicianRequestFailure = "@/center/@updatePhysicianRequestFailure";
// adding type of file
export const updateCenterFilePrivacyCalling = "@/center/@updateCenterFileCalling";
export const updateCenterFilePrivacySuccess = "@/center/@updateCenterFileSusccess";
export const updateCenterFilePrivacyFailure = "@/center/@updateCenterFileFailure";
