import * as types from "./types";

const initialState = {
  myNotes: [],
  creatingNote: false,
  updatingNote: false,
  gettingMyNotes: false,
  getMyNotesFailure: "",
  createNoteFailure: "",
  updateNoteFailure: "",
};
export const notesReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.creatingNote:
      return {
        ...state,
        creatingNote: true,
        createNoteFailure: "",
      };
    case types.createNoteSuccess:
      return {
        ...state,
        creatingNote: false,
        myNotes: action.payload,
      };
    case types.createNoteFailure:
      return {
        ...state,
        creatingNote: false,
        createNoteFailure: action.payload,
      };

    case types.updatingNote:
      return {
        ...state,
        updatingNote: true,
        updateNoteFailure: "",
      };
    case types.updateNoteSuccess:
      return {
        ...state,
        updatingNote: false,
        myNotes: action.payload,
      };
    case types.updateNoteFailure:
      return {
        ...state,
        updatingNote: false,
        updateNoteFailure: action.payload,
      };

    case types.gettingMyNotes:
      return {
        ...state,
        gettingMyNotes: true,
        myNotes: [],
        getMyNotesFailure: "",
      };
    case types.getMyNotesSuccess:
      return {
        ...state,
        gettingMyNotes: false,
        myNotes: action.payload,
      };
    case types.getMyNotesFailure:
      return {
        ...state,
        gettingMyNotes: false,
        getMyNotesFailure: action.payload,
      };
    default: {
      return state;
    }
  }
};
