import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { USER_TYPE } from "../../../store/types";
import Management from "../../dashboardComponents/center/Management";
import { Explore } from "../../dashboardComponents/physician/Explore";
import Home from "../../dashboardComponents/physician/Home";
import Portal from "../../dashboardComponents/physician/Portal";
import { Profile } from "../../dashboardComponents/Profile";
import Appbar from "../../navbars/Appbar";
import SideBar from "../../sidebars/Sidebar";
import { useStyles } from "../routesMuiStyling";
import PingRoutes from "./PingRoutes";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../../theme/ColorPalette";
import PatientList from "../../dashboardComponents/physician/PatientProfile/PatientList";
import PatientProfile from "../../dashboardComponents/physician/PatientProfile/ItemPatientProfile";
import FeedForPhysician from "../../dashboardComponents/physician/FeedForPhysician";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { chatCalls as physicianChatCalls } from "../../../store/physician/chat";
import PhysicalTimeKeeeping from "../../dashboardComponents/physician/PhysicalTimeKeeeping";

import { connect } from "react-redux";
import { feedSelectors } from "../../../store/feed";
import { feedCalls } from "../../../store/feed";
import PhysicalScheduleTime from "../../dashboardComponents/physician/PhysicalScheduleTime";

const onlineURL = `${process.env.REACT_APP_API_URL}/userOnline`;

interface OwnProps {
  history: any;
}

interface DispatchProps {
  handleDrawerOpen: () => Promise<void>;
}
interface StateProps {
  drawerOpen: boolean;
}

type Props = StateProps & DispatchProps & OwnProps;

export function _Main(props: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  // const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };

  // const handleDrawerOpen = () => {
  //   if (USER_TYPE !== undefined) {
  //     setOpen(!open);
  //   }
  // };
  useEffect(() => {
    var socket: SocketIOClient.Socket = io(onlineURL, {
      transports: ["polling", "websocket"],
    });
    socket.on("connect", () => {
      if (localStorage.getItem("_id") && USER_TYPE.PHYSICIAN) {
        socket.emit("msgToServer", localStorage.getItem("_id"));
        socket.emit(
          "dormantTime",
          `${localStorage.getItem("_id")}/${USER_TYPE.PHYSICIAN}/${new Date()}`
        );
      }
    });
  }, []);

  useEffect(() => {
    dispatch(physicianChatCalls.getAllChats());
  }, [dispatch]);

  return (
    <ThemeProvider theme={deloresTheme ? lightTheme : darkTheme}>
      <div className={classes.main}>
        <SideBar
          history={history}
          type={"physician"}
          opendrawer={window.screen.width <= 668 ? props.drawerOpen : !props.drawerOpen}
          handleDrawerOpen={props.handleDrawerOpen}
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: deloresTheme ? "white" : "#272822",
            color: deloresTheme ? "black" : "white",
          }}
        >
          <header className={classes.header}>
            <Appbar
              type={USER_TYPE.PHYSICIAN}
              handleTheme={handleTheme}
              deloresTheme={deloresTheme}
              handleDrawerOpen={props.handleDrawerOpen}
            />
          </header>
          <div className={classes.routes}>
            <Switch>
              <Route exact path="/physician" component={Home} />
              <Route path="/physician/portal" component={Portal} />
              <Route path="/physician/ping" component={PingRoutes} />
              <Route path="/physician/management" component={Management} />
              <Route exact path="/physician/explore" component={Explore} />
              <Route
                exact
                path="/physician/profile"
                render={() => <Profile userType={USER_TYPE.PHYSICIAN} />}
              />
              <Route exact path="/physician/patients-profile" component={PatientList} />
              <Route
                exact
                path="/physician/patient-profile/:id"
                children={<PatientProfile user={USER_TYPE.PHYSICIAN} />}
              />
              <Route path="/physician/feeds" component={FeedForPhysician} />
              <Route path="/physician/time-keeping" component={PhysicalTimeKeeeping} />
              <Route path="/physician/scheduling" component={PhysicalScheduleTime} />
              <Redirect to="/physician" />
            </Switch>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  handleDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});

const mapStateToProps = (state: any) => ({
  drawerOpen: feedSelectors.drawerOpen(state.feeds),
});

export const Main = connect(mapStateToProps, mapDispatchToProps)(_Main);
