import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import React from "react";
import { ICenter, IPhysician } from "../../../../../store/types";
import { PrimaryButton } from "../../../../base/Components/PrimaryButton";

export const PhysicianList = (props: {
  centerProfile: ICenter;
  denyPhysicianRequest: (id: string) => Promise<void>;
}) => {
  const [physician, setPhysician] = React.useState("");
  const [openPopup, setOpenPopup] = React.useState(false);
  const handleDeletePhysician = (physicianId: string) => {
    setPhysician(physicianId);
    setOpenPopup(true);
  };
  const handlePopup = () => {
    setOpenPopup(false);
  };
  const handleAgree = async () => {
    await props.denyPhysicianRequest(physician);
    setOpenPopup(false);
  };
  return (
    <>
      <div style={{ width: "90%", margin: "0 auto" }}>
        <List>
          {props.centerProfile.physicians.map((value: IPhysician, i: number) => {
            const labelId = `checkbox-list-label-${value}`;
            return (
              <>
                <ListItem
                  alignItems="center"
                  key={i}
                  role={undefined}
                  dense
                  button
                  style={{
                    height: "12vh",
                    margin: "0.5rem 0",
                  }}
                >
                  <ListItemText id={labelId} primary={`${value.firstName} ${value.lastName}`} />
                  <ListItemSecondaryAction>
                    <PrimaryButton
                      onClick={() => handleDeletePhysician(value._id)}
                      title="Remove"
                    />
                  </ListItemSecondaryAction>
                </ListItem>
                <ConfirmationPopup
                  title="Are you sure you want to remove the Professional?"
                  text={""}
                  open={openPopup}
                  handleOpenPopup={handlePopup}
                  buttonText="Remove Professional"
                  handleAgree={handleAgree}
                />
                <Divider />
                {/* <Snackbar
                  open={openPopup}
                  autoHideDuration={10000}
                  onClose={(e, reason) => {
                    if (reason === "timeout") setOpenPopup(false);
                  }}
                >
                  <AlertPopup
                    title="Physician Successfully Removed"
                    text={""}
                    open={openPopup}
                    handleOpenPopup={handlePopup}
                    buttonText="Acknowledge"
                  />
                </Snackbar> */}
              </>
            );
          })}
        </List>
      </div>
    </>
  );
};

function ConfirmationPopup(props: {
  title: string;
  text: string;
  open: boolean;
  handleOpenPopup: any;
  buttonText: string;
  handleAgree: any;
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.handleOpenPopup}
        aria-labelledby="responsive-dialog-title"
        style={{ background: "none" }}
      >
        <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.text}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleOpenPopup} color="primary" autoFocus>
            DisAgree
          </Button>
          <Button onClick={props.handleAgree} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
