import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Explore } from "../../dashboardComponents/center/Explore";
import Home from "../../dashboardComponents/center/Home";
import { Setting } from "../../dashboardComponents/center/Setting";
import SideBar from "../../sidebars/Sidebar";
import { Profile } from "../../dashboardComponents/Profile";
import Appbar from "../../navbars/Appbar";
import { useStyles } from "../routesMuiStyling";
import { USER_TYPE } from "../../../store/types";
import Management from "../../dashboardComponents/center/Management";
import PingRoutes from "./PingRoutes";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../../theme/ColorPalette";
import feedsUpload from "../../dashboardComponents/center/feeds/FeedsUpload";
import io from "socket.io-client";
import { useDispatch } from "react-redux";
import { chatCalls as centerChatCalls } from "../../../store/centers/chat";
import { useHistory } from "react-router-dom";

import { connect } from "react-redux";
import { feedSelectors } from "../../../store/feed";
import { feedCalls } from "../../../store/feed";
import ProviderScheduleTime from "../../dashboardComponents/center/ProviderScheduleTime";

interface OwnProps {
  history: any;
}

interface DispatchProps {
  handleDrawerOpen: () => Promise<void>;
  // getAll: () => Promise<void>;
}
interface StateProps {
  drawerOpen: boolean;
  // handleDrawerOpen: any;
}
type Props = StateProps & DispatchProps & OwnProps;

const URL = `${process.env.REACT_APP_API_URL}/userOnline`;
export function _Main(props: Props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  // const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };
  // const handleDrawerOpen = () => {
  //   setOpen(!open);
  //   // if (open === true) {
  //   //   document.body.style.marginLeft = "0px";
  //   // } else {
  //   //   document.body.style.marginLeft = "280px";
  //   // }
  // };
  useEffect(() => {
    var socket: SocketIOClient.Socket = io(URL, {
      transports: ["polling", "websocket"],
    });
    socket.on("connect", () => {
      if (localStorage.getItem("_id") !== null) {
        socket.emit("msgToServer", localStorage.getItem("_id"));
      }
    });
    socket.on("disconnect", () => {
      console.log("disconnect");
    });
  }, []);

  useEffect(() => {
    dispatch(centerChatCalls.getAllChats());
  }, [dispatch]);

  return (
    <ThemeProvider theme={deloresTheme ? lightTheme : darkTheme}>
      <div className={classes.main}>
        <SideBar
          history={history}
          type={"center"}
          open={props.drawerOpen}
          opendrawer={window.screen.width <= 668 ? props.drawerOpen : !props.drawerOpen}
          handleDrawerOpen={props.handleDrawerOpen}
          // className={!open && classes.body}
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: deloresTheme ? "white" : "#272822",
            color: deloresTheme ? "black" : "white",
          }}
        >
          <header className={classes.header}>
            <Appbar
              type={USER_TYPE.CENTER}
              handleTheme={handleTheme}
              deloresTheme={deloresTheme}
              handleDrawerOpen={props.handleDrawerOpen}
            />
          </header>
          <div className={classes.routes}>
            <Switch>
              <Route exact path="/center" component={Home} />
              <Route path="/center/management" component={Management} />
              <Route path="/center/ping" component={PingRoutes} />
              <Route exact path="/center/explore" component={Explore} />
              <Route exact path="/center/settings" component={Setting} />
          //    <Route path="/center/feedUploads" component={feedsUpload} />
              <Route
                exact
                path="/center/profile"
                render={() => <Profile userType={USER_TYPE.CENTER} />}
              />
              <Route path="/center/scheduling" component={ProviderScheduleTime} />
              <Redirect to="/center" />
            </Switch>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  handleDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});

const mapStateToProps = (state: any) => ({
  drawerOpen: feedSelectors.drawerOpen(state.feeds),
});

export const Main = connect(mapStateToProps, mapDispatchToProps)(_Main);
