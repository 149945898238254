import { CardHeader, withStyles } from "@material-ui/core";
import React, { Component } from "react";
// import { getCenterProfile } from "../../../../../store/centers/profile/apiCalls";
// import { getPhysicianProfile } from "../../../../../store/physician/profile/apiCalls";
import { ICenter, IPatient, IPhysician } from "../../../../../store/types";
import { USER_TYPE } from "../../../../../store/types/enums";
import { topstyles } from "../../muiStyling";
import { UploadDocument } from "./UploadDocument";

interface Props {
  classes: any;
  userType: USER_TYPE;
  patientProfile?: IPatient | undefined;
  physicianProfile?: IPhysician | undefined;
  centerProfile?: ICenter | undefined;
  getPatientProfile: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
  getCenterProfile: () => Promise<void>;
  read?: boolean;
}
const renderUploadComp = (
  userType: USER_TYPE,
  patientProfile: IPatient | undefined,
  physicianProfile: IPhysician | undefined,
  centerProfile: ICenter | undefined,
  getPatientProfile: () => Promise<void>,
  getPhysicianProfile: () => Promise<void>,
  getCenterProfile: () => Promise<void>,
  read?: boolean
) => {
  switch (userType) {
    case USER_TYPE.PATIENT:
      return (
        <UploadDocument
          type={userType}
          profile={patientProfile}
          getProfile={getPatientProfile}
          read={read}
        />
      );
    case USER_TYPE.PHYSICIAN:
      return (
        <UploadDocument
          type={userType}
          profile={physicianProfile}
          getProfile={getPhysicianProfile}
          read={read}
        />
      );
    case USER_TYPE.CENTER:
      return (
        <UploadDocument
          type={userType}
          profile={centerProfile}
          getProfile={getCenterProfile}
          read={read}
        />
      );
    default:
      break;
  }
};
class _UploadDocumentComp extends Component<Props> {
  render() {
    const {
      classes,
      userType,
      patientProfile,
      physicianProfile,
      centerProfile,
      getPatientProfile,
      getPhysicianProfile,
      getCenterProfile,
    } = this.props;
    return (
      <>
        <CardHeader
          title={
            patientProfile
              ? `${patientProfile?.firstName + " " + patientProfile?.lastName} Files`
              : "Your Files"
          }
          classes={{ content: classes.top }}
        />
        {renderUploadComp(
          userType,
          patientProfile,
          physicianProfile,
          centerProfile,
          getPatientProfile,
          getPhysicianProfile,
          getCenterProfile,
          this.props?.read
        )}
      </>
    );
  }
}
export const UploadDocumentComp = withStyles(topstyles)(_UploadDocumentComp);
