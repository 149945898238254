import { Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import Axios from "axios";
import React, { Component } from "react";
import { ICenter } from "../../../../../store/types/center";
import { IChat } from "../../../../../store/types/chat";
import { IPatient } from "../../../../../store/types/patient";
import { IPhysician } from "../../../../../store/types/physician";
import PatientDisplayModal from "../../../../base/Components/PatientDisplayModal";
import PhysicianDisplayModal from "../../../../base/Components/PhysicianDisplayModal";
import SingleChatInsight from "./SingleChatInsight";

interface Props {
  profile: ICenter | IPhysician;
  lookingAtGroupDiscussions: Boolean;
}
interface State {
  chats: IChat[];
  showPhysicianProfile: boolean;
  physicianToShow?: IPhysician;
  showPatientProfile: boolean;
  patientToShow?: IPatient;
}
export class ChatInsights extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      chats: [],
      showPhysicianProfile: false,
      physicianToShow: undefined,
      showPatientProfile: false,
      patientToShow: undefined,
    };
  }

  handleShowPhysicianModal = (showVal: boolean, physician?: IPhysician) => {
    this.setState({
      showPhysicianProfile: showVal,
      physicianToShow: physician || undefined,
    });
  };
  handleShowPatientModal = (showVal: boolean, patient?: IPatient) => {
    this.setState({
      showPatientProfile: showVal,
      patientToShow: patient || undefined,
    });
  };
  componentDidMount = async () => {
    const res = await Axios.get<IPhysician[]>(`/management/chat-doc/${this.props.profile._id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    let chats: IChat[] = [];
    res.data
      .map((phy: IPhysician) => {
        return phy.chats || [];
      })
      .flat()
      .forEach((chat: IChat) => {
        const bool = chats.filter((res: IChat) => res._id === chat._id).length;
        if (!bool) chats.push(chat);
      });
    this.setState({
      chats: chats,
    });
  };
  render() {
    return this.state.chats.length ? (
      <div>
        <Table style={{ margin: "15px 0px" }} component={Paper}>
          <TableHead style={{ background: "#075d19" }}>
            <TableRow>
              <TableCell style={{ color: "whitesmoke", textAlign: "center" }}>
                <h4>User</h4>
              </TableCell>
              <TableCell style={{ color: "whitesmoke", textAlign: "center" }}>
                <h4>{this.props.lookingAtGroupDiscussions ? "Doctors" : "Accepting Doctor"}</h4>
              </TableCell>
              <TableCell style={{ color: "whitesmoke", textAlign: "center" }}>
                <h4>Number Of Messages</h4>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody style={{ background: "rgb(216, 216, 216)" }}>
            {this.state.chats.map((chat) => {
              return this.props.lookingAtGroupDiscussions === chat.isGroup ? (
                <SingleChatInsight
                  handleShowPhysicianModal={this.handleShowPhysicianModal}
                  handleShowPatientModal={this.handleShowPatientModal}
                  lookingAtGroupDiscussion={this.props.lookingAtGroupDiscussions}
                  chat={chat}
                ></SingleChatInsight>
              ) : null;
            })}
          </TableBody>
        </Table>
        <PhysicianDisplayModal
          show={this.state.showPhysicianProfile}
          physician={this.state.physicianToShow}
          handleShowPhysicianModal={this.handleShowPhysicianModal}
        />
        <PatientDisplayModal
          show={this.state.showPatientProfile}
          patient={this.state.patientToShow}
          handleShowPatientModal={this.handleShowPatientModal}
        />
      </div>
    ) : null;
  }
}

export default ChatInsights;
