import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Switch,
  Tab,
  Tabs,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { topstyles } from "../../muiStyling";
import axios from "axios";
import { IFileData } from "../../../../../store/types/file-data";
import { FILE_TYPE, USER_TYPE } from "../../../../../store/types/enums";
import { ICenter, IPatient, IPhysician } from "../../../../../store/types";
import FileUpload from "./FileUpload";
import CircleSpinner from "../../../../CircleSpinner";

import FolderModal from "../../../../base/Components/FolderModal";

interface Props {
  type: USER_TYPE;
  profile: IPatient | IPhysician | ICenter | undefined;
  getProfile: () => Promise<void | undefined>;
  read?: boolean;
}
export const UploadDocument = (props: Props) => {
  const classes = topstyles;
  const [activeComp, setActiveComp] = useState("");
  const [renderUpload, setRenderUpload] = useState(false);
  const [displayFiles, setDisplayFiles] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [fileType, setFileType] = useState("");

  const [showPrivacy, setShowPrivacy] = React.useState(true);
  const [state, setState] = useState<{
    file?: File;
    error?: string;
    uploading: boolean;
    uploaded?: boolean;
    hasFiles?: [];
  }>();

  const handleChangePrivacyFiles = () => {
    setShowPrivacy(!showPrivacy);
  };

  const handleClickA = () => {
    setFileType(FILE_TYPE.RADIO);
    setDisplayFiles(true);
    setActiveComp(FILE_TYPE.RADIO);
    setRenderUpload(true);
  };
  const handleClickB = () => {
    setFileType(FILE_TYPE.NOTES);
    setDisplayFiles(true);
    setActiveComp(FILE_TYPE.NOTES);
    setRenderUpload(true);
  };
  const handleClickC = () => {
    setFileType(FILE_TYPE.OTHER);
    setDisplayFiles(true);
    setActiveComp(FILE_TYPE.OTHER);
    setRenderUpload(true);
  };
  const handleClickD = () => {
    setFileType(FILE_TYPE.FOLDER);
    setDisplayFiles(true);
    setActiveComp(FILE_TYPE.FOLDER);
    setRenderUpload(true);
  };
  const renderFileTitle = () => {
    switch (fileType) {
      case FILE_TYPE.RADIO:
        return <p>Radiology Files</p>;
      case FILE_TYPE.NOTES:
        return <p>Notes Files</p>;
      case FILE_TYPE.OTHER:
        return <p>Other Files</p>;
      case FILE_TYPE.FOLDER:
        return (
          <>
            {props.type === USER_TYPE.PATIENT && (
              <div
                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={() => setShowModal(true)}
              >
                <Add />
                <p>New Folder</p>
              </div>
            )}
          </>
        );
      default:
        break;
    }
  };

  const uploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!(e.currentTarget.files && e.currentTarget.files.length > 0)) return;
    const file = e.currentTarget.files[0];
    setState({
      file: file,
      uploading: false,
      error: "",
    });
    const data = new FormData();
    data.append("fileField", file);
    data.append("fileType", activeComp);
    setState({ ...state, uploading: true });
    try {
      const res = await axios.post<IFileData>("/files-manager/upload-file", data, {
        headers: {
          Authorization: `Bearer ${
            localStorage.getItem("patientToken") ||
            localStorage.getItem("physicianToken") ||
            localStorage.getItem("centerToken")
          }`,
        },
      });
      setState({ ...state, uploading: false });
      if (res.status === 201) {
        setState({ ...state, uploading: false, uploaded: true });
        await props.getProfile();
      }
    } catch (error) {
      setState({ ...state, uploading: false, uploaded: false });
    }
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }
  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }
  return (
    <>
      <Card style={{ marginTop: "-3em", backgroundColor: "#E1E2E1", color: "black" }}>
        <FormGroup
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            paddingRight: "5%",
            color: "white",
            marginTop: "-2px",
          }}
        >
          {props.read ? (
            ""
          ) : (
            <FormControlLabel
              style={{ marginTop: "-5px" }}
              control={<Switch checked={showPrivacy} onChange={handleChangePrivacyFiles} />}
              label={window.screen.width >= 768 ? "Show Privacy" : ""}
            />
          )}
        </FormGroup>
        <CardContent className={classes.content}>
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={12}
              style={{
                textAlign: "center",
                paddingTop: "4rem",
                paddingBottom: "2rem",
                overflow: "hidden",
              }}
            >
              <Tabs
                value={value}
                indicatorColor="primary"
                textColor="primary"
                onChange={handleChange}
                aria-label="disabled tabs example"
                TabIndicatorProps={{ style: { background: "#075d19" } }}
                centered
              >
                <Tab label="Radiology" onClick={handleClickA}></Tab>
                <Tab label="Users Notes" onClick={handleClickB}></Tab>
                <Tab label="Other Files" onClick={handleClickC}></Tab>
                {props.type === USER_TYPE.PATIENT && (
                  <Tab label="Folder" onClick={handleClickD}></Tab>
                )}
              </Tabs>

              {state?.uploading ? (
                <CircleSpinner />
              ) : (
                <>
                  <TabPanel value={value} index={0}>
                    {props.read ? (
                      ""
                    ) : (
                      <FileUpload
                        fileType={fileType}
                        renderFileTitle={renderFileTitle}
                        displayFiles={displayFiles}
                        uploadFile={uploadFile}
                        renderUpload={renderUpload}
                        profile={props.profile}
                        uploading={state?.uploading}
                        error={state?.error}
                        loggedUserType={props.type}
                        showPrivacy={showPrivacy}
                        getProfile={props.getProfile}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {props.read ? (
                      ""
                    ) : (
                      <FileUpload
                        fileType={fileType}
                        renderFileTitle={renderFileTitle}
                        displayFiles={displayFiles}
                        uploadFile={uploadFile}
                        renderUpload={renderUpload}
                        profile={props.profile}
                        uploading={state?.uploading}
                        error={state?.error}
                        loggedUserType={props.type}
                        showPrivacy={showPrivacy}
                        getProfile={props.getProfile}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    {props.read ? (
                      ""
                    ) : (
                      <FileUpload
                        fileType={fileType}
                        renderFileTitle={renderFileTitle}
                        displayFiles={displayFiles}
                        uploadFile={uploadFile}
                        renderUpload={renderUpload}
                        profile={props.profile}
                        uploading={state?.uploading}
                        error={state?.error}
                        loggedUserType={props.type}
                        showPrivacy={showPrivacy}
                        getProfile={props.getProfile}
                      />
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    {props.read ? (
                      ""
                    ) : (
                      <FileUpload
                        fileType={fileType}
                        renderFileTitle={renderFileTitle}
                        displayFiles={displayFiles}
                        uploadFile={uploadFile}
                        renderUpload={renderUpload}
                        profile={props.profile}
                        uploading={state?.uploading}
                        error={state?.error}
                        loggedUserType={props.type}
                        showPrivacy={showPrivacy}
                        getProfile={props.getProfile}
                      />
                    )}
                  </TabPanel>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {showModal && (
        <FolderModal
          handleShowFolderModal={setShowModal}
          show={showModal}
          getProfile={props.getProfile}
        />
      )}
    </>
  );
};
