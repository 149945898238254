import { createStyles, Theme, makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import { IChat } from "../../store/types/chat";
import { Divider } from "@material-ui/core";
import { USER_TYPE } from "../../store/types";
import { IPhysician } from "../../store/types/physician";
import { ICenter } from "../../store/types/center";
import React, { useState } from "react";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiListItem-root.Mui-selected,  .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#DAE2D5",
        //backgroundColor: `${onTrial === "true" ? '#A685BC' : '#DAE2D5'}
        //onTrial: true? backgroundColor: "#A685BC": backgroundColor: "#DAE2D5"
      },
    },
    inline: {
      display: "inline",
    },
  })
);

const useStyles2 = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      "& .MuiListItem-root.Mui-selected,  .MuiListItem-root.Mui-selected:hover": {
        backgroundColor: "#A685BC",
        //backgroundColor: `${onTrial === "true" ? '#4F2649' : '#DAE2D5'}
        //onTrial: true? backgroundColor: "#4F2649": backgroundColor: "#DAE2D5"
      },
    },
    inline: {
      display: "inline",
    },
  })
);


interface Props {
  userType: USER_TYPE;
  chats?: IChat[];
  convClick: (chat: IChat) => void;
  currentPhy?: string;
  setPhysician?: any;
  currentChat?: IChat;
  profile?: IPhysician | ICenter;
}

export default function ContactsComponent(props: Props) {
  let classes = useStyles();
  let classes2 = useStyles2();
  const [currentclass, setclass] = useState(false)
  const vitaclass = () => {
    setclass(!currentclass);
  };



  /* {props.chats?.map((ch, i) => {
    ch.patient;
    classes: ch.patient?.onTrial === true? classes: classes2
})} */

  return (
    <List className={currentclass ? classes2.root : classes.root}>
      {props.chats?.map((ch, i) => {
        const names = ch.patient
          ? [ch.patient.firstName, ...ch.physicians.map((phy) => phy.firstName)].toString()
          : ch.center
          ? [ch.center.name, ...ch.physicians.map((phy) => phy.firstName)].toString()
          : [...ch.physicians.map((phy) => phy.firstName)].toString();
        return (
          <div key={i}>
            <ListItem
              selected={props.currentChat?._id === ch._id}
              button
              alignItems="flex-start"
              onClick={() => {
                // TODO :FIX CURRENT PHY
               if(ch.patient?.isPay === true) {
                 {vitaclass()};
              }
              console.log(ch.patient?.isPay)
                if (props.setPhysician) {
                  const physicians = ch.physicians;
                  for (const phy in physicians) {
                    if (physicians[phy]._id !== props.currentPhy) {
                      if (physicians[phy]._id) {
                        props.setPhysician(physicians[phy]._id);
                      }
                    }
                  }
                }
                props.convClick(ch);
              }}
            >
              <ListItemText
                primary={
                  ch.isGroup
                    ? names
                    : props.userType === USER_TYPE.PATIENT
                    ? ch.physicians[0].firstName
                    : props.userType === USER_TYPE.CENTER
                    ? ch.physicians[0].firstName
                    : ch.patient
                    ? ch.patient?.firstName
                    : ch.center?.name ||
                      ch.physicians?.filter((phy) => phy._id !== props.profile?._id)[0]?.firstName
                }
                secondary={
                  ch.isGroup
                    ? "Group"
                    : props.userType === USER_TYPE.PATIENT
                    ? ch.physicians[0].center.name
                    : props.userType === USER_TYPE.CENTER
                    ? "Your Professional"
                    : ch.patient
                    ? "User"
                    : ch.center
                    ? "Your Provider"
                    : "Professional"
                }
              />
              <ListItemAvatar>
                <AvatarGroup max={4}>
                  {ch.patient && ch.isGroup ? (
                    <Avatar
                      alt={"User Icon"}
                      src={
                        ch.patient?.files.filter((f) => f.isProfile)[0]?.url ||
                        "https://via.placeholder.com/150?text=Profile"
                      }
                    />
                  ) : (
                    ""
                  )}
                  {ch.physicians
                    .filter((phy) => phy._id !== props.profile?._id)
                    .map((phy, i) => {
                      return (
                        <Avatar
                          key={i}
                          alt={"Physician Icon"}
                          src={
                            phy.files.filter((f) => f.isProfile)[0]?.url ||
                            "https://via.placeholder.com/150?text=Profile"
                          }
                        />
                      );
                    })}
                </AvatarGroup>
              </ListItemAvatar>
            </ListItem>
            <Divider component="li" />
          </div>
        );
      })}
    </List>
  );
}
