import { combineReducers } from "redux";
import { authReducer } from "./auth/reducers";
import { chatReducer } from "./chat";
import { physicianProfileReducer } from "./profile/reducers";
// import {physicianNotesReducer} from '../Notes/reducers'
const appReducer = combineReducers({
  auth: authReducer,
  physicianProfile: physicianProfileReducer,
  chat: chatReducer,
  // notes: physicianNotesReducer
});

export default appReducer;
