import { Button, DialogContent, DialogTitle, makeStyles, Typography } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
// import classes from "*.module.css";

const useStyles = makeStyles((theme) => ({
  dailog: {
    padding: theme.spacing(2),
    position: "absolute",
  },
}));

export const ProfilePopup = (props: {
  children: any;
  openPopupProfile: boolean;
  setOpenPopupProfile: any;
}) => {
  const classes = useStyles();
  return (
    <Dialog open={props.openPopupProfile} classes={{ paper: classes.dailog }}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            style={{
              flexGrow: 1,
            }}
          >
            Update Profile
          </Typography>
          <Button onClick={() => props.setOpenPopupProfile(false)}>X</Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
    </Dialog>
  );
};
