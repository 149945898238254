import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Avatar,
  Badge,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  createStyles,
  makeStyles,
  Typography,
  withStyles,
} from "@material-ui/core";
import { IFeed } from "../../../store/types";
import { feedCalls, feedSelectors } from "../../../store/feed";
import { onlineCalls, onlineSelectors } from "../../../store/online";

const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);

interface OwnProps {}
interface DispatchProps {
  getOnlineUsers: () => Promise<void>;
  getAllFeeds: () => Promise<void>;
}

interface StateProps {
  isOnlineSuccess: string[];
  getAllFeedsSuccess: IFeed[];
  getAllFeeds: () => Promise<void>;
}

const useStyles = makeStyles(() =>
  createStyles({
    media: {
      height: "400px",
    },
    container: {
      marginTop: 30,
    },
    card: {
      maxWidth: 550,
      margin: "0 auto",
    },
    multiline: {
      whiteSpace: "pre-line",
    },
    feedTitle: {
      fontWeight: "bold",
    },
  })
);

type Props = OwnProps & StateProps & DispatchProps;
export const FeedforAdmin = (props: Props) => {
  const classes = useStyles();
  useEffect(() => {
    props.getAllFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const allFeed = props?.getAllFeedsSuccess.filter((onlyMass: IFeed) => {
    if (onlyMass.isMass === true) {
      return onlyMass.isMass;
    } else {
      return false;
    }
  });
  const onlineNow = props.isOnlineSuccess;
  return (
    <>
      {/* <Typography variant="h6" align={"center"} style={{ color: "#000" }}>
        Delores Digital Feed
      </Typography> */}
      <div style={{ height: "90vh", overflowY: "scroll" }}>
        {allFeed.map((data, i) => {
          return (
            <Container className={classes.container} key={i}>
              <Card className={classes.card}>
                <CardHeader
                  avatar={
                    onlineNow.includes(data._id) ? (
                      <StyledOnlineBadge
                        variant={onlineNow.includes(data._id) ? "dot" : "standard"}
                      >
                        <Avatar
                          src={
                            data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                            `https://via.placeholder.com/150?text=Profile`
                          }
                        ></Avatar>
                      </StyledOnlineBadge>
                    ) : (
                      <StyledOffLineBadge variant="dot">
                        <Avatar
                          src={
                            data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                            `https://via.placeholder.com/150?text=Profile`
                          }
                        ></Avatar>
                      </StyledOffLineBadge>
                    )
                  }
                  title={data.title}
                  subheader={`Provider :${data?.center?.name}-${data.createdAt.slice(0, 10)}`}
                  classes={{
                    title: classes.feedTitle,
                  }}
                />
                {data?.file &&
                  (data?.file.url?.includes("mp4") ? (
                    <video src={data.file.url} width="100%" height="100%" controls={true} />
                  ) : (
                    <CardMedia
                      className={classes.media}
                      component="img"
                      image={data.file.url}
                      title={data.file.name}
                    />
                  ))}
                <CardContent>
                  <Typography
                    variant="body2"
                    className={classes.multiline}
                    dangerouslySetInnerHTML={{ __html: data.description }}
                  />
                </CardContent>
              </Card>
            </Container>
          );
        })}
      </div>
    </>
  );
};
const mapDispatchToProps = (dispatch: any) => ({
  getAllFeeds: () => dispatch(feedCalls.getAllFeeds()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
});

const mapStateToProps = (state: any) => ({
  getAllFeedsSuccess: feedSelectors.getAllFeedsSuccess(state.feeds),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedforAdmin);
