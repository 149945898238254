import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    calendar: {
      width: "50px",
      height: "50px",
      "@media (max-width: 500px)": {
        width: "50px",
        height: "50px",
      },
    },
    slots: {
      height: "22rem",
      overflowY: "scroll",
      "@media (max-width: 500px)": {
        height: "15rem",
      },
    },
    grid: {
      marginTop: "25px",
      border: "1px solid green",
      textAlign: "center",
    },
    scheduleButton: {
      textAlign: "right",
      marginRight: "20px",
      marginBottom: "20px",
    },
  })
);
