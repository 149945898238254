import React, { useMemo } from "react";
import { createWidget } from '@typeform/embed'
import { Widget } from '@typeform/embed-react'
import { PopupButton } from '@typeform/embed-react'
import '@typeform/embed/build/css/widget.css'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { NotificationDTO } from "../../store/types/type";
interface Props {
  socket: any;
  currentChat: any;
  sendBookFullSessions: () => void;
  handleCloseFullSessionPopup: () => void;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}

const isUser = localStorage.getItem("current") === "PATIENT";
function BookFullSession(props: Props) {
  const { currentChat, sendBookFullSessions, handleCloseFullSessionPopup } = props;
  const [phyLink, setPhyLink] = React.useState("");
  useMemo(() => {
    if (currentChat) {
      setPhyLink(currentChat.physicians[0].link);
    }
  }, [currentChat]);
  const handlesendSms = async () => {
    const url = `https://app.deloresdigital.com/auth`;
    const messageSentText = `${
      "Confirm you Delores session ended. \n\n" +
      "Your provider sent you a 3-question form to confirm you had a sessio. Access the form in your Delores portal. "
    }${url}\n`;

    await props.sendSMS({ message: messageSentText, phone: currentChat?.patient?.phoneNumber });
  };
  return (
    <div>
      {!isUser ? (
        <DialogTitle id="alert-dialog-title">
          {`Session Confirmation`}
        </DialogTitle>
      ) : null}

      <DialogContent>
        {!isUser && (
          <DialogContentText id="alert-dialog-description">
            Form for Client to complete:{" "}
            <Widget id="DGuzrEL0" style={{ width: '100%', height: '600px' }} className="SessionConfirm">
             </Widget>
            <br />
            Reminder: This form is to confirm the session was completed.
          </DialogContentText>
        )}
        {isUser && (
          <DialogContentText id="alert-dialog-description">
            Your provider is sending you a a form to confirm you completed your session. <br />
            Please ensure you complete the form. <br />
            <Widget id="DGuzrEL0" style={{ width: '100%', height: '600px' }} className="SessionConfirm2">
             </Widget>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <>
          {!isUser ? (
            <Button
              variant="contained"
              onClick={() => {
                sendBookFullSessions();
                handleCloseFullSessionPopup();
                setPhyLink("");
                handlesendSms();
              }}
              color="primary"
            >
              Send | Confirm
            </Button>
          ) : null}
        </>
        <Button
          variant="contained"
          onClick={() => {
            handleCloseFullSessionPopup();
            setPhyLink("");
          }}
          color="primary"
        >
          {isUser ? "Close" : "Cancel"}
        </Button>
      </DialogActions>
    </div>
  );
}

export default BookFullSession;
