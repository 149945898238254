import React from "react";
import { USER_TYPE } from "../../../../store/types";
import { ChatComponent } from "../../../chat/Chat";
// import { Messages as PhysicianMessages } from "../../main/PortalComponents/Messages";

export const Messages = ({ group }: { group: boolean }) => {
  return (
    <>
      <ChatComponent userType={USER_TYPE.PHYSICIAN} group={group} />
    </>
  );
};
