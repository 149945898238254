import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Messages } from "./PortalComponents/Messages";
export default function Portal() {
  return (
    <div>
      {/* <BrowserRouter> */}
      <Switch>
        <Route path="/physician/portal/messages" component={() => Messages({ group: false })} />
        <Route
          path="/physician/portal/group-discussions"
          component={() => Messages({ group: true })}
        />
        <Redirect to="/physician/portal/messages" />
      </Switch>
      {/* </BrowserRouter> */}
    </div>
  );
}
