import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import PingProviderComponent from "../../pingComponents/StartChatForCenter";
import { PingComponent } from "../../base/Ping/Ping";
import { USER_TYPE } from "../../../store/types";

export default function PingRoutes() {
  return (
    <div>
      <Switch>
        <Route exact path={`/center/ping/start-chat`} component={PingProviderComponent} />
        <Route
          exact
          path={`/center/ping/single`}
          component={() => <PingComponent group={false} userType={USER_TYPE.CENTER} />}
        />
        <Route
          exact
          path={`/center/ping/group-discussions`}
          component={() => <PingComponent group={true} userType={USER_TYPE.CENTER} />}
        />
        <Redirect to={`center/ping`} />
      </Switch>
    </div>
  );
}
