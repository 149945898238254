// import { USER_TYPE } from "../../../store/types";
// import { Home as PatientHome } from "../main/Home";
import FeedForPatient from "./FeedForPatient";
import { PingProfessioanl } from "./PingProfessioanl";
export default function Home() {
  // Note: For this upddate underneath <Ping...> I deleted:         <FeedForPatient />
  // const profile = "Edit you profile and settings. Upload files and control file privacy";
  // const msg = "Securely chat with verified professionals and providers";
  // const Items = [
  //   { title: "Messages", desc: msg },
  //   { title: "Your Profile", desc: profile },
  //   {
  //     title: "Contact a Provider",
  //     desc: "Browse providers and professionals to connect with",
  //   },
  // ];

  return (
    <>
      <div style={{ overflowY: "scroll", height: "100vh" }}>
        {/* <PatientHome type={USER_TYPE.PATIENT} patientItems={Items} /> */}
        <PingProfessioanl />
      </div>
    </>
  );
}
