export enum USER_TYPE {
  SUPER = "SUPER",
  PATIENT = "PATIENT",
  PHYSICIAN = "PHYSICIAN",
  CENTER = "CENTER",
  ADMIN = "ADMIN",
}

export enum FILE_TYPE {
  RADIO = "RADIO",
  NOTES = "NOTES",
  OTHER = "OTHER",
  FOLDER = "FOLDER",
}
