import {
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React from "react";
import { NotificationDTO, PhysicianSignUpDTO } from "../../store/types/type";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { theme } from "../base/ColorPalete";
import { ThemeProvider } from "@material-ui/core/styles";
import { ICenter } from "../../store/types/center";
import { Alert, Autocomplete } from "@material-ui/lab";
import { IPhysician } from "../../store/types";
import { useStyles } from "./muiStyling";
import "./style.css";
import { useStateList } from "../../store/types/states";

interface StateProps {
  centers: ICenter[];
  gettingCenters: boolean;
  errorWhileGetCenters: string;
  registeringPhysician: boolean;
  physicianRegistered: boolean;
  errorWhilePhysicianRegister: string;
  whenRegisterSuccessPhysician: string;
  changeType: (type: string) => void;
}
interface DispatchProps {
  physicainSignUp: (physicianData: PhysicianSignUpDTO) => Promise<IPhysician>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}

type Props = StateProps & DispatchProps;
export default function PhysicianSignUpCard(props: Props) {
  const [physicianData, setPhysicianData] = React.useState<PhysicianSignUpDTO>({
    email: "",
    rates: "",
    firstName: "",
    lastName: "",
    password: "",
    practice: "",
    practiceAddress: "",
    phoneNumber: "",
    state: "",
    practiceID: props.centers[0]?._id as any,
  });

  const [errorState, setErrorState] = React.useState({
    email: "",
    firstName: "",
    lastName: "",
    password: "",
    practice: "",
    state: "",
    practiceAddress: "",
    phoneNumber: "",
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (isValidated(event)) {
      const phone = "+1" + physicianData.phoneNumber;

      props.physicainSignUp({ ...physicianData, phoneNumber: phone }).then(() => {
        props.changeType("login");
      });
      // get center for phone
      const center: any = props.centers.filter((ad: any) => ad._id !== physicianData.practiceID);
      console.log(physicianData.practiceID)
      console.log(center._id)
      console.log(center)
      console.log(center.phoneNumber)

      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New professional signup\n\n" +
        "A new professional is registering with your practice. Accept or Deny the request in your provider account. "
      }${url}\n`;

      await props.sendSMS({ message: messageSentText, phone: center[0]?.phoneNumber });
    }
  };

  const isValidated = (e: any) => {
    e.preventDefault();
    let errors = {
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      rates: "",
      practice: "",
      state: "",
      practiceAddress: "",
      phoneNumber: "",
    };
    errors.email = physicianData.email ? "" : "Please Enter Email";
    errors.firstName = physicianData.firstName ? "" : "Please Enter First Name";
    errors.lastName = physicianData.lastName ? "" : "Please Enter Last Name";
    errors.password = physicianData.password ? "" : "Please Enter Password";
    errors.practice = physicianData.practice ? "" : "Please Enter Practice Name";
    errors.state = physicianData.state ? "" : "Please Select State";
    errors.practiceAddress = physicianData.practiceAddress ? "" : "Please Enter Practice Address";
    errors.phoneNumber = physicianData.phoneNumber ? "" : "Please Enter Phone Number";
    if (physicianData.email && physicianData.email !== "") {
      const pattern = new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/);
      if (!pattern.test(physicianData.email)) {
        errors.email = "Please Enter Valid Email";
      }
    }
    if (physicianData.phoneNumber && physicianData.phoneNumber !== "") {
      const phone = "+1" + physicianData.phoneNumber;

      const pattern = new RegExp(/^\+[1-9]\d{10,14}$/);
      if (!pattern.test(phone)) {
        errors.phoneNumber = "Please enter a valid phone number start with +1**********";
      }
    }
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };
  const classes = useStyles();
  return props.registeringPhysician ? (
    <CircularProgress />
  ) : (
    <Container className={classes.gridContainer}>
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email"
                  type="string"
                  name="email"
                  placeholder={`No capitals in email `}
                  onChange={(e) =>
                    setPhysicianData({
                      ...physicianData,
                      email: e.target.value.toLocaleLowerCase(),
                    })
                  }
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  type="string"
                  name="phone"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">+1</InputAdornment>,
                  }}
                  placeholder={`Start number without "+1" `}
                  onChange={(e) =>
                    setPhysicianData({ ...physicianData, phoneNumber: e.target.value })
                  }
                  error={errorState["phoneNumber"] ? true : false}
                  helperText="Phone Number to receive notifications"
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="First Name"
                  type="string"
                  name="firstName"
                  onChange={(e) =>
                    setPhysicianData({ ...physicianData, firstName: e.target.value })
                  }
                  error={errorState["firstName"] ? true : false}
                  helperText={errorState["firstName"]}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Last Name"
                  type="string"
                  name="lastName"
                  onChange={(e) => setPhysicianData({ ...physicianData, lastName: e.target.value })}
                  error={errorState["lastName"] ? true : false}
                  helperText={errorState["lastName"]}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Practice"
                  type="string"
                  name="practice"
                  onChange={(e) => setPhysicianData({ ...physicianData, practice: e.target.value })}
                  error={errorState["practice"] ? true : false}
                  helperText={errorState["practice"]}
                />
              </Grid>

              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Practice Address"
                  type="string"
                  name="practiceAddress"
                  onChange={(e) =>
                    setPhysicianData({ ...physicianData, practiceAddress: e.target.value })
                  }
                  error={errorState["practiceAddress"] ? true : false}
                  helperText={errorState["practiceAddress"]}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <Autocomplete
                  id="state"
                  fullWidth
                  // freeSolo
                  value={physicianData?.state}
                  options={useStateList.map((item: any) => item)}
                  onChange={(e, value) => {
                    setPhysicianData({ ...physicianData, state: value });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                      placeholder="Select State"
                      name="state"
                    />
                  )}
                />
              </Grid>
              {props.centers.length ? (
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth>
                    <InputLabel htmlFor="age-native-simple">Provider</InputLabel>
                    <Select
                      native
                      // variant="outlined"
                      // placeholder="Select Provider"
                      // value={""}
                      onChange={(e) =>
                        setPhysicianData({ ...physicianData, practiceID: e.target.value })
                      }
                    >
                      {props.centers.map((center, i) => {
                        return (
                          <option key={i} value={center._id}>
                            {center.name}
                          </option>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              ) : (
                ""
              )}
              <Grid item xs={12} sm={6} lg={6} style={{ marginBottom: "0.5em" }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  name="password"
                  onChange={(e) => setPhysicianData({ ...physicianData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              {props.errorWhilePhysicianRegister && (
                <Grid item xs={12}>
                  {props.errorWhilePhysicianRegister && (
                    <Alert severity="error">{props.errorWhilePhysicianRegister}</Alert>
                  )}
                  {props.whenRegisterSuccessPhysician && (
                    <Alert severity="success">{props.whenRegisterSuccessPhysician}</Alert>
                  )}
                </Grid>
              )}
            </Grid>
            <Grid item xs={6} md={6} style={{ paddingTop: "20px" }}>
              <ThemeProvider theme={theme}>
                <Button
                  onClick={handleSubmit}
                  color="secondary"
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  Sign up
                </Button>
              </ThemeProvider>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
