import axios from "axios";
import { AcceptPingDTO, PingRequestDTO } from "../types/type";
import * as actions from "./actions";

// export const getMyPingRequests = () => async (
//   dispatch: (arg0: { type: string; payload?: any }) => void
// ) => {
//   dispatch(actions.gettingMyPingRequests());
//   try {
//     const response = await axios.get(`/PingRequests/my-PingRequests`, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(
//           `${localStorage.getItem("current")?.toLowerCase()}Token`
//         )}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch(actions.getMyPingRequestsSuccess(response.data));
//     } else throw new Error("Unable to get your PingRequests");
//   } catch (err) {
//     dispatch(actions.getMyPingRequestFailure(err.message));
//   }
// };

export const createPingRequest = (PingRequestCreatedto: PingRequestDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.creatingPingRequest());
  let url = "new-request";
  let data: PingRequestDTO | FormData = PingRequestCreatedto;
  // In case of file -> route and dto updated
  if (PingRequestCreatedto.file) {
    data = new FormData();
    url = "new-request-withFile";
    data.append("fileField", PingRequestCreatedto.file);

    if (PingRequestCreatedto.initText) {
      data.append("initText", PingRequestCreatedto.initText);
    }
    if (PingRequestCreatedto?.toCenter) {
      data.append("toCenter", PingRequestCreatedto.toCenter);
    }
    if (PingRequestCreatedto.toPhysician) {
      data.append("toPhysician", PingRequestCreatedto.toPhysician);
    }
  }
  try {
    const response = await axios.post(`/ping-request/${url}`, data, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("physicianToken")}`,
      },
    });
    if (response.status === 201) dispatch(actions.createPingRequestuccess(response.data));
    else throw new Error("Unable to create new PingRequest");
  } catch (err: any) {
    dispatch(
      actions.createPingRequestFailure(err.response?.data.message || "Unexpected Error Occurred")
    );
  }
};
export const acceptPingRequest = (acceptPingDto: AcceptPingDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.acceptingPingRequest());
  try {
    const response = await axios.post(`/ping-request/accept-request`, acceptPingDto, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
      },
    });
    if (response.status === 200) dispatch(actions.acceptPingRequestuccess(response.data));
    else throw new Error("Unable to accept new PingRequest");
  } catch (err: any) {
    dispatch(
      actions.acceptPingRequestFailure(err.response?.data.message || "Unexpected Error Occurred")
    );
  }
};

// export const updatePingRequest = (PingRequestUpdatedto: PingRequestPatchDTO) => async (
//   dispatch: (arg0: { type: string; payload?: any }) => void
// ) => {
//   dispatch(actions.updatingPingRequest());
//   try {
//     // console.log(physicianUpdateDTO)
//     const response = await axios.patch(`/PingRequests/update-PingRequest`, PingRequestUpdatedto, {
//       headers: {
//         Authorization: `Bearer ${localStorage.getItem(
//           `${localStorage.getItem("current")?.toLowerCase()}Token`
//         )}`,
//       },
//     });
//     if (response.status === 200) {
//       dispatch(actions.updatePingRequestsuccess(response.data));
//     } else throw new Error("Unable to update PingRequest");
//   } catch (err) {
//     dispatch(actions.updatePingRequestFailure(err.message));
//   }
// };
