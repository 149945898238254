export const isCallingProfile = (state: any) => state.adminProfileCalling;
export const isAdminProfile = (state: any) => state.adminProfile || undefined;
export const isProfileFailure = (state: any) => state.adminProfileFailure || undefined;

export const isUpdatingProfile = (state: any) => state.centerProfileUpdating;
export const isCenterProfileUpdated = (state: any) => state.centerProfileUpdateSuccess || undefined;
export const isCenterProfileUpdatedFail = (state: any) =>
  state.centerProfileUpdateFailure || undefined;

export const updatingCenterRequest = (state: any) => state.updatingCenterRequst;
// export const CenterRequestUpdated= (state:any) => state.updateCenterRequestSuccess
export const CenterRequestFailed = (state: any) => state.updateCenterRequestFailure;

export const isCallingCenterFileUpdate = (state: any) =>
  state.updateCenterFilePrivacyCalling || undefined;
export const isCenterFileUpdated = (state: any) => state.updateCenterFilePrivacy || undefined;
export const isCenterFileUpdatedFail = (state: any) =>
  state.updateCenterFilePrivacyFailure || undefined;
