/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-new-wrappers */
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Badge,
  Typography,
  withStyles,
} from "@material-ui/core";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import { basicCalls, basicSelectors } from "../../../../store/centers/basicCalls";
import {
  basicCalls as superBasicCalls,
  basicSelectors as superBasicSelectors,
} from "../../../../store/supers/basicCalls/index";
import { useStyles } from "../../patient/muiStyling";
import { Alert, Autocomplete } from "@material-ui/lab";

import { ICenter } from "../../../../store/types/center";
// import { chatCalls, chatSelectors } from "../../../../store/patients/chat";
import { patientProfileCalls, patientProfileSelectors } from "../../../../store/patients/profile";
// import CircleSpinner from "../../../CircleSpinner";
import { onlineCalls /* onlineSelectors*/, onlineSelectors } from "../../../../store/online";
import CrisisPopup from "../../../popup/CrisisPopup";
import { NotificationDTO, PingRequestDTO, SearchPhyDTO } from "../../../../store/types/type";
import { notificationSMSCalls } from "../../../../store/notification/sms";
import { usRedListStates, useStateList, greenList } from "../../../../store/types/states";
import { specialties } from "../../../../store/types/states";
import { IChat, IPatient, IPhysician } from "../../../../store/types";
import { searchPhysician } from "../../../../store/patients/profile/apiCalls";
// import ChatIcon from "@material-ui/icons/Chat";
import { NewMessageDTO } from "../../../../store/types/type";
import { IMessage } from "../../../../store/types/message";
import PatientAppointment from "../../patient/Appointment/PatientAppointment";
import axios from "axios";
import { chatSelectors as patientChatSelector } from "../../../../store/patients/chat";
import io from "socket.io-client";
import PingCenterCards from "../../../base/Ping/PingCenterCards";
import { PingRequestsCalls } from "../../../../store/Ping";

const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      // content: '""',
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);

const useStyle = makeStyles((theme) => ({
  paper: {
    margin: 20,
    height: 180,
    width: 260,
  },
  select: {
    minWidth: "40%",
    margin: "0px 20px",
    "@media (max-width: 600px)": {
      textAlign: "center",
      margin: "0px 10px",
      minWidth: "60%",
    },
  },
  btnStyle: {
    minWidth: "20%",
    borderRadius: "20px",
    fontSize: "0.9125rem",
    "@media (max-width: 600px)": {
      minWidth: "40%",
      fontSize: "0.7125rem",
    },
  },
  btnDiv: {
    alignSelf: "center",
    marginTop: "15px",
    minWidth: "35%",
    "@media (max-width: 600px)": {
      minWidth: "40%",
    },
  },
  selectDiv: {
    width: "100%",
    textAlign: "center",
    "@media (max-width: 600px)": {
      textAlign: "left",
      "& .MuiOutlinedInput-input": {
        padding: "8px 19px 13px 5px",
        fontSize: "0.8rem",
      },
    },
  },
  innerSelectDiv: {
    width: "70%",
  },
  label: {
    fontWeight: "bold",
    textAlign: "center",
    whiteSpace: "nowrap",
  },
}));
type mapStateProps = {
  centers: ICenter[];
  isOnlineSuccess: string[];
  initiateRequest: (selectedPhyscian: string, initText: string) => Promise<void>;
  patientChats: IChat[];
  errorWhileGetCenters: string;
  gettingCenters: boolean;
  searchPhysician: (search: SearchPhyDTO) => Promise<void>;
  searchPhysicianSuccess: IPhysician[] | null;
  physicians: IPhysician[];
  profile: IPatient;
  searchPhysicianFailure: string;
};
type mapDispatchProps = {
  getAllCenters: any;
  getPatient: () => Promise<void>;
  getPhysicians: () => Promise<void>;
  getOnlineUsers: () => Promise<void>;
  initiateRequest: (centerID: string, initText: string) => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  searchPhysician: any;
  handlePhysicianSelectPing: (pingDto: any) => Promise<void>;
  ping: (pingDto: PingRequestDTO) => Promise<void>;
};
type ownProps = { isPingComp?: boolean };
type invisibleProps = {
  history?: {
    push(url: string): void;
  };
};
type Props = ownProps & mapDispatchProps & mapStateProps & invisibleProps;
export const _SelectCenter = (props: Props) => {
  const classes = useStyle();
  const cardClass = useStyles();
  const [selectedSpecialty, setSelectedSpecialty] = React.useState<any>("Any");
  const [selectedState, setSelectedState] = React.useState<any>("Any");
  const [selectedCenter, setSelectedCenter] = React.useState<string>("Any");
  const [searchRes, setSearchRes] = React.useState<any>([]);
  const [centerRes, setCentersRes] = React.useState<ICenter>();
  const [messageVal, setMessageVal] = React.useState("");
  const [openScheduleDiag, setOpenScheduleDiag] = React.useState(false);

  const [phyAppointment, setPhyAppointment] = React.useState<any>([]);

  const [phyId, setPhyId] = React.useState<string | null>(null);
  const [centerId, setCenterId] = React.useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [rooms, setRooms] = React.useState("");
  let [selectedPhyscian, setselectedPhyscian] = React.useState(
  props.physicians.length ? props.physicians[0]._id : "");

  const asyncAwait = async () => {
    await props.getAllCenters();
    // setCenters(props.centers);
  };
  React.useEffect(() => {
    asyncAwait();
    filterCenter();
    // @ts-ignore
  }, []);

  useEffect(() => {
    setSelectedSpecialty("Any");
    setSelectedCenter("Any");
    setSelectedState("Any");
    window.scrollTo(0, 0);
    filterCenter();
  }, [searchRes]);

  const handleClickCenterOpen = (id: string | null) => {
    setCenterId(id);
    setOpen(true);
  //  setselectedPhyscian(specialistProfile._id as string);
  };
  const handleClose = () => {
    setOpen(false);
    setPhyId("");
    setOpenScheduleDiag(false);
  };
  const handleScheduleOpen = (id: string | null) => {
    const phyDetail: any = props.physicians?.filter((phy) => {
      return phy._id === id;
    });

    setPhyAppointment(phyDetail[0]);

    setPhyId(id);
    setOpenScheduleDiag(true);
  };
  const [commentVal, setCommentVal] = React.useState("");
  const [initMessage, setInitText] = React.useState<string>("");
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [requestSuccessfull, setRequestSuccessfull] = React.useState<{
    success: boolean;
    errorMessage: string;
  }>({ success: false, errorMessage: "" });
  const requestPhysician = async () => {
    setRequesting(true);
    setRequestSuccessfull({ success: false, errorMessage: "" });
    if (selectedPhyscian = "6168d8766c3ba4000bda4bea") {
      var Useid = "6168d80a6c3ba4000bda4be9"; /// DD Provider
    } else if (selectedPhyscian = "61cf37cfa43402000a3915dc") {
      var Useid = "61cf329f90c17c000a8423d1" ///Dominique Hickman
    } else if (selectedPhyscian = "61cf7621db1e02000a613a75") {
      var Useid = "61cf71fdec28fe000a9a3770" ///Thurman Brown
    } else if (selectedPhyscian = "61d881ba13941c000b886c57") {
      var Useid = "61d87db1aa923a000af5dfe0" ///Tami Allen
    } else if (selectedPhyscian = "61df248e2baf7a000a38a385") {
      var Useid = "61df1fe42baf7a000a38a2f8" ///Oluwatosin Akindele
    } else if (selectedPhyscian = "6203f25272cec0000a92b784") {
      var Useid = "6203ee8e72cec0000a92b727" ///Dr. TaNika Harlis
    } else if (selectedPhyscian = "6206c83d0840b2000a55797c") {
      var Useid = "6206c5160840b2000a55791e" ///Eneze Obuaya, M.D.
    } else if (selectedPhyscian = "620e86bf0537d0000ad7a62a") {
      var Useid = "620e838a0537d0000ad7a5db" ///Laquanya Haggray
    } else if (selectedPhyscian = "6228d37293c12e000aa21d4d") {
      var Useid = "6228d0c53007c9000a88f67f" ///Motier Haskins
    } else if (selectedPhyscian = "62c5a173fad547000a1639e4") {
      var Useid = "6168d80a6c3ba4000bda4be9" ///Alison Running Coach
    } else {
      var Useid = selectedPhyscian
    }
    const response = await patientProfileCalls.initPatientRequest(Useid, initMessage);
    // send sms
    const phy = props.physicians.filter((phy) => phy._id === selectedPhyscian);
    if (phy) {
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New ping message on Delores\n\n" +
        "You have a new ping message from user. To see the message, login to "
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: phy[0].phoneNumber });
    }
    setRequesting(false);
    setRequestSuccessfull(response);
    setOpenCrisisPopup(true);
  };

  const handleSendMessage = async (specialistProfile: any) => {
    const URL = `${process.env.REACT_APP_API_URL}/chat`;
    const socket = io(URL, { transports: ["polling"] });
    socket.on("joinedRoom", (room: string) => {
      console.log("joined room ", room);
      setRooms(room);
    });
    console.log("props.patientChats==", props.patientChats);
    console.log(
      "props?.profile?._id==",
      props?.profile?._id,
      "specialistProfile?._id==",
      specialistProfile?._id
    );

    const initialChat = props.patientChats.filter((pc) => pc.isGroup === false)[0];
    console.log("initialChat==", initialChat);

    const PatientChatId = props.patientChats.filter(
      (pc) =>
        props?.profile?._id === pc.patient?._id &&
        pc.physicians?.map((phy: any) => specialistProfile?._id === phy?._id)
    )[0]?._id;

    console.log(
      "filter==",
      props.patientChats.map(
        (pc) =>
          props?.profile?._id === pc.patient?._id &&
          pc.physicians?.map((phy: any) => specialistProfile?._id === phy?._id && pc?._id)
      )
    );

    console.log("matchPatientId==", PatientChatId);
    socket.on("connect", () => {
      console.log("connected");
      if (rooms) {
        socket.emit("leaveRoom", PatientChatId);
        socket.emit("joinRoom", PatientChatId);
      }
    });

    if (messageVal === "") return;
    console.log(`Adding a new message: ${messageVal} to Chat with ID: ${specialistProfile._id}`);
    const newMessage: NewMessageDTO = {
      chatID: PatientChatId,
      text: messageVal,
    };

    try {
      const res = await axios.post<IMessage>(`/messages/new-message`, newMessage, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      });
      socket.emit("send-message", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("handleMessagesNotifications", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("activeTime", newMessage);
      // if (specialistProfile) {
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New message on Delores.\n\n" +
        `You have a new message from ${props?.profile?.firstName}. To see the message, login to `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: specialistProfile.phoneNumber });
      // }
      if (res.status === 201) {
        setOpenCrisisPopup(true);
        setMessageVal("");
      }
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      socket.disconnect();
    } catch (err) {
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      console.log("error", err);
    }
  };
  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setSearchRes([]);
    if (selectedSpecialty !== "Any" || selectedState !== "Any") {
      let data = await props.searchPhysician({
        specialties: selectedSpecialty,
        state: selectedState,
      });
      data ? setSearchRes(data) : setSearchRes([]);
    } else {
      await props
        .getPhysicians()
        .then(() => setSearchRes(props.physicians))
        .catch(() => setSearchRes([]));
    }
  };
  React.useMemo(() => {
    if (props.physicians && props.physicians?.length > 0) {
      setSearchRes(props.physicians);
    }
    if (props.searchPhysicianSuccess) {
      let arr = [];
      arr = props.searchPhysicianSuccess.filter(
        (value, index, self) => index === self.findIndex((t) => t._id === value._id)
      );
      setSearchRes(arr);
    }
  }, [props.searchPhysicianSuccess, props.physicians]);

  const filterCenter = () => {
    let result: any = [];
    // eslint-disable-next-line array-callback-return
    searchRes.map((phy: any) => {
      const res = props?.centers?.filter((center: any) => phy?.center === center?._id);
      const check = result?.filter((r: any) => r?._id === res[0]?._id);
      if (!check?.length) {
        result.push(res[0]);
      }
    });
    setCentersRes(result ? result : centerRes);
  };

  const [openCrisisPopup, setOpenCrisisPopup] = React.useState(false);
  const onlineNow = props.isOnlineSuccess;

  const handleSendCenterMessage = async () => {
    if (centerId) {
      await props.ping({
        initText: messageVal ? messageVal : "",
        toCenter: centerId,
        file: undefined,
      });
    }
    // TODO : sendSMS
  };
  return (
    <>
      <div style={{ padding: "15px" }}>
        <>
          <div className={cardClass.cardMainDiv}>
            <div className={cardClass.cardHeaderMainDiv}>
              <div style={{ textDecoration: "none", color: "white", cursor: "pointer" }}>
                <CardHeader
                  className={cardClass.cardHeader}
                  title="Search For a Provider to Connect With"
                  titleTypographyProps={{ variant: "h6" }}
                ></CardHeader>
              </div>
            </div>
          </div>
          {props.centers.length ? (
            <div className={cardClass.cardMainDiv}>
              <div className={classes.selectDiv}>
                <div className={classes.innerSelectDiv}>
                  <FormControl style={{ flexDirection: "row", width: "50%" }}>
                    <div className={classes.select}>
                      <label className={classes.label}>Area Of Expertise</label>

                      <Autocomplete
                        id="specialty"
                        fullWidth
                        // freeSolo
                        value={selectedSpecialty}
                        options={specialties.map((item: any) => item)}
                        onChange={(e, value) => {
                          setSelectedSpecialty(value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth variant="outlined" name="specialty" />
                        )}
                      />
                    </div>
                    <div className={classes.select}>
                      <label className={classes.label}>State</label>
                      <Autocomplete
                        id="state"
                        fullWidth
                        // freeSolo
                        value={selectedState}
                        options={useStateList.map((item: any) => item)}
                        onChange={(e, value) => {
                          setSelectedState(value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth variant="outlined" name="state" />
                        )}
                      />
                    </div>
                    <div className={classes.select}>
                      <label className={classes.label}>Practice</label>
                      <Autocomplete
                        id="center"
                        fullWidth
                        freeSolo
                        value={selectedCenter}
                        options={props.centers.map((item: any) => item.name)}
                        onChange={(e, value) => {
                          setSelectedCenter(value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth variant="outlined" name="center" />
                        )}
                      />
                    </div>
                    <div className={classes.btnDiv}>
                      <Button
                        className={classes.btnStyle}
                        color="primary"
                        size="small"
                        fullWidth
                        onClick={handleSubmit}
                        variant="contained"
                      >
                        Search
                      </Button>
                    </div>
                  </FormControl>
                </div>
              </div>
            </div>
          ) : null}
        </>
        <CrisisPopup openCrisisPopup={openCrisisPopup} setOpenCrisisPopup={setOpenCrisisPopup} />
      </div>

      <CardContent className={cardClass.cardContent}>
        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
          <PingCenterCards
            centerRes={centerRes}
            cardClass={cardClass}
            handleClickCenterOpen={handleClickCenterOpen}
            handleSendMessage={handleSendCenterMessage}
          />
          {searchRes &&
            searchRes?.map((specialistProfile: any, i: any) => {
              return (
                <>
                  {usRedListStates.includes(props.profile?.state) &&
                    props.profile?.state === specialistProfile?.state && (
                      <div className={cardClass.cardContentInnerDiv} key={i}>
                        {usRedListStates.includes(specialistProfile?.state) && (
                          <Alert severity="error" style={{ marginBottom: "10px" }}>
                            {" "}
                            {
                              "Due to your state's legislation you are only able to contact professionals registered within your state "
                            }{" "}
                          </Alert>
                        )}
                        <Paper className={cardClass.paper}>
                          <Grid container spacing={2} key={i}>
                            <Grid item sm={9} md={9} xs={8} lg={10} xl={10}>
                              <CardHeader
                                style={{
                                  padding: "0px",
                                  width: 130,
                                  height: 130,
                                  alignSelf: "center",
                                }}
                                avatar={
                                  onlineNow?.includes(specialistProfile.center) ? (
                                    <StyledOnlineBadge
                                      variant={
                                        onlineNow?.includes(specialistProfile.center)
                                          ? "dot"
                                          : "standard"
                                      }
                                    >
                                      <Avatar
                                        alt={specialistProfile?.firstName}
                                        src={
                                          specialistProfile?.files[0]?.url ||
                                          `https://via.placeholder.com/150?text=Profile`
                                        }
                                        style={{ width: 130, height: 130, alignSelf: "center" }}
                                      />
                                    </StyledOnlineBadge>
                                  ) : (
                                    <StyledOffLineBadge variant="dot">
                                      <Avatar
                                        alt={specialistProfile?.firstName}
                                        src={
                                          specialistProfile?.files[0]?.url ||
                                          `https://via.placeholder.com/150?text=Profile`
                                        }
                                        style={{ width: 130, height: 130, alignSelf: "center" }}
                                      />
                                    </StyledOffLineBadge>
                                  )
                                }
                              />
                            </Grid>
                            <Grid item sm={3} md={3} xs={4} lg={2} xl={2}>
                              <div
                                style={{
                                  paddingRight: "10px",
                                  display: "flex",
                                  flexWrap: "wrap",
                                  justifyContent: "space-around",
                                }}
                              >
                                {props.isPingComp ? (
                                  <Typography>Practice: {specialistProfile?.practice}</Typography>
                                ) : (
                                  <>
                                    <Button
                                      autoFocus
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        handleScheduleOpen(specialistProfile._id);
                                        // props.getPhysicianProfile()
                                      }}
                                      style={{
                                        alignSelf: "center",
                                        float: "right",
                                        marginTop: "8px",
                                        minWidth: "45%",
                                      }}
                                    >
                                      Schedule Time
                                    </Button>
                                    {/* <Button
                                      autoFocus
                                      variant="outlined"
                                      color="primary"
                                      endIcon={<ChatIcon />}
                                      onClick={() => handleClickOpen(specialistProfile._id)}
                                      style={{
                                        alignSelf: "center",
                                        float: "right",
                                        marginTop: "8px",
                                        marginRight: -10,
                                      }}
                                    >
                                      message
                                    </Button> */}
                                  </>
                                )}
                              </div>
                            </Grid>
                            <Grid item sm={9} md={9} container>
                              <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>Name :</span>{" "}
                                      {specialistProfile?.firstName}
                                    </Typography>
                                  </Box>

                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>Rates: $</span>{" "}
                                      {specialistProfile?.firstName == "Dr. TaNika Harlis" ? <Typography> $TBD/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:No </Typography>: null}

                                      {specialistProfile?.firstName == "Eneze Obuaya, M.D." ? <Typography> $70/30min <br/>
                                      $7-For-7minutes Participant:Yes
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Dominique" ? <Typography> $30/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:No </Typography>: null}

                                      {specialistProfile?.firstName == "Tami Vienn Allen, Licensed Professional Counselor" ? <Typography> $30/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:No </Typography>: null}

                                      {specialistProfile?.firstName == "Laquanya" ? <Typography> $59/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Dennard" ? <Typography> $TBD/30min <br/>
                                      $7-For-7minutes Participant:TBD
                                      <br/>
                                      Member Discount Participant:TBD </Typography>: null}

                                      {specialistProfile?.firstName == "Motier" ? <Typography> $70/30min <br/>
                                      $7-For-7minutes Participant:TBD
                                      <br/>
                                      Member Discount Participant:TBD </Typography>: null}

                                      {specialistProfile?.firstName == "Alison" ? <Typography> $TBD/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:No </Typography>: null}

                                      {specialistProfile?.firstName == "Oluwatosin Akindele" ? <Typography> $129/30min <br/>
                                      $7-For-7minutes Participant:Yes
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Christopher" ? <Typography> $60/30min <br/>
                                      $7-For-7minutes Participant:Yes
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "VoicedMania" ? <Typography> $59/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Bradford" ? <Typography> $82/30min <br/>
                                      $7-For-7minutes Participant:No
                                      <br/>
                                      Member Discount Participant:No </Typography>: null}

                                      {specialistProfile?.firstName == "Tiana" ? <Typography> $75/30min <br/>
                                      $7-For-7minutes Participant:Yes
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Thurman Brown" ? <Typography> $45/30min <br/>
                                      $7-For-7minutes Participant:Yes
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Nigeria" ? <Typography> $75/30min <br/>
                                      $7-For-7minutes Participant:Yes
                                      <br/>
                                      Member Discount Participant:Yes </Typography>: null}

                                      {specialistProfile?.firstName == "Delores Digital Professional" ? <Typography> For Help & Support <br/>
                                      </Typography>: null}

                                    </Typography>
                                  </Box>

                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>Rate per 30 minutes:$</span>{" "}
                                      {specialistProfile?.rates}
                                    </Typography>
                                  </Box>

                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>Address :</span>{" "}
                                      {specialistProfile?.practiceAddress}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>
                                        My Background and Approach :{" "}
                                      </span>{" "}
                                      {specialistProfile?.bio ? specialistProfile?.bio : "no data"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>
                                        My Techniques and Specialties :
                                      </span>{" "}
                                      {specialistProfile?.specialties.map((spec: any, ind: any) => {
                                        return <span key={ind}>{spec},</span>;
                                      })}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}> Phone Number : </span>{" "}
                                      {specialistProfile?.publicNumber
                                        ? specialistProfile?.publicNumber
                                        : "No Public Number"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={cardClass.title}>State : </span>{" "}
                                      {specialistProfile?.state
                                        ? specialistProfile?.state
                                        : "No State"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    )}
                  {(props.profile && props.profile.state === undefined) ||
                  !props.profile?.state ||
                  (props.profile?.state &&
                    !usRedListStates.includes(props.profile?.state) &&
                    greenList.includes(props.profile?.state)) ? (
                    <div className={cardClass.cardContentInnerDiv}>
                      <Paper className={cardClass.paper}>
                        <Grid container spacing={2} key={i}>
                          <Grid item sm={9} md={9} xs={8} lg={10} xl={10}>
                            {/* <Avatar
                              alt={specialistProfile?.firstName}
                              src={
                                specialistProfile?.files?.filter((f: any) => f.isProfile)?.url ||
                                `https://via.placeholder.com/150?text=Profile`
                              }
                              style={{ width: 130, height: 130, alignSelf: "center" }}
                            /> */}
                            <CardHeader
                              style={{
                                padding: "0px",
                                width: 130,
                                height: 130,
                                alignSelf: "center",
                              }}
                              avatar={
                                onlineNow?.includes(specialistProfile.center) ? (
                                  <StyledOnlineBadge
                                    variant={
                                      onlineNow?.includes(specialistProfile.center)
                                        ? "dot"
                                        : "standard"
                                    }
                                  >
                                    <Avatar
                                      alt={specialistProfile?.firstName}
                                      src={
                                        specialistProfile?.files[0]?.url ||
                                        `https://via.placeholder.com/150?text=Profile`
                                      }
                                      style={{ width: 130, height: 130, alignSelf: "center" }}
                                    />
                                  </StyledOnlineBadge>
                                ) : (
                                  <StyledOffLineBadge variant="dot">
                                    <Avatar
                                      alt={specialistProfile?.firstName}
                                      src={
                                        specialistProfile?.files[0]?.url ||
                                        `https://via.placeholder.com/150?text=Profile`
                                      }
                                      style={{ width: 130, height: 130, alignSelf: "center" }}
                                    />
                                  </StyledOffLineBadge>
                                )
                              }
                            />
                          </Grid>
                          <Grid item sm={3} md={3} xs={4} lg={2} xl={2}>
                            <div
                              style={{
                                paddingRight: "10px",
                                display: "flex",
                                flexWrap: "wrap",
                                justifyContent: "space-around",
                              }}
                            >
                              {" "}
                              {props.isPingComp ? (
                                <Typography>{specialistProfile.practice}</Typography>
                              ) : (
                                <>
                                  <Button
                                    autoFocus
                                    variant="outlined"
                                    color="primary"
                                    onClick={() => {
                                      handleScheduleOpen(specialistProfile._id);
                                      // props.getPhysicianProfile()
                                    }}
                                    style={{
                                      alignSelf: "center",
                                      float: "right",
                                      marginTop: "8px",
                                      minWidth: "45%",
                                    }}
                                  >
                                    Schedule Time
                                  </Button>
                                  {/* <Button
                                    autoFocus
                                    variant="outlined"
                                    color="primary"
                                    endIcon={<ChatIcon />}
                                    onClick={() => handleClickOpen(specialistProfile._id)}
                                    style={{
                                      alignSelf: "center",
                                      float: "right",
                                      marginTop: "8px",
                                      marginRight: -10,
                                    }}
                                  >
                                    message
                                  </Button> */}
                                </>
                              )}
                            </div>
                          </Grid>
                          <Grid item sm={9} md={9} container>
                            <Grid item xs container direction="column" spacing={2}>
                              <Grid item xs={12}>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>Name :</span>{" "}
                                    {specialistProfile?.firstName}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>Rates: $</span>{" "}
                                    {specialistProfile?.firstName == "Dr. TaNika Harlis" ? <Typography> $TBD/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:No </Typography>: null}

                                    {specialistProfile?.firstName == "Eneze Obuaya, M.D." ? <Typography> $70/30min <br/>
                                    $7-For-7minutes Participant:Yes
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Dominique" ? <Typography> $30/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:No </Typography>: null}

                                    {specialistProfile?.firstName == "Tami Vienn Allen, Licensed Professional Counselor" ? <Typography> $30/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:No </Typography>: null}

                                    {specialistProfile?.firstName == "Laquanya" ? <Typography> $59/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Dennard" ? <Typography> $TBD/30min <br/>
                                    $7-For-7minutes Participant:TBD
                                    <br/>
                                    Member Discount Participant:TBD </Typography>: null}

                                    {specialistProfile?.firstName == "Motier" ? <Typography> $70/30min <br/>
                                    $7-For-7minutes Participant:TBD
                                    <br/>
                                    Member Discount Participant:TBD </Typography>: null}

                                    {specialistProfile?.firstName == "Alison" ? <Typography> $TBD/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:No </Typography>: null}

                                    {specialistProfile?.firstName == "Oluwatosin Akindele" ? <Typography> $129/30min <br/>
                                    $7-For-7minutes Participant:Yes
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Christopher" ? <Typography> $60/30min <br/>
                                    $7-For-7minutes Participant:Yes
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "VoicedMania" ? <Typography> $59/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Bradford" ? <Typography> $82/30min <br/>
                                    $7-For-7minutes Participant:No
                                    <br/>
                                    Member Discount Participant:No </Typography>: null}

                                    {specialistProfile?.firstName == "Tiana" ? <Typography> $75/30min <br/>
                                    $7-For-7minutes Participant:Yes
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Thurman Brown" ? <Typography> $45/30min <br/>
                                    $7-For-7minutes Participant:Yes
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Nigeria" ? <Typography> $75/30min <br/>
                                    $7-For-7minutes Participant:Yes
                                    <br/>
                                    Member Discount Participant:Yes </Typography>: null}

                                    {specialistProfile?.firstName == "Delores Digital Professional" ? <Typography> For Help & Support <br/>
                                    </Typography>: null}

                                  </Typography>
                                </Box>

                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>Rate per 30 minutes:$</span>{" "}
                                    {specialistProfile?.rates}
                                  </Typography>
                                </Box>

                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>Address :</span>{" "}
                                    {specialistProfile?.practiceAddress}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>
                                      My Background and Approach :{" "}
                                    </span>{" "}
                                    {specialistProfile?.bio ? specialistProfile?.bio : "no data"}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>
                                      My Techniques and Specialties :
                                    </span>{" "}
                                    {specialistProfile?.specialties.map((spec: any, ind: any) => {
                                      return <span key={ind}>{spec},</span>;
                                    })}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}> Phone Number : </span>{" "}
                                    {specialistProfile?.publicNumber
                                      ? specialistProfile?.publicNumber
                                      : "No Public Number"}
                                  </Typography>
                                </Box>
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginTop: "8px",
                                  }}
                                >
                                  <Typography
                                    style={{ marginLeft: "10px" }}
                                    component="div"
                                    variant="subtitle2"
                                  >
                                    <span className={cardClass.title}>State : </span>{" "}
                                    {specialistProfile?.state
                                      ? specialistProfile?.state
                                      : "No State"}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Paper>
                    </div>
                  ) : null}
                  <Dialog
                    open={openScheduleDiag}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {`Book a Session with this Professional ${
                        searchRes?.find((o: any, i: any) => {
                          if (o._id === phyId) {
                            return o;
                          }
                          return null;
                        })?.firstName
                      }`}
                    </DialogTitle>
                    <div style={{ padding: "20px" }}>
                      <PatientAppointment
                        patientId={localStorage.getItem("_id")}
                        userType={"Patient"}
                        physicianProfile={phyAppointment}
                        phyId={phyId}
                        sendSMS={props.sendSMS}
                        profile={props.profile}
                      />
                    </div>
                    <DialogActions style={{ padding: "10px 30px" }}>
                      <Button autoFocus variant="outlined" color="primary" onClick={handleClose}>
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                  >
                    <DialogTitle id="responsive-dialog-title">
                      {"Send a message to the Professional"}
                    </DialogTitle>
                    <Box>
                      <Box
                        style={{ border: "1px solid balck", padding: "10px 30px" }}
                        component="div"
                      >
                        To:{" "}
                        <b>
                          {phyId &&
                            `${
                              searchRes?.find((o: any, i: any) => {
                                if (o._id === phyId) {
                                  return o;
                                }
                                return null;
                              }).firstName
                            }`}
                        </b>
                      </Box>
                      <Box
                        style={{ border: "1px solid balck", textAlign: "center" }}
                        component="div"
                      >
                        <TextField
                          style={{ width: "90%" }}
                          rowsMax="5"
                          variant="outlined"
                          multiline
                          rows={4}
                          label="Comment"
                          required
                          //value={messageVal}
                          //onChange={(event) => setMessageVal(event?.target.value)}
                          value={commentVal}
                          onChange={(event) => { setCommentVal(event?.target.value); setInitText(event?.target.value); setselectedPhyscian(specialistProfile._id as string);}}
                        />
                      </Box>
                    </Box>
                    <DialogActions style={{ border: "1px solid balck", padding: "10px 30px" }}>
                      <Button
                        autoFocus
                        variant="outlined"
                        color="primary"
                        onClick={ async () => {
                          centerId &&
                            searchRes?.find((o: any, i: any) => {
                              if (o._id === centerId) {
                                const physician = props.physicians?.filter((phy: any) => specialistProfile?._id === phy?._id);
                                console.log(centerId as string);

                                return o;
                              }
                              return null;
                            })

                          let selectedPhyscian = specialistProfile._id
                          console.log(centerId)

                          let CellText = "+19492803981";
                          let Useid = "631b6cfa97ae24000a8f8988";
                          if (centerId === "6168d8766c3ba4000bda4bea") {
                             Useid = "6168d80a6c3ba4000bda4be9"; /// DD Provider
                             CellText = "+19492803981";
                          } else if (centerId === "61cf329f90c17c000a8423d1") {
                             Useid = "61cf329f90c17c000a8423d1"; ///Dominique Hickman
                             CellText = "+13364939875";
                          } else if (centerId === "61cf71fdec28fe000a9a3770") {
                             Useid = "61cf71fdec28fe000a9a3770"; ///Thurman Brown
                             CellText = "+13477824097";
                          } else if (centerId === "61d87db1aa923a000af5dfe0") {
                             Useid = "61d87db1aa923a000af5dfe0"; ///Tami Allen
                             CellText = "+17135974499";
                          } else if (centerId === "61df1fe42baf7a000a38a2f8") {
                             Useid = "61df1fe42baf7a000a38a2f8"; ///Oluwatosin Akindele
                             CellText = "+19177050776";
                          } else if (centerId === "6203ee8e72cec0000a92b727") {
                             Useid = "6203ee8e72cec0000a92b727"; ///Dr. TaNika Harlis
                             CellText = "+19545314872";
                          } else if (centerId === "6206c5160840b2000a55791e") {
                             Useid = "6206c5160840b2000a55791e"; ///Eneze Obuaya, M.D.
                             CellText = "+18322633229";
                          } else if (centerId === "620e838a0537d0000ad7a5db") {
                             Useid = "620e838a0537d0000ad7a5db"; ///Laquanya Haggray
                             CellText = "+15185454807";
                          } else if (centerId === "6228d0c53007c9000a88f67f") {
                             Useid = "6228d0c53007c9000a88f67f"; ///Motier Haskins
                             CellText = "+15182509251";
                          } else if (centerId === "62c5a173fad547000a1639e4") {
                             Useid = "6168d80a6c3ba4000bda4be9" ///Alison Running Coach
                             CellText = "+14436776573";
                          } else if (centerId === "631b6cfa97ae24000a8f8988") {
                             Useid = "631b6dc397ae24000a8f89d7"; ///DDProf
                             CellText = "+18482257562";
                           } else if (centerId === "6168d80a6c3ba4000bda4be9") {
                              Useid = "6168d80a6c3ba4000bda4be9"; ///Base
                              CellText = "+19492803981";
                            } else if (centerId === "63319ec2bc3f40000ae9bafa") {
                               Useid = "63319ec2bc3f40000ae9bafa"; ///Christopher White
                               CellText = "+12672210700";
                            } else if (centerId === "6334840bc6c134000a13d9fb") {
                               Useid = "6334840bc6c134000a13d9fb"; ///Delzora / VoicedMania
                               CellText = "+12523499672";
                             } else if (centerId === "633ef1df99aae4000a30fa7d") {
                                Useid = "633ef1df99aae4000a30fa7d"; ///Bradford
                                CellText = "+19196497734";
                            } else if (centerId === "633f30ebbf3f8e000bf5d640") {
                               Useid = "633f30ebbf3f8e000bf5d640"; ///Tiana
                               CellText = "+15107348736";

                           } else if (centerId === "6365307e7afc44000b8a2028") {
                              Useid = "6365307e7afc44000b8a2028"; ///Nigeria
                              CellText = "+19044396524";
                          } else {
                             Useid = centerId as string;

                          }

                      //    let Useid = phyId as string
                          console.log(Useid)
                          setRequesting(true);
                          setRequestSuccessfull({ success: false, errorMessage: "" });

                        //  let Useid = specialistProfile.center
                      //    console.log(specialistProfile.center)
                  //        console.log(specialistProfile.center._id)
                          //console.log(Useid.phoneNumber)
                        //  const phy = props.physicians?.filter((phy: any) => Useid === Useid);
                        //  console.log(CellText)



                        const response = await patientProfileCalls.initPatientRequest(Useid, initMessage);
                        //  console.log(specialistProfile._id)
                        //  console.log(Useid)
                        //   send sms
                        const phy = props.physicians?.filter((phy: any) => specialistProfile?._id === phy?._id);
                        if (phy) {
                        const url = `https://app.deloresdigital.com/auth`;
                        const messageSentText = `${
                         "New client message on Delores\n\n" +
                         "You have a new inbound message from a client. To see the message, login to "
                        }${url}\n`;
                        await props.sendSMS({ message: messageSentText, phone: CellText });
                                  console.log(CellText)

                       }
                       setRequesting(false);
                       setRequestSuccessfull(response);
                       setOpenCrisisPopup(true);

                        }
                        }
                      //  onClick={handleSendCenterMessage}
                        // () =>
                        // handleSendMessage(
                        //   phyId &&
                        //     searchRes?.find((o: any, i: any) => {
                        //       if (o._id === phyId) {
                        //         return o;
                        //       }
                        //       return null;
                        //     })
                        // )
                        // }
                      >
                        Send
                      </Button>
                      <Button autoFocus variant="outlined" color="primary" onClick={handleClose}>
                        Close
                      </Button>
                    </DialogActions>
                    <CrisisPopup
                      openCrisisPopup={openCrisisPopup}
                      setOpenCrisisPopup={setOpenCrisisPopup}
                    />
                  </Dialog>
                  {/* </div> */}
                </>
              );
            })}
          {searchRes?.length === 0 && !usRedListStates.includes(selectedState[0]) && (
            <Alert severity="info">
              We haven't found the perfect professionals for this just yet. We promise they're
              coming soon.
            </Alert>
          )}
        </div>
      </CardContent>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAllCenters: () => dispatch(basicCalls.getAllCenters()),
  getPhysicians: () => dispatch(superBasicCalls.getPhysicians()),
  searchPhysician: (search: SearchPhyDTO) => dispatch(searchPhysician(search)),
  getPatient: () => dispatch(patientProfileCalls.getPatientProfile()),
  getOnlineUsers: () => dispatch(onlineCalls.getAllOnline()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),
  ping: (pingDto: PingRequestDTO) => dispatch(PingRequestsCalls.createPingRequest(pingDto)),
});
const mapStateToProps = (state: any) => ({
  centers: basicSelectors.fetchCenters(state.centers.basicCalls),
  patientChats: patientChatSelector.patientChats(state.patients.chat),
  physicians: superBasicSelectors.fetchPhysicians(state.supers.basicCalls),
  errorWhileGetCenters: basicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  getPatientError: patientProfileSelectors.isProfileFailure(state.patients.patientProfile),
  gettingPatient: patientProfileSelectors.isCallingProfile(state.patients.patientProfile),
  profile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  isOnlineSuccess: onlineSelectors.isOnlineSuccess(state.online),
  searchPhysicianSuccess: patientProfileSelectors.searchPhysicianSuccess(
    state.patients.patientProfile
  ),

  searchPhysicianFailure: patientProfileSelectors.searchPhysicianFailure(state.patients),
});
export const SelectCenter = connect(mapStateToProps, mapDispatchToProps)(_SelectCenter);
