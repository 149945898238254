import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import Home from "../../dashboardComponents/super/Home";
import SideBar from "../../sidebars/Sidebar";
import Appbar from "../../navbars/Appbar";
import { useStyles } from "../routesMuiStyling";
import { USER_TYPE } from "../../../store/types";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../../theme/ColorPalette";
import SuperManagement from "../../dashboardComponents/super/SuperManagement";
import AssignCenters from "../../dashboardComponents/super/AssignCenters";
import ControlAccounts from "../../dashboardComponents/super/Control";
import TimeControlAccount from "../../dashboardComponents/super/TimeControl";
import Request from "../../dashboardComponents/super/Request";
import Tracking from "../../dashboardComponents/super/Tracking";
import Users from "../../dashboardComponents/super/Users";
import AdminControl from "../../dashboardComponents/super/controlAccount/AdminControl";
import PatientControl from "../../dashboardComponents/super/controlAccount/PatientControl";
import PhysicianControl from "../../dashboardComponents/super/controlAccount/PhysicianControl";
import CenterControl from "../../dashboardComponents/super/controlAccount/CenterControl";
import AssignCen from "../../dashboardComponents/super/AssignUser/AssignCenter";
import RemoveAdm from "../../dashboardComponents/super/AssignUser/RemoveCenters";
import ControleTiming from "../../dashboardComponents/super/timeKeeping/ControleTiming";
import superScheduleTime from "../../dashboardComponents/super/Scheduling/superScheduleTime";

export function Main(props: any) {
  const history = useHistory();
  useEffect(() => {
    document.body.style.background = "none";
    document.body.style.backgroundColor = "none";
  });
  const classes = useStyles();
  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };
  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  return (
    <ThemeProvider theme={deloresTheme ? lightTheme : darkTheme}>
      <div className={classes.main}>
        <SideBar
          history={history}
          type={"super"}
          opendrawer={window.screen.width <= 668 ? !open : open}
          handleDrawerOpen={handleDrawerOpen}
        />

        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: deloresTheme ? "white" : "#272822",
            color: deloresTheme ? "black" : "white",
          }}
        >
          <header>
            <Appbar
              type={USER_TYPE.SUPER}
              handleTheme={handleTheme}
              deloresTheme={deloresTheme}
              handleDrawerOpen={handleDrawerOpen}
            />
          </header>
          <div className={classes.routes}>
            <Switch>
              <Route exact path="/super" component={Home} />
              {/* <Route exact path="/super/management" component={SuperManagement} /> */}
              <Route
                exact
                path="/super/management/admin"
                render={() => <SuperManagement userType={USER_TYPE.ADMIN} />}
              />
              <Route
                exact
                path="/super/management/center"
                render={() => <SuperManagement userType={USER_TYPE.CENTER} />}
              />
              <Route
                exact
                path="/super/management/admin/request"
                render={() => <Request userType={USER_TYPE.ADMIN} />}
              />

              <Route
                exact
                path="/super/management/admin/tracking"
                render={() => <Tracking userType={USER_TYPE.ADMIN} />}
              />
              <Route
                exact
                path="/super/management/center/tracking"
                render={() => <Tracking userType={USER_TYPE.CENTER} />}
              />

              <Route
                exact
                path="/super/management/admin/users"
                render={() => <Users userType={USER_TYPE.ADMIN} />}
              />
              <Route
                exact
                path="/super/management/center/users"
                render={() => <Users userType={USER_TYPE.CENTER} />}
              />

              <Route exact path="/super/assignCenters" component={AssignCenters} />
              <Route exact path="/super/assignCenters/center" component={AssignCen} />
              <Route exact path="/super/assignCenters/Admins" component={RemoveAdm} />

              <Route exact path="/super/control" component={ControlAccounts} />
              <Route exact path="/super/control/admins" component={AdminControl} />
              <Route exact path="/super/control/centers" component={CenterControl} />
              <Route exact path="/super/control/patient" component={PatientControl} />
              <Route exact path="/super/control/physician" component={PhysicianControl} />

              <Route exact path="/super/timekeeping" component={TimeControlAccount} />
              <Route exact path="/super/timekeeping/centers" component={ControleTiming} />
              <Route exact path="/super/timekeeping/physician" component={ControleTiming} />

              <Route exact path="/super/scheduling" component={superScheduleTime} />
              <Redirect to="/super" />
            </Switch>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
