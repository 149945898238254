// /* eslint-disable react-hooks/exhaustive-deps */
import { IPhysician } from "../../../store/types";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { physicianProfileCalls, physicianProfileSelectors } from "../../../store/physician/profile";
import MaterialTable from "material-table";
import {
  Chip,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import dayjs from "dayjs";
import moment from "moment";

import axios from "axios";
const useStyles = makeStyles({
  table: {
    minWidth: 350,
  },
});
interface OwnProps {
  getPhysicianProfile: () => Promise<void>;
  physicianProfile?: IPhysician;
}
type Props = OwnProps;
const PhysicalScheduleTime = (props: Props) => {
  const [bookedTimes, setBookedTimes] = useState<any>();
  const scheduleTime = async () => {
    try {
      const res = await axios.get<IPhysician>(`/appointment`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("centerToken")}`,
        },
      });
      if (res.status === 200) {
        setBookedTimes(res.data);
      }
    } catch (err) {
      return err;
    }
  };
  const getData = async () => {
    await props.getPhysicianProfile();
  };

  const { physicianProfile } = props;
  useEffect(() => {
    getData();
    scheduleTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dayName = (date: any) => {
    let d = new Date(date);
    let weekday = d.getUTCDay();
    switch (weekday) {
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return d;
    }
  };
  const inRange = (output: any, time: any) => {
    const result = output.filter((ot: any) => {
      const out = ot.range;

      //@ts-ignore

      const range = out?.split(" - ");

      const min = range[0];

      const max = range[1];

      const format2 = "MM/DD/YYYY";

      const currentTime = moment(time?.date).format(format2);

      if (currentTime >= min && currentTime <= max) {
        return out;
      }
      return false;
    });

    return result.length > 0 ? result[0] : null;
  };

  const classes = useStyles();

  return (
    <>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          title="Physical Time Keeping"
          columns={[
            { title: "Name", field: "firstName", type: "string" },
            { title: "Email", field: "email", type: "string" },
            { title: "Phone", field: "phoneNumber", type: "string" },
          ]}
          data={[
            {
              firstName: physicianProfile?.firstName,
              email: physicianProfile?.email,
              phoneNumber: physicianProfile?.phoneNumber,
              availableTime: physicianProfile?.availabilities,
              bookedTime: bookedTimes?.availabilities,
            },
          ]}
          detailPanel={[
            {
              tooltip: "Show Available Time and Booked Time",
              render: (rowData: any) => {
                const output1: any = [];

                let output: any = [];

                let startDate: any;

                let endDate: any;
                rowData?.availableTime?.map((time: any, index: any) => {
                  startDate = dayjs(time?.date).startOf("week").format("MM/DD/YYYY");

                  endDate = dayjs(time?.date).endOf("week").format("MM/DD/YYYY");

                  return output1.push({ key: index, range: `${startDate} - ${endDate}` });
                });

                rowData?.availableTime?.map((time: any) => {
                  let aTime: any = [];

                  let bTime: any = [];

                  let out: any = inRange(output1, time);

                  if (out) {
                    const day = dayName(time.date);
                    time?.slots?.map((slot: any) => {
                      if (slot.booked) {
                        return bTime.push(`${slot?.time} ${day}`);
                      }

                      if (slot.available && !slot.booked) {
                        return aTime.push(`${slot?.time} ${day}`);
                      }

                      return null;
                    });

                    return output.push({
                      key: out.key,
                      range: out.range,
                      available: aTime,
                      booked: bTime,
                    });
                  }
                  return null;
                });

                let sessions: any = [];
                output?.map((session: any) => {
                  const filter = output1?.filter((out: any) => out.key === session.key);

                  const sessionFilter2: any = sessions?.filter(
                    (se: any) => se.key === session.range
                  );
                  if (sessionFilter2.length > 0) {
                    const sessionFil = sessions?.filter((se: any) => se.key !== session.range);
                    const av: any = [].concat(session?.available, sessionFilter2[0]?.available);
                    const bk = [].concat(session?.booked, sessionFilter2[0]?.booked);

                    const x = [
                      ...sessionFil,
                      {
                        key: filter[0]?.range,
                        available: av,
                        booked: bk,
                      },
                    ];
                    sessions = x;
                    return sessions;
                  } else {
                    return sessions.push({
                      key: filter[0]?.range,
                      available: session?.available,
                      booked: session?.booked,
                    });
                  }
                });

                return (
                  <>
                    <div>
                      <TableContainer component={Paper}>
                        <Table className={classes.table} aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Week of</TableCell>
                              <TableCell align="center">Available Time</TableCell>
                              <TableCell align="center">Booked Time</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {sessions?.map((session: any, key: any) => (
                              <TableRow key={key}>
                                <TableCell component="th" scope="row">
                                  {session.key}
                                </TableCell>
                                <TableCell align="center">
                                  {session.available?.map((availTime: any) => {
                                    return (
                                      <Chip
                                        label={availTime}
                                        variant="outlined"
                                        style={{
                                          background: "grey",
                                          color: "white",
                                          margin: "5px",
                                        }}
                                      />
                                    );
                                  })}
                                </TableCell>
                                <TableCell align="center">
                                  {session?.booked?.map((booked: any, index: any) => {
                                    return (
                                      <Chip
                                        label={booked}
                                        key={index}
                                        variant="outlined"
                                        style={{
                                          background: "grey",
                                          color: "white",
                                          margin: "5px",
                                        }}
                                      />
                                    );
                                  })}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </div>
                  </>
                );
              },
            },
          ]}
          options={{
            actionsColumnIndex: -1,
            search: false,
            paging: false,
            showTitle: false,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
});
const mapDispatchToProps = (dispatch: any) => ({
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(PhysicalScheduleTime);
