import { Grid } from "@material-ui/core";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SelectCenter } from "../main/PortalComponents/SelectCenter";
import PinProvider from "./PinProviderProfessional";
import { Messages } from "./PortalComponents/Messages";
import { Specialties } from "./Specialties";
export default function Portal() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Switch>
          <Route path="/patient/portal/messages" component={() => <Messages group={false} />} />
          <Route
            path="/patient/portal/group-discussions"
            component={() => <Messages group={true} />}
          />
          <Route path="/patient/portal/selectCenter" component={SelectCenter} />
          <Route path="/patient/portal/pin" component={PinProvider} />
          <Route path="/patient/specialties" component={Specialties} />

          <Redirect to="/patient/portal/messages" />
        </Switch>
      </Grid>
    </Grid>
  );
}
