import React from "react";
import { Redirect, Route } from "react-router-dom";

// interface props {
//   component: Component<T, T>;
//   isAuthenticated: boolean;
// }

const AuthRedirectRoute = ({ component, ...rest }: any) => {
  const routeComponent = (props: any) =>
    localStorage.getItem("adminToken") ? (
      <Redirect to={{ pathname: "/admin" }} />
    ) : localStorage.getItem("superToken") ? (
      <Redirect to={{ pathname: "/super" }} />
    ) : localStorage.getItem("centerToken") ? (
      <Redirect to={{ pathname: "/center" }} />
    ) : localStorage.getItem("patientToken") ? (
      <Redirect to={{ pathname: "/patient" }} />
    ) : localStorage.getItem("physicianToken") ? (
      <Redirect to={{ pathname: "/physician" }} />
    ) : (
      React.createElement(component, props)
    );

  return <Route {...rest} render={routeComponent} />;
};
export default AuthRedirectRoute;
