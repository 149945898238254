export const patientProfileCalling = "@/patient/@profileCalling";
export const patientProfileSuccess = "@/patient/@profileSuccess";
export const patientProfileFailure = "@/patient/@profileFailure";

export const patientProfileUpdating = "@/patient/@patientProfileUpdating";
export const patientProfileUpdateSuccess = "@/patient/@patientProfileUpdateSuccess";
export const patientProfileUpdateFailure = "@/patient/@patientProfileUpdateFailure";

export const patientNumberUpdating = "@/patient/@patientNumberUpdating";
export const patientNumberUpdateSuccess = "@/patient/@patientNumberUpdateSuccess";
export const patientNumberUpdateFailure = "@/patient/@patientNumberUpdateFailure";

export const patientNumberVerificationUpdating = "@/patient/@patientNumberVerificationUpdating";
export const patientNumberVerificationUpdateSuccess =
  "@/patient/@patientNumberVerificationUpdateSuccess";
export const patientNumberVerificationUpdateFailure =
  "@/patient/@patientNumberVerificationUpdateFailure";

export const updatePatientFilePrivacyCalling = "@/patient/@updatePatientFilePrivacyCalling";
export const updatePatientFilePrivacySuccess = "@/patient/@updatePatientFilePrivacySuccess";
export const updatePatientFilePrivacyFailure = "@/patient/@updatePatientFilePrivacyFailure";

export const searchPhysicianCalling = "@/patient/@searchPhysicianCalling";
export const searchPhysicianSuccess = "@/patient/@searchPhysicianSuccess";
export const searchPhysicianFailure = "@/patient/@searchPhysicianFailure";
