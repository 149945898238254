export const gettingAdmins = (state: any) => state.gettingAdmins;
export const fetchAdmins = (state: any) => state.admins;
export const getAdminsError = (state: any) => state.getAdminsError;

export const gettingCenters = (state: any) => state.gettingCenters;
export const fetchCenters = (state: any) => state.centers;
export const getCentersError = (state: any) => state.getCentersError;

export const gettingPatients = (state: any) => state.gettingPatients;
export const fetchPatients = (state: any) => state.patients;
export const getPatientsError = (state: any) => state.getPatientsError;

export const gettingPhysicians = (state: any) => state.gettingPhysicians;
export const fetchPhysicians = (state: any) => state.physicians;
export const getPhysiciansError = (state: any) => state.getPhysiciansError;

export const updateAdminRequest = (state: any) => state.updateAdminRequest;
export const adminRequestUpdated = (state: any) => state.adminRequestUpdated;
export const adminRequestFailed = (state: any) => state.adminRequestFailed;

export const updatingAccount = (state: any) => state.updatingAccount;
export const updatedAccount = (state: any) => state.updatedAccount;
export const updateAccountError = (state: any) => state.updateAccountError;

export const assignCenterRequest = (state: any) => state.assignCenterRequest;
export const assignCenterRequestSuccess = (state: any) => state.assignCenterRequestSuccess;
export const assignCenterRequestFailure = (state: any) => state.assignCenterRequestFailure;

export const removeCenterFromAdminRequest = (state: any) => state.removeCenterFromAdminRequest;
export const removeCenterFromAdminRequestSuccess = (state: any) =>
  state.removeCenterFromAdminRequestSuccess;
export const removeCenterFromAdminRequestFailure = (state: any) =>
  state.removeCenterFromAdminRequestFailure;

export const removeCenterFeed = (state: any) => state.removeCenterFeed;
export const removeCenterFeedSuccess = (state: any) => state.removeCenterFeedSuccess;
export const removeCenterFeedFailure = (state: any) => state.removeCenterFeedFailure;

export const getAllCenterFeeds = (state: any) => state.getAllCenterFeeds;
export const getAllCenterFeedsSuccess = (state: any) => state.getAllCenterFeedsSuccess;
export const getAllCenterFeedsFailure = (state: any) => state.getAllCenterFeedsFailure;

export const gettingScheduleTime = (state: any) => state.gettingScheduleTime;
export const fetchScheduleTime = (state: any) => state.fetchScheduleTime;
export const getScheduleTimeError = (state: any) => state.getScheduleTimeError;
