import {
  Button,
  createStyles,
  Dialog,
  Grid,
  IconButton,
  Theme,
  Toolbar,
  withStyles,
  WithStyles,
} from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import io from "socket.io-client";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { ComposeComponent } from "./Compose";
import ContactsComponent from "./Contacts";
import ConversationComponent from "./Conversation";
import "../sidebars/sidebar.css";
import { chatSelectors as patientChatSelector } from "../../store/patients/chat";
import { chatSelectors as physicianChatSelector } from "../../store/physician/chat";
import { IChat } from "../../store/types/chat";
import { IMessage } from "../../store/types/message";
import { chatCalls as patientChatCalls } from "../../store/patients/chat";
import { chatCalls as physicianChatCalls } from "../../store/physician/chat";
import { physicianProfileCalls } from "../../store/physician/profile";
import { notesCalls, notesSelectors } from "../../store/Notes";
import {
  NewMessageDTO,
  NewNoteDTO,
  NotePatchDTO,
  NotificationDTO,
  RetryMessageDTO,
} from "../../store/types/type";
import { IPatient, USER_TYPE } from "../../store/types";
import { basicCalls, basicSelectors } from "../../store/centers/basicCalls";
import { ICenter } from "../../store/types/center";
import { IPhysician } from "../../store/types/physician";
import { physicianProfileSelectors } from "../../store/physician/profile";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Note from "../ChatDetails/Note";
import FilesList from "../ChatDetails/FilesList";
import { INote } from "../../store/types/note";
import CloseIcon from "@material-ui/icons/Close";
import { UploadDocumentComp } from "../dashboardComponents/main/ProfileComponents/UploadDocument/UploadDocumentComp";
import { patientProfileCalls, patientProfileSelectors } from "../../store/patients/profile";
import FileView from "../ChatDetails/FileView";
import { Alert } from "@material-ui/lab";
import BookFullSession from "../popup/BookFullSession";
import VideoCall from "./VideoCall";
import { notificationSMSCalls } from "../../store/notification/sms";
// import * as smsText from "../../staticResources/notifications/smsText";

import { feedSelectors } from "../../store/feed";
import { feedCalls } from "../../store/feed";

const styles = (theme: Theme) => {
  createStyles({
    root: {
      flexGrow: 1,
    },
    contacts: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    conversation: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    compose: {},
  });
};
interface StateProps {
  patientChats: IChat[];
  physicianChats: IChat[];

  centersAndPhysicians: ICenter[];
  gettingCenters: boolean;
  getCentersError: string;
  physicianProfile: IPhysician;
  patientProfile: IPatient;
  physicianProfileCalling: boolean;
  physicianProfileFailure: string;
  gettingMyNotes: boolean;
  getMyNotesError: string;
  creatingMyNotes: boolean;
  createMyError: string;
  updatingMyNote: boolean;
  updateMyNoteError: string;
  myNotes: INote[];

  // ErrorWhileGettingChats
  chatGetError: string;
  drawerOpen: boolean;
}
interface DispatchProps {
  getPatientAllChats: () => Promise<void>;
  getPhysicianAllChats: () => Promise<void>;
  getAllCentersAndPhysicians: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
  getPatientProfile: () => Promise<void>;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;

  getMyNotes: () => Promise<void>;
  updateMyNote: (noteUpdateDTO: NotePatchDTO) => Promise<void>;
  createMyNote: (createNoteDTO: NewNoteDTO) => Promise<void>;
  handleDrawerOpen: () => Promise<void>;
}

interface OwnProps extends WithStyles {
  userType: USER_TYPE;
  group: boolean;
}

type Props = OwnProps & StateProps & DispatchProps;

interface State {
  messages: IMessage[];
  retryMessages: RetryMessageDTO[];
  currentChat?: IChat;
  socket: SocketIOClient.Socket;
  rooms: string;
  chatExpanded: boolean;
  currentNote?: INote;
  uploading: boolean;
  // screenSize:Number;
  open: boolean;
  openFullSessionPopup: boolean;
  openVideoCallPopup: boolean;
}
const URL = `${process.env.REACT_APP_API_URL}/chat`;
export class _ChatComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      messages: [],
      retryMessages: [],
      // TODO
      // socket: io(URL),   // Production App Engine Flex (With Sockets Support)
      socket: io(URL, { transports: ["polling"] }), // Production App Engine Standard (NO Sockets Support)
      rooms: "",
      chatExpanded: true,
      uploading: false,
      // screenSize:window.screen.width,
      open: false,
      openFullSessionPopup: false,
      openVideoCallPopup: false,
    };
    this.state.socket.on("catch-room-message", (message: IMessage) => {
      // console.log(message, "received message in this room");
      this.setState({
        ...this.state,
        messages: [...this.state.messages, message],
      });
    });
    this.state.socket.on("connect", () => {
      console.log("connected", this.state.rooms);
      if (this.state.rooms) {
        this.state.socket.emit("leaveRoom", this.state.rooms);
        this.state.socket.emit("joinRoom", this.state.rooms);
      }
    });
    this.state.socket.on("disconnect", (reason: any) => {
      console.log("disconnected", reason);
    });
    this.state.socket.on("joinedRoom", (room: string) => {
      console.log("joined room ", room);
      this.setState({
        ...this.state,
        rooms: room,
      });
    });
    this.state.socket.on("book-full-session-popup", (data: any) => {
      const { patientId } = data;
      if (patientId === this.props.patientProfile._id) {
        this.setState({
          ...this.state,
          openFullSessionPopup: true,
        });
      }
    });
    this.state.socket.on("video-call-popup", (data: any) => {
      const { patientId } = data;
      if (patientId === this.props.patientProfile._id) {
        this.setState({
          ...this.state,
          openVideoCallPopup: true,
          //requestFullscreen: true
        });
      }
    });
  }
  componentWillUnmount() {
    if (this.state.rooms) this.state.socket.emit("leaveRoom", this.state.rooms);
    this.state.socket.disconnect();
  }
  // componentWillMount() {
  //   this.setState({screenSize:window.screen.width})
  //    console.log("window.screen.width",this.state.screenSize);

  // }
  async componentDidMount() {
    await this.props.getMyNotes();
    // await this.getChats();
    if (this.state.currentChat) {
      this.state.socket.emit("joinRoom", this.state.currentChat._id);
    }
  }
  async getChats() {
    if (this.props.userType === USER_TYPE.PATIENT) {
      await this.props.getPatientAllChats();
      const initialChat = this.props.patientChats.filter(
        (pc) => pc.isGroup === this.props.group
      )[0];
      let initialNote: any = this.props.myNotes.filter(
        (note) => note.chat?._id === initialChat?._id
      );
      initialNote = initialNote.length ? initialNote[0] : undefined;
      this.setState({
        ...this.state,
        currentChat: initialChat,
        currentNote: initialNote,
      });
      await this.props.getPatientProfile();
    } else if (this.props.userType === USER_TYPE.PHYSICIAN) {
      await this.props.getPhysicianAllChats();
      const initialChat = this.props.physicianChats.filter(
        (pc) => pc.isGroup === this.props.group && pc.patient !== undefined
      )[0];
      let initialNote: any = this.props.myNotes.filter(
        (note) => note.chat?._id === initialChat?._id
      );
      initialNote = initialNote.length ? initialNote[0] : undefined;
      this.setState({
        ...this.state,
        currentChat: initialChat,
        currentNote: initialNote,
      });
      await this.props.getPhysicianProfile();
    }
  }
  render() {
    const handleClose = () => {
      this.setState({
        ...this.state,
        open: false,
      });
    };
    const handleCloseFullSessionPopup = () => {
      this.setState({
        ...this.state,
        openFullSessionPopup: false,
      });
    };
    const handleCloseVideoCallPopup = () => {
      this.setState({
        ...this.state,
        openVideoCallPopup: false,
      });
    };
    const handleUploadDialog = () => {
      this.setState({
        ...this.state,
        open: true,
      });
    };
    const sendVideoCall = async () => {
      const { socket, currentChat } = this.state;
      if (this.state.currentChat) {
        this.state.socket.emit("video-call", {
          socketId: socket.id,
          chatID: currentChat?._id,
          patientId: currentChat?.patient?._id,
        });
      }
    };

    const sendBookFullSessions = () => {
      const { socket, currentChat } = this.state;
      if (this.state.currentChat) {
        this.state.socket.emit("book-full-session", {
          socketId: socket.id,
          chatId: currentChat?._id,
          patientId: currentChat?.patient?._id,
        } as any);
      }
    };
    const convClick = async (chat: IChat) => {
      const res = await axios.get<IMessage[]>(`/chat/chat-messages/${chat._id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(
            this.props.userType === USER_TYPE.PATIENT
              ? "patientToken"
              : this.props.userType === USER_TYPE.CENTER
              ? "centerToken"
              : "physicianToken"
          )}`,
        },
      });
      if (this.state.rooms) this.state.socket.emit("leaveRoom", this.state.rooms);
      this.state.socket.emit("joinRoom", chat?._id);
      let note: INote[] | INote | undefined = this.props.myNotes?.filter(
        (note) => note.chat?._id === chat?._id
      );
      note = note.length ? note[0] : undefined;
      this.setState({
        ...this.state,
        messages: res.data,
        currentChat: chat,
        currentNote: note,
      });
    };

    const getChatsAfterAdd = async (chat: IChat) => {
      await this.getChats();
      convClick(chat);
    };

    const changeChatPrivacy = async (chatID: string, newPrivacy: boolean) => {
      if (this.props.userType !== USER_TYPE.PATIENT) return;

      const res = await axios.patch<IChat>(
        `/chat/chat-privacy/${chatID}`,
        { isPrivate: newPrivacy ? "1" : "0" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
          },
        }
      );

      this.setState({
        ...this.state,
        currentChat: {
          ...this.state.currentChat!,
          isPrivate: res.data.isPrivate,
        },
      });
    };

    const changeMessagePrivacy = async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (this.props.userType !== USER_TYPE.PATIENT) return;
      console.log(`Privacy changed for ${event.target.name}`);
      console.log(`New privacy: ${event.target.checked}`);
      // TODO
      // API call
      // Update local state for messages
      const res = await axios.patch<IMessage>(
        `/messages/message-privacy/${event.target.name}`,
        { isPrivate: event.target.checked ? "1" : "0" },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
          },
        }
      );

      const newMessages = [...this.state.messages];
      const msg = newMessages.find((e) => e._id === event.target.name);
      if (msg) msg.isPrivate = res.data.isPrivate;
      this.setState({
        ...this.state,
        messages: [...newMessages],
      });
    };
    // const dispatch = useDispatch();

    const sendMessage = async (text: string) => {
      if (!this.state.currentChat) return;
      console.log(`Adding a new message: ${text} to Chat with ID: ${this.state.currentChat._id}`);
      const newMessage: NewMessageDTO = {
        chatID: this.state.currentChat._id,
        text,
      };
      try {
        const res = await axios.post<IMessage>(`/messages/new-message`, newMessage, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem(
              this.props.userType === USER_TYPE.PATIENT ? "patientToken" : "physicianToken"
            )}`,
          },
        });
        this.state.socket.emit("send-message", {
          chatID: newMessage.chatID,
          message: res.data,
        });
        this.state.socket.emit("handleMessagesNotifications", {
          chatID: newMessage.chatID,
          message: res.data,
        });
        this.state.socket.emit("activeTime", newMessage.chatID);
        this.setState({
          ...this.state,
          messages: [...this.state.messages, res.data],
        });
        const us =
          this.props.userType === USER_TYPE.PATIENT
            ? this.state.currentChat?.physicians[0]
            : this.props.userType === USER_TYPE.PHYSICIAN
            ? this.state.currentChat?.patient
            : null;
        const currentuser =
          this.props.userType === USER_TYPE.PATIENT
            ? this.state.currentChat?.patient
            : this.props.userType === USER_TYPE.PHYSICIAN
            ? this.state.currentChat?.physicians[0]
            : null;
        if (us && currentuser) {
          const url = `https://app.deloresdigital.com/auth`;
          const messageSentText = `${
            "New message on Delores.\n\n" +
            `You have a new message from ${currentuser.firstName}. To see the message, login to `
          }${url}\n`;
          await this.props.sendSMS({ message: messageSentText, phone: us.phoneNumber });
        }
      } catch (err) {
        this.setState({
          ...this.state,
          retryMessages: [
            ...this.state.retryMessages,
            {
              //TODO profile fname
              fromPatient:
                this.props.userType === USER_TYPE.PATIENT
                  ? { firstName: "a", _id: localStorage.getItem("_id") || "" }
                  : undefined,
              fromPhysician:
                this.props.userType === USER_TYPE.PHYSICIAN
                  ? { firstName: "s", _id: localStorage.getItem("_id") || "" }
                  : undefined,
              isPrivate: false,
              ...newMessage,
            },
          ],
        });
      }
    };
    const uploadChatFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!(e.currentTarget.files && e.currentTarget.files.length > 0)) return;
      const data = new FormData();
      data.append("fileField", e.currentTarget.files[0]);
      if (!this.state.currentChat) return;
      data.append("chatID", this.state.currentChat._id);
      this.setState({ ...this.state, uploading: true });
      try {
        const res = await axios.post("/files-manager/upload-chat", data, {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("patientToken") ||
              localStorage.getItem("physicianToken") ||
              localStorage.getItem("centerToken")
            }`,
          },
        });
        this.setState({ ...this.state, uploading: false });
        if (res.status === 201) {
          this.getChats();
        }
      } catch (error) {
        this.setState({ ...this.state, uploading: false });
      }
    };
    return (
      <div className="chatBox">
        {this.props.physicianChats?.filter((pc) => pc.isGroup === this.props.group && pc.patient)
          .length ||
        this.props.patientChats?.filter((pc) => pc.isGroup === this.props.group).length ? (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={5}
              lg={5}
              xl={4}
              style={{
                borderRight: "2px solid #12d43b",
                background: "antiquewhite",
                alignItems: "center",
              }}
            >
              <div style={{ width: "100%", background: "white" }}>
                <Accordion
                  onChange={(e, expanded) =>
                    this.setState({ ...this.state, chatExpanded: expanded })
                  }
                  expanded={this.state.chatExpanded}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Chats</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div
                      className="contactList"
                      style={{ minHeight: "fit-content", maxHeight: "48vh" }}
                    >
                      <ContactsComponent
                        userType={this.props.userType}
                        chats={
                          this.props.userType === USER_TYPE.PATIENT
                            ? this.props.patientChats.filter(
                                (pc) => pc.isGroup === this.props.group
                              )
                            : this.props.physicianChats.filter(
                                (pc) => pc.isGroup === this.props.group && pc.patient
                              )
                        }
                        profile={
                          this.props.userType === USER_TYPE.PHYSICIAN
                            ? this.props.physicianProfile
                            : undefined
                        }
                        convClick={convClick}
                        currentChat={this.state.currentChat}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>
                {this.state.currentChat ? (
                  <Accordion
                    expanded={!this.state.chatExpanded}
                    onChange={(e, expanded) =>
                      this.setState({ ...this.state, chatExpanded: !expanded })
                    }
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography>Chat Tools</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ flexDirection: "column" }}>
                      <Note
                        note={this.state.currentNote}
                        saveNote={this.props.updateMyNote}
                        errorWhileSavingNote={this.props.updateMyNoteError}
                        savingNote={this.props.updatingMyNote}
                      />
                      <div
                        style={{
                          overflow: "scroll",
                          overflowX: "hidden",
                          overflowY: "auto",
                          maxHeight: "300px",
                        }}
                      >
                        {this.state.currentChat.files.length > 0 && (
                          <h5 style={{ textAlign: "center" }}>Chat Files</h5>
                        )}
                        {this.state.currentChat && this.state.currentChat.files.length > 0 && (
                          <FilesList files={this.state.currentChat.files} />
                        )}
                        <h5 style={{ textAlign: "center" }}>
                          {this.props.userType === USER_TYPE.PHYSICIAN
                            ? "My Public Files"
                            : "Professional Files"}{" "}
                        </h5>
                        {this.state.currentChat && this.state.currentChat.physicians.length > 0
                          ? this.state.currentChat.physicians.map((phy, i) => {
                              return <FilesList files={phy.files} key={i} />;
                            })
                          : ""}
                        <h5 style={{ textAlign: "center" }}>
                          {this.props.userType === USER_TYPE.PHYSICIAN
                            ? "Users Files"
                            : "My Public Files"}
                        </h5>
                        {this.state.currentChat &&
                          this.state.currentChat?.patient?.files.map((pat, i) => {
                            return (
                              <div style={{ display: "flex", justifyContent: "center" }} key={i}>
                                <FileView file={pat} />
                              </div>
                            );
                          })}
                      </div>
                      <Button
                        variant="contained"
                        color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        disabled={this.state.uploading}
                      >
                        Upload File
                        <input type="file" hidden onChange={uploadChatFile} />
                      </Button>
                      <Button
                        variant="contained"
                        style={{ marginTop: "10px" }}
                        color="primary"
                        component="label"
                        startIcon={<CloudUploadIcon />}
                        disabled={this.state.uploading}
                        onClick={handleUploadDialog}
                      >
                        Add From Profile
                      </Button>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}
              </div>
              {this.props.userType === "PHYSICIAN" && this.state.currentChat && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    background: "white",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                    width: "100%",
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px", marginRight: "10px" }}
                    onClick={() => {
                      this.setState({
                        ...this.state,
                        openVideoCallPopup: true,
                      });
                    }}
                  >
                    Start Video Call
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px" }}
                    onClick={() => {
                      this.setState({ ...this.state, openFullSessionPopup: true });
                    }}
                  >
                    Send Session Confirmaton Form
                  </Button>
                </div>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={7}
              lg={7}
              xl={8}
              style={{ background: "antiquewhite", alignItems: "center" }}
            >
              <div className="conversationList">
                {this.state.currentChat ? (
                  <>
                    <ConversationComponent
                      sendSMS={this.props.sendSMS}
                      messages={this.state.messages}
                      retryMessages={this.state.retryMessages}
                      retrySending={(text: string, messageIndex: number) => {
                        const tempArr = [...this.state.retryMessages];
                        tempArr.splice(messageIndex, 1);
                        this.setState({
                          ...this.state,
                          retryMessages: tempArr,
                        });
                        sendMessage(text);
                      }}
                      userType={this.props.userType}
                      currentChat={this.state.currentChat}
                      changeChatPrivacy={changeChatPrivacy}
                      changeMessagePrivacy={changeMessagePrivacy}
                      getAllCentersAndPhysicians={this.props.getAllCentersAndPhysicians}
                      centersAndPhysicians={this.props.centersAndPhysicians}
                      getCentersError={this.props.getCentersError}
                      gettingCenters={this.props.gettingCenters}
                      getChatsAfterAdd={getChatsAfterAdd}
                      physicianProfile={this.props.physicianProfile}
                      physicianProfileCalling={this.props.physicianProfileCalling}
                      physicianProfileFailure={this.props.physicianProfileFailure}
                    />
                    <ComposeComponent sendMessage={sendMessage} />
                  </>
                ) : (
                  <div className="parent">
                    <div className="child">Select a chat from the chat menu on the left</div>
                  </div>
                )}
                {window.innerWidth < 768 && !this.state.currentChat && (
                  <div>
                    <ComposeComponent sendMessage={sendMessage} />
                  </div>
                )}
              </div>
            </Grid>
            <Dialog fullScreen open={this.state.open} onClose={handleClose}>
              <Toolbar>
                <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                  <CloseIcon />
                </IconButton>
              </Toolbar>
              <div>
                <UploadDocumentComp
                  getCenterProfile={async () => {}}
                  getPhysicianProfile={this.props.getPhysicianProfile}
                  patientProfile={this.props.patientProfile}
                  physicianProfile={this.props.physicianProfile}
                  userType={this.props.userType}
                  classes={"" as any}
                  getPatientProfile={this.props.getPatientProfile}
                />
              </div>
            </Dialog>
            <Dialog open={this.state.openFullSessionPopup} onClose={handleCloseFullSessionPopup}>
              <div>
                <BookFullSession
                  socket={this.state.socket}
                  currentChat={this.state.currentChat}
                  sendBookFullSessions={sendBookFullSessions}
                  handleCloseFullSessionPopup={handleCloseFullSessionPopup}
                  sendSMS={this.props.sendSMS}
                />
              </div>
            </Dialog>
            <Dialog
              open={this.state.openVideoCallPopup}
              disableBackdropClick={true}
              maxWidth={false}
              fullScreen
            >
              <div>
                <VideoCall
                  sendSMS={this.props.sendSMS}
                  socketId={this.state.socket}
                  currentChat={this.state.currentChat}
                  sendVideoCall={sendVideoCall}
                  handleCloseVideoCallPopup={handleCloseVideoCallPopup}
                  handleDrawerOpen={this.props.handleDrawerOpen}
                  drawerOpen={this.props.drawerOpen}
                />
              </div>
            </Dialog>
          </Grid>
        ) : (
          <Alert severity="info">
            You dont have any {this.props.group ? "group discussions" : "chats"} at the moment
          </Alert>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state: any) => ({
  patientChats: patientChatSelector.patientChats(state.patients.chat),
  physicianChats: physicianChatSelector.physicianChats(state.physicians.chat),
  centersAndPhysicians: basicSelectors.fetchCenters(state.centers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  physicianProfile: physicianProfileSelectors.isPhysicianProfile(state.physicians.physicianProfile),
  patientProfile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  physicianProfileCalling: physicianProfileSelectors.isCallingProfile(
    state.physicians.physicianProfile
  ),
  physicianProfileFailure: physicianProfileSelectors.isProfileFailure(
    state.physicians.physicianProfile
  ),
  // ErrorWhileGettingChats
  chatGetError:
    localStorage.getItem("current") === USER_TYPE.PATIENT
      ? patientChatSelector.getAllChatsFailure(state.patients.chat)
      : physicianChatSelector.getAllChatsFailure(state.physicians.chat),
  // getAllNotes
  gettingMyNotes: notesSelectors.isGettingMyNotes(state.notes),
  getMyNotesError: notesSelectors.errorWhileGettingMyNotes(state.notes),
  //createANote
  creatingMyNotes: notesSelectors.isCreatingNote(state.notes),
  createMyError: notesSelectors.errorWhileCreatingNote(state.notes),
  //updateExistingNote
  updatingMyNote: notesSelectors.isUpdatingNote(state.notes),
  updateMyNoteError: notesSelectors.errorWhileUpdatingNote(state.notes),
  //myNotes
  myNotes: notesSelectors.myNotes(state.notes),
  drawerOpen: feedSelectors.drawerOpen(state.feeds),
});
const mapDispatchToProps = (dispatch: any) => ({
  getPatientAllChats: () => dispatch(patientChatCalls.getAllChats()),
  getPhysicianAllChats: () => dispatch(physicianChatCalls.getAllChats()),
  getAllCentersAndPhysicians: () => dispatch(basicCalls.getAllCenters()),
  getPhysicianProfile: () => dispatch(physicianProfileCalls.getPhysicianProfile()),
  getPatientProfile: () => dispatch(patientProfileCalls.getPatientProfile()),
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),

  getMyNotes: () => dispatch(notesCalls.getMyNotes()),
  updateMyNote: (noteUpdateDTO: NotePatchDTO) => dispatch(notesCalls.updateNote(noteUpdateDTO)),
  createMyNote: (createNoteDTO: NewNoteDTO) => dispatch(notesCalls.createNote(createNoteDTO)),
  handleDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});
export const ChatComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(_ChatComponent));
