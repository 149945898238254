import { Avatar, Card, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { ICenter, IFileData, IPatient, IPhysician, USER_TYPE } from "../../../../../store/types";
import { AvatarPopup } from "../../../../popup/AvatarPopup";
import { CenterAvatar } from "../../../center/ProfileComponents/CenterAvatar";
import { PatientAvatar } from "../../../patient/ProfileComponents/PatientAvatar";
import { PhysicianAvatar } from "../../../physician/ProfileComponents/PhysicianAvatar";
import { ProfileInfoUser } from "./ProfileInfoUser";
import EditIcon from "@material-ui/icons/Edit";
import { PrimaryButton } from "../../../../base/Components/PrimaryButton";

interface Props {
  userType: USER_TYPE;
  centerProfile?: ICenter;
  physicianProfile?: IPhysician;
  patientProfile?: IPatient;
  getCenterProfile: () => Promise<void>;
  getPatientProfile: () => Promise<void>;
  getPhysicianProfile: () => Promise<void>;
  read?: boolean;
}
export const MainAvatorComp = (props: Props) => {
  const [openPopupAvatar, setOpenPopupAvatar] = useState(false);
  const [files, setFiles] = useState<IFileData[]>();

  useEffect(() => {
    switch (props.userType) {
      // get profile state wrt user
      case USER_TYPE.PATIENT:
        props.getPatientProfile();
        break;
      case USER_TYPE.PHYSICIAN:
        props.getPhysicianProfile();
        break;
      case USER_TYPE.CENTER:
        props.getCenterProfile();
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    switch (props.userType) {
      // set profile files
      case USER_TYPE.PATIENT:
        setFiles(props.patientProfile?.files);
        break;
      case USER_TYPE.PHYSICIAN:
        setFiles(props.physicianProfile?.files);
        break;
      case USER_TYPE.CENTER:
        setFiles(props.centerProfile?.files);
        break;
      default:
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.centerProfile?.files || props.physicianProfile?.files || props.patientProfile?.files]);
  return (
    <>
      <div>
        {props.userType === USER_TYPE.PATIENT ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Avatar
              style={{
                width: "120px",
                height: "120px",
                margin: "auto",
                boxShadow:
                  "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
              }}
              alt="physician pic"
              src={
                files?.filter((f: any) => f.isProfile)[0]?.url ||
                `https://via.placeholder.com/150?text=Profile`
              }
            />
            <div
              style={{
                marginTop: "115px",
                marginLeft: "60px",
                position: "absolute",
                cursor: "pointer",
              }}
            >
              <EditIcon
                style={{
                  fontSize: "2rem",
                  color: "#fff",
                  backgroundColor: "#000",
                  padding: "5px",
                  borderRadius: "50%",
                }}
                onClick={() => setOpenPopupAvatar(true)}
              />
            </div>
          </div>
        ) : (
          <Avatar
            style={{
              width: "120px",
              height: "120px",
              margin: "auto",
              boxShadow:
                "0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
            }}
            alt="physician pic"
            src={
              files?.filter((f: any) => f.isProfile)[0]?.url ||
              `https://via.placeholder.com/150?text=Profile`
            }
          />
        )}
      </div>
      <Card
        style={{
          height: "400px",
          marginTop: "-5em",
          backgroundColor: "#E1E2E1",
          color: "black",
          paddingBottom: 10,
        }}
      >
        <div
          style={{
            marginTop: "7em",
            paddingLeft: "15px",
          }}
        >
          <Typography
            variant="h5"
            gutterBottom
            style={{
              fontSize: "1rem",
              fontStyle: "italic",
            }}
          >
            {props.userType === USER_TYPE.CENTER ? `${props.centerProfile?.name}` : ``}
            {props.userType === USER_TYPE.PHYSICIAN
              ? `${props.physicianProfile?.firstName} ${props.physicianProfile?.lastName}`
              : ``}
            {props.userType === USER_TYPE.PATIENT ? <ProfileInfoUser /> : ``}
          </Typography>
        </div>
        <div>
          {props.read ? (
            ""
          ) : (
            <>
              {props.userType !== USER_TYPE.PATIENT && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <PrimaryButton
                    onClick={() => setOpenPopupAvatar(!openPopupAvatar)}
                    title="Edit Avatar"
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Card>

      <AvatarPopup openPopupAvatar={openPopupAvatar} setOpenPopupAvatar={setOpenPopupAvatar}>
        {props.userType === USER_TYPE.CENTER ? (
          <CenterAvatar
            profile={props.centerProfile}
            getCenterProfile={props.getCenterProfile}
            setOpenPopupAvatar={setOpenPopupAvatar}
          />
        ) : (
          ""
        )}
        {props.userType === USER_TYPE.PHYSICIAN ? (
          <PhysicianAvatar
            profile={props.physicianProfile}
            getPhysicianProfile={props.getPhysicianProfile}
            setOpenPopupAvatar={setOpenPopupAvatar}
          />
        ) : (
          ""
        )}
        {props.userType === USER_TYPE.PATIENT ? (
          <PatientAvatar
            profile={props.patientProfile}
            getPatientProfile={props.getPatientProfile}
            setOpenPopupAvatar={setOpenPopupAvatar}
          />
        ) : (
          ""
        )}
      </AvatarPopup>
    </>
  );
};
