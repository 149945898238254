export const isCreatingNote = (state: any) => state.creatingNote;
export const errorWhileCreatingNote = (state: any) => state.createNoteFailure;

export const myNotes = (state: any) => state.myNotes || [];

export const isUpdatingNote = (state: any) => state.updatingNote;
export const errorWhileUpdatingNote = (state: any) => state.updateNoteFailure;

export const isGettingMyNotes = (state: any) => state.gettingMyNotes;
export const errorWhileGettingMyNotes = (state: any) => state.getMyNotesFailure;
