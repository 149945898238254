import * as types from "./types";

const initialState = {
  heartWPCCalling: false,
  heartWPCResult: "",
  heartWPCFailure: "",

  liverWPCCalling: false,
  liverWPCResult: "",
  liverWPCFailure: "",

  kidneyWPCCalling: false,
  kidneyWPCResult: "",
  kidneyWPCFailure: "",
};
export const WPCReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.heartWPCCalling:
      return {
        ...state,
        heartWPCCalling: true,
        heartWPCResult: "",
        heartWPCFailure: "",
      };
    case types.heartWPCSuccess:
      return {
        ...state,
        heartWPCCalling: false,
        heartWPCResult: action.payload,
      };
    case types.heartWPCFailure:
      return {
        ...state,
        heartWPCCalling: false,
        heartWPCFailure: action.payload,
      };

    case types.liverWPCCalling:
      return {
        ...state,
        liverWPCCalling: true,
        liverWPCResult: "",
        liverWPCFailure: "",
      };
    case types.liverWPCSuccess:
      return {
        ...state,
        liverWPCCalling: false,
        liverWPCResult: action.payload,
      };
    case types.liverWPCFailure:
      return {
        ...state,
        liverWPCCalling: false,
        liverWPCFailure: action.payload,
      };

    case types.kidneyWPCCalling:
      return {
        ...state,
        kidneyWPCCalling: true,
        kidneyWPCResult: "",
        kidneyWPCFailure: "",
      };
    case types.kidneyWPCSuccess:
      return {
        ...state,
        kidneyWPCCalling: false,
        kidneyWPCResult: action.payload,
      };
    case types.kidneyWPCFailure:
      return {
        ...state,
        kidneyWPCCalling: false,
        kidneyWPCFailure: action.payload,
      };
    default: {
      return state;
    }
  }
};
