/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  Button,
  DialogContent,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import "../authComponents/style.css";
import { PrimaryButton } from "../base/Components/PrimaryButton";
import { RateUpdateDTO } from "../../store/types/type";
import { IPhysician } from "../../store/types/physician";
import { PhysicianProfile } from "../../components/dashboardComponents/PhysicianProfile";



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  dailog: {
    padding: theme.spacing(2),
    position: "absolute",
  },
}));

var DDrate = ""
var DHrate = "" ///Dominique Hickman
var TBrate = "" ///Thurman Brown
var TArate = "" ///Tami Allen
var OArate = "" ///Oluwatosin Akindele
var TNrate = "" ///Dr. TaNika Harlis
var EOrate = "" ///Eneze Obuaya, M.D.
var LHrate = "" ///Laquanya Haggray
var MHrate = "" // Motier Haskins
var ARrate = "" ///Alison Running Coach
var Dtest1 = "" // prof2@gmail.com
var Dtest2 = "" // dprof3@gmail.com
var Dtest3 = "" // deloresp@gmail.com
var Baserate = ""
var profRate = ""
//export const profRate = ""

export const UpdateRatePopup = (props: {
  openPopupRate: boolean;
  setOpenPopupRate: any;
  updateProfileRate: (rateUpdateDTO: RateUpdateDTO) => {};
  getPatientProfile: () => Promise<any>;
  physicianProfile?: IPhysician;
  getPhysicianProfile: () => Promise<any>;

  //setOpenPopupOtp: () => void;
}) => {
  const classes = useStyles();

  const [updateProfile, setUpdateProfile] = useState({
    rates: "",
    type: "",
    id: "",
  });

  const [ratesDT2, setratesDT2] = useState("TBD")
  const [ratesDT3, setratesDT3] = useState("TBD")


  const [numberError, setRateError] = useState("");
  const bground = String(props.physicianProfile?.firstName)



  const handleSubmit = () => {
    if (updateProfile.rates.trim() === "") {
      setRateError("Please enter your rate in USD.");
      return;
    }
    localStorage.setItem("rates", updateProfile.rates);
    props.updateProfileRate(updateProfile);
    props.getPatientProfile();
    props.setOpenPopupRate(false);
    setUpdateProfile({ ...updateProfile, rates: "" });
    console.log(String(updateProfile.rates));
    console.log(String(localStorage.firstName));
    console.log(String(localStorage.email));
    console.log(String(localStorage._id));
    console.log(String(bground))


    if (localStorage._id = "6168d8766c3ba4000bda4bea") {
      localStorage.setItem("ratesDT1", updateProfile.rates)}; // prof2@gmail.com

  //  if (localStorage._id = "62e994104176bd000a953929") {
    //  localStorage.setItem("ratesDT1", updateProfile.rates)}; // prof2@gmail.com

    if (localStorage._id = "63129234778fd3000afb25d8") {
      localStorage.setItem("ratesDT2", updateProfile.rates)}; // dprof3@gmail.com

    if (localStorage._id = "6312939b778fd3000afb270f") {
      localStorage.setItem("ratesDT3", updateProfile.rates)}; // deloresp@gmail.com


    var DDrate = ""
    var DHrate = "" ///Dominique Hickman
    var TBrate = "" ///Thurman Brown
    var TArate = "" ///Tami Allen
    var OArate = "" ///Oluwatosin Akindele
    var TNrate = "" ///Dr. TaNika Harlis
    var EOrate = "" ///Eneze Obuaya, M.D.
    var LHrate = "" ///Laquanya Haggray
    var MHrate = "" // Motier Haskins
    var ARrate = "" ///Alison Running Coach
    var Dtest1 = "" // prof2@gmail.com
    var Dtest2 = "" // dprof3@gmail.com
    var Dtest3 = "" // deloresp@gmail.com
    var Baserate = ""
    var profRate = ""

    if (localStorage._id = "6168d8766c3ba4000bda4bea") {
      var DDrate = String(updateProfile.rates); /// DD Provider
    } else if (localStorage._id = "61cf37cfa43402000a3915dc") {
      var DHrate = String(updateProfile.rates) ///Dominique Hickman
    } else if (localStorage._id = "61cf7621db1e02000a613a75") {
      var TBrate = String(updateProfile.rates) ///Thurman Brown
    } else if (localStorage._id = "61d881ba13941c000b886c57") {
      var TArate = String(updateProfile.rates) ///Tami Allen
    } else if (localStorage._id = "61df248e2baf7a000a38a385") {
      var OArate = String(updateProfile.rates) ///Oluwatosin Akindele
    } else if (localStorage._id = "6203f25272cec0000a92b784") {
      var TNrate = String(updateProfile.rates) ///Dr. TaNika Harlis
    } else if (localStorage._id = "6206c83d0840b2000a55797c") {
      var EOrate = String(updateProfile.rates) ///Eneze Obuaya, M.D.
    } else if (localStorage._id = "620e86bf0537d0000ad7a62a") {
      var LHrate = String(updateProfile.rates) ///Laquanya Haggray
    } else if (localStorage._id = "6228d37293c12e000aa21d4d") {
      var MHrate = String(updateProfile.rates) ///Motier Haskins
    } else if (localStorage._id = "62c5a173fad547000a1639e4") {
      var ARrate = String(updateProfile.rates) ///Alison Running Coach
    } else if (localStorage._id = "62e994104176bd000a953929") {
      var Dtest1 = String(updateProfile.rates) // prof2@gmail.com
    } else if (localStorage._id = "63129234778fd3000afb25d8") {
      var Dtest2 = String(updateProfile.rates) // dprof3@gmail.com
    } else if (localStorage._id = "6312939b778fd3000afb270f") {
      var Dtest3 = String(updateProfile.rates) // deloresp@gmail.com
    } else {
      var Baserate = "TBD"
    }


    if (localStorage._id = "6168d8766c3ba4000bda4bea") {
      var profRate =  DDrate; /// DD Provider
    } else if (localStorage._id = "61cf37cfa43402000a3915dc") {
      var profRate = DHrate ///Dominique Hickman
    } else if (localStorage._id = "61cf7621db1e02000a613a75") {
      var profRate = TBrate ///Thurman Brown
    } else if (localStorage._id = "61d881ba13941c000b886c57") {
      var profRate = TArate ///Tami Allen
    } else if (localStorage._id = "61df248e2baf7a000a38a385") {
      var profRate = OArate ///Oluwatosin Akindele
    } else if (localStorage._id = "6203f25272cec0000a92b784") {
      var profRate = TNrate ///Dr. TaNika Harlis
    } else if (localStorage._id = "6206c83d0840b2000a55797c") {
      var profRate = EOrate ///Eneze Obuaya, M.D.
    } else if (localStorage._id = "620e86bf0537d0000ad7a62a") {
      var profRate = LHrate ///Laquanya Haggray
    } else if (localStorage._id = "6228d37293c12e000aa21d4d") {
      var profRate = MHrate ///Motier Haskins
    } else if (localStorage._id = "62c5a173fad547000a1639e4") {
      var profRate = ARrate ///Alison Running Coach
    } else if (localStorage._id = "62e994104176bd000a953929") {
      var profRate = Dtest1 // prof2@gmail.com
    } else if (localStorage._id = "63129234778fd3000afb25d8") {
      var profRate = Dtest2 // dprof3@gmail.com
    } else if (localStorage._id = "6312939b778fd3000afb270f") {
      var profRate = Dtest3 // deloresp@gmail.com
    } else {
      var profRate = Baserate
    }

    //const profRate = String(updateProfile.rates);
    var holdrating = String(profRate);
    Object.freeze(profRate)
    Object.freeze(holdrating)
    console.log(String(holdrating));


  //  props.setOpenPopupOtp();
  };

  useEffect(() => {
    const type: string | null = localStorage.getItem("current");
    const id: string | null = localStorage.getItem("_id");
    setUpdateProfile({ ...updateProfile, type: type!, id: id! });
    props.getPhysicianProfile();

    if (localStorage._id = "62e994104176bd000a953929") {
      localStorage.setItem("ratesDT1", updateProfile.rates)}; // prof2@gmail.com

    if (localStorage._id = "63129234778fd3000afb25d8") {
      localStorage.setItem("ratesDT2", updateProfile.rates)}; // dprof3@gmail.com

    if (localStorage._id = "6312939b778fd3000afb270f") {
      localStorage.setItem("ratesDT3", updateProfile.rates)}; // deloresp@gmail.com

    var DDrate = ""
    var DHrate = "" ///Dominique Hickman
    var TBrate = "" ///Thurman Brown
    var TArate = "" ///Tami Allen
    var OArate = "" ///Oluwatosin Akindele
    var TNrate = "" ///Dr. TaNika Harlis
    var EOrate = "" ///Eneze Obuaya, M.D.
    var LHrate = "" ///Laquanya Haggray
    var MHrate = "" // Motier Haskins
    var ARrate = "" ///Alison Running Coach
    var Dtest1 = "" // prof2@gmail.com
    var Dtest2 = "" // dprof3@gmail.com
    var Dtest3 = "" // deloresp@gmail.com
    var Baserate = ""
    var profRate = ""

    if (localStorage._id = "6168d8766c3ba4000bda4bea") {
      var DDrate = String(updateProfile.rates); /// DD Provider
    } else if (localStorage._id = "61cf37cfa43402000a3915dc") {
      var DHrate = String(updateProfile.rates) ///Dominique Hickman
    } else if (localStorage._id = "61cf7621db1e02000a613a75") {
      var TBrate = String(updateProfile.rates) ///Thurman Brown
    } else if (localStorage._id = "61d881ba13941c000b886c57") {
      var TArate = String(updateProfile.rates) ///Tami Allen
    } else if (localStorage._id = "61df248e2baf7a000a38a385") {
      var OArate = String(updateProfile.rates) ///Oluwatosin Akindele
    } else if (localStorage._id = "6203f25272cec0000a92b784") {
      var TNrate = String(updateProfile.rates) ///Dr. TaNika Harlis
    } else if (localStorage._id = "6206c83d0840b2000a55797c") {
      var EOrate = String(updateProfile.rates) ///Eneze Obuaya, M.D.
    } else if (localStorage._id = "620e86bf0537d0000ad7a62a") {
      var LHrate = String(updateProfile.rates) ///Laquanya Haggray
    } else if (localStorage._id = "6228d37293c12e000aa21d4d") {
      var MHrate = String(updateProfile.rates) ///Motier Haskins
    } else if (localStorage._id = "62c5a173fad547000a1639e4") {
      var ARrate = String(updateProfile.rates) ///Alison Running Coach
    } else if (localStorage._id = "62e994104176bd000a953929") {
      var Dtest1 = String(updateProfile.rates) // prof2@gmail.com
    } else if (localStorage._id = "63129234778fd3000afb25d8") {
      var Dtest2 = String(updateProfile.rates) // dprof3@gmail.com
    } else if (localStorage._id = "6312939b778fd3000afb270f") {
      var Dtest3 = String(updateProfile.rates) // deloresp@gmail.com
    } else {
      var Baserate = "TBD"
    }

    if (localStorage._id = "6168d8766c3ba4000bda4bea") {
      var profRate =  DDrate; /// DD Provider
    } else if (localStorage._id = "61cf37cfa43402000a3915dc") {
      var profRate = DHrate ///Dominique Hickman
    } else if (localStorage._id = "61cf7621db1e02000a613a75") {
      var profRate = TBrate ///Thurman Brown
    } else if (localStorage._id = "61d881ba13941c000b886c57") {
      var profRate = TArate ///Tami Allen
    } else if (localStorage._id = "61df248e2baf7a000a38a385") {
      var profRate = OArate ///Oluwatosin Akindele
    } else if (localStorage._id = "6203f25272cec0000a92b784") {
      var profRate = TNrate ///Dr. TaNika Harlis
    } else if (localStorage._id = "6206c83d0840b2000a55797c") {
      var profRate = EOrate ///Eneze Obuaya, M.D.
    } else if (localStorage._id = "620e86bf0537d0000ad7a62a") {
      var profRate = LHrate ///Laquanya Haggray
    } else if (localStorage._id = "6228d37293c12e000aa21d4d") {
      var profRate = MHrate ///Motier Haskins
    } else if (localStorage._id = "62c5a173fad547000a1639e4") {
      var profRate = ARrate ///Alison Running Coach
    } else if (localStorage._id = "62e994104176bd000a953929") {
      var profRate = Dtest1 // prof2@gmail.com
    } else if (localStorage._id = "63129234778fd3000afb25d8") {
      var profRate = Dtest2 // dprof3@gmail.com
    } else if (localStorage._id = "6312939b778fd3000afb270f") {
      var profRate = Dtest3 // deloresp@gmail.com
    } else {
      var profRate = Baserate
    }

    //const profRate = String(updateProfile.rates);
    var holdrating = String(profRate);
    const bground = String(props.physicianProfile?.firstName)

  }, []);


  return (
    <Dialog open={props.openPopupRate} classes={{ paper: classes.dailog }}>
      <DialogTitle>
        <div style={{ display: "flex" }}>
          <Typography
            variant="h6"
            component="div"
            gutterBottom
            style={{
              flexGrow: 1,
            }}
          >
            Update Your Rate
          </Typography>
          <Button onClick={() => props.setOpenPopupRate(false)}>X</Button>
        </div>
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.root}>
          <TextField
            id="margin-normal"
            margin="normal"
            fullWidth
            error={numberError ? true : false}
            helperText={numberError ? numberError : false}
            type="text"
            style={{ margin: 8 }}
            size="small"
            label="Your Rate"
            onChange={(e) => {
              setUpdateProfile({ ...updateProfile, rates: e.target.value });
              setRateError("");
            }}
          />

          <PrimaryButton onClick={handleSubmit} title="Update Rate" />

        </div>
      </DialogContent>
    </Dialog>
  );
};

export var holdrating = String(localStorage.rates);
export var trate1 = String(localStorage.ratesDT1);
export var trate2 = String(localStorage.ratesDT2);
export var trate3 = String(localStorage.ratesDT3);

// export const profRate = String(localStorage.rates)
