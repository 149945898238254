import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import React from "react";
import { connect } from "react-redux";
import { basicSelectors, basicCalls } from "../../../store/supers/basicCalls";
import { IAdmin, USER_TYPE } from "../../../store/types";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

interface OwnProps {
  userType: USER_TYPE.ADMIN | USER_TYPE.CENTER;
}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  approveAdminRequest: (id: string) => Promise<void>;
  denyAdminRequest: (id: string) => Promise<void>;
  // updatingAdminRequest: boolean;
  // updatingAdminError: string;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  // gettingCenters: boolean;
  // fetchCenters: ICenter[];
  // getCentesrError: string;
}

type Props = OwnProps & StateProps & DispatchProps;
function Request(props: Props) {
  React.useEffect(() => {
    props.getAdmins();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const admins = props.fetchAdmins.filter((adm) => {
    return !adm?.approved;
  });

  const handleAcceptAdmin = async (adminID: string) => {
    await props.approveAdminRequest(adminID);
    await props.getAdmins();
  };
  const handleRejectAdmin = async (adminID: string) => {
    await props.denyAdminRequest(adminID);
    await props.getAdmins();
  };

  const classes = useStyles();
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {admins.map((value: IAdmin, i: number) => {
          return (
            <Grid style={{ padding: "10px", width: "20%" }} key={i} item xs={12} sm={6} md={3}>
              <Card className={classes.root}>
                <CardContent>
                  <Typography
                    className={classes.title}
                    style={{ color: "black", fontSize: "2em" }}
                    gutterBottom
                  >
                    {`${value.name}`}
                  </Typography>
                  <Typography
                    className={classes.title}
                    style={{ color: "black", fontSize: "1em" }}
                    gutterBottom
                  >
                    {`Email :${value.email}`}
                  </Typography>
                  <CustomButtonGroup
                    buttonType="horizontal"
                    values={["Accept", "Remove"]}
                    handleClicks={[
                      () => handleAcceptAdmin(value?._id),
                      () => handleRejectAdmin(value?._id),
                    ]}
                  />
                </CardContent>
              </Card>
            </Grid>
          );
        })}
      </div>
      {!Boolean(admins.length) && (
        <Alert severity="info">
          <AlertTitle>Provider List</AlertTitle>
          You don't have any <strong>Admins</strong> to Accept or Remove
          <strong> at the movement!</strong>
        </Alert>
      )}
    </>
  );
}

const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  approveAdminRequest: (adminID: string) => dispatch(basicCalls.approveAdminRequest(adminID)),
  denyAdminRequest: (adminID: string) => dispatch(basicCalls.denyAdminRequest(adminID)),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(Request);
