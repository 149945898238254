import React, { Component } from "react";
import { Switch, BrowserRouter, Redirect, Route } from "react-router-dom";
// import { deloresTheme } from "./theme/ColorPalette";
import "./App.css";
import { Main as AuthMain } from "./components/authComponents/Main";
import ProtectedPhysician from "./components/Routes/ProtectedPhysician";
import ProtectedCenter from "./components/Routes/ProtectedCenter";
import ProtectedPatient from "./components/Routes/ProtectedPatient";
import AuthRedirectRoute from "./components/Routes/AuthRedirectRoute";
import { Main as PatientMain } from "./components/Routes/PatientRoutes/Main";
import { Main2 as DemoMain } from "./components/Routes/PatientRoutes/Main2";
// import { Main as AdminMain } from "./components/Routes/AdminRoutes/Main";
import { Main as SuperMain } from "./components/Routes/SuperRoutes/Main";
import {Main3 as RealMain } from "./components/authComponents/Main3";
import RestrictUnApprovedPhysician from "./components/Routes/PhysicianRoutes/RestrictUnApprovedPhysician";
import ProtectedAdmin from "./components/Routes/ProtectedAdmin";
import RestrictUnApprovedCenter from "./components/Routes/CenterRoutes/RestrictUnApprovedCenter";
import RestrictUnApprovedAdmin from "./components/Routes/AdminRoutes/RestrictUnApprovedAdmin";
import { ProtectedSuper } from "./components/Routes/ProtectedSuper";
import Auth from "./views/auth/Auth";
import AutoLogout from "./components/AutoLogout";
class App extends Component {
  render() {
    return (
      <>
        <BrowserRouter>
          <Switch>
            <AuthRedirectRoute path="/auth" exact component={AuthMain} />
            <Route path="/auth/super" component={Auth} />
            <Route path="/authenticate" component={RealMain} />
            <Route path="/patient/specialties2" component={DemoMain} />
            <ProtectedAdmin path={"/admin"}>
              <AutoLogout ComposedClass={RestrictUnApprovedAdmin} />
            </ProtectedAdmin>
            <ProtectedSuper path="/super" component={SuperMain} />
            <ProtectedCenter path={"/center"}>
              <AutoLogout ComposedClass={RestrictUnApprovedCenter} />
            </ProtectedCenter>
            <ProtectedPatient path={"/patient"}>
              <AutoLogout ComposedClass={PatientMain} />
            </ProtectedPatient>
            <ProtectedPhysician path={"/physician"}>
              <AutoLogout ComposedClass={RestrictUnApprovedPhysician} />
            </ProtectedPhysician>
            <Redirect to="/auth" />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}
export default App;
