import * as types from "./types";

const initialState = {
  // myPingRequests: [],
  creatingPingRequest: false,
  // updatingPingRequest: false,
  // gettingMyPingRequests: false,
  // getMyPingRequestsFailure: "",
  createPingRequestFailure: "",
  createPingRequestSuccess: false,
  // updatePingRequestFailure: "",
};
export const PingRequestsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.creatingPingRequest:
      return {
        ...state,
        creatingPingRequest: true,
        createPingRequestFailure: "",
        createPingRequestSuccess: false,
      };
    case types.createPingRequestsuccess:
      return {
        ...state,
        creatingPingRequest: false,
        createPingRequestSuccess: true,
      };
    case types.createPingRequestFailure:
      return {
        ...state,
        creatingPingRequest: false,
        createPingRequestFailure: action.payload,
      };
    case types.acceptingPingRequest:
      return {
        ...state,
        acceptingPingRequest: true,
        acceptPingRequestFailure: "",
        acceptPingRequestSuccess: false,
      };
    case types.acceptPingRequestsuccess:
      return {
        ...state,
        acceptingPingRequest: false,
        acceptPingRequestSuccess: true,
      };
    case types.acceptPingRequestFailure:
      return {
        ...state,
        acceptingPingRequest: false,
        acceptPingRequestFailure: action.payload,
      };

    // case types.updatingPingRequest:
    //   return {
    //     ...state,
    //     updatingPingRequest: true,
    //     updatePingRequestFailure: "",
    //   };
    // case types.updatePingRequestsuccess:
    //   return {
    //     ...state,
    //     updatingPingRequest: false,
    //     myPingRequests: action.payload,
    //   };
    // case types.updatePingRequestFailure:
    //   return {
    //     ...state,
    //     updatingPingRequest: false,
    //     updatePingRequestFailure: action.payload,
    //   };

    // case types.gettingMyPingRequests:
    //   return {
    //     ...state,
    //     gettingMyPingRequests: true,
    //     myPingRequests: [],
    //     getMyPingRequestsFailure: "",
    //   };
    // case types.getMyPingRequestsSuccess:
    //   return {
    //     ...state,
    //     gettingMyPingRequests: false,
    //     myPingRequests: action.payload,
    //   };
    // case types.getMyPingRequestsFailure:
    //   return {
    //     ...state,
    //     gettingMyPingRequests: false,
    //     getMyPingRequestsFailure: action.payload,
    //   };
    default: {
      return state;
    }
  }
};
