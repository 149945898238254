/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Avatar,
  Box,
  Button,
  Badge,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  Grid,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import React from "react";
import { connect } from "react-redux";
import { patientProfileCalls, patientProfileSelectors } from "../../../store/patients/profile";
import { IChat, IPatient, IPhysician, ICenter } from "../../../store/types";
import { NotificationDTO, SearchPhyDTO, SearchPhyDTO2  } from "../../../store/types/type";
import { USER_TYPE } from "../../../store/types";
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import PatientLoginCard from "../../../components/authComponents/PatientLoginCard";
import { useState, useRef, useEffect } from "react";
import { IAdmin } from "../../../store/types";
import { IRegister } from "../../../store/types/jwt";






import {
  AdminLoginDTO,
  AdminSignUpDTO,
  CenterLoginDTO,
  CenterSignUpDTO,
  PatientSignUpDTO,
  PatientSignUpInfo,
  PhysicianSignUpDTO,
  SuperLoginDTO,
  SuperSignUpDTO,
  UserLoginDTO,
} from "../../../store/types/type";
import {
  authCalls as superAuthCalls,
  authSelectors as superAuthSelectors,
} from "../../../store/supers/auth";
import {
  authCalls as adminAuthCalls,
  authSelectors as adminAuthSelectors,
} from "../../../store/admins/auth";
import {
  authCalls as centerAuthCalls,
  authSelectors as centerAuthSelectors,
} from "../../../store/centers/auth";
import {
  authCalls as patientAuthCalls,
  authSelectors as patientAuthSelectors,
} from "../../../store/patients/auth";
import {
  authCalls as physicianAuthCalls,
  authSelectors as physicianAuthSelectors,
} from "../../../store/physician/auth";
import { IOtp } from "../../../store/types";

import { authCalls, authSelectors } from "../../../store/centers/auth";
import {
  basicCalls as centerBasicCalls,
  basicSelectors as centerBasicSelectors,
} from "../../../store/centers/basicCalls";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  basicCalls as adminBasicCalls,
  basicSelectors as adminBasicSelectors,
} from "../../../store/admins/basicCalls";
import PatientSignUpCard from "../../../components/authComponents/PatientSignUpCard";
import AdminSignupCard from "../../../components/authComponents/AdminSignupCard";
import AdminLoginCard from "../../../components/authComponents/AdminLoginCard";
import CenterLoginCard from "../../../components/authComponents/CenterLoginCard";
import PhysicainLoginCard from "../../../components/authComponents/PhysicianLoginCard";
import { CenterSignupCard } from "../../../components/authComponents/CenterSignupCard";
import PhysicianSignUpCard from "../../../components/authComponents/PhysicianSignupCard";
import IconButton from "@material-ui/core/IconButton";
import { useLocation } from "react-router";
import { makeStyles } from "@material-ui/styles";

import { parse } from "query-string";
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { PayPalButtonsComponentOptions } from "@paypal/paypal-js/types/components/buttons";
import axios from "axios";
import { IPatientPayment } from "../../../store/types/patient";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, { AlertProps } from "@material-ui/lab/Alert";



import ChatIcon from "@material-ui/icons/Chat";
import { searchPhysician } from "../../../store/patients/profile/apiCalls2";
import { useStyles } from "./muiStyling";
import Logo from "../../../staticResources/logos/logo.png";
import Anxiety from "../../../staticResources/UserDashboard/Anxiety_Stress.jpg";
import Depression from "../../../staticResources/UserDashboard/Depression.jpg";
import Gender from "../../../staticResources/UserDashboard/Gender_Sexuality.jpg";
import Professional from "../../../staticResources/UserDashboard/ReligiousCounseling.jpg";
import Sexual from "../../../staticResources/UserDashboard/SexualHealth.jpg";
import Disease from "../../../staticResources/UserDashboard/Disease_Illness.jpg";
import Diabetes from "../../../staticResources/UserDashboard/Diabetes.jpg";
import Chronic from "../../../staticResources/UserDashboard/ChronicDisease.jpg";
import Relationships from "../../../staticResources/UserDashboard/Relationships.jpg";
import Family from "../../../staticResources/UserDashboard/FamilyMatters.jpg";
import PTSD from "../../../staticResources/UserDashboard/PTSD_Tramus.jpg";
import Health from "../../../staticResources/UserDashboard/Health&Fitness.jpg";
import Substance from "../../../staticResources/UserDashboard/SubstanceAbuse.jpg";
import Nutrition from "../../../staticResources/UserDashboard/Nutrition_Diet.jpg";
import Holistic from "../../../staticResources/UserDashboard/HolisticMedicine.jpg";
import Venting from "../../../staticResources/UserDashboard/JustVenting.jpg";
import GeneralClinical from "../../../staticResources/UserDashboard/GeneralClinicalAdvice.jpg";
import GeneralMental from "../../../staticResources/UserDashboard/GeneralMentalAdvice.jpg";
import Career from "../../../staticResources/UserDashboard/Professional.jpg";
import Coaching from "../../../staticResources/UserDashboard/Holistic_Coaching.jpg";

import { IMessage } from "../../../store/types/message";
// import { NewMessageDTO } from "../../../store/types/type";
import { chatSelectors as patientChatSelector } from "../../../store/patients/chat";
import { chatCalls as physicianChatCalls } from "../../../store/physician/chat";
import { chatSelectors as physicianChatSelector } from "../../../store/physician/chat";
import { chatCalls as patientChatCalls } from "../../../store/patients/chat";

import CrisisPopup from "../../popup/CrisisPopup";
import { usRedListStates, greenList } from "../../../store/types/states";

import "react-calendar/dist/Calendar.css";
import PatientAppointment from "../patient/Appointment/PatientAppointment";
import {
  basicCalls as superBasicCalls,
  basicSelectors as superBasicSelectors,
} from "../../../store/supers/basicCalls/index";
import { notificationSMSCalls } from "../../../store/notification/sms";
import io from "socket.io-client";
// import DynamicMessage from "../../chat/DynamicMessage";

const specialties = [
  { title: "General Clinical Advice", url: GeneralClinical },
  { title: "Anxiety / Stress", url: Anxiety },
  { title: "Depression", url: Depression },
  { title: "General Mental Advice", url: GeneralMental },
  { title: "Venting", url: Venting },
  { title: "Sexual Health", url: Sexual },
  { title: "Disease / Illness", url: Disease },
  { title: "Diabetes", url: Diabetes },
  { title: "Chronic Disease", url: Chronic },
  { title: "Relationships", url: Relationships },
  { title: "Family Matters", url: Family },
  { title: "PTSD/Trauma", url: PTSD },
  { title: "Health & Fitness", url: Health },
  { title: "Substance Abuse", url: Substance },
  { title: "Nutrition / Diet", url: Nutrition },
  { title: "Holistic Medicine", url: Holistic },
  { title: "Spiritual Guidance", url: Professional },
  { title: "Gender / Sexuality", url: Gender },
  { title: "Professional / Career Support", url: Career },
  { title: "Holistic Coaching", url: Coaching },
] as any;

const StyledOnlineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "$ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
    },
  },
}))(Badge);
const StyledOffLineBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#ffcc00",
    color: "#ffcc00",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    overlap: "circular",
  },
}))(Badge);
interface OwnProps {
  group?: boolean;
}
interface DispatchProps {
  searchPhysician: (search: SearchPhyDTO) => Promise<void>;
  initiateRequest: (selectedPhyscian: string, initText: string) => Promise<void>;
  getPhysicians: () => Promise<void>;
  getPatient: () => Promise<void>;
  getAllCenters: any;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
  handlePhysicianSelectPing: (pingDto: any) => Promise<void>;
  getOnlineUsers: () => Promise<void>;
  patientLogin: (dto: UserLoginDTO, type: string) => Promise<void>;
  verifyPatientOTP: (userOtp: IOtp) => Promise<void>;
  successResponse: string;
  adminLogin: (adminLogin: AdminLoginDTO) => Promise<void>;
  verifyAdminOTP: (userOtp: IOtp) => Promise<void>;
  centerLogin: (dto: CenterLoginDTO) => Promise<void>;
  verifyCenterOTP: (userOtp: IOtp) => Promise<void>;
  physicainLogin: (dto: UserLoginDTO) => Promise<void>;
  verifyPhysicianOTP: (userOtp: IOtp) => Promise<void>;
  patientSignUp: (patientData: PatientSignUpDTO, type: string) => Promise<IPatient>;
  updatePatientRegister: (patientData: PatientSignUpInfo) => Promise<IPatient>;
  queryParams?: any;
  setPatientDetails: (type: string) => void;
  adminSignUp: (adminLogin: AdminSignUpDTO) => Promise<IAdmin>;
  changeType: (type: string) => void;
  physicainSignUp: (physicianData: PhysicianSignUpDTO) => Promise<IPhysician>;

}

interface StateProps {
  patientChats: IChat[];
  physicianChats: IChat[];
  searchPhysicianSuccess: IPhysician[] | null;
  searchPhysicianFailure: string;
  profile?: IPatient;
  ppprofile: IPhysician | ICenter;
  isOnlineSuccess: string[];
  messages: IMessage[];
  physicianProfile: IPhysician;
  pinging: boolean;
  handlePhysicianSelectPing: (pingDto: any) => Promise<void>;
  type: string;
  pingError: string;
  history?: any;
  physicians: IPhysician[];
  loggingInPatient: boolean;
  patientLoggedIn: boolean;
  errorWhilePatientLogin: string;
  loggingInAdmin: boolean;
  adminLoggedIn: boolean;
  errorWhileAdminLogin: string;
  loggingInCenter: boolean;
  centerLoggedIn: boolean;
  errorWhileCenterLogin: string;
  loggingInPhysician: boolean;
  physicianLoggedIn: boolean;
  errorWhilePhysicianLogin: string;
  registeringPatient: boolean;
  patientRegistered: boolean;
  errorWhilePatientRegister: string;
  whenRegisterSuccessPatient: IRegister;
  updateRegisterSuccess: string;
  updateRegisterFailure: string;
  registeringAdmin: boolean;
  adminRegistered: boolean;
  errorWhileAdminRegister: string;
  whenRegisterSuccessAdmin: string;
  admins: IAdmin[];
  gettingAdmins: boolean;
  errorWhileGetAdmins: string;
  registeringCenter: boolean;
  centerRegistered: boolean;
  errorWhileCenterRegister: string;
  whenRegisterSuccessCenter: string;
  centerSignUp: (centerLogin: CenterSignUpDTO) => Promise<ICenter>;
  changeType: (type: string) => void;
  centers: ICenter[];
  gettingCenters: boolean;
  errorWhileGetCenters: string;
  registeringPhysician: boolean;
  physicianRegistered: boolean;
  errorWhilePhysicianRegister: string;
  whenRegisterSuccessPhysician: string;
}

type Props = OwnProps & DispatchProps & StateProps;


export function SelectAPhysician(props: Props) {
  let [selectedPhyscian, setselectedPhyscian] = React.useState(
    props.physicians.length ? props.physicians[0]._id : ""
  );
}

export const _Specialties2 = (props: Props) => {
  const [specialtiesDate, setSpecialtiesDate] = React.useState<any>({
    specialties: "",
  });
  const [search, setSearch] = React.useState<any>([]);
  const [open, setOpen] = React.useState(false);
  const [openScheduleDiag, setOpenScheduleDiag] = React.useState(false);
  const [openCrisisPopup, setOpenCrisisPopup] = React.useState(false);
  const [isNonRedList, setIsNonRedList] = React.useState(false);
  const [commentVal, setCommentVal] = React.useState("");
  const [selectedCenter, setSelectedCenter] = React.useState<string>("");
  const [authType, setAuthType] = useState("user");
  const [type, setType] = useState("main2");
  const [titleText, setTitleText] = useState(" ");
  const location = useLocation();
  const queryParams = parse(location.search);
  const [patientEmail, setPatientEmail] = useState("");
  const [errorResponse, setErrorResponse] = useState("");
  const [successResponse, setSuccessResponse] = useState("");
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [openErrorPopup, setOpenErrorPopup] = useState(false);
  const [step, setStep] = useState(0);

  const changeType = (type: string) => {
    setType(type);
  };




  const [phyId, setPhyId] = React.useState<string | null>(null);
  const [phyAppointment, setPhyAppointment] = React.useState<any>([]);
  const [specialtyTitle, setSpecialtyTitle] = React.useState("");
  const [rooms, setRooms] = React.useState("");
  ///const [selectedPhyscian, setselectedPhyscian] = React.useState<string>("Any");
  let [selectedPhyscian, setselectedPhyscian] = React.useState(
    props.physicians.length ? props.physicians[0]._id : ""
  );
  // const [currentChat, setCurrentChat] = React.useState<IChat>();

  function SelectAPhysician(props: Props) {
    let [selectedPhyscian, setselectedPhyscian] = React.useState(
      props.physicians.length ? props.physicians[0]._id : ""
    );
  }

  let history = useHistory();


  const handleClickIntro = () => {
       //history.push("../../patient/MainScreen2");
       history.push("/authenticate");
   }



  const nonRedArr = [
    "Venting",
    "Holistic Medicine",
    "Nutrition / Diet",
    "Health & Fitness",
    "Professional / Career Support",
    "Sexual Health",
    "Spiritual Guidance",
    "Holistic Coaching",
  ];
  React.useMemo(() => {
    if (nonRedArr.includes(specialtyTitle)) {
      setIsNonRedList(true);
    }
  }, [specialtyTitle]);
  React.useMemo(() => {
    setSearch(props.searchPhysicianSuccess);
  }, [props.searchPhysicianSuccess, props.profile]);
  const classes = useStyles();

  React.useEffect(() => {
    const asyncAwait = async () => {
     await props.getAllCenters();
     await props.getOnlineUsers();
     await props.getPhysicians();
    };
    asyncAwait();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    props.getPhysicians();
    handleSubmit();
  }, [specialtiesDate]);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  const updateState = (title: any) => {
    setSpecialtiesDate({ specialties: title });
  };
  const handleSubmit = async () => {
    if (specialtiesDate.specialties !== "") {
      await props.searchPhysician(specialtiesDate);
    } else if (location?.state?.state) {
      await props.searchPhysician({ specialties: location?.state?.state });
    } else if (location?.state?.state === undefined)
      await props.searchPhysician({ specialties: "General Clinical Advice" });
  };


  const [initMessage, setInitText] = React.useState<string>("");
  const [requesting, setRequesting] = React.useState<boolean>(false);
  const [requestSuccessfull, setRequestSuccessfull] = React.useState<{
    success: boolean;
    errorMessage: string;
  }>({ success: false, errorMessage: "" });
  const requestPhysician = async () => {
    setRequesting(true);
    setRequestSuccessfull({ success: false, errorMessage: "" });
    if (selectedPhyscian = "6168d8766c3ba4000bda4bea") {
      var Useid = "6168d80a6c3ba4000bda4be9"; /// DD Provider
    } else if (selectedPhyscian = "61cf37cfa43402000a3915dc") {
      var Useid = "61cf329f90c17c000a8423d1" ///Dominique Hickman
    } else if (selectedPhyscian = "61cf7621db1e02000a613a75") {
      var Useid = "61cf71fdec28fe000a9a3770" ///Thurman Brown
    } else if (selectedPhyscian = "61d881ba13941c000b886c57") {
      var Useid = "61d87db1aa923a000af5dfe0" ///Tami Allen
    } else if (selectedPhyscian = "61df248e2baf7a000a38a385") {
      var Useid = "61df1fe42baf7a000a38a2f8" ///Oluwatosin Akindele
    } else if (selectedPhyscian = "6203f25272cec0000a92b784") {
      var Useid = "6203ee8e72cec0000a92b727" ///Dr. TaNika Harlis
    } else if (selectedPhyscian = "6206c83d0840b2000a55797c") {
      var Useid = "6206c5160840b2000a55791e" ///Eneze Obuaya, M.D.
    } else if (selectedPhyscian = "620e86bf0537d0000ad7a62a") {
      var Useid = "620e838a0537d0000ad7a5db" ///Laquanya Haggray
    } else if (selectedPhyscian = "6228d37293c12e000aa21d4d") {
      var Useid = "6228d0c53007c9000a88f67f" ///Motier Haskins
    } else if (selectedPhyscian = "62c5a173fad547000a1639e4") {
      var Useid = "6168d80a6c3ba4000bda4be9" ///Alison Running Coach
    } else {
      var Useid = selectedPhyscian
    }
    const response = await patientProfileCalls.initPatientRequest(Useid, initMessage);
    // send sms
    const phy = props.physicians.filter((phy) => phy._id === selectedPhyscian);
    if (phy) {
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New ping message on Delores\n\n" +
        "You have a new ping message from user. To see the message, login to "
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: phy[0].phoneNumber });
    }
    setRequesting(false);
    setRequestSuccessfull(response);
    setOpenCrisisPopup(true);
  };


  const handleSendMessage = async (specialistProfile: any) => {
    const URL = `${process.env.REACT_APP_API_URL}/chat`;
    const socket = io(URL, { transports: ["polling"] });
    let chatId = specialistProfile._id;
    props.patientChats.filter((pc) => {
      if (props?.profile?._id === pc.patient?._id && pc.physicians.length === 1) {
        const physician = pc.physicians?.filter((phy: any) => specialistProfile?._id === phy?._id);
        if (physician.length > 0) {
          chatId = pc?._id;
          return pc;
        } //else {
        //  return handleDoneSelecting();
        //}
      //  if (physician.length = 0) {
    //      return handleDoneSelecting();
        //}
      }
      return null;
    });
    socket.on("joinedRoom", (room: string) => {
      console.log("joined room ", room);
      setRooms(room);
    });

    socket.on("connect", () => {
      console.log("connected");
      if (rooms) {
        socket.emit("leaveRoom", chatId);
        socket.emit("joinRoom", chatId);
      }
    });

    if (commentVal === "") return;
    // console.log(`Adding a new message: ${commentVal} to Chat with ID: ${specialistProfile._id}`);
    let newMessage: any = null;
    if (chatId) {
      newMessage = {
        chatID: chatId,
        text: commentVal,
      };
    }

    try {
      const res = await axios.post<IMessage>(`/messages/new-message`, newMessage, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      });
      socket.emit("send-message", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("handleMessagesNotifications", {
        chatID: newMessage.chatID,
        message: res.data,
      });
      socket.emit("activeTime", newMessage);

      // send sms
      const url = `https://app.deloresdigital.com/auth`;
      const messageSentText = `${
        "New message on Delores.\n\n" +
        `You have a new message from ${props?.profile?.firstName}. To see the message, login to `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: specialistProfile.phoneNumber });

      if (res.status === 201) {
        setOpenCrisisPopup(true);
        setCommentVal("");
      }
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      socket.disconnect();
    } catch (err) {
      socket.on("disconnect", (reason: any) => {
        console.log("disconnected", reason);
        // socket.emit("leaveRoom", rooms);
      });
      console.log("error", err);
    }
  };

  const handleClickOpen = (id: string | null) => {
    setPhyId(id);
    setOpen(true);
  };
  const handleScheduleOpen = (id: string | null) => {
    const phyDetail: any = props.physicians?.filter((phy) => {
      return phy._id === id;
    });

    setPhyAppointment(phyDetail[0]);

    setPhyId(id);
    setOpenScheduleDiag(true);
  };
  const handleClose = () => {
    setOpen(false);
    setOpenScheduleDiag(false);
    setPhyId("");
  };

  const twoCalls = (event: any) => {
    setCommentVal(event.target.value)
    setInitText(event.target.value)
  }

  const moreCalls = (event: any) => {
    handleClickOpen(event.target.value)
    setselectedPhyscian(event.target.value as string)
  }

  const handleChange = (event: any) => {
    setSpecialtyTitle(event.target.value);
  };
  const onlineNow = props.isOnlineSuccess;

  return (
    <>
      <div style={{ textAlign: "center" }}>
        <img src={Logo} alt={"delores"} style={{ width: "300px" }} />
      </div>

      <Box style={{ textAlign: "center" }}>
        <form onSubmit={handleSubmit}>
          <Grid container className={classes.specialtiesGrid}>
            {specialties?.map((specialty: any, index: number) => {
              // eslint-disable-next-line
              {
                if (index < 5)
                  return (
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1} key={index}>
                      <Chip
                        label={specialty.title}
                        onClick={() => {
                          updateState(specialty.title);
                        }}
                        className={classes.chip}
                        style={{
                          backgroundColor:
                            specialtiesDate.specialties === specialty ? "#018C1F" : "#fff",
                          color: specialtiesDate.specialties === specialty ? "#fff" : "#000",
                        }}
                      />
                    </Grid>
                  );
                else {
                  return "";
                }
              }
            })}
            <Select
              className={classes.dropdown}
              value={specialtyTitle}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
            >
              <MenuItem value="">
                <em>{specialtiesDate.specialties !== "" ? specialtiesDate.specialties : "More"}</em>
              </MenuItem>

              {specialties?.map((specialty: any, index: number) => {
                // eslint-disable-next-line
                {
                  if (index >= 5)
                    return (
                      <MenuItem
                        className={classes.dropdown}
                        value={specialty.title}
                        onClick={() => {
                          updateState(specialty.title);
                        }}
                      >
                        {specialty.title}
                      </MenuItem>
                    );
                  else {
                    return "Specialty not found";
                  }
                }
              })}
            </Select>
          </Grid>
        </form>
      </Box>
      <div className={classes.cardMainDiv}>
        <div className={classes.cardHeaderMainDiv}>
          <CardHeader
            className={classes.cardHeader}
            title={
              specialtiesDate.specialties !== ""
                ? `Experts in ${specialtiesDate.specialties}`
                : location?.state?.state === "General Clinical Advice"
                ? `${location?.state?.state}`
                : location?.state?.state === undefined
                ? "General Clinical Advice"
                : `Experts in ${location?.state?.state}`
            }
            titleTypographyProps={{ variant: "h6" }}
          ></CardHeader>
        </div>
        <CardContent className={classes.cardContent}>
          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
            {search &&
              search?.map((specialistProfile: any, i: any) => {
                return (
                  <>
                    {usRedListStates.includes(props.profile?.state) &&
                      props.profile?.state === specialistProfile?.state &&
                      !isNonRedList && (
                        <div className={classes.cardContentInnerDiv} key={i}>
                          <>
                            <Alert severity="error" style={{ marginBottom: "10px" }}>
                              {" "}
                              {
                                "Due to your state's legislation you are only able to contact professionals registered within your state "
                              }{" "}
                            </Alert>
                            <Paper className={classes.paper}>
                              <Grid container spacing={2} key={i}>
                                <Grid item sm={8} md={8} xs={8} lg={8} xl={8}>
                                  <CardHeader
                                    style={{
                                      padding: "0px",
                                      width: 130,
                                      height: 130,
                                      alignSelf: "center",
                                    }}
                                    avatar={
                                      onlineNow?.includes(specialistProfile.center) ? (
                                        <StyledOnlineBadge
                                          variant={
                                            onlineNow?.includes(specialistProfile.center)
                                              ? "dot"
                                              : "standard"
                                          }
                                        >
                                          <Avatar
                                            alt={specialistProfile?.firstName}
                                            src={
                                              specialistProfile?.files[0]?.url ||
                                              `https://via.placeholder.com/150?text=Profile`
                                            }
                                            style={{ width: 130, height: 130, alignSelf: "center" }}
                                          />
                                        </StyledOnlineBadge>
                                      ) : (
                                        <StyledOffLineBadge variant="dot">
                                          <Avatar
                                            alt={specialistProfile?.firstName}
                                            src={
                                              specialistProfile?.files[0]?.url ||
                                              `https://via.placeholder.com/150?text=Profile`
                                            }
                                            style={{ width: 130, height: 130, alignSelf: "center" }}
                                          />
                                        </StyledOffLineBadge>
                                      )
                                    }
                                  />
                                </Grid>
                                <Grid item sm={4} md={4} xs={4} lg={4} xl={4}>
                                  {!usRedListStates.includes(props.profile?.state) && (
                                    <div className={classes.messageBtns}>
                                      <Button
                                        autoFocus
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                          handleScheduleOpen(specialistProfile._id);
                                          // props.getPhysicianProfile()
                                        }}
                                        className={classes.singlebtn}
                                      >
                                        Schedule Time
                                      </Button>

                                      <Button
                                        autoFocus
                                        variant="outlined"
                                        color="primary"
                                        endIcon={<ChatIcon />}
                                        onClick={() => {handleClickOpen(specialistProfile._id); setselectedPhyscian(specialistProfile._id as string);setSelectedCenter(specialistProfile._id as string);}}
                                        className={classes.singlebtn}
                                      >
                                        message
                                      </Button>
                                    </div>
                                  )}
                                </Grid>
                                <Grid item sm={9} md={9} container>
                                  <Grid item xs container direction="column" spacing={2}>
                                    <Grid item xs={12}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Typography
                                          style={{ marginLeft: "10px" }}
                                          component="div"
                                          variant="subtitle2"
                                        >
                                          <span className={classes.title}>Name :</span>{" "}
                                          {specialistProfile?.firstName}
                                        </Typography>
                                      </Box>

                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Typography
                                          style={{ marginLeft: "10px" }}
                                          component="div"
                                          variant="subtitle2"
                                        >
                                          <span className={classes.title}>Address :</span>{" "}
                                          {specialistProfile?.practiceAddress}
                                        </Typography>
                                      </Box>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Typography
                                          style={{ marginLeft: "10px" }}
                                          component="div"
                                          variant="subtitle2"
                                        >
                                          <span className={classes.title}>
                                            My Background and Approach :{" "}
                                          </span>{" "}
                                          {specialistProfile?.bio
                                            ? specialistProfile?.bio
                                            : "no data"}
                                        </Typography>
                                      </Box>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Typography
                                          style={{ marginLeft: "10px" }}
                                          component="div"
                                          variant="subtitle2"
                                        >
                                          <span className={classes.title}>
                                            My Techniques and Specialties :
                                          </span>{" "}
                                          {specialistProfile?.specialties.map(
                                            (spec: any, ind: any) => {
                                              return <>{spec}, </>;
                                            }
                                          )}
                                        </Typography>
                                      </Box>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Typography
                                          style={{ marginLeft: "10px" }}
                                          component="div"
                                          variant="subtitle2"
                                        >
                                          <span className={classes.title}> Phone Number : </span>{" "}
                                          {specialistProfile?.publicNumber
                                            ? specialistProfile?.publicNumber
                                            : "No Public Number"}
                                        </Typography>
                                      </Box>
                                      <Box
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          marginTop: "8px",
                                        }}
                                      >
                                        <Typography
                                          style={{ marginLeft: "10px" }}
                                          component="div"
                                          variant="subtitle2"
                                        >
                                          <span className={classes.title}>State : </span>{" "}
                                          {specialistProfile?.state
                                            ? specialistProfile?.state
                                            : "No State"}
                                        </Typography>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Paper>
                          </>
                        </div>
                      )}
                    {(props.profile && props.profile.state === undefined) ||
                    !props.profile?.state ||
                    (props.profile?.state &&
                      !usRedListStates.includes(props.profile?.state) &&
                      greenList.includes(props.profile?.state) &&
                      !isNonRedList) ? (
                      <div className={classes.cardContentInnerDiv}>
                        <Paper className={classes.paper}>
                          <Grid container spacing={2} key={i}>
                            <Grid item sm={8} md={8} xs={8} lg={8} xl={8}>
                              <CardHeader
                                style={{
                                  padding: "0px",
                                  width: 130,
                                  height: 130,
                                  alignSelf: "center",
                                }}
                                avatar={
                                  onlineNow?.includes(specialistProfile.center) ? (
                                    <StyledOnlineBadge
                                      variant={
                                        onlineNow?.includes(specialistProfile.center)
                                          ? "dot"
                                          : "standard"
                                      }
                                    >
                                      <Avatar
                                        alt={specialistProfile?.firstName}
                                        src={
                                          specialistProfile?.files[0]?.url ||
                                          `https://via.placeholder.com/150?text=Profile`
                                        }
                                        style={{ width: 130, height: 130, alignSelf: "center" }}
                                      />
                                    </StyledOnlineBadge>
                                  ) : (
                                    <StyledOffLineBadge variant="dot">
                                      <Avatar
                                        alt={specialistProfile?.firstName}
                                        src={
                                          specialistProfile?.files[0]?.url ||
                                          `https://via.placeholder.com/150?text=Profile`
                                        }
                                        style={{ width: 130, height: 130, alignSelf: "center" }}
                                      />
                                    </StyledOffLineBadge>
                                  )
                                }
                              />
                            </Grid>

                            <Grid item sm={4} md={4} xs={4} lg={4} xl={4}>
                              <div className={classes.messageBtns}>
                                <Button
                                  autoFocus
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    handleScheduleOpen(specialistProfile._id);
                                    // props.getPhysicianProfile()
                                  }}
                                  className={classes.singlebtn}
                                >
                                  Schedule Time
                                </Button>
                                {/* <DynamicMessage
                                  //  physicianId={specialistProfile._id}
                                   onClick={handleClickOpen(specialistProfile._id)}
                                   btnStyle={classes.singlebtn}
                                /> */}
                                <Button
                                  autoFocus
                                  variant="outlined"
                                  color="primary"
                                  endIcon={<ChatIcon />}
                                  onClick={() => handleClickOpen(specialistProfile._id)}
                                  className={classes.singlebtn}
                                >
                                  message
                                </Button>
                              </div>
                            </Grid>
                            <Grid item sm={9} md={9} container>
                              <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>Name :</span>{" "}
                                      {specialistProfile?.firstName}
                                    </Typography>
                                  </Box>

                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>Address :</span>{" "}
                                      {specialistProfile?.practiceAddress}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>
                                        My Background and Approach :{" "}
                                      </span>{" "}
                                      {specialistProfile?.bio ? specialistProfile?.bio : "no data"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>
                                        My Techniques and Specialties :
                                      </span>{" "}
                                      {specialistProfile?.specialties.map((spec: any, ind: any) => {
                                        return <>{spec}, </>;
                                      })}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}> Phone Number : </span>{" "}
                                      {specialistProfile?.publicNumber
                                        ? specialistProfile?.publicNumber
                                        : "No Public Number"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>State : </span>{" "}
                                      {specialistProfile?.state
                                        ? specialistProfile?.state
                                        : "No State"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    ) : null}
                    {/* non red list */}
                    {isNonRedList ? (
                      <div className={classes.cardContentInnerDiv}>
                        <Paper className={classes.paper}>
                          <Grid container spacing={2} key={i}>
                            <Grid item sm={8} md={8} xs={8} lg={8} xl={8}>
                              <CardHeader
                                style={{
                                  padding: "0px",
                                  width: 130,
                                  height: 130,
                                  alignSelf: "center",
                                }}
                                avatar={
                                  onlineNow?.includes(specialistProfile.center) ? (
                                    <StyledOnlineBadge
                                      variant={
                                        onlineNow?.includes(specialistProfile.center)
                                          ? "dot"
                                          : "standard"
                                      }
                                    >
                                      <Avatar
                                        alt={specialistProfile?.firstName}
                                        src={
                                          specialistProfile?.files[0]?.url ||
                                          `https://via.placeholder.com/150?text=Profile`
                                        }
                                        style={{ width: 130, height: 130, alignSelf: "center" }}
                                      />
                                    </StyledOnlineBadge>
                                  ) : (
                                    <StyledOffLineBadge variant="dot">
                                      <Avatar
                                        alt={specialistProfile?.firstName}
                                        src={
                                          specialistProfile?.files[0]?.url ||
                                          `https://via.placeholder.com/150?text=Profile`
                                        }
                                        style={{ width: 130, height: 130, alignSelf: "center" }}
                                      />
                                    </StyledOffLineBadge>
                                  )
                                }
                              />
                            </Grid>

                            <Grid item sm={4} md={4} xs={4} lg={4} xl={4}>
                              <div className={classes.messageBtns}>
                                <Button
                                  autoFocus
                                  variant="outlined"
                                  color="primary"
                                  onClick={() => {
                                    handleScheduleOpen(specialistProfile._id);
                                    // props.getPhysicianProfile()
                                  }}
                                  className={classes.singlebtn}
                                >
                                  Schedule Time
                                </Button>
                                {/* <DynamicMessage
                                  //  physicianId={specialistProfile._id}
                                   onClick={handleClickOpen(specialistProfile._id)}
                                   btnStyle={classes.singlebtn}
                                /> */}
                                <Button
                                  autoFocus
                                  variant="outlined"
                                  color="primary"
                                  endIcon={<ChatIcon />}
                                  onClick={() => handleClickOpen(specialistProfile._id)}
                                  className={classes.singlebtn}
                                >
                                  message
                                </Button>
                              </div>
                            </Grid>
                            <Grid item sm={9} md={9} container>
                              <Grid item xs container direction="column" spacing={2}>
                                <Grid item xs={12}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>Name :</span>{" "}
                                      {specialistProfile?.firstName}
                                    </Typography>
                                  </Box>

                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>Address :</span>{" "}
                                      {specialistProfile?.practiceAddress}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>
                                        My Background and Approach :{" "}
                                      </span>{" "}
                                      {specialistProfile?.bio ? specialistProfile?.bio : "no data"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>
                                        My Techniques and Specialties :
                                      </span>{" "}
                                      {specialistProfile?.specialties.map((spec: any, ind: any) => {
                                        return <>{spec}, </>;
                                      })}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}> Phone Number : </span>{" "}
                                      {specialistProfile?.publicNumber
                                        ? specialistProfile?.publicNumber
                                        : "No Public Number"}
                                    </Typography>
                                  </Box>
                                  <Box
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginTop: "8px",
                                    }}
                                  >
                                    <Typography
                                      style={{ marginLeft: "10px" }}
                                      component="div"
                                      variant="subtitle2"
                                    >
                                      <span className={classes.title}>State : </span>{" "}
                                      {specialistProfile?.state
                                        ? specialistProfile?.state
                                        : "No State"}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Paper>
                      </div>
                    ) : null}
                    <Dialog
                      open={openScheduleDiag}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                      fullWidth
                    >
                    <DialogTitle id="responsive-dialog-title" >

                    <Typography align="center">Your Expert Needs To Know Who You Are!</Typography>
                      {`Sign-In:`}
                    </DialogTitle>
                    <Button autoFocus variant="outlined" color="primary" onClick={handleClickIntro}>
                      Sign-In
                    </Button>
                    <span> &nbsp; </span>
                    <Typography align="center">New to Delores? </Typography>
                    <Typography align="center">Create an account and a consult with experts for as little as $7</Typography>
                    <span> &nbsp; </span>
                    <Button autoFocus variant="outlined" color="primary" onClick={handleClickIntro}>
                      Create an Account
                    </Button>
                    <DialogActions style={{ padding: "10px 30px" }}>
                        <Button autoFocus variant="outlined" color="primary" onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <Dialog
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="responsive-dialog-title"
                      fullWidth
                    >
                      <DialogTitle id="responsive-dialog-title" >

                      <Typography align="center">Your Expert Needs To Know Who You Are!</Typography>
                        {`Sign-In:`}
                      </DialogTitle>
                      <Button autoFocus variant="outlined" color="primary" onClick={handleClickIntro}>
                        Sign-In
                      </Button>
                      <span> &nbsp; </span>
                      <Typography align="center">New to Delores? </Typography>
                      <Typography align="center">Create an account and a consult with experts for as little as $7</Typography>
                      <span> &nbsp; </span>
                      <Button autoFocus variant="outlined" color="primary" onClick={handleClickIntro}>
                        Create an Account
                      </Button>


                      <DialogActions style={{ padding: "10px 30px" }}>

                        <Button autoFocus variant="outlined" color="primary" onClick={handleClose}>
                          Close
                        </Button>
                      </DialogActions>
                      <CrisisPopup
                        openCrisisPopup={openCrisisPopup}
                        setOpenCrisisPopup={setOpenCrisisPopup}
                      />
                    </Dialog>
                  </>
                );
              })}

            {props.searchPhysicianFailure && (
              <Alert severity="info">
                {" "}
                {
                  "We haven't found the perfect professionals for this just yet. We promise they're coming soon"
                }{" "}
              </Alert>
            )}
          </div>
        </CardContent>
        {requestSuccessfull.errorMessage && (
               <Alert icon={false} severity="info">
                 {requestSuccessfull.errorMessage}
               </Alert>
             )}
             {requestSuccessfull.success && requestSuccessfull && selectedPhyscian&& (
               <Alert icon={false} severity="success">
                 Request for a professional from provider done
               </Alert>
             )}
      </div>
      <div className="flex">
        {authType === "userAuth" ? (
          <PatientLoginCard
            changeType={setType}
            successResponse={successResponse}
            patientLogin={props.patientLogin}
            loggingInPatient={props.loggingInPatient}
            patientLoggedIn={props.patientLoggedIn}
            errorWhilePatientLogin={props.errorWhilePatientLogin}
            verifyPatientOTP={props.verifyPatientOTP}
          />
        ) : authType === "adminAuth" ? (
          <AdminLoginCard
            adminLogin={props.adminLogin}
            loggingInAdmin={props.loggingInAdmin}
            adminLoggedIn={props.adminLoggedIn}
            errorWhileAdminLogin={props.errorWhileAdminLogin}
            verifyAdminOTP={props.verifyAdminOTP}
          />
        ) : authType === "providerAuth" ? (
          <CenterLoginCard
            centerLogin={props.centerLogin}
            loggingInCenter={props.loggingInCenter}
            centerLoggedIn={props.centerLoggedIn}
            errorWhileCenterLogin={props.errorWhileCenterLogin}
            verifyCenterOTP={props.verifyCenterOTP}
          />
        ) : authType === "professionalAuth" ? (
          <PhysicainLoginCard
            physicainLogin={props.physicainLogin}
            loggingInPhysician={props.loggingInPhysician}
            physicianLoggedIn={props.physicianLoggedIn}
            errorWhilePhysicianLogin={props.errorWhilePhysicianLogin}
            verifyPhysicianOTP={props.verifyPhysicianOTP}
          />
        ) : null}
      </div>
      <div className="flex flex-column">
        {authType === "userAuth" ? (
          <PatientSignUpCard
            queryParams={queryParams}
            changeType={setType}
            patientSignUp={props.patientSignUp}
            registeringPatient={props.registeringPatient}
            patientRegistered={props.patientRegistered}
            errorWhilePatientRegister={props.errorWhilePatientRegister}
            whenRegisterSuccessPatient={props.whenRegisterSuccessPatient}
            updatePatientRegister={props.updatePatientRegister}
            updateRegisterSuccess={props.updateRegisterSuccess}
            updateRegisterFailure={props.updateRegisterFailure}
            setPatientDetails={setPatientEmail}
            step={step}
            setStep={setStep}
          />
        ) : authType === "adminAuth" ? (
          <AdminSignupCard
            changeType={changeType}
            adminSignUp={props.adminSignUp}
            registeringAdmin={props.registeringAdmin}
            adminRegistered={props.adminRegistered}
            errorWhileAdminRegister={props.errorWhileAdminRegister}
            whenRegisterSuccessAdmin={props.whenRegisterSuccessAdmin}
          />
        ) : authType === "providerAuth" ? (
          <>
            <CenterSignupCard
              sendSMS={props.sendSMS}
              changeType={changeType}
              admins={props.admins}
              gettingAdmins={props.gettingAdmins}
              errorWhileGetAdmins={props.errorWhileGetAdmins}
              centerSignUp={props.centerSignUp}
              registeringCenter={props.registeringCenter}
              centerRegistered={props.centerRegistered}
              errorWhileCenterRegister={props.errorWhileCenterRegister}
              whenRegisterSuccessCenter={props.whenRegisterSuccessCenter}
            />
          </>
        ) : authType === "professionalAuth" ? (
          <PhysicianSignUpCard
            sendSMS={props.sendSMS}
            changeType={changeType}
            centers={props.centers}
            gettingCenters={props.gettingCenters}
            errorWhileGetCenters={props.errorWhileGetCenters}
            physicainSignUp={props.physicainSignUp}
            registeringPhysician={props.registeringPhysician}
            physicianRegistered={props.physicianRegistered}
            errorWhilePhysicianRegister={props.errorWhilePhysicianRegister}
            whenRegisterSuccessPhysician={props.whenRegisterSuccessPhysician}
          />
        ) : null}
      </div>
    </>
  );
};

export const mapStateToProps = (state: any) => ({
  loggingInPatient: patientAuthSelectors.isLoggingIn(state.patients.auth),
  patientLoggedIn: patientAuthSelectors.hasAuthToken(state.patients.auth),
  errorWhilePatientLogin: patientAuthSelectors.hasLoginError(state.patients.auth),

  loggingInPhysician: physicianAuthSelectors.isLoggingIn(state.physicians.auth),
  physicianLoggedIn: physicianAuthSelectors.hasAuthToken(state.physicians.auth),
  errorWhilePhysicianLogin: physicianAuthSelectors.hasLoginError(state.physicians.auth),

  loggingInCenter: centerAuthSelectors.isLoggingIn(state.centers.auth),
  centerLoggedIn: centerAuthSelectors.hasAuthToken(state.centers.auth),
  errorWhileCenterLogin: centerAuthSelectors.hasLoginError(state.centers.auth),

  loggingInAdmin: adminAuthSelectors.isLoggingIn(state.admins.auth),
  adminLoggedIn: adminAuthSelectors.hasAuthToken(state.admins.auth),
  errorWhileAdminLogin: adminAuthSelectors.hasLoginError(state.admins.auth),

  loggingInSuper: superAuthSelectors.isLoggingIn(state.supers.auth),
  superLoggedIn: superAuthSelectors.hasAuthToken(state.supers.auth),
  errorWhileSuperLogin: superAuthSelectors.hasLoginError(state.supers.auth),

  //sign up
  admins: adminBasicSelectors.fetchAdmins(state.admins.basicCalls),
  errorWhileGetAdmins: adminBasicSelectors.getAdminsError(state.admins.basicCalls),
  gettingAdmins: adminBasicSelectors.gettingAdmins(state.admins.basicCalls),

  centers: centerBasicSelectors.fetchCenters(state.centers.basicCalls),
  errorWhileGetCenters: centerBasicSelectors.getCentersError(state.centers.basicCalls),
  gettingCenters: centerBasicSelectors.gettingCenters(state.centers.basicCalls),

  registeringPatient: patientAuthSelectors.isSigningUp(state.patients.auth),
  patientRegistered: patientAuthSelectors.signUpSuccess(state.patients.auth),
  errorWhilePatientRegister: patientAuthSelectors.hasSignUpError(state.patients.auth),
  whenRegisterSuccessPatient: patientAuthSelectors.successMessage(state.patients.auth),
  updateRegisterSuccess: patientAuthSelectors.updateRegisterSuccess(state.patients.auth),
  updateRegisterFailure: patientAuthSelectors.updateRegisterFailure(state.patients.auth),

  registeringPhysician: physicianAuthSelectors.isSigningUp(state.physicians.auth),
  physicianRegistered: physicianAuthSelectors.signUpSuccess(state.physicians.auth),
  errorWhilePhysicianRegister: physicianAuthSelectors.hasSignUpError(state.physicians.auth),
  whenRegisterSuccessPhysician: physicianAuthSelectors.successMessage(state.physicians.auth),

  registeringCenter: authSelectors.isSigningUp(state.centers.auth),
  centerRegistered: authSelectors.signUpSuccess(state.centers.auth),
  errorWhileCenterRegister: authSelectors.hasSignUpError(state.centers.auth),
  whenRegisterSuccessCenter: authSelectors.successMessage(state.centers.auth),

  registeringSuper: authSelectors.isSigningUp(state.supers.auth),
  superRegistered: authSelectors.signUpSuccess(state.supers.auth),
  errorWhileSuperRegister: authSelectors.hasSignUpError(state.supers.auth),
  whenRegisterSuccessSuper: authSelectors.successMessage(state.supers.auth),

  registeringAdmin: authSelectors.isSigningUp(state.admins.auth),
  adminRegistered: adminAuthSelectors.signUpSuccess(state.admins.auth),
  errorWhileAdminRegister: adminAuthSelectors.hasSignUpError(state.admins.auth),
  whenRegisterSuccessAdmin: adminAuthSelectors.successMessage(state.admins.auth),

//

  searchPhysicianSuccess: patientProfileSelectors.searchPhysicianSuccess(
    state.patients.patientProfile
  ),
  searchPhysicianFailure: patientProfileSelectors.searchPhysicianFailure(
    state.patients.patientProfile
  ),
  profile: patientProfileSelectors.isPatientProfile(state.patients.patientProfile),
  physicians: superBasicSelectors.fetchPhysicians(state.supers.basicCalls),
  patientChats: patientChatSelector.patientChats(state.patients.chat),
  physicianChats: physicianChatSelector.physicianChats(state.physicians.chat),
});

export const mapDispatchToProps = (dispatch: any) => ({
  sendSMS: (notificationDTO: NotificationDTO) =>
    dispatch(notificationSMSCalls.sendSMSNotification(notificationDTO)),

  superLogin: (superLogin: SuperLoginDTO) => dispatch(superAuthCalls.login(superLogin)),
  adminLogin: (adminLogin: AdminLoginDTO) => dispatch(adminAuthCalls.login(adminLogin)),
  centerLogin: (centerLogin: CenterLoginDTO) => dispatch(centerAuthCalls.login(centerLogin)),
  patientLogin: (userLogin: UserLoginDTO, type: string) =>
    dispatch(patientAuthCalls.login(userLogin, type)),
  physicainLogin: (userLogin: UserLoginDTO) => dispatch(physicianAuthCalls.login(userLogin)),

  verifySuperOTP: (otp: IOtp) => dispatch(superAuthCalls.verifyToken(otp)),
  verifyCenterOTP: (otp: IOtp) => dispatch(centerAuthCalls.verifyToken(otp)),
  verifyAdminOTP: (otp: IOtp) => dispatch(adminAuthCalls.verifyToken(otp)),
  verifyPatientOTP: (otp: IOtp) => dispatch(patientAuthCalls.verifyToken(otp)),
  verifyPhysicianOTP: (otp: IOtp) => dispatch(physicianAuthCalls.verifyToken(otp)),

  //register
  getAllCenters: () => dispatch(centerBasicCalls.getAllCenters()),
  getAllAdmins: () => dispatch(adminBasicCalls.getAllAdmins()),
  superSignUp: (superData: SuperSignUpDTO) => dispatch(superAuthCalls.signUp(superData)),
  adminSignUp: (adminData: AdminSignUpDTO) => dispatch(adminAuthCalls.signUp(adminData)),
  centerSignUp: (centerData: CenterSignUpDTO) => dispatch(authCalls.signUp(centerData)),
  patientSignUp: (patientData: PatientSignUpDTO, type: string) =>
    dispatch(patientAuthCalls.signUp(patientData, type)),
  physicainSignUp: (physicianData: PhysicianSignUpDTO) =>
    dispatch(physicianAuthCalls.signUp(physicianData)),
  updatePatientRegister: (data: PatientSignUpInfo) =>
    dispatch(patientAuthCalls.updatePatientRegister(data)),


//

  getPatientAllChats: () => dispatch(patientChatCalls.getAllChats()),
  getPhysicianAllChats: () => dispatch(physicianChatCalls.getAllChats()),

  searchPhysician: (search: SearchPhyDTO) => dispatch(searchPhysician(search)),
  getPhysicians: () => dispatch(superBasicCalls.getPhysicians()),

});
export const Specialties2 = connect(mapStateToProps, mapDispatchToProps)(_Specialties2);
