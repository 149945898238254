import React from "react";
import { connect } from "react-redux";
import { Badge, Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { basicCalls, basicSelectors } from "../../../store/supers/basicCalls";
import { IAdmin, ICenter, USER_TYPE } from "../../../store/types";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
    marginTop: "15px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

interface OwnProps {
  userType: USER_TYPE.ADMIN | USER_TYPE.CENTER;
}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;
}

type Props = OwnProps & StateProps & DispatchProps;
function Tracking(props: Props) {
  const centers = props.fetchCenters;
  const admins = props.fetchAdmins;

  React.useEffect(() => {
    if (props.userType === USER_TYPE.ADMIN) props.getAdmins();
    if (props.userType === USER_TYPE.CENTER) props.getCenters();
    // eslint-disable-next-line
  }, []);

  const classes = useStyles();
  return (
    <>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}
      >
        {centers &&
          props.userType === USER_TYPE.CENTER &&
          centers?.map((center, i) => {
            return (
              <Grid style={{ padding: "10px", width: "20%" }} key={i} item xs={12} sm={6} md={3}>
                <Card key={i} style={{ marginTop: "15px" }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1.5em" }}
                      gutterBottom
                    >
                      {center.name}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`NPI :${center.npi ? center.npi : "N/A"}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Email : ${center.email}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Phone Number: ${center.phoneNumber}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Doctor Name: ${center.physicianName}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Doctor NPI: ${center.physicianNpi}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      Professionals :
                      <Badge
                        color="primary"
                        max={1000}
                        badgeContent={center.physicians.length}
                      ></Badge>
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      Chats :
                      <Badge color="primary" max={1000} badgeContent={center.chats.length}></Badge>
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      Messages :
                      <Badge
                        color="primary"
                        max={1000}
                        badgeContent={center.chats?.map((msg) => {
                          return msg.messages.length;
                        })}
                      ></Badge>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        {admins &&
          props.userType === USER_TYPE.ADMIN &&
          admins?.map((adm, i) => {
            return (
              <Grid style={{ padding: "10px", width: "20%" }} key={i} item xs={12} sm={6} md={3}>
                <Card key={i} style={{ marginTop: "15px" }}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1.5em" }}
                      gutterBottom
                    >
                      {adm.name}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Email: ${adm.email}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Phone Number: ${adm.phoneNumber}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Address: ${adm.address}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      Providers
                      <Badge color="primary" max={2000} badgeContent={adm.centers.length}></Badge>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  getCenters: () => dispatch(basicCalls.getCenters()),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),

  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(Tracking);
