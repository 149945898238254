import React from "react";
import clsx from "clsx";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SendIcon from "@material-ui/icons/Send";
import TextareaAutosize from 'react-textarea-autosize';




const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      backgroundColor: "rgb(218, 226, 213)",
    },
    margin: {
      margin: theme.spacing(1),
    },
    withoutLabel: {
      marginTop: theme.spacing(3),
    },
    TextareaAutosize: {
      width: "100%",
    },
  })
);

interface State {
  message: string;
}

interface Props {
  sendMessage: (text: string) => void;
}

export function ComposeComponent(props: Props) {
  const classes = useStyles();
  const [values, setValues] = React.useState<State>({
    message: "",
  });

  const handleChange = (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const submitMessage = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && values.message) {
      props.sendMessage(values.message);
      setValues({ ...values, message: "" });
    }
  };

  return (
    <div className={classes.root}>
      <div style={{ width: "100%" }}>
        <FormControl
          className={clsx(classes.margin, classes.TextareaAutosize)}
          variant="outlined"
          //   onSubmit={handleMouseDownPassword}
        >
          <InputLabel htmlFor="outlined-adornment-message">Compose Message in The Green Box Below This One</InputLabel>
          <TextareaAutosize
           style={{boxSizing: 'border-box'}}
           value={values.message}
           minRows={3}
           maxRows={6}
           defaultValue=""
           />
          <OutlinedInput
            id="outlined-adornment-message"
            type="TextareaAutosize"
            onChange={handleChange("message")}
            onKeyPress={submitMessage}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="send message"
                  onClick={() => {
                    if (values.message) {
                      props.sendMessage(values.message);
                      setValues({ ...values, message: "" });
                    }
                  }}
                  //   onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <SendIcon />
                </IconButton>
              </InputAdornment>
            }
            labelWidth={70}
          />
        </FormControl>
      </div>
    </div>
  );
}
