import axios from "axios";
import {
  NumberUpdateDTO,
  PatientUpdateDTO,
  SearchPhyDTO,
  SearchPhyDTO2,
  VerifyUpdatedNumberDTO,
} from "../../types/type";
import * as actions from "./actions";

export const getPatientProfile = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.patientProfileCalling());
  try {
    const response = await axios.get(`/patient`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.patientProfileSuccess(response.data));
    } else throw new Error("Unable to get User Profile");
  } catch (err: any) {
    dispatch(actions.patientProfileFailure(err.message));
  }
};

export const updatePatientProfile = (patientUpdateDTO: PatientUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.patientProfileUpdating());
  try {
    const response = await axios.patch(`/patient`, patientUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.patientProfileUpdateSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.patientProfileUpdateFailure(err.message));
  }
};

export const updatePatientNumber = (numberUpdateDTO: NumberUpdateDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.patientNumberUpdating());
  try {
    const response = await axios.patch(`/auth/update-number`, numberUpdateDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.patientNumberUpdateSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.patientNumberUpdateFailure(err.message));
  }
};

export const verifyUpdatedNumber = (verifyUpdatedNumberDTO: VerifyUpdatedNumberDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.verifyNumberUpdating());
  try {
    const response = await axios.post(`/auth/verify-update-number`, verifyUpdatedNumberDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.verifyNumberUpdatingSuccess(response.data));
    } else throw new Error("Unable to update user Profile");
  } catch (err: any) {
    dispatch(actions.verifyNumberUpdatingFailure(err.message));
  }
};

export const initPatientRequest = async (centerID: string, initText: string) => {
  try {
    await axios.post(
      `/patient-request/new-request`,
      { centerID, initText },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      }
    );
    return { success: true, errorMessage: "" };
  } catch (err: any) {
    if (err.response.status === 409) {
      return {
        success: false,
        errorMessage:
          "Your requested is send to professional from this Provider, please wait for the Provider to approve or deny your request",
      };
    } else {
      return {
        success: false,
        errorMessage: "Unable to request for a professional from this Provider",
      };
    }
  }
};
//////update file privacy of Patient////
export const updatePatientFilePrivacy = (fileId: string, fileState: boolean) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.updatePatientFilePrivacyCalling());
  try {
    const response = await axios.patch(
      `/files-manager/change-privacy/${fileId}`,
      { isPublic: fileState ? 1 : 0 },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.updatePatientFilePrivacySuccess(response.data));
    } else throw new Error("Unable to update professional request status");
  } catch (err: any) {
    dispatch(actions.updateCenterFilePrivacyFailure(err.message));
  }
};

//////search physician by there spex////
export const searchPhysician = (search: SearchPhyDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.searchPhysicianCalling());
  try {
    const { specialties, state } = search;

    const response = await axios.patch(
      `/patient/search-physician`,
      {
        specialties: specialties,
        state: state,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
        },
      }
    );
    if (response.status === 200) {
      dispatch(actions.searchPhysicianSuccess(response.data));
      return response.data;
    }
    console.log("data", response);
  } catch (err: any) {
    if (err.response?.status === 404) {
      dispatch(actions.searchPhysicianFailure("No physician found"));
    }
  }
};
