import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { basicSelectors, basicCalls } from "../../../store/supers/basicCalls";
import { CustomButtonGroup } from "../../base/Components/CustomButtonGroup";
import { IAdmin, ICenter, IPatient, IPhysician } from "../../../store/types";
interface OwnProps {}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
  getPatients: () => Promise<void>;
  getPhysicians: () => Promise<void>;
}
interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;

  gettingPatients: boolean;
  fetchPatients: IPatient[];
  getPatientsError: string;

  gettingPhysicians: boolean;
  fetchPhysicians: IPhysician[];
  getPhysiciansError: string;
}
type Props = OwnProps & StateProps & DispatchProps;
function ControlAccount(props: Props) {
  const history = useHistory();
  const handleClickA = () => {
    props.getAdmins();
    history.push(`/super/control/admins`);
  };
  const handleClickB = () => {
    props.getCenters();
    history.push(`/super/control/centers`);
  };
  const handleClickC = () => {
    props.getPatients();
    history.push(`/super/control/patient`);
  };
  const handleClickD = () => {
    props.getPhysicians();
    history.push(`/super/control/physician`);
  };
  return (
    <>
      <div style={{ textAlign: "center", marginTop: "2em" }}>
        <CustomButtonGroup
          buttonType="horizontal"
          values={["Admins", "Provider", "User", "Professional"]}
          handleClicks={[handleClickA, handleClickB, handleClickC, handleClickD]}
          style={{ textAlign: "center", marginTop: "3%" }}
        />
      </div>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  getCenters: () => dispatch(basicCalls.getCenters()),
  getPatients: () => dispatch(basicCalls.getPatients()),
  getPhysicians: () => dispatch(basicCalls.getPhysicians()),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),

  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),

  fetchPatients: basicSelectors.fetchPatients(state.supers.basicCalls),
  gettingPatients: basicSelectors.gettingPatients(state.supers.basicCalls),
  getPatientsError: basicSelectors.getPatientsError(state.supers.basicCalls),

  fetchPhysicians: basicSelectors.fetchPhysicians(state.supers.basicCalls),
  gettingPhysicians: basicSelectors.gettingPhysicians(state.supers.basicCalls),
  getPhysiciansError: basicSelectors.getPhysiciansError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(ControlAccount);
