import { IFileData } from "../../types";
import * as types from "./types";

const initialState: any = {
  adminProfileCalling: false,
  adminProfile: null,
  adminProfileFailure: "",
  updateAdminFilePrivacyCalling: false,
  updateAdminFilePrivacyFailure: "",
};
export const adminProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.adminProfileCalling:
      return {
        ...state,
        adminProfileCalling: true,
        adminProfile: null,
        adminProfileFailure: "",
      };
    case types.adminProfileSuccess:
      return {
        ...state,
        adminProfileCalling: false,
        adminProfile: action.payload,
      };
    case types.adminProfileFailure:
      return {
        ...state,
        adminProfileCalling: false,
        adminProfileFailure: action.payload,
      };

    case types.adminProfileUpdating:
      return {
        ...state,
        adminProfileUpdating: true,
        adminProfileUpdateSuccess: null,
        adminProfileUpdateFailure: "",
      };
    case types.adminProfileUpdateSuccess:
      return {
        ...state,
        AdminProfileUpdating: false,
        AdminProfileUpdateSuccess: action.payload,
      };
    case types.adminProfileUpdateFailure:
      return {
        ...state,
        AdminProfileUpdating: false,
        AdminProfileUpdateFailure: action.payload,
      };
    case types.updateCenterRequest:
      return {
        ...state,
        updatingPhysicianRequst: true,
        // updatePhysicianRequestSuccess: undefined,
        updatePhysicianRequestFailure: "",
      };
    case types.updateCenterRequestSuccess:
      return {
        ...state,
        updatingPhysicianRequst: false,
        // updatePhysicianRequestSuccess: false,
        // updatePhysicianRequestFailure:false,
      };
    case types.updateCenterRequestFailure:
      return {
        ...state,
        updatingCenterRequst: false,
        // updateCenterRequestSuccess: false,
        updateCenterRequestFailure: action.payload,
      };
    case types.updateAdminFilePrivacyCalling:
      return {
        ...state,
        updateAdminFilePrivacyCalling: true,
        AdminProfileFailure: "",
      };
    case types.updateAdminFilePrivacySuccess:
      return {
        ...state,
        AdminProfile: {
          ...state.AdminProfile,
          files: state.AdminProfile.files.map((f: IFileData) => {
            if (f._id === action.payload._id) {
              return { ...f, isPublic: action.payload.isPublic };
            } else return f;
          }),
        },
        updateAdminFilePrivacyCalling: false,
      };
    case types.updateAdminFilePrivacyFailure:
      return {
        ...state,
        updateAdminFilePrivacyCalling: false,
        updateAdminFilePrivacyFailure: action.payload,
      };
    default: {
      return state;
    }
  }
};
