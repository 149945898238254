import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
// import { lightTheme } from "../../theme/ColorPalette";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    main: {
      width: "100%",
      // display: "flex",
      // height: "120vh",
      color: "primary",
    },
    header: {
      top: "0",
      left: "auto",
      position: "fixed",
      display: "flex",
      justifyContent: "center",
      width: "100%",
      zIndex: 999,
    },
    routes: {
      marginLeft: "217px",
      marginTop: "60px",
      "@media (max-width:768px)": {
        marginLeft: "0px",
      },
    },
  })
);
