import { Button, DialogContent, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import axios from "axios";
import Video from "twilio-video";
import ChatRoom from "./ChatRoom";
import { NotificationDTO } from "../../store/types/type";
import CallEndIcon from "@material-ui/icons/CallEnd";
import { ThemeProvider } from "@material-ui/core";
import SideBar from "../sidebars/Sidebar";
import { darkTheme, lightTheme } from "../../theme/ColorPalette";

interface VideoCallDto {
  patientId: string;
  physicianId: string;
}

interface Props {
  socketId?: any;
  currentChat?: any;
  sendVideoCall: () => void;
  handleCloseVideoCallPopup: () => void;
  handleDrawerOpen: any;
  drawerOpen: any;
  sendSMS: (notificationDTO: NotificationDTO) => Promise<void>;
}
function VideoCall(props: Props) {
  const { currentChat, sendVideoCall, handleCloseVideoCallPopup } = props;
  const [user, setUser] = React.useState<VideoCallDto>();
  const [roomToken, setRoomToken] = React.useState("");
  // const [connecting, setConnecting] = React.useState(false)
  const [room, setRoom] = React.useState<any>();
  const [onCallA, setOnCallA] = React.useState<Boolean>(false);
  const [onCallB, setOnCallB] = React.useState<Boolean>(false);

  React.useEffect(() => {
    const getUser = async () => {
      if (currentChat) {
        const userID = props.currentChat?.patient?._id;
        const phyID = props.currentChat?.physicians[0]._id;
        const user = {
          patientId: userID,
          physicianId: phyID,
        };
        setUser(user);
      }
    };
    getUser();
  }, [
    currentChat,
    props.currentChat?.patient?._id,
    props.currentChat?.physicians,
    roomToken,
    user?.physicianId,
  ]);

  const handleSubmit = useCallback(
    async (event) => {
      event.preventDefault();
      // setConnecting(true);
      // props.handleDrawerOpen();
      const { data } = await axios.post(
        "/video/call",
        { room: user?.physicianId },
        {
          headers: {
            Authorization: `Bearer ${
              localStorage.getItem("physicianToken") || localStorage.getItem("patientToken")
            }`,
          },
        }
      );
      setRoomToken(data.token);
      Video.connect(data.token, {
        name: user?.physicianId,
      })
        .then((room: any) => {
          // setConnecting(false);
          setRoom(room);
        })
        .catch((err: any) => {
          console.error(err);
          // setConnecting(false);
        });
    },
    [user]
  );

  const handleLogout = useCallback(async () => {
    console.log("chat", props.currentChat);

    setRoom((prevRoom: any) => {
      if (prevRoom) {
        prevRoom.localParticipant.tracks.forEach((trackPub: any) => {
          trackPub.track.stop();
        });
        prevRoom.disconnect();
      }
      return null;
    });
    handleCloseVideoCallPopup();
    // send sms
    const url = `https://app.deloresdigital.com/auth`;
    const user = isUserPatient ? props.currentChat?.physicians[0] : props.currentChat?.patient;
    if (user) {
      const messageSentText = `${
        "The other participant left the call.\n\n" +
        `Your fellow participant has left the video call. View the conversation. `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: user.phoneNumber });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleCloseVideoCallPopup]);

  useEffect(() => {
    if (room) {
      const tidyUp = (event: any) => {
        if (event.persisted) {
          return;
        }
        if (room) {
          handleLogout();
        }
      };
      window.addEventListener("pagehide", tidyUp);
      window.addEventListener("beforeunload", tidyUp);
      return () => {
        window.removeEventListener("pagehide", tidyUp);
        window.removeEventListener("beforeunload", tidyUp);
      };
    }
  }, [room, handleLogout]);

  const handleCloseVideo = async () => {
    // return
    setRoomToken("");
    handleCloseVideoCallPopup();
    // send sms
    const url = `https://app.deloresdigital.com/auth`;
    const user = isUserPatient ? props.currentChat?.physicians[0] : props?.currentChat?.patient;
    if (user && isUserPatient) {
      const messageSentText = `${
        "Video call request declined.\n\n" +
        `The user ${props.currentChat?.patient?.firstName} has declined your video call request. View the conversation. `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: user.phoneNumber });
    }
  };
  const isUserPatient = localStorage.getItem("current") === "PATIENT";

  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  // const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };

  const handleNotifyUser = async () => {
    // send sms
    const url = `https://app.deloresdigital.com/auth`;
    const user = !isUserPatient && props?.currentChat?.patient;
    if (user) {
      const messageSentText = `${
        "New Request for a video call.\n\n" +
        `Your provider would like to give you a
        call. Accept or Cancel the request in your Delores
        messages. `
      }${url}\n`;
      await props.sendSMS({ message: messageSentText, phone: user.phoneNumber });
    }
  };
  return (

    <div style={{ display: "flex", width: "100vw", height: "100vh" }}>
      {isUserPatient && (
        <>
          <div>
          <div>
            <SideBar
              type={"physician"}
              handleDrawerOpen={props.handleDrawerOpen}
            />
              </div>
            <DialogContent>
              {!room && (
                <Typography variant="h6" style={{ textAlignLast: "center" }}>
                  Your Provider is Attempting to Call You Via Video
                </Typography>
              )}
              {!onCallA && (
                <>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    {" "}
                    <Button
                      variant="contained"
                      startIcon={
                        <CallEndIcon
                          style={{
                            transform: "rotate(180deg)",
                            color: "black",
                            fontSize: "30px",
                            marginLeft: "8px",
                          }}
                        />
                      }
                      style={{
                        margin: "10px",
                        display: "block",
                        borderRadius: "20px",
                        minWidth: "90px",
                        color: "white",
                      }}
                      color="primary"
                      onClick={(e) => {
                        handleSubmit(e);
                        setOnCallA(true);
                      }}
                    >
                      Accept
                    </Button>
                    <Button
                      variant="contained"
                      color="secondary"
                      startIcon={
                        <CallEndIcon
                          style={{ color: "black", fontSize: "30px", marginLeft: "8px" }}
                        />
                      }
                      onClick={handleCloseVideo}
                      style={{
                        borderRadius: "20px",
                        margin: "10px",
                        display: "block",
                        minWidth: "90px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </>
              )}
              {room && (
                <ChatRoom handleLogout={handleLogout} room={room} roomName={user?.physicianId} />
              )}
            </DialogContent>
          </div>
        </>
      )}
      {!isUserPatient && (
        <>
          <div>
          <div>
            <SideBar
              type={"physician"}
              handleDrawerOpen={props.handleDrawerOpen}
            />
              </div>
            <DialogContent>
              {!room && (
                <Typography variant="h6" style={{ textAlignLast: "center" }}>
                  You Are About to Call This User Via Video
                </Typography>
              )}
              {!onCallB && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {" "}
                  <Button
                    variant="contained"
                    startIcon={
                      <CallEndIcon
                        style={{
                          transform: "rotate(180deg)",
                          color: "black",
                          fontSize: "30px",
                          marginLeft: "8px",
                        }}
                      />
                    }
                    style={{
                      margin: "10px",
                      display: "block",
                      borderRadius: "20px",
                      minWidth: "90px",
                      color: "white",
                    }}
                    color="primary"
                    onClick={(e) => {
                      handleNotifyUser();
                      sendVideoCall();
                      handleSubmit(e);
                      setOnCallB(true);
                    }}
                  >
                    Call
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={
                      <CallEndIcon
                        style={{ color: "black", fontSize: "30px", marginLeft: "8px" }}
                      />
                    }
                    onClick={handleCloseVideo}
                    style={{
                      borderRadius: "20px",
                      margin: "10px",
                      display: "block",
                      minWidth: "90px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
              {room && (
                <ChatRoom handleLogout={handleLogout} room={room} roomName={user?.physicianId} />
              )}
            </DialogContent>
          </div>
        </>
      )}
    </div>
  );
}

export default VideoCall;
