import { Grid } from "@material-ui/core";
import React, { Component } from "react";
import { ICenter } from "../../../../../store/types/center";
import { IPatientRequest } from "../../../../../store/types/patient-request";
import { IPhysician } from "../../../../../store/types/physician";
import { IPingRequest } from "../../../../../store/types/ping-request";
import SingleRequest from "./SingleRequest";
import SinglePingRequest from "./SinglePingRequest";

interface Props {
  type: string;
  profile: ICenter | IPhysician;
  pingrequests?: IPingRequest[];
  patientrequests: IPatientRequest[];
  physicians: IPhysician[];
  getProfile: () => Promise<void>;
}
interface State {}
export class Requests extends Component<Props, State> {
  render() {
    return (
      <div>
        <Grid container direction="row" alignItems="center" spacing={2} style={{ margin: "15px" }}>
          {this.props?.patientrequests?.map((req, i) => {
            return (
              <Grid item alignItems="center" justify="center" xs={3} key={i}>
                <SingleRequest
                  type={this.props?.type}
                  getProfile={this.props?.getProfile}
                  message={req?.initText}
                  patient={req?.patient}
                  physicians={this.props?.physicians}
                  requestID={req?._id}
                />
              </Grid>
            );
          })}
          {this.props.pingrequests?.map((pingReq: IPingRequest, i) => {
            return (
              <Grid item alignItems="center" justify="center" xs={3} key={i}>
                <SinglePingRequest
                  type={this.props.type}
                  getProfile={this.props.getProfile}
                  message={pingReq.initText}
                  sender={pingReq.fromPhysician}
                  physician={pingReq.toPhysician}
                  center={pingReq.toCenter}
                  requestID={pingReq._id}
                />
              </Grid>
            );
          })}
        </Grid>
      </div>
    );
  }
}

export default Requests;
