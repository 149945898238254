import * as types from "./types";

export const isOnline = () => ({
  type: types.isOnline,
});

export const isOnlineSuccess = (payload: any) => ({
  type: types.isOnlineSuccess,
  payload,
});

export const isOnlineFailure = (payload: any) => ({
  type: types.isOnlineFailure,
  payload,
});
