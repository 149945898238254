import { green } from "@material-ui/core/colors";

import { createMuiTheme, createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupbtnHorizontal: {
      color: "#ffffff",
      border: "1px solid rgb(255 255 255 / 50%)",
      fontSize: "1.1rem",
      backgroundColor: " #075d19",
      "@media (max-width: 475px)": {
        fontSize: "0.7rem",
      },
      "&:hover": {
        color: "#075d19",
        border: "1px solid #075d19",
      },
      "&$focused": {
        color: "blue",
        border: "1px solid rgba(0,0,0,0.54)",
        outline: "2px auto rgba(19,124,189,.6)",
      },
    },
    responsiveWidth: {
      "@media (max-width: 500px)": {
        width: "100%",
        justifyContent: "center",
      },
    },
    groupbtnVertical: {
      color: "#075d19",
      border: "1px solid #075d19",
      "&:hover": {
        color: "#ffffff",
        fontSize: "0.9rem",
        border: "1px solid rgb(255 255 255 / 50%)",
        backgroundColor: " #075d19",
      },
    },
  })
);

export const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: green,
  },
});
