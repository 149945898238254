import { Grid, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { PrimaryButton } from "../../../base/Components/PrimaryButton";
import { CenterProfile } from "../../CenterProfile";
import { centerProfileCalls } from "../../../../store/centers/profile";
import { ICenter } from "../../../../store/types/center";
import { ProfilePopup } from "../../../popup/ProfilePopup";
import {
  CenterUpdateDTO,
  NumberUpdateDTO,
  VerifyUpdatedNumberDTO,
} from "../../../../store/types/type";
import { UpdateNumberPopup } from "../../../popup/UpdateNumberPopup";
import { OtpPopup } from "../../../popup/OtpPopup";

interface StateProps {
  // centerProfile: ICenter;
}
interface DispatchProps {
  // getCenterProfile: () => Promise<any>;
  updateCenterProfile: (updateCenterData: CenterUpdateDTO) => Promise<any>;
  updateProfileNumber: (numberUpdateDTO: NumberUpdateDTO) => Promise<any>;
  verifyUpdatedNumber: (verifyUpdatedNumberDTO: VerifyUpdatedNumberDTO) => Promise<any>;
}
interface OwnProps {
  read?: boolean;
  centerProfile?: ICenter;
  getCenterProfile: () => Promise<any>;
}
type Props = OwnProps & StateProps & DispatchProps;

const _ProfileCard = (props: Props) => {
  const [openPopupProfile, setOpenPopupProfile] = useState(false);
  const [openPopupNumber, setOpenPopupNumber] = useState(false);
  const [openPopupOtp, setOpenPopupOtp] = useState(false);

  React.useEffect(() => {
    props.getCenterProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {}, [props.centerProfile]);

  return (
    <div>
      <Grid spacing={2} container>
        {/* todo :dynamic profile by using map */}
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "3em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            Name : {props.centerProfile?.name}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "3em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            NPI: {props.centerProfile?.npi}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "3em",
              fontSize: "1rem",
              lineHeight: "0.6",
            }}
          >
            Bio: {props.centerProfile?.bio}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography
            variant="h6"
            gutterBottom
            style={{
              marginTop: "3em",
              fontSize: "1rem",
              lineHeight: "1.2",
            }}
          >
            Phone number for login OTP: {props.centerProfile?.phoneNumber}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {props.read ? (
            ""
          ) : (
            <PrimaryButton
              onClick={() => setOpenPopupProfile(!openPopupProfile)}
              title="Edit Profile"
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {props.read ? (
            ""
          ) : (
            <PrimaryButton
              onClick={() => setOpenPopupNumber(!openPopupNumber)}
              title="Edit Number"
            />
          )}
        </Grid>
      </Grid>

      <ProfilePopup openPopupProfile={openPopupProfile} setOpenPopupProfile={setOpenPopupProfile}>
        <CenterProfile
          updateCenterProfile={props.updateCenterProfile}
          getCenterProfile={props.getCenterProfile}
          setOpenPopupProfile={setOpenPopupProfile}
        />
      </ProfilePopup>

      <UpdateNumberPopup
        openPopupNumber={openPopupNumber}
        setOpenPopupNumber={setOpenPopupNumber}
        updateProfileNumber={props.updateProfileNumber}
        getPatientProfile={props.getCenterProfile}
        setOpenPopupOtp={() => setOpenPopupOtp(!openPopupOtp)}
      />
      <OtpPopup
        openPopupOtp={openPopupOtp}
        setOpenPopupOtp={setOpenPopupOtp}
        verifyUpdatedNumber={props.verifyUpdatedNumber}
        getPatientProfile={props.getCenterProfile}
      />
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  // centerProfile: centerProfileSelectors.isCenterProfile(state.centers.centerProfile),
});

const mapDispatchToProps = (dispatch: any) => ({
  // getCenterProfile: () => dispatch(centerProfileCalls.getCenterProfile()),
  updateCenterProfile: (centerUpdateDTO: CenterUpdateDTO) =>
    dispatch(centerProfileCalls.updateCenterProfile(centerUpdateDTO)),
  updateProfileNumber: (numberUpdateDTO: NumberUpdateDTO) =>
    dispatch(centerProfileCalls.updateNumber(numberUpdateDTO)),
  verifyUpdatedNumber: (verifyUpdatedNumber: VerifyUpdatedNumberDTO) =>
    dispatch(centerProfileCalls.verifyUpdatedNumber(verifyUpdatedNumber)),
});

export const ProfileCard = connect(mapStateToProps, mapDispatchToProps)(_ProfileCard);
