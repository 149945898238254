import React, { useEffect, useState } from "react";
import { Button, createStyles, Grid, makeStyles } from "@material-ui/core";
import CallEndIcon from "@material-ui/icons/CallEnd";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import Participant from "./Participant";
import sideImage from "../../staticResources/background/bds.png";


const useStyles = makeStyles(() =>
  createStyles({
    videoContainer: {
      position: "relative",
      width: "95vw",
      height: "100vh",
      overflow: "hidden",
    },
    leftSide: {
     flex: "0 0 30%", // Set the width of the left side (adjust as needed)
     height: "100vh", // Ensure it covers the entire height
     right: 20,
     backgroundImage: `../../staticResources/background/bds.png`, // Add your image path here
     backgroundSize: "cover", // Adjust as needed
     backgroundRepeat: "no-repeat", // Adjust as needed
   },
    callerVideo: {
      position: "absolute",
      top: 20,
      right: 20,
      zIndex: 1,
      width: "20%",
      maxWidth: "200px",
      borderRadius: "8px",
    },

    receiverVideo: {
      position: "absolute",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      zIndex: 0,
    },

    controls: {
      position: "absolute",
      bottom: 20,
      left: "50%", // Center horizontally
      transform: "translateX(-50%)", // Center horizontally
      display: "flex",
      alignItems: "center",
      zIndex: 2,
    },
    muteButton: {
      backgroundColor: "#F1F5FA",
      color: "white",
      marginRight: "20px",
    },
    endCallButton: {
      backgroundColor: "red",
      color: "white",
      borderRadius: "20px",
      minWidth: "100px",
    },
  })
);

interface RoomProps {
  roomName: any;
  room: any;
  handleLogout: any;
}

const ChatRoom = (props: RoomProps) => {
  const classes = useStyles();

  const { room, handleLogout } = props;
  const [participants, setParticipants] = useState<any[]>([]);
  const [mute, setMute] = useState<boolean>(false);

  const handlevolume = (isMute: any, participantTracks: any) => {
    setMute(!isMute);
  };

  useEffect(() => {
    const participantConnected = (participant: any) => {
      setParticipants((prevParticipants: any) => [...prevParticipants, participant]);
    };

    const participantDisconnected = (participant: any) => {
      setParticipants((prevParticipants) => prevParticipants.filter((p) => p !== participant));
    };

    room.on("participantConnected", participantConnected);
    room.on("participantDisconnected", participantDisconnected);
    room.participants.forEach(participantConnected);

    return () => {
      room.off("participantConnected", participantConnected);
      room.off("participantDisconnected", participantDisconnected);
    };
  }, [room]);

  const remoteParticipants = participants.map((participant) => (
    <Participant key={participant.sid} participant={participant} mute={mute} />
  ));

  // Determine the receiver video size based on screen size
   const receiverVideoStyle = {
    width: window.innerWidth,
    height: window.innerHeight,
    objectFit: "cover",
  };

  const aspectRatio = '56.25%'; // (9 / 16) * 100


  const receivervideoContainerStyle = {
  width: '95vw',
  height: '100vh',
  overflow: 'hidden',
  paddingTop: aspectRatio,

};

  return (
    <div className={classes.videoContainer}>
        <div className={classes.receiverVideo} style={{ width: '95vw', height: '100vh'}}>
          {remoteParticipants}
        </div>


       {room && (
         <div className={classes.callerVideo}>
           <Participant
             key={room.localParticipant.sid}
             participant={room.localParticipant}
             mute={mute}
           />
         </div>
       )}
       <div className={classes.leftSide}>
       <img className="sideImage" alt="" src={sideImage} style={{ height:  "100vh"}} loading={"lazy"} />
       </div>
       <div className={classes.controls}>
         <Button
           variant="outlined"
           startIcon={
             mute ? (
               <VolumeOffIcon style={{ color: "green" }} />
             ) : (
               <VolumeUpIcon style={{ color: "green" }} />
             )
           }
           onClick={() => handlevolume(mute, room.localParticipant)}
           className={classes.muteButton}
         />
         <Button
           variant="contained"
           startIcon={<CallEndIcon style={{ color: "white" }} />}
           onClick={handleLogout}
           className={classes.endCallButton}
         />
       </div>
     </div>
   );
 };

export default ChatRoom;
