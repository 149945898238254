import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
// import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
// import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
// import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import GradeOutlinedIcon from "@material-ui/icons/GradeOutlined";
// import { patientNotification } from "./_nav";
// import { Drawer } from "@material-ui/core";
// import { StarBorderSharp } from "@material-ui/icons";
import { IPatient, IPhysician, USER_TYPE } from "../../../store/types";
import CustomDialogBox from "../../base/Components/CustomDialogBox";
import { patientText1, patientText2 } from "./data";
import axios from "axios";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
  },
  drawer: {
    width: 240,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 240,
  },
});

type Anchor = "top" | "left" | "bottom" | "right";

export default function Navbar(props: {
  handleMenuClose: any;
  state: any;
  setState: any;
  isOpen: any;
  toggleDrawer: any;
  type: USER_TYPE;
  patientProfile: IPatient;
  getPatientProfile: () => Promise<void>;
  setNotifications: React.Dispatch<React.SetStateAction<number>>;
  fetchPhysicians: IPhysician[];
  getPhysicians: () => Promise<void>;
}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  // const [physicians, setPhysicians] = React.useState<any[]>([]);
  const [Notifications, setNotifications] = React.useState<any[]>([]);
  const [current, setCurrent] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState("");

  React.useEffect(() => {
    const getProfile = async () => {
      await props.getPhysicians();
      await props.getPatientProfile();
    };
    if (props.type === USER_TYPE.PATIENT) {
      getProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    let filtered;
    if (props.patientProfile) {
      filtered = props.fetchPhysicians.filter((phy) => {
        return props.patientProfile.filesApprovedForPhysicians?.includes(phy._id);
      });
      setNotifications(filtered);
      props.setNotifications(filtered.length);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.fetchPhysicians, props.patientProfile]);

  const handleNotification = (id: any) => {
    setOpen(true);
    setCurrent(id);
  };

  const handleSubmit = async () => {
    if (checked) {
      setErrorMsg("");
      try {
        const res = await axios.patch<IPatient>(
          `patient/patient-approved`,
          {
            toPhysician: current,
            // toPhysician:props.patientProfile.
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
            },
          }
        );
        console.log(res);
      } catch (err) {
        console.log(err);
      }
      setOpen(!open);
    } else {
      setErrorMsg("Check the condition");
    }
  };
  const handleChange = () => {
    setChecked(!checked);
    if (checked) setErrorMsg("");
  };
  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "right",
      })}
      role="presentation"
    >
      <List>
        {Notifications.map((text: any, index: any) => (
          <ListItem button key={text?._id} onClick={() => handleNotification(text?._id)}>
            {/* <ListItemIcon>
              <StarBorderSharp />{" "}
            </ListItemIcon> */}
            <ListItemText primary={`Request from ${text?.firstName}`} />
          </ListItem>
        ))}
      </List>
      <CustomDialogBox
        title="Professional Request"
        open={open}
        setOpen={setOpen}
        textA={patientText1(props.patientProfile?.firstName)}
        textB={patientText2}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        checked={checked}
        files={props.patientProfile?.files}
        errorMessage={errorMsg}
        patient={props.patientProfile}
      />
    </div>
  );

  return (
    <div>
      {/* {(['left', 'right', 'top', 'bottom'] as Anchor[]).map((anchor) => ( */}
      <React.Fragment key="right">
        {/* <PrimaryButton>Click</PrimaryButton> */}
        {/* <Button onClick={toggleDrawer("right", true)}>{"right"}</Button> */}
        {/* <SwipeableDrawer
            anchor='right'
            open={state['right']}
            onClose={toggleDrawer('right', false)}
            onOpen={toggleDrawer('right', true)}
          > */}
        <SwipeableDrawer
          // className={classes.drawer}
          open={props.state}
          onClose={props.handleMenuClose}
          onOpen={props.toggleDrawer("right", true)}
          // onOpen={toggleDrawer('right', true)}
          // close={props.isClose}
          // variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="right"
        >
          {list("right")}
        </SwipeableDrawer>
        {/* </SwipeableDrawer> */}
      </React.Fragment>
      {/* ))} */}
    </div>
  );
}
