import React from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ICenter } from "../../../../store/types/center";
import { USER_TYPE } from "../../../../store/types";
import { IPatientRequest } from "../../../../store/types/patient-request";
import { IPhysician } from "../../../../store/types/physician";
import { IPingRequest } from "../../../../store/types/ping-request";
import { CustomButtonGroup } from "../../../base/Components/CustomButtonGroup";
import { useStyles } from "../muiStyling";
import ChatInsights from "./Tracking/ChatInsights";
import Requests from "./Tracking/Requests";

interface Props {
  type: string;
  profile: IPhysician | ICenter;
  patientrequests: IPatientRequest[];
  pingrequests: IPingRequest[];
  physicians: IPhysician[];
  getProfile: () => Promise<void>;
}
export const Tracking = (props: Props) => {
  const history = useHistory();
  const handleRequestsClick = () => {
    history.push(`/${props.type?.toLowerCase()}/management/tracking/requests`);
  };
  const handleChatOrClaimedClick = () => {
    if (props.type === USER_TYPE.PHYSICIAN) history.push("/physician/portal/messages");
    else history.push("/center/management/tracking/claimed_conversation");
  };
  const handleDiscussionClick = () => {
    if (props.type === USER_TYPE.PHYSICIAN) history.push("/physician/portal/messages");
    else history.push(`/${props.type?.toLowerCase()}/management/tracking/group_dicussions`);
  };
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <div style={{ textAlign: "center", marginTop: "3.5em" }}>
        <CustomButtonGroup
          buttonType="horizontal"
          values={[
            "Requests",
            `${props.type === USER_TYPE.PHYSICIAN ? "Chat" : "Claimed Conversations"}`,
            "Discussion Groups",
          ]}
          handleClicks={[handleRequestsClick, handleChatOrClaimedClick, handleDiscussionClick]}
          style={{ textAlign: "center", marginTop: "3%" }}
        />
      </div>
      <Switch>
        <Route
          exact
          path={`/center/management/tracking/claimed_conversation`}
          component={() => (
            <ChatInsights lookingAtGroupDiscussions={false} profile={props.profile}></ChatInsights>
          )}
        />
        <Route
          exact
          path={`/${props.type?.toLowerCase()}/management/tracking/group_dicussions`}
          component={() => (
            <ChatInsights lookingAtGroupDiscussions={true} profile={props.profile}></ChatInsights>
          )}
        />
        <Route
          exact
          path={`/${props.type?.toLowerCase()}/management/tracking/requests`}
          component={() => (
            <Requests
              getProfile={props.getProfile}
              type={props.type}
              profile={props.profile}
              patientrequests={props.patientrequests}
              pingrequests={props.pingrequests}
              physicians={props.physicians}
            />
          )}
        />
        <Redirect to={`/${props.type?.toLowerCase()}/management/tracking/requests`} />
      </Switch>
    </div>
  );
};
