// export const gettingMyPingRequests = "@/PingRequests/@gettingMyPingRequests";
// export const getMyPingRequestsSuccess = "@/PingRequests/@profileSuccess";
// export const getMyPingRequestsFailure = "@/PingRequests/@getMyPingRequestsFailure";

// export const updatingPingRequest = "@/PingRequests/@updatingPingRequest";
// export const updatePingRequestsuccess = "@/PingRequests/@updatingPingRequestsuccess";
// export const updatePingRequestFailure = "@/PingRequests/@updatePingRequestFailure";

export const acceptingPingRequest = "@/PingRequests/@acceptingPingRequest";
export const acceptPingRequestsuccess = "@/PingRequests/@acceptPingRequestsuccess";
export const acceptPingRequestFailure = "@/PingRequests/@createPingRequestFailure";

export const creatingPingRequest = "@/PingRequests/@creatingPingRequest";
export const createPingRequestsuccess = "@/PingRequests/@createPingRequestsuccess";
export const createPingRequestFailure = "@/PingRequests/@createPingRequestFailure";
