import axios from "axios";
import { NewChatDTO } from "../../types/type";
import * as actions from "./actions";

export const startChat = (chatDTO: NewChatDTO) => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.startChat());
  try {
    const response = await axios.post(`/chat/new-chat`, chatDTO, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
      },
    });
    if (response.status === 201) {
      dispatch(actions.startChatSuccess(response.data));
    } else throw new Error("Could not Create new chat");
  } catch (err: any) {
    dispatch(actions.startChatFailure(err.message));
  }
};

export const getAllChats = () => async (
  dispatch: (arg0: { type: string; payload?: any }) => void
) => {
  dispatch(actions.gettingAllChats());
  try {
    const response = await axios.get(`/chat/my-chat`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("patientToken")}`,
      },
    });
    if (response.status === 200) {
      dispatch(actions.getAllChatsSuccess(response.data));
    } else throw new Error("Could not fetch chats");
  } catch (err: any) {
    dispatch(actions.getAllChatsFailure(err.message));
  }
};
