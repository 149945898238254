import "./style.css";
import "fontsource-roboto";
import MainScreen from "../../views/auth/MainScreen";
import { Grid } from "@material-ui/core";
import desktopBackImage from "../../staticResources/background/desktop.png";
// import mobileBackImage from "../../staticResources/background/mobile.png";

export const Main = () => {
  return (
    <Grid className="mainContainer" container>
      <Grid item lg={12} md={12}>
        <div className="main">
          <MainScreen />
        </div>
      </Grid>

    </Grid>
  );
};
