import { Typography, Box, FormControl, InputLabel, Select, Button } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { basicCalls, basicSelectors } from "../../store/centers/basicCalls";
import { physicianProfileCalls, physicianProfileSelectors } from "../../store/physician/profile";
import { ICenter } from "../../store/types/center";
import { PhysicianUpdateDTO } from "../../store/types/type";
import CircleSpinner from "../CircleSpinner";

interface OwnProps {
  message: string;
  denied: boolean;
  checkPhysicianApprovalState: () => Promise<void>;
}
interface StateProps {
  centers: ICenter[];
  gettingCenters: boolean;
  getCentersError: string;

  changingCenter: boolean;
  changeCenterError: string;
  centerChanged: boolean;
}
interface DispatchProps {
  getCenters: () => Promise<void>;
  changeCenter: (updateObj: PhysicianUpdateDTO) => Promise<void>;
}
type Props = StateProps & DispatchProps & OwnProps;
interface State {
  selectedCenter: string;
  changedCenter: boolean;
}
export class DeniedOrWaitingForApprovalPhysician extends Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      selectedCenter: "",
      changedCenter: false,
    };
  }

  componentDidMount = async () => {
    await this.props.getCenters();
  };
  handleCenterChange = async () => {
    if (this.state.selectedCenter) {
      await this.props.changeCenter({ center: this.state.selectedCenter });
      await this.props.checkPhysicianApprovalState();
      this.setState({
        ...this.state,
        changedCenter: true,
      });
    }
  };
  render() {
    return (
      <div className="main" style={{}}>
        <div style={{ paddingTop: "1.5em" }}>
          <Typography
            style={{
              fontWeight: "bolder",
              textShadow: "white",
              textDecoration: "none",
            }}
            variant="h2"
            gutterBottom
          >
            <a href="/home" className="logo">
              Delores
            </a>
          </Typography>
        </div>
        <Box className="container" border={"2px solid black"}>
          <h5>{this.props.message}</h5>
        </Box>
        {this.props.denied && this.props.centers.length ? (
          this.props.changingCenter ? (
            <CircleSpinner />
          ) : (
            <Box style={{ textAlign: "center", padding: "15px" }}>
              <FormControl>
                <InputLabel htmlFor="age-native-simple">Provider</InputLabel>
                <Select
                  style={{ minWidth: "350px" }}
                  fullWidth
                  native
                  defaultValue={this.props.centers[0]._id}
                  onChange={(e) => {
                    this.setState({
                      ...this.state,
                      selectedCenter: e.target.value as string,
                    });
                  }}
                  inputProps={{
                    name: "age",
                    id: "age-native-simple",
                  }}
                >
                  {this.props.centers.map((center, i) => {
                    return (
                      <option key={i} value={center._id}>
                        {center.name}
                      </option>
                    );
                  })}
                </Select>
                <Button onClick={this.handleCenterChange}>Change</Button>
              </FormControl>
            </Box>
          )
        ) : null}

        {this.props.changeCenterError || this.state.changedCenter
          ? `${this.state.selectedCenter} has been selected and awaiting for approval`
          : null}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  getCenters: () => dispatch(basicCalls.getAllCenters()),
  changeCenter: (updateObj: PhysicianUpdateDTO) =>
    dispatch(physicianProfileCalls.updatePhysicianProfile(updateObj)),
});
const mapStateToProps = (state: any) => ({
  gettingCenters: basicSelectors.gettingCenters(state.centers.basicCalls),
  centers: basicSelectors.fetchCenters(state.centers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.centers.basicCalls),

  changingCenter: physicianProfileSelectors.isUpdatingProfile(state.physicians.physicianProfile),
  changeCenterError: physicianProfileSelectors.isPhysicianProfileUpdatedFail(
    state.physicians.physicianProfile
  ),
  centerChanged: physicianProfileSelectors.isPhysicianProfileUpdated(
    state.physicians.physicianProfile
  ),
});
export default connect(mapStateToProps, mapDispatchToProps)(DeniedOrWaitingForApprovalPhysician);
