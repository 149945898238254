import React, { useEffect } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { Profile } from "../../dashboardComponents/Profile";
import Home from "../../dashboardComponents/patient/Home";
import Portal from "../../dashboardComponents/patient/Portal";
import SideBar from "../../sidebars/Sidebar";
import { useStyles } from "../routesMuiStyling";
import Appbar from "../../navbars/Appbar";
import { USER_TYPE } from "../../../store/types";
// import Explore from "../../dashboardComponents/patient/Explore";
import { ThemeProvider } from "@material-ui/core";
import { darkTheme, lightTheme } from "../../../theme/ColorPalette";
import FeedForPatient from "../../dashboardComponents/patient/FeedForPatient";
import { useDispatch } from "react-redux";
import { chatCalls as patientChatCalls } from "../../../store/patients/chat";
import { Specialties } from "../../dashboardComponents/patient/Specialties";
import { Specialties2 } from "../../dashboardComponents/patient/Specialties2";
import { MainScreen2 }  from "../../dashboardComponents/patient/MainScreen2";
import PatientLoginCard from "../../../components/authComponents/PatientLoginCard";




import { connect } from "react-redux";
import { feedSelectors } from "../../../store/feed";
import { feedCalls } from "../../../store/feed";

interface OwnProps {
  history: any;
}

interface DispatchProps {
  handleDrawerOpen: () => Promise<void>;
  // getAll: () => Promise<void>;
}
interface StateProps {
  drawerOpen: boolean;
  // handleDrawerOpen: any;
}
type Props = StateProps & DispatchProps & OwnProps;
export function _Main2(props: Props) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const [deloresTheme, setDeloresTheme]: any = React.useState(true);
  // const [open, setOpen]: any = React.useState(true);
  const handleTheme = () => {
    setDeloresTheme(!deloresTheme);
  };
  // const handleDrawerOpen = () => {
  //   if (USER_TYPE !== undefined) {
  //     setOpen(!open);
  //   }
  // };
  useEffect(() => {
    dispatch(patientChatCalls.getAllChats());
    document.body.style.background = "none";
    document.body.style.backgroundColor = "none";
  }, [dispatch]);
  return (
    <ThemeProvider theme={deloresTheme ? lightTheme : darkTheme}>
      <div className={classes.main}>
        <SideBar
          history={history}
          type={"patient"}
          open={props.drawerOpen}
          opendrawer={window.screen.width <= 668 ? props.drawerOpen : !props.drawerOpen}
          handleDrawerOpen={props.handleDrawerOpen}
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            backgroundColor: deloresTheme ? "white" : "#272822",
            color: deloresTheme ? "black" : "white",
            width: "100%",
          }}
        >
          <header className={classes.header}>

          </header>
          <div className={classes.routes}>
            <Switch>
              <Route path="/patient/specialties2/" component={Specialties2} />
              <Redirect to="/patient/specialties2" />
            </Switch>
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  handleDrawerOpen: () => dispatch(feedCalls.handleDrawerOpen()),
});

const mapStateToProps = (state: any) => ({
  drawerOpen: feedSelectors.drawerOpen(state.feeds),
});

export const Main2 = connect(mapStateToProps, mapDispatchToProps)(_Main2);
