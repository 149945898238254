/* eslint-disable array-callback-return */
import { connect } from "react-redux";
import { basicSelectors, basicCalls } from "../../../../store/supers/basicCalls";
import { ICenter, IFeed, USER_TYPE } from "../../../../store/types";
import BlockIcon from "@material-ui/icons/Block";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import MaterialTable from "material-table";
import { FreezeDTO } from "../../../../store/types/type";
import React, { useEffect, useState } from "react";
import AlertPopup from "../../../base/Components/AlertPopup";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Container,
  createStyles,
  Dialog,
  DialogTitle,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
interface OwnProps {}
interface DispatchProps {
  getCenters: () => Promise<void>;
  freezeCenters: (id: string, body: FreezeDTO) => Promise<void>;
  removeCenterFeed: (feedID: string, fileID: string | undefined) => Promise<void>;
  centerFeeds: () => Promise<void>;
}
interface StateProps {
  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;
  updatingCenters: boolean;
  updatedCenters: ICenter[];
  updateCentersError: string;
  centersFeeds: IFeed[];
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      textAlign: "center",
    },
    media: {
      height: 400,
      objectFit: "fill",
    },
    container: {
      marginTop: 30,
    },
    button: {
      margin: theme.spacing(1),
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
      width: "200px",
      height: "44px",
      borderRadius: "28px",
    },
    card: {
      maxWidth: 550,
      margin: "0 auto",
    },
    removeIcon: {
      marginTop: "10px",
      color: "#d01616",
    },
    backDrop: {
      backdropFilter: "blur(3px)",
      backgroundColor: "rgba(0,0,30,0.4)",
    },
    multiline: {
      whiteSpace: "pre-line",
    },
    feedTitle: {
      fontWeight: "bold",
    },
  })
);

type Props = OwnProps & StateProps & DispatchProps;
function CenterControl(props: Props) {
  const [openPopup, setOpenPopup] = useState(false);
  const [reason, setReason] = useState("");
  const [userID, setUserID] = useState("");
  const [open, setOpen] = React.useState(false);
  const [agreeID, setAgreeID] = React.useState({
    feedID: "",
    fileID: "" as any,
  });

  useEffect(() => {
    props.centerFeeds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const centers = props.fetchCenters.filter((cen) => {
    return cen?.approved;
  });
  const handleDelete = async (feedID: string, fileID: string | undefined) => {
    await props.removeCenterFeed(agreeID.feedID, agreeID.fileID);
    await props.centerFeeds();
    setAgreeID({ feedID: "", fileID: "" });
    setOpen(false);
  };
  const handleClickOpen = (feedID: string, fileID: string | undefined) => {
    setAgreeID({
      feedID: feedID,
      fileID: fileID,
    });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAgreeID({ feedID: "", fileID: "" });
  };

  const handlePopup = () => {
    setOpenPopup(false);
    if (userID) {
      props.freezeCenters(userID, { frozen: "1", frozenReason: reason, user: USER_TYPE.CENTER });
      props.getCenters();
    }
    props.getCenters();
  };
  const handleFreeze = (e: { preventDefault: () => void }, data: any) => {
    e.preventDefault();
    if (data?._id) {
      setOpenPopup(true);
      setUserID(data._id);
    }
  };
  const handleUnfreeze = (e: { preventDefault: () => void }, data: any) => {
    e.preventDefault();
    if (data?._id) {
      props.freezeCenters(data?._id, { frozen: "0", user: USER_TYPE.CENTER });
    }
    props.getCenters();
  };
  const handleInput = (e: { target: { value: any } }) => {
    setReason(e.target.value);
  };
  const renderFreezeIcon = () => {
    return <BlockIcon style={{ color: "red" }} />;
  };
  const renderUnFreezeIcon = () => {
    return <CheckCircleIcon style={{ color: "green" }} />;
  };
  const classes = useStyles();
  return (
    <>
      <div style={{ width: "90%", margin: "0 auto", marginTop: "4%" }}>
        <MaterialTable
          columns={[
            { title: "id", field: "id", type: "string", hidden: true },
            { title: "Name", field: "name", type: "string" },
            { title: "Email", field: "email", type: "string" },
            {
              title: "NPI",
              field: "npi",
              type: "string",
              render: (rowData) => (rowData.npi ? rowData.npi : "N/A"),
            },
            {
              title: "Freeze",
              field: "freeze",
              type: "boolean",
              render: (rowData) => (rowData.frozen ? "true" : "false"),
            },
            { title: "Phone", field: "phoneNumber", type: "string" },
            {
              title: "LoginCount",
              field: "signedInCount",
              type: "numeric",
              headerStyle: { textAlign: "center", width: 30 },
              cellStyle: { textAlign: "center", width: 30 },
            },
            {
              title: "Last SignIn",
              field: "lastLoginTime",
              type: "date",
              render: (rowData) => new Date(rowData.lastLoginTime).toLocaleDateString(),
            },
            {
              title: "Date of Joining",
              field: "createdAt",
              type: "datetime",
              render: (rowData) => new Date(rowData.createdAt).toLocaleDateString(),
            },
          ]}
          actions={[
            (rowData) => ({
              icon: renderFreezeIcon,
              tooltip: "Freeze Account",
              onClick: (e, rowData) => {
                handleFreeze(e, rowData);
                props.getCenters();
              },
              disabled: rowData.frozen,
            }),
            (rowData) => ({
              icon: renderUnFreezeIcon,
              tooltip: "Un Freeze",
              onClick: (e, rowData) => {
                handleUnfreeze(e, rowData);
                props.getCenters();
              },
              disabled: !rowData.frozen,
            }),
          ]}
          title="Provider Accounts"
          options={{
            actionsColumnIndex: 8,
            exportButton: true,
            showEmptyDataSourceMessage: true,
            searchAutoFocus: true,
            headerStyle: {
              backgroundColor: "#0d5d18",
              color: "#FFF",
              fontWeight: "bold",
            },
          }}
          data={centers}
          detailPanel={[
            {
              tooltip: "View Feeds",
              render: (rowData) => (
                <Grid container spacing={2} style={{ maxHeight: "100vh", overflow: "auto" }}>
                  {props.centersFeeds.map((data, i) => {
                    if (data.center._id === rowData._id) {
                      return (
                        <Grid item xs={12} sm={4} md={4} key={i}>
                          <Container className={classes.container}>
                            <Card className={classes.card}>
                              <CardHeader
                                avatar={
                                  <Avatar
                                    alt={data?.file?.name}
                                    src={
                                      data?.center?.files.filter((f) => f.isProfile)[0]?.url ||
                                      `https://via.placeholder.com/150?text=Profile`
                                    }
                                  />
                                }
                                title={data.title}
                                subheader={`Provider :${data?.center?.name}-${data.createdAt.slice(
                                  0,
                                  10
                                )}`}
                                classes={{
                                  title: classes.feedTitle,
                                }}
                                action={
                                  <Grid container>
                                    <Grid>
                                      <DeleteForeverIcon
                                        className={classes.removeIcon}
                                        onClick={() => handleClickOpen(data._id, data.file?._id)}
                                      />
                                    </Grid>
                                  </Grid>
                                }
                              />
                              {data?.file &&
                                (data?.file.url?.includes("mp4") ? (
                                  <video
                                    src={data.file.url}
                                    width="100%"
                                    height="100%"
                                    controls={true}
                                  />
                                ) : (
                                  <CardMedia
                                    className={classes.media}
                                    component="img"
                                    image={data.file.url}
                                    title={data.file.name}
                                  />
                                ))}
                              <CardContent>
                                <Typography
                                  variant="body2"
                                  className={classes.multiline}
                                  dangerouslySetInnerHTML={{ __html: data.description }}
                                />
                              </CardContent>
                            </Card>
                          </Container>
                        </Grid>
                      );
                    } else {
                      return null;
                    }
                  })}
                </Grid>
              ),
            },
          ]}
        />
      </div>
      <AlertPopup
        title="Freeze Account"
        text={"Enter the reason for freezing this Account."}
        open={openPopup}
        handleOpenPopup={handlePopup}
        buttonText="Submit"
        input={true}
        inputValue={reason}
        handleInputValue={handleInput}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="responsive-dialog-title">
        <DialogTitle id="responsive-dialog-title">
          {"Are you sure you want to delete this?"}
        </DialogTitle>
        <Button autoFocus onClick={handleClose}>
          Disagree
        </Button>
        <Button onClick={() => handleDelete(agreeID.feedID, agreeID.fileID)} autoFocus>
          Agree
        </Button>
      </Dialog>
    </>
  );
}
const mapDispatchToProps = (dispatch: any) => ({
  getCenters: () => dispatch(basicCalls.getCenters()),
  freezeCenters: (id: string, body: FreezeDTO) => dispatch(basicCalls.freezeAccount(id, body)),
  removeCenterFeed: (feedID: string, fileID: string | undefined) =>
    dispatch(basicCalls.removeFeedsById(feedID, fileID)),
  centerFeeds: () => dispatch(basicCalls.getAllCenterFeeds()),
});
const mapStateToProps = (state: any) => ({
  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),

  updatedCenters: basicSelectors.updatedAccount(state.supers.basicCalls),
  updatingCenters: basicSelectors.updatingAccount(state.supers.basicCalls),
  updateCentersError: basicSelectors.updateAccountError(state.supers.basicCalls),
  centersFeeds: basicSelectors.getAllCenterFeedsSuccess(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(CenterControl);
