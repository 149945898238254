import React from "react";
import { USER_TYPE } from "../../../store/types";
import { Home as CenterHome } from "../main/Home";
import FeedsUpload from "./feeds/FeedsUpload";

export default function Home() {
  const profile = "Edit your profile and settings. Upload files and control file privacy";
  const portal = "Access the messaging portal. Start a conversation with your providers";
  const manage = "Manage requests to join your network";
  const myPhysicians = "View and adjust the professional on your network";
  const Items = [
    { title: "Portal", desc: portal },
    { title: "Management", desc: manage },
    { title: "My Professional", desc: myPhysicians },
    { title: "Your Profile", desc: profile },
  ];
  return (
    <>
      <div>
        <CenterHome type={USER_TYPE.CENTER} centerItems={Items} />
        <FeedsUpload />
      </div>
    </>
  );
}
