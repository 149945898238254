import React from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import SignUpContainer from "../../containers/signup/SignupContainer";
import LoginContainer from "../../containers/login/LoginContainer";
import { useLocation } from "react-router-dom";
import { Container, Grid } from "@material-ui/core";

export default function Auth() {
  const location = useLocation();
  const [value, setValue] = React.useState(location.search ? 0 : 1);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
    // set current Auth in LS
    localStorage.setItem("tabA", JSON.stringify(newValue));
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    );
  }
  return (
    <Container maxWidth="md">
      <Grid container direction="column" style={{ minHeight: "100vh" }} justify="center">
        <Box boxShadow={3}>
          <Tabs
            value={value}
            indicatorColor="primary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
            TabIndicatorProps={{ style: { background: "#075d19" } }}
            centered
          >
            <Tab label="SignUp">
              <SignUpContainer></SignUpContainer>
            </Tab>
            <Tab label="Login">
              <LoginContainer></LoginContainer>
            </Tab>
          </Tabs>
          <TabPanel value={value} index={0}>
            <SignUpContainer />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <LoginContainer />
          </TabPanel>
        </Box>
      </Grid>
    </Container>
  );
}
