import React from "react";
import { Card, CardContent, Grid, makeStyles, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import { basicSelectors, basicCalls } from "../../../store/supers/basicCalls";
import { IAdmin, ICenter, USER_TYPE } from "../../../store/types";

const useStyles = makeStyles({
  root: {
    // minWidth: 275,
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  title: {
    fontSize: 12,
  },
  pos: {
    marginBottom: 10,
  },
});

interface OwnProps {
  userType: USER_TYPE.ADMIN | USER_TYPE.CENTER;
}
interface DispatchProps {
  getAdmins: () => Promise<void>;
  getCenters: () => Promise<void>;
}

interface StateProps {
  gettingAdmins: boolean;
  fetchAdmins: IAdmin[];
  getAdminsError: string;

  gettingCenters: boolean;
  fetchCenters: ICenter[];
  getCentersError: string;
}

type Props = OwnProps & StateProps & DispatchProps;
const Users = (props: Props) => {
  const centers = props.fetchCenters.filter((cen) => {
    return cen?.approved;
  });

  const admins = props.fetchAdmins.filter((adm) => {
    return adm?.approved;
  });

  const classes = useStyles();
  React.useEffect(() => {
    if (props.userType === USER_TYPE.ADMIN) props.getAdmins();
    if (props.userType === USER_TYPE.CENTER) props.getCenters();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        {centers &&
          props.userType === USER_TYPE.CENTER &&
          centers.map((value: ICenter, i: number) => {
            return (
              <>
                <Grid style={{ padding: "10px", width: "20%" }} key={i} item xs={12} sm={6} md={3}>
                  <Card className={classes.root} key={i}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        style={{ color: "black", fontSize: "2em" }}
                        gutterBottom
                      >
                        {`${value.name}`}
                      </Typography>
                      <Typography
                        className={classes.title}
                        style={{ color: "black", fontSize: "1em" }}
                        gutterBottom
                      >
                        {`EMAIL :${value.email}`}
                      </Typography>
                      <Typography
                        className={classes.title}
                        style={{ color: "black", fontSize: "1em" }}
                        gutterBottom
                      >
                        {`NPI :${value.npi ? value.npi : "N/A"}`}
                      </Typography>
                      <Typography
                        className={classes.title}
                        style={{ color: "black", fontSize: "1em" }}
                        gutterBottom
                      >
                        {`Address :${value.address}`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </>
            );
          })}
        {admins &&
          props.userType === USER_TYPE.ADMIN &&
          admins.map((value: IAdmin, i: number) => {
            return (
              <Grid style={{ padding: "10px", width: "20%" }} key={i} item xs={12} sm={6} md={3}>
                <Card className={classes.root} key={i}>
                  <CardContent>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "2em" }}
                      gutterBottom
                    >
                      {`${value.name}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Email :${value.email}`}
                    </Typography>
                    <Typography
                      className={classes.title}
                      style={{ color: "black", fontSize: "1em" }}
                      gutterBottom
                    >
                      {`Address :${value.address}`}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  getAdmins: () => dispatch(basicCalls.getAdmins()),
  getCenters: () => dispatch(basicCalls.getCenters()),
});
const mapStateToProps = (state: any) => ({
  fetchAdmins: basicSelectors.fetchAdmins(state.supers.basicCalls),
  gettingAdmins: basicSelectors.gettingAdmins(state.supers.basicCalls),
  getAdminsError: basicSelectors.getAdminsError(state.supers.basicCalls),

  fetchCenters: basicSelectors.fetchCenters(state.supers.basicCalls),
  gettingCenters: basicSelectors.gettingCenters(state.supers.basicCalls),
  getCentersError: basicSelectors.getCentersError(state.supers.basicCalls),
});
export default connect(mapStateToProps, mapDispatchToProps)(Users);
