// Dependencies
import * as React from "react";
import {SquarePaymentsForm, CreditCardInput } from "react-square-web-payments-sdk";

const PaymentForm = ({setVerified,setError}) => (
  <SquarePaymentsForm
    /**
     * Identifies the calling form with a verified application ID
     * generated from the Square Application Dashboard.
     */
    applicationId={process.env.SQUARE_APPLICATION_ID  || "sq0idp-4X4wwslLRfdNEv2B_Q3I0w"}
    /**
     * Invoked when payment form receives the result of a tokenize generation request.
     * The result will be a valid credit card or wallet token, or an error.
     */
    cardTokenizeResponseReceived={(token, buyer) => {
      if(token.token && token.status==="OK"){
        setVerified(true)
      }else{
        console.log("error");
        setError(true)
      }
      console.log(token);
      // console.info({ token, buyer });
    }}
    /**
     * This function enable the Strong Customer Authentication (SCA) flow
     *
     * We strongly recommend use this function to verify the buyer and
     * reduce the chance of fraudulent transactions.
     */
    createVerificationDetails={() => ({
      amount: "40.99",
      /* collected from the buyer */
      // billingContact: {
      //   addressLines: ["123 Main Street", "Apartment 1"],
      //   familyName: "Doe",
      //   givenName: "John",
      //   countryCode: "GB",
      //   city: "London",
      // },
      currencyCode: "USD",
      intent: "CHARGE",
    })}
    /**
     * Identifies the location of the merchant that is taking the payment.
     * Obtained from the Square Application Dashboard - Locations tab.
     */
    locationId={process.env.SQUARE_LOCATION_ID  || "LF94Z4P0HSNEG"}
  >
    <CreditCardInput />
  </SquarePaymentsForm>
);

export default PaymentForm;
