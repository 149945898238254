import { IFileData } from "../../types";
import * as types from "./types";

const initialState: any = {
  centerProfileCalling: false,
  centerProfile: null,
  centerProfileFailure: "",
  updateCenterFilePrivacyCalling: false,
  updateCenterFilePrivacyFailure: "",
};
export const centerProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.centerProfileCalling:
      return {
        ...state,
        centerProfileCalling: true,
        centerProfile: null,
        centerProfileFailure: "",
      };
    case types.centerProfileSuccess:
      return {
        ...state,
        centerProfileCalling: false,
        centerProfile: action.payload,
      };
    case types.centerProfileFailure:
      return {
        ...state,
        centerProfileCalling: false,
        centerProfileFailure: action.payload,
      };

    case types.centerProfileUpdating:
      return {
        ...state,
        centerProfileUpdating: true,
        centerProfileUpdateSuccess: null,
        centerProfileUpdateFailure: "",
      };
    case types.centerProfileUpdateSuccess:
      return {
        ...state,
        centerProfileUpdating: false,
        centerProfileUpdateSuccess: action.payload,
      };
    case types.centerProfileUpdateFailure:
      return {
        ...state,
        centerProfileUpdating: false,
        centerProfileUpdateFailure: action.payload,
      };
    case types.updatePhysicianRequest:
      return {
        ...state,
        updatingPhysicianRequst: true,
        // updatePhysicianRequestSuccess: undefined,
        updatePhysicianRequestFailure: "",
      };
    case types.updatePhysicianRequestSuccess:
      return {
        ...state,
        updatingPhysicianRequst: false,
        // updatePhysicianRequestSuccess: false,
        // updatePhysicianRequestFailure:false,
      };
    case types.updatePhysicianRequestFailure:
      return {
        ...state,
        updatingPhysicianRequst: false,
        // updatePhysicianRequestSuccess: false,
        updatePhysicianRequestFailure: action.payload,
      };
    case types.updateCenterFilePrivacyCalling:
      return {
        ...state,
        updateCenterFilePrivacyCalling: true,
        centerProfileFailure: "",
      };
    case types.updateCenterFilePrivacySuccess:
      return {
        ...state,
        centerProfile: {
          ...state.centerProfile,
          files: state.centerProfile.files.map((f: IFileData) => {
            if (f._id === action.payload._id) {
              return { ...f, isPublic: action.payload.isPublic };
            } else return f;
          }),
        },
        updateCenterFilePrivacyCalling: false,
      };
    case types.updateCenterFilePrivacyFailure:
      return {
        ...state,
        updateCenterFilePrivacyCalling: false,
        updateCenterFilePrivacyFailure: action.payload,
      };
    default: {
      return state;
    }
  }
};
