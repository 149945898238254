import * as types from "./types";
import { IFileData } from "../../types";

const initialState: any = {
  patientProfileCalling: false,
  patientProfile: null,
  patientProfileFailure: "",
  patientProfileUpdating: false,
  patientProfileUpdateSuccess: null,
  patientProfileUpdateFailure: "",
  updatePatientFilePrivacyCalling: false,
  updatePatientFilePrivacyFailure: "",
  searchPhysicianCalling: false,
  searchPhysicianSuccess: "",
  searchPhysicianFailure: "",
};
export const patientProfileReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case types.patientProfileCalling:
      return {
        ...state,
        patientProfileCalling: true,
        patientProfile: null,
        patientProfileFailure: "",
      };
    case types.patientProfileSuccess:
      return {
        ...state,
        patientProfileCalling: false,
        patientProfile: action.payload,
      };
    case types.patientProfileFailure:
      return {
        ...state,
        patientProfileCalling: false,
        patientProfileFailure: action.payload,
      };

    case types.patientProfileUpdating:
      return {
        ...state,
        patientProfileUpdating: true,
        patientProfileUpdateSuccess: null,
        patientProfileUpdateFailure: "",
      };
    case types.patientProfileUpdateSuccess:
      return {
        ...state,
        patientProfileUpdating: false,
        patientProfileUpdateSuccess: action.payload,
      };
    case types.patientProfileUpdateFailure:
      return {
        ...state,
        patientProfileUpdating: false,
        patientProfileUpdateFailure: action.payload,
      };
    case types.updatePatientFilePrivacyCalling:
      return {
        ...state,
        updateCenterFilePrivacyCalling: true,
        centerProfileFailure: "",
      };
    case types.updatePatientFilePrivacySuccess:
      return {
        ...state,
        patientProfile: {
          ...state.patientProfile,
          files: state.patientProfile.files.map((f: IFileData) => {
            if (f._id === action.payload._id) {
              return { ...f, isPublic: action.payload.isPublic };
            } else return f;
          }),
        },
        updateCenterFilePrivacyCalling: false,
      };
    case types.updatePatientFilePrivacyFailure:
      return {
        ...state,
        updateCenterFilePrivacyCalling: false,
        updateCenterFilePrivacyFailure: action.payload,
      };
    case types.searchPhysicianCalling:
      return {
        ...state,
        searchPhysicianCalling: true,
        searchPhysicianSuccess: null,
        searchPhysicianFailure: "",
      };
    case types.searchPhysicianSuccess:
      return {
        ...state,
        searchPhysicianCalling: false,
        searchPhysicianSuccess: action.payload,
      };
    case types.searchPhysicianFailure:
      return {
        ...state,
        searchPhysicianCalling: false,
        searchPhysicianFailure: action.payload,
      };
    default: {
      return state;
    }
  }
};
