/* eslint-disable no-new-wrappers */
import { Button, FormControl, TextField } from "@material-ui/core";
import { useStyles } from "../../dashboardComponents/patient/muiStyling";

import { useStateList } from "../../../store/types/states";
import { specialties } from "../../../store/types/states";

import { Autocomplete } from "@material-ui/lab";

//   type mapStateProps = {
//     centers: ICenter[];
//     physicians: IPhysician[] | null;
//     searchPhysicianSuccess: IPhysician[] | null;
//   };
//   type mapDispatchProps = {
//     getAllCenters: any;
//     searchPhysician: any;
//     getPhysicians: () => Promise<void>;
//   };
type ownProps = {
  classes?: any;
  centers: any;
  handleSubmit: any;
  selectedSpecialty: any;
  setSelectedSpecialty: any;
  setSelectedState: any;
  setSelectedCenter: any;
  selectedState: any;
  selectedCenter: any;
};

type Props = ownProps;
//   & mapDispatchProps & mapStateProps;

function PingSearchFields(props: Props) {
  const cardClass = useStyles();
  return (
    <div style={{ padding: "15px" }}>
      <div className={cardClass.cardMainDiv}>
        <div className={props.classes.selectDiv}>
          <div className={props.classes.innerSelectDiv}>
            <FormControl className={cardClass.formSearch}>
              <div className={props.classes.select}>
                <label className={props.classes.label}>Area Of Expertise</label>

                <Autocomplete
                  id="specialty"
                  fullWidth
                  // freeSolo
                  value={props.selectedSpecialty}
                  options={specialties.map((item: any) => item)}
                  onChange={(e, value) => {
                    props.setSelectedSpecialty(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" name="specialty" />
                  )}
                />
              </div>
              <div className={props.classes.select}>
                <label className={props.classes.label}>State</label>
                <Autocomplete
                  id="state"
                  fullWidth
                  // freeSolo
                  value={props.selectedState}
                  options={useStateList.map((item: any) => item)}
                  onChange={(e, value) => {
                    props.setSelectedState(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" name="state" />
                  )}
                />
              </div>
              <div className={props.classes.select}>
                <label className={props.classes.label}>Practice</label>
                <Autocomplete
                  id="center"
                  fullWidth
                  // freeSolo
                  value={props.selectedCenter}
                  options={props.centers.map((item: any) => item.name)}
                  onChange={(e, value: any) => {
                    props.setSelectedCenter(value);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} fullWidth variant="outlined" name="center" />
                  )}
                />
              </div>
              <div className={props.classes.btnDiv}>
                <Button
                  className={props.classes.btnStyle}
                  color="primary"
                  size="small"
                  fullWidth
                  onClick={props.handleSubmit}
                  variant="contained"
                >
                  Search
                </Button>
              </div>
            </FormControl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PingSearchFields;
