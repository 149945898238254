import { IChat } from "../../types/chat";
import * as types from "./types";

export const startChat = () => ({
  type: types.startNewChat,
});
export const startChatSuccess = (chats: IChat[]) => ({
  type: types.startNewChatSuccess,
  payload: chats,
});

export const startChatFailure = (errorMessage: string) => ({
  type: types.startNewChatFailure,
  payload: errorMessage,
});

export const gettingAllChats = () => ({
  type: types.gettingAllChats,
});
export const getAllChatsSuccess = (chats: IChat[]) => ({
  type: types.getAllChatsSuccess,
  payload: chats,
});
export const getAllChatsFailure = (errorMessage: string) => ({
  type: types.getAllChatsFailure,
  payload: errorMessage,
});

// export const registerIn = () => ({
//   type: types.registerIn
// });
// export const registerSuccess = (token: string) => ({
//   type: types.registerSuccess,
//   payload: token
// });
// export const registerFailure = (errorMessage: string) => ({
//   type: types.registerFailure,
//   payload: errorMessage
// });
