import { ICenter } from "../../types/center";
import * as types from "./types";

export const gettingAllCenters = () => ({
  type: types.gettingCenters,
});
export const getAllCentersSuccess = (centers: ICenter[]) => ({
  type: types.getCentersSuccess,
  payload: centers,
});

export const getAllCentersFailure = (errorMessage: string) => ({
  type: types.getCentersFailure,
  payload: errorMessage,
});
