import React from "react";
import { USER_TYPE } from "../../../store/types";
import { Home as SuperHome } from "../main/Home";

export default function Home() {
  const control = "Control User Accounts";
  const portal = "super Portal.";
  const manage = "Manage physician's requests.";
  const tKeeping = "Time Keeping.";
  const Items = [
    { title: "Assign Providers", desc: portal },
    { title: "Management", desc: manage },
    { title: "Control Accounts", desc: control },
    { title: "Time Keeping", desc: tKeeping },
  ];
  return (
    <>
      <SuperHome type={USER_TYPE.SUPER} superItems={Items} />
    </>
  );
}
