import React from "react";
import TextField from "@material-ui/core/TextField";
import { Button, CircularProgress, Container, Grid } from "@material-ui/core";
import { SuperSignUpDTO } from "../../store/types/type";
import { useStyles } from "./muiStyling";
import { ThemeProvider } from "@material-ui/core/styles";
import { theme } from "../base/ColorPalete";
import { Alert } from "@material-ui/lab";
export default function SuperSignupCard(props: {
  superSignUp: any;
  registeringSuper: boolean;
  superRegistered: boolean;
  errorWhileSuperRegister: string;
  whenRegisterSuccessSuper: string;
}) {
  const [superData, setSuperData] = React.useState<SuperSignUpDTO>({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const [errorState, setErrorState] = React.useState({
    name: "",
    password: "",
    email: "",
    phoneNumber: "",
    address: "",
  });
  const handleSubmit = (event: any) => {
    if (isValidated(event)) {
      props.superSignUp(superData);
      // setDisableButton(true);
      window.open("https://www.deloresdigital.com/how-to-join.html", "_blank");
    }
  };
  const isValidated = (e: any) => {
    e.preventDefault();
    // let input = superData;
    let errors = {
      name: "",
      password: "",
      email: "",
      address: "",
      phoneNumber: "",
    };
    errors.name = superData.name ? "" : "Please Enter Name";
    errors.password = superData.password ? "" : "Please Enter Password";
    if (superData.email && superData.email !== "") {
      const pattern = new RegExp(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/);
      if (!pattern.test(superData.email)) {
        errors.email = "Please Enter Valid Email";
      }
    }
    if (superData.phoneNumber && superData.phoneNumber !== "") {
      const pattern = new RegExp(/^\+[1-9]\d{10,14}$/);
      if (!pattern.test(superData.phoneNumber)) {
        errors.phoneNumber = "Please enter a valid phone number start with +1**********";
      }
    }
    errors.address = superData.address ? "" : "Please Enter your address";
    setErrorState({ ...errors });
    return Object.values(errors).every((error) => error === "");
  };

  const classes = useStyles();
  return props.registeringSuper ? (
    <CircularProgress />
  ) : (
    <Container className={classes.container} maxWidth="xs">
      <form>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Email Address"
                  type="string"
                  name="email"
                  onChange={(e) =>
                    setSuperData({ ...superData, email: e.target.value.toLocaleLowerCase() })
                  }
                  error={errorState["email"] ? true : false}
                  helperText={errorState["email"]}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  placeholder="Enter phone number starting with '+1' "
                  type="string"
                  name="phone"
                  onChange={(e) => setSuperData({ ...superData, phoneNumber: e.target.value })}
                  error={errorState["phoneNumber"] ? true : false}
                  helperText={errorState["phoneNumber"] || "include '+1' with phone number"}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Address"
                  type="string"
                  name="address"
                  onChange={(e) => setSuperData({ ...superData, address: e.target.value })}
                  error={errorState["address"] ? true : false}
                  helperText={errorState["address"]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Name"
                  type="string"
                  name="name"
                  onChange={(e) => setSuperData({ ...superData, name: e.target.value })}
                  error={errorState["name"] ? true : false}
                  helperText={errorState["name"]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label="Password"
                  type="password"
                  onChange={(e) => setSuperData({ ...superData, password: e.target.value })}
                  error={errorState["password"] ? true : false}
                  helperText={errorState["password"]}
                />
              </Grid>
              <Grid item xs={12}>
                <ThemeProvider theme={theme}>
                  <Button
                    onClick={handleSubmit}
                    color="primary"
                    fullWidth
                    type="submit"
                    variant="contained"
                    // disabled={disableButton}
                  >
                    Apply Now
                  </Button>
                </ThemeProvider>
              </Grid>
              <Grid item xs={12}>
                {props.errorWhileSuperRegister && (
                  <Alert severity="error">{props.errorWhileSuperRegister}</Alert>
                )}
                {props.whenRegisterSuccessSuper && (
                  <Alert severity="success">{props.whenRegisterSuccessSuper}</Alert>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
